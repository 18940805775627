/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
  StyledFormContainer,
  StyledFormSection,
  StyledHeader2,
  StyledSectionLeft,
  StyledSectionRight,
  Title
} from '../../../../styledComponents'
import ErrorMessage1 from '../../../common/ErrorMessage1'
import SuccessMessage from '../../../common/SuccessMessage'
import Loader from '../../../common/Loader'
import { Grid, IconButton, Typography } from '@mui/material'
import { iconMap } from '../../../../constants/iconsList'
import { useNavigate, useParams } from 'react-router-dom'
import WayPoint from '../../../common/WayPoint'

export default function RouteOptimizationForm() {
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }

  const handleBackClick = () => {
    navigate(-1)
  }
  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title item>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap['LeftArrow']} alt="LeftArrow" />
            </IconButton>
            {id ? 'Update' : 'Create'} Route Optimization
          </Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Way points</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <WayPoint setIsLoading={setIsLoading} setErrors={setErrors} />
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  )
}
