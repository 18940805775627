import axios from "axios";
import { driverApi } from "../utils/constant";

const api = axios.create({
  baseURL: driverApi,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    // Something happened in setting up the request that triggered an error
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// const fetchCustomer = async () => {
//   try {
//     const response = await api.get("/");
//     return response.data;
//   } catch (error) {
//     handleApiError(error);
//   }
// };

const fetchDriver = async (page, limit) => {
    const page1 = page==undefined ? 1 : page;
    const limit1 = limit==undefined ? 15 : limit;
    try {
      const response = await api.get(`/?page=${page1}&limit=${limit1}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchDriverByName = async (name) => {
    try {
      const response = await api.get(`/findByFullName?name=${name}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
  const fetchDriverByUserName = async (name) => {
    try {
      const response = await api.get(`/findByUserName?name=${name}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
  

  
const updateDriverStatusById = async (id, status) => {
  const body = {
    status: status,
  };
  try {
    const response = await api.put(`/${id}/status`, body);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
  
  const fetchDriverById = async (id) => {
    try {
      const response = await api.get(`/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
    
  // const fetchDriverByName = async (name) => {
  //   try {
  //     const response = await api.get(`/findByName?name=${name}`);
  //     return response.data;
  //   } catch (error) {
  //     handleApiError(error);
  //   }
  // };

const createDriver = async (driverData) => {
  try {
    const response = await api.post("/", driverData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

const updateDriver = async (id, driverData) => {
  try {
    const response = await api.put(`/${id}`, driverData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

const onPlaceChanged = (autocomplete, setMapCenter, setMapZoom) => {
    if (autocomplete !== null) {
        const place = autocomplete.getPlace();
        console.log('Autocomplete result:', place);

        if (place && place.geometry && place.geometry.location) {
            const { location } = place.geometry;
            setMapCenter({ lat: location.lat(), lng: location.lng() });
            setMapZoom(15); // Set your desired zoom level
        } else {
            console.error('Invalid place object:', place);
        }
    } else {
        console.error('Autocomplete is not loaded yet!');
    }
};

const deleteDriver = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

const handleApiResponse = async (promise) => {
  try {
    const response = await promise;
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export {
    fetchDriver,
    fetchDriverByName,
    fetchDriverByUserName,
  fetchDriverById,
  createDriver,
  updateDriver,
  deleteDriver,
  onPlaceChanged,
  handleApiResponse,
  updateDriverStatusById,
};
