/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material'
import FormField from '../../modules/common/FormField'
import FormAddressWaypoint from './FormAddressWayPoint'
import { createWayPoint } from '../../services/waypoint.service'
import FormSelect from './FormSelect'
import { fetchConsignmentsByMultipleCriteria } from '../../services/consignmentNew.service'
import { formatToLocal } from '../../utils/functions'
import { RouteOptimizationModel } from '../../model/routeOptimization.model'
import { getRoute } from '../../constants/routes'
import { useNavigate, useParams } from 'react-router-dom'
import { validateForm, validateForm2 } from '../../utils/yupValidations'
import { optimizedRouteSchema } from '../../utils/validation-schemas/routeOptimizationSchema'

// Helper function to format the address
const getFullAddress = (address) => {
  if (!address) return ''

  // Check if addressType is 'geofence' and streetOne is empty or null
  if (
    address.addressType === 'Geofence' &&
    (!address.streetOne || address.streetOne.trim() === '')
  ) {
    return address.geofenceId || '' // Return geofenceName if present
  }

  // Otherwise, return the formatted address
  return [
    address.streetOne,
    address.streetTwo,
    address.city,
    address.state,
    address.country,
    address.postalCode
  ]
    .filter((field) => field && field.trim() !== '')
    .join(', ')
}

const WayPoint = ({ setIsLoading, setErrors, onSaveSuccess }) => {
  const { id } = useParams()
  // const [isLoading, setIsLoading] = useState(isLoading1) // Loading state for API call
  const [error, setError] = useState(null) // Error state for handling API errors
  const [waypointsResponse, setWaypointsResponse] = useState()
  const [selectedConsignment, setselectedConsignment] = useState({})
  const [consignments, setConsignments] = useState([])
  const [count, setCount] = useState(0)
  const [isEmpty, setIsEmpty] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  // const [errors, setErrors] = useState([])
  const [validationErrors, setValidationErrors] = useState({})
  const [routeOptimzation, setRouteOptimization] = useState(
    RouteOptimizationModel
  )
  const navigate = useNavigate()

  useEffect(() => {
    _fetchConsignmentsByMultipleCriteria()
  }, [])

  const _fetchConsignmentsByMultipleCriteria = async (
    customerId,
    unitId,
    driverId,
    tripStatus,
    startDate,
    endDate,
    page,
    limit
  ) => {
    setIsLoading(true)

    try {
      const response = await fetchConsignmentsByMultipleCriteria(
        customerId,
        unitId,
        driverId,
        tripStatus,
        startDate,
        endDate,
        page,
        limit
      )
      setCount(response.count)

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          )
        },
        checkpoints: element.checkpoints
      }))
      setConsignments(formattedRows)
      setIsEmpty(formattedRows.length === 0) // Set empty state
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const addWaypoint = () => {
    setRouteOptimization((prevRoutes) => ({
      ...prevRoutes,
      waypoints: [...(prevRoutes.waypoints || []), { address: '' }] // Ensure waypoints exists
    }))
  }

  const handleWaypointChange = (index, value) => {
    setRouteOptimization((prevRoutes) => {
      const updatedWaypoints = [...(prevRoutes.waypoints || [])] // Ensure waypoints exists
      updatedWaypoints[index] = { ...updatedWaypoints[index], address: value } // Safely update the waypoint

      return {
        ...prevRoutes,
        waypoints: updatedWaypoints
      }
    })
  }

  const handleFieldChange = async (fieldName, value) => {
    if (fieldName == 'consignment') {
      setselectedConsignment(value)
      const pickup = getFullAddress(value?.pickupDetails?.address)
      const delivery = getFullAddress(value?.deliveryDetails?.address)
      setRouteOptimization((prevRoutes) => ({
        ...prevRoutes,
        consignmentId: value.id,
        pickup: pickup,
        delivery: delivery
      }))
      // Validate all relevant fields one by one
      const fieldNames = ['pickup', 'delivery', 'consignmentId']

      for (const name of fieldNames) {
        await validateForm2(
          {
            [name]:
              name === 'consignmentId'
                ? value.id
                : name === 'pickup'
                ? pickup
                : delivery
          },
          name,
          setValidationErrors,
          optimizedRouteSchema
        )
      }
    } else {
      setRouteOptimization((prevRoutes) => ({
        ...prevRoutes,
        [fieldName]: value
      }))
      // Validate the individual field
      await validateForm2(
        { ...routeOptimzation, [fieldName]: value }, // Updated value for validation
        fieldName,
        setValidationErrors,
        optimizedRouteSchema
      )
    }
  }

  const handleWaypointDelete = (index) => {
    setRouteOptimization((prevRoutes) => {
      const updatedWaypoints = prevRoutes.waypoints.filter(
        (_, i) => i !== index
      ) // Filter out the waypoint by index
      return {
        ...prevRoutes,
        waypoints: updatedWaypoints
      }
    })
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      // Validate the form fields
      const isValid = await validateForm(
        routeOptimzation,
        setValidationErrors,
        optimizedRouteSchema
      )
      if (!isValid) {
        console.error('Form validation failed')
        setIsLoading(false)
        return
      }

      let response
      // Perform API call based on whether id is defined or not
      if (id === undefined) {
        response = await createWayPoint(routeOptimzation)
        handleResponse(response, 'Device created successfully!', 'Card')
      }
      //   else {
      //    response = await updateDevice(device)
      //    handleResponse(response, 'Device updated successfully!', 'Card')
      //  }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
      setTimeout(() => {
        navigate(getRoute('Route Optimization', mode), {
          state: { response } // Only pass the response
        })
      }, 3000) // Closing parentheses and braces for setTimeout
    }
  }

  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }
  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  return (
    <div>
      <Grid container spacing={2}>
        {/* Non-editable Pickup Address */}
        <Grid item xs={12}>
          <FormSelect
            label="Choose Consignment"
            value={selectedConsignment}
            required
            onChange={(selected) => {
              handleFieldChange('consignment', selected)
            }}
            options={consignments.map((item) => ({
              value: item,
              label: item.customerName,
              item
            }))}
            error={!!validationErrors.consignmentId}
            errorMessage={validationErrors.consignmentId}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="Route Name"
            placeholder="Enter Waypoint Address"
            value={routeOptimzation.routeName}
            onChange={(e) => handleFieldChange('routeName', e.target.value)}
            error={!!validationErrors.routeName}
            errorMessage={validationErrors.routeName}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="Pickup Address"
            value={routeOptimzation.pickup}
            readOnly
            error={!!validationErrors.pickup}
            errorMessage={validationErrors.pickup}
          />
        </Grid>

        {/* Waypoints */}
        <FormAddressWaypoint
          waypoints={routeOptimzation.waypoints}
          handleWaypointChange={handleWaypointChange}
          handleWaypointDelete={handleWaypointDelete}
        />

        {/* Non-editable Delivery Address */}
        <Grid item xs={12}>
          <FormField
            label="Delivery Address"
            value={routeOptimzation.delivery}
            readOnly
            error={!!validationErrors.delivery}
            errorMessage={validationErrors.delivery}
          />
        </Grid>

        {waypointsResponse && (
          <Paper elevation={3} style={{ padding: '16px', margin: '16px' }}>
            <Typography variant="h5" gutterBottom>
              Optimized Route
            </Typography>
            <div style={{ marginBottom: '16px' }}>
              <Typography variant="h6" gutterBottom>
                Start Address:
              </Typography>
              <Typography variant="body1">{routeOptimzation.pickup}</Typography>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <Typography variant="h6" gutterBottom>
                End Address:
              </Typography>
              <Typography variant="body1">
                {routeOptimzation.delivery}
              </Typography>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <Typography variant="h6" gutterBottom>
                Distance:
              </Typography>
              <Typography variant="body1">
                {waypointsResponse.legs[0].distance.text}
              </Typography>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <Typography variant="h6" gutterBottom>
                Duration:
              </Typography>
              <Typography variant="body1">
                {waypointsResponse.legs[0].duration.text}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Steps:
              </Typography>
              <List>
                {waypointsResponse.legs[0].steps.map((step, index) => (
                  <React.Fragment key={index}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: step.html_instructions
                            }}
                          />
                        }
                        secondary={`Distance: ${step.distance.text}, Duration: ${step.duration.text}`}
                      />
                    </ListItem>
                    {index < waypointsResponse.legs[0].steps.length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </div>
          </Paper>
        )}

        {/* Add Waypoint and Save Button aligned in row */}
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: '#0772b8', color: '#fff' }}
                onClick={addWaypoint}
              >
                Add Waypoint
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: '#0772b8', color: '#fff' }}
                onClick={handleSave}
              >
                Optimize Route
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

WayPoint.propTypes = {
  pickupAddressDetails: PropTypes.object,
  deliveryAddressId: PropTypes.string,
  addresses: PropTypes.array, // Ensure addresses prop is passed
  onSaveSuccess: PropTypes.array
}

export default WayPoint
