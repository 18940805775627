/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Modal,
  Typography,
  MenuItem,
  Menu,
  Grid,
  Button,
} from "@mui/material";
import {
  // blockPayment,
  deletePayment,
  fetchAllPayments,
  // unblockPayment,
} from "../../../../services/paymentNew.service";
import ErrorMessage from "../../../common/ErrorMessage";
import { useNavigate, Outlet } from "react-router-dom";
import ListHeader from "../../../common/ListHeader";
import { PaymentModel } from "../../../../model/payments.model";
import { paymentColumn } from "../../../../utils/constant";
import {
  ModalContent,
  StyledIconButton,
  StyledModal,
} from "../../../../styledComponents";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmptyScreenPayment from "../../../../assets/icons/EmptyScreenPayment.svg";
import positionMarkIcon from "../../../../assets/icons/positionMarkIcon.svg";
import EditScreenEmptyState from "../../../common/EditScreenEmptyState";
import Loader from "../../../common/Loader";
import { formatDate } from "../../../../utils/functions";

export default function AccountsPaymentTable(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentsData, setPaymentsData] = useState([PaymentModel]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(props.rows);
    if (props.mode === "accounts") {
      setPaymentsData(props.rows);
    }
  }, [props.rows]);

  const getPayment = async () => {
    try {
      const data = await fetchAllPayments();
      setPaymentsData(data.rows);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const paymentDelete1 = async (event) => {
    setIsLoading(true);
    try {
      event.preventDefault();
      event.stopPropagation();
      await deletePayment(selectedPayment.id);
      setIsLoading(false);
    } catch (error) {
      if (error.response.data) {
        setErrorMessage(error.response.data.message);
        setIsLoading(false);
      } else {
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    }
    handleMenuClose();
    setOpen(false);
    getPayment();
  };

  const handleDeleteModalOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
    handleMenuClose();
  };

  const handleDeleteModalClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  };

  const handleIconButtonClick = (event, device) => {
    event.stopPropagation();
    setSelectedPayment(device);
    handleMenuOpen(event);
  };
  // const handleBlockPayment = async () => {
  //   try {
  //     await blockPayment(selectedPayment.id);
  //     // Update the 'blocked' property of the selected payment
  //     const updatedPaymentsData = paymentsData.map(payment => {
  //       if (payment.id === selectedPayment.id) {
  //         return { ...payment, blocked: true };
  //       }
  //       return payment;
  //     });
  //     setPaymentsData(updatedPaymentsData);
  //   } catch (error) {
  //     setErrorMessage(error.message);
  //   }
  //   handleMenuClose();
  //   setOpen(false);
  // };

  // const handleUnblockPayment = async () => {
  //   try {
  //     await unblockPayment(selectedPayment.id);
  //     // Update the 'blocked' property of the selected payment
  //     const updatedPaymentsData = paymentsData.map(payment => {
  //       if (payment.id === selectedPayment.id) {
  //         return { ...payment, blocked: false };
  //       }
  //       return payment;
  //     });
  //     setPaymentsData(updatedPaymentsData);
  //   } catch (error) {
  //     setErrorMessage(error.message);
  //   }
  //   handleMenuClose();
  //   setOpen(false);
  // };

  const navigateToEditScreen = (id) => {
    navigate(`/management/payments/edit/${id}`);
  };

  return (
    <Box>
      {isLoading && <Loader />}
      <Outlet />
      {props.mode !== "accounts" && (
        <ListHeader
          name="Payment"
          dataToExport={paymentsData}
          fileName="payment_data_list"
        />
      )}
      <div style={{ marginTop: "30px", overflowX: "auto" }}>
        <ErrorMessage message={errorMessage} />
        {paymentsData && paymentsData.length === 0 ? ( // Check if there are no payments
          <Typography align="center">
            <EditScreenEmptyState
              name="Payment"
              iconTop={EmptyScreenPayment}
              iconBottom={positionMarkIcon}
            />
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {paymentColumn.map((column) => (
                  <TableCell
                    style={{
                      borderBottom: "none",
                      fontSize: "14px",
                      color: "#4F636B",
                      background: "#F4F7F7",
                      padding: "5px 10px",
                    }}
                    key={column}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsData?.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => navigateToEditScreen(row.id)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    {/* {dayjs(row.billingDate, "DD-MM-YYYY").format(
                      "MMMM DD, YYYY"
                    )} */}
                    {formatDate(row.billingDate)}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    {row.lateFeeAmount} {row.lateFeeCurrency}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    {row.amount} {row.amountCurrency}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    {row.paymentMode}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    <div
                      style={{ whiteSpace: "normal", wordBreak: "break-word" }}
                    >
                      {row.description}
                    </div>
                  </TableCell>

                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    {row.blocked ? "Blocked" : "Unblocked"}
                  </TableCell>
                  <TableCell style={{ fontSize: "16px", color: "#272560" }}>
                    <Grid sx={{ alignSelf: "center" }}>
                      <StyledIconButton
                        onClick={(event) => handleIconButtonClick(event, row)}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={(e) =>
                            handleDeleteModalOpen(e, row.description)
                          }
                        >
                          Delete Payment
                        </MenuItem>
                        {/* {row.blocked ? (
                        <MenuItem onClick={handleUnblockPayment}>Unblock Payment</MenuItem>
                      ) : (
                        <MenuItem onClick={handleBlockPayment}>Block Payment</MenuItem>
                      )} */}
                      </Menu>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Modal
              open={open}
              onClose={(e) => handleDeleteModalClose(e)}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <StyledModal>
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  id="child-modal-title"
                >
                  Delete?
                </Typography>
                <ModalContent>
                  <Box sx={{ marginBottom: "15px" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ textAlign: "center", flexDirection: "column" }}
                    >
                      Are you sure you want to delete payment{" "}
                      <strong>{selectedPayment?.description}</strong>?
                    </Typography>
                  </Box>
                  <hr />
                  <Grid sx={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      onClick={(e) => paymentDelete1(e)}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        backgroundColor: "#e71111",
                        color: "#ffffff",
                        margin: "10px 0px 0px 0px",
                        "&:hover": {
                          backgroundColor: "#e71111",
                        },
                      }}
                    >
                      YES, DELETE
                    </Button>
                    <Button
                      onClick={(e) => handleDeleteModalClose(e)}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#6c6c81",
                        border: "1px solid #d8d8df",
                        marginTop: "10px",
                      }}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </ModalContent>
              </StyledModal>
            </Modal>
          </Table>
        )}
      </div>
    </Box>
  );
}
