import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { paymentColumn } from "../../../../utils/constant";
import PaymentTable from "../../payments/components/PaymentTable";
import {
  fetchAllPayments,
  // fetchPaymentsByDateRange
} from "../../../../services/paymentNew.service";
import { useNavigate } from "react-router";
import { PaymentModel } from "../../../../model/payments.model";
import Loader from "../../../common/Loader";

const styles = {
  searchBox: {
    width: "300px",
    height: "44px",
    padding: "0 12px",
    borderRadius: "8px",
    border: "solid 1px #d8d8df",
    display: "flex",
    justifyContent: "center",
    "& .MuiInput-underline:before": { borderBottom: "none" },
  },
  createBtn: {
    width: "180px",
    height: "44px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#6c6c81",
    padding: "12px 16px",
    boxShadow:
      "0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07)",
    border: "solid 1px #d8d8df",
    borderRadius: "8px",
    marginLeft: "16px",
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0)" /* transparent */,
    zIndex: "9999" /* ensure that the overlay appears on top */,
    transition: "background-color 0.5s ease-in-out 0.2", // Fade in/out transition
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0, // Initially hidden
    transition: "opacity 0.5s ease-in-out 0.2s", // Fade in/out transition with a slight delay
    zIndex: "99999",
  },
  spinnerActive: {
    opacity: 1, // Show spinner when active
  },
};

export default function AccountsPayment() {
  const [searchValue, setSearchValue] = useState("");
  const [paymentsData, setPaymentsData] = useState([PaymentModel]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPayments = async () => {
      setIsLoading(true);
      try {
        const data = await fetchAllPayments();
        setPaymentsData(data.rows);
        setIsLoading(false);
      } catch (error) {
        if (error.response.data) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error.message);
          setIsLoading(false);
        }
      }
    };
    getPayments();
  }, []);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  // const handleFilter = async () => {
  //   try {
  //     const data = await fetchPaymentsByDateRange(searchValue)
  //     console.log(data)
  //     setPaymentsData(data)
  //   } catch (error) {
  //     if (error.response && error.response.data) {
  //       console.log(error.response.data.message)
  //     } else {
  //       console.log(error.message)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   handleFilter()
  // }, [searchValue])

  const handleNavigate = () => {
    navigate("/management/payments/create");
  };

  return (
    <Box>
      {isLoading && <Loader />}
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "24px",
        }}
      >
        <Grid md={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subTitle1">Payment History</Typography>
          <Typography variant="content">
            Lorem ipsum dolor sit amet, consectetur{" "}
          </Typography>
        </Grid>
        <Grid md={6} sx={{ display: "flex" }}>
          <TextField
            variant="standard"
            placeholder="Find Payments..."
            value={searchValue}
            onChange={handleSearchChange}
            sx={styles.searchBox}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ marginRight: 1, color: "action.active" }} />
              ),
            }}
          />
          <Button sx={styles.createBtn} onClick={handleNavigate}>
            <AddIcon sx={{ marginLeft: "5px" }} />
            ADD PAYMENTS
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <PaymentTable
          rows={paymentsData}
          column={paymentColumn}
          mode="accounts"
        />
      </Grid>
    </Box>
  );
}
