/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { Grid, InputAdornment, Switch, Typography } from "@mui/material";
import {
  DoubleArrowIconContainer,
  StyledFormContainer,
  StyledInputControlContainer,
} from "../styles/BillingPlansStyles";
import { iconMap } from "../../../../constants/iconsList";
import {
  StyledCheckboxButton,
  StyledEndAdornmentTextfield,
} from "../../../../styledComponents";

const BillingPlansForm = ({ billingPlan, onFormValueChange }) => {
  const checkboxTypes = [
    { name: "Access", value: true },
    { name: "No Access", value: false },
  ];

  const checkboxLimits = [
    { name: "Unlimited", value: -1 },
    { name: "Set Limit", value: 0 },
  ];

  const addSensor = (service, value) => {
    const updatedServicesLimit = billingPlan.servicesLimit.map(
      (serviceLimit) => {
        if (serviceLimit.serviceId === service.serviceId) {
          if (value === true) {
            return { ...serviceLimit, cost: service.cost, value: value };
          } else {
            return { ...serviceLimit, value: value };
          }
        }
        return serviceLimit;
      }
    );

    // Update the billingPlan with the modified servicesLimit array
    onFormValueChange({
      ...billingPlan,
      servicesLimit: updatedServicesLimit,
    });
  };

  const addFeature = (service, value) => {
    const updatedServicesLimit = billingPlan.servicesLimit.map(
      (serviceLimit) => {
        if (serviceLimit.serviceId === service.serviceId) {
          if (value === true) {
            return {
              ...serviceLimit,
              days: service.days,
              MB: service.MB,
              value: value,
            };
          } else {
            return { ...serviceLimit, value: value };
          }
        }
        return serviceLimit;
      }
    );
    // Update the billingPlan with the modified servicesLimit array
    onFormValueChange({
      ...billingPlan,
      servicesLimit: updatedServicesLimit,
    });
  };

  const handleServiceLimitChange = (option, service) => {
    const updatedServicesLimit = billingPlan.servicesLimit.map(
      (serviceLimit) => {
        if (serviceLimit.serviceId === service.serviceId) {
          if (service.fieldType === "Checkbox") {
            return { ...serviceLimit, value: option.value };
          } else if (service.fieldType === "Limit") {
            return { ...serviceLimit, limit: parseInt(option.value) };
          } else if (service.fieldType === "Days") {
            return {
              ...serviceLimit,
              limit: parseInt(option.value),
              Days: parseInt(option.value),
            };
          } else if (service.fieldType === "MB") {
            return {
              ...serviceLimit,
              limit: parseInt(option.value),
              MB: parseInt(option.value),
            };
          }
        }
        return serviceLimit;
      }
    );

    // Update the billingPlan with the modified servicesLimit array
    onFormValueChange({
      ...billingPlan,
      servicesLimit: updatedServicesLimit,
    });
  };

  const renderServiceItems = (serviceType) => {
    return (
      billingPlan &&
      billingPlan.servicesLimit
        .filter((serviceLimit) => serviceLimit.type === serviceType)
        .map((service, index) => (
          <StyledInputControlContainer
            key={index}
            sx={{
              display: service.type === "General" ? "flex" : "grid",
              alignItems: "center",
            }}
          >
            {/* {service.type === "Sensors" &&
          <>
          <Typography variant="label">{service.serviceName}</Typography>
          </>
          } */}
            {service.type !== "Sensors" && (
              <>
                <Typography variant="label" sx={{ width: "300px" }}>
                  {service.type === "General" && (
                    <img
                      src={iconMap[service.serviceName]}
                      alt="arrow"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {service.fieldType === "Limit"
                    ? `Set ${service.serviceName} Limit`
                    : service.fieldType !== "Frequency"
                    ? service.serviceName
                    : ""}
                </Typography>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      backgroundColor: "#f7f7f8",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={1}
                  >
                    {service.fieldType === "Checkbox" && (
                      <Grid container spacing={2}>
                        {checkboxTypes.map((option, optionIndex) => (
                          <Grid item key={optionIndex}>
                            <StyledCheckboxButton
                              item
                              variant={
                                service.value === option.value
                                  ? "contained"
                                  : "outlined"
                              }
                              onClick={() =>
                                handleServiceLimitChange(option, service)
                              }
                            >
                              {option.name}
                            </StyledCheckboxButton>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {service.fieldType === "Limit" &&
                      checkboxLimits.map((option, optionIndex) => (
                        <Grid item key={optionIndex} xs="auto">
                          <StyledCheckboxButton
                            item
                            variant={
                              service.limit === option.value
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleServiceLimitChange(
                                { value: option.value },
                                service
                              )
                            }
                          >
                            {option.name}
                          </StyledCheckboxButton>
                        </Grid>
                      ))}
                    {service.fieldType === "Days" &&
                      checkboxLimits.map((option, optionIndex) => (
                        <Grid item key={optionIndex} xs="auto">
                          <StyledCheckboxButton
                            item
                            variant={
                              service.limit === option.value
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleServiceLimitChange(
                                { value: option.value },
                                service
                              )
                            }
                          >
                            {option.name}
                          </StyledCheckboxButton>
                        </Grid>
                      ))}
                    {service.fieldType === "MB" &&
                      checkboxLimits.map((option, optionIndex) => (
                        <Grid item key={optionIndex} xs="auto">
                          <StyledCheckboxButton
                            item
                            variant={
                              service.limit === option.value
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleServiceLimitChange(
                                { value: option.value },
                                service
                              )
                            }
                          >
                            {option.name}
                          </StyledCheckboxButton>
                        </Grid>
                      ))}
                    {service.fieldType === "Frequency" && (
                      <>
                        <Grid
                          container
                          flexDirection="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <Switch
                            item
                            xs="auto"
                            sx={{ marginTop: "-10px" }}
                            checked={service.value == true}
                            onChange={(event) =>
                              addFeature(service, event.target.checked)
                            }
                          />
                          <Typography item xs={4} variant="label" mr={1}>
                            {service.serviceName}
                          </Typography>
                          <Typography
                            item
                            xs="auto"
                            variant="label"
                            fontWeight="600"
                          >
                            {service.frequency}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    {service.limit !== -1 && (
                      <DoubleArrowIconContainer>
                        <img src={iconMap["Arrow"]} alt="arrow" />
                      </DoubleArrowIconContainer>
                    )}
                  </Grid>
                  <Grid item xs>
                    {service.limit !== -1 && (
                      <StyledEndAdornmentTextfield
                        variant="outlined"
                        margin="normal"
                        type="number"
                        value={service.limit}
                        onChange={(e) =>
                          handleServiceLimitChange(
                            { value: e.target.value },
                            service
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Grid className="inputAdornmentContainer">
                                <Typography variant="endAdornment">
                                  Max.{" "}
                                  {service.serviceName == "Data Bundle"
                                    ? "MB"
                                    : service.serviceName == "Tracking History"
                                    ? "Days"
                                    : service.serviceName}
                                </Typography>
                              </Grid>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            {service.type === "Sensors" && (
              <>
                <Grid
                  container
                  flexDirection="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Switch
                    item
                    xs="auto"
                    sx={{ marginTop: "-10px" }}
                    checked={service.value == true}
                    onChange={(event) =>
                      addSensor(service, event.target.checked)
                    }
                  />
                  <Typography item xs={4} variant="label" mr={1}>
                    {service.serviceName}
                  </Typography>
                  <Typography
                    item
                    xs={4}
                    variant="label"
                    mr={1}
                    fontWeight="600"
                  >
                    TZS
                  </Typography>
                  <Typography item xs="auto" variant="label" fontWeight="600">
                    {service?.cost}
                  </Typography>
                  {service.fieldType === "Frequency" && (
                    <Typography item xs="auto" variant="label" fontWeight="600">
                      / {service.frequency}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </StyledInputControlContainer>
        ))
    );
  };

  return (
    <StyledFormContainer>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">General</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("General")}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "25px" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">Management</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("Management")}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "25px" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">Monitoring</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("Monitoring")}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "25px" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">Features</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("Features")}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "25px" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">Add-ons</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("Sensors")}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "25px" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">Reports</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("Reports")}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "25px" }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "50px",
          }}
        >
          <Typography variant="heading">Alerts</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
          {renderServiceItems("Alerts")}
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};

BillingPlansForm.propTypes = {
  onFormValueChange: PropTypes.func.isRequired,
  billingPlan: PropTypes.shape({
    servicesLimit: PropTypes.arrayOf(
      PropTypes.shape({
        serviceId: PropTypes.string.isRequired,
        serviceName: PropTypes.string.isRequired,
        limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        value: PropTypes.bool.isRequired,
        cost: PropTypes.number,
        type: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default BillingPlansForm;
