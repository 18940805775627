// Function to retrieve the filter options for each feature in my search box
export const getSearchFilterList = (name) => {
  switch (name) {
    case "Billing Plan":
      return ["Name", "Account"];
    case "Account":
      return ["Name", "Billing Plan"];
    case "User":
      return ["Name", "Username", "Role"];
    case "Unit":
      return ["Name", "VIN", "Make", "Model", "Lock Id", "Serial Number"];
    case "Unit Group":
      return ["Name"];
    case "Device":
      return ["Name", "Make", "Model"];
    case "Service":
      return ["Name", "Type"];
    case "Payment":
      return ["Date"];
    case "Role":
      return ["Name"];
    case "Sim Card":
      return ["Sim #", "IMEI #", "ICCD #", "MSSIDN #", "Account Name"];
    case "Geofence":
      return ["Name"];
    case "Driver":
      return ["Name", "User Name"];
    case "Customer":
      return ["Name"];
    case "Consignment":
      return ["Customer Name", "Unit Name", "Driver Name", "Trip Status"];
    case "DriverConsignment":
      return ["Customer Name", "Unit Name", "Trip Status"];
    default:
      return [];
  }
};
