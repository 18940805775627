/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton, Grid, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  GoogleMap,
  LoadScript,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";
import { Title } from "../../drivers/styles/DriversStyles";
import {
  fetchUnitsByAccountId,
  fetchUnitsByMultipleCriteria,
} from "../../../../services/unitNew.service";
import { iconMap } from "../../../../constants/iconsList";
import {
  UnitMonitoringContainer,
  UnitMonitoringCustomSearchField,
  UnitMonitoringFilterIconCard,
  UnitMonitoringFilterIconImg,
  UnitMonitoringGoogleMapContainer,
  UnitMonitoringIconBox,
  UnitMonitoringSearchFieldBox,
  UnitMonitoringSidePanel,
  UnitMonitoringStyledCard,
  UnitMonitoringStyledCardContent,
  UnitMonitoringStyledTitleGrid,
  UnitMonitoringTotalUnits,
} from "../../../../styledComponents";
import Unit from "../components/UnitComponent";
import SelectedUnitDetails from "../components/SelectedUnitDetailsComponent";
import { useLogMessages } from "../../../common/WebsocketContext";
import Loader from "../../../common/Loader"; // Import your Loader component
import EmptyState from "../../../common/EmptyState"; // Import your EmptyState component

const UnitsMonitoringPage = () => {
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [totalUnits, setTotalUnits] = useState(0);
  const [units, setUnits] = useState([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);
  const { logMessages } = useLogMessages({});
  const [expanded, setExpanded] = useState(true);
  const [directions, setDirections] = useState(null);
  const [startPoint, setStartPoint] = useState(null);

  const [drivingSessions, setDrivingSessions] = useState([]);
  const [isDriving, setIsDriving] = useState(false);
  const [startTime, setStartTime] = useState(null);

  const endPoint = {
    lat: -7.0338392,
    lng: 39.0790001,
  };

  // useEffect(() => {
  //   if (logMessages.module === "IotDevice") {
  //     console.log("log message", logMessages.message);

  //     setSelectedUnit((prevSelectedUnit) => ({
  //       ...prevSelectedUnit,
  //       ...logMessages.message,
  //     }));
  //   }
  // }, [logMessages]);

  useEffect(() => {
    if (logMessages.module === "IotDevice") {
      console.log("log message", logMessages.message);

      const matchingUnit = units.find(
        (unit) =>
          unit.deviceDetails?.UID === logMessages.message.parsedPacket.id
      );

      if (matchingUnit) {
        const eLockDetails = { ...logMessages.message };

        setSelectedUnit((prevSelectedUnit) => ({
          ...prevSelectedUnit,
          ...matchingUnit,
          eLockDetails,
        }));

        setUnits((prevUnits) =>
          prevUnits.map((unit) => {
            if (
              unit.deviceDetails?.UID === logMessages.message.parsedPacket.id
            ) {
              return {
                ...unit,
                eLockDetails,
              };
            }
            return unit;
          })
        );

            // Check if location info is available before processing
      const locationInfo = logMessages?.message?.decodedResponse?.locationInfo;
        if (locationInfo) {
          const { dateTime, status } = locationInfo;  
          const currentTime = new Date(dateTime);
          const accStatus = status?.accStatus; // 'ACC ON' or 'ACC OFF'
  
          if (accStatus === "ACC ON" && !isDriving) {
            setIsDriving(true);
            setStartTime(currentTime);
          } else if (accStatus === "ACC OFF" && isDriving) {
            setIsDriving(false);
            if (startTime) {
              const elapsedTime = (currentTime - startTime) / (1000 * 60 * 60); // Convert to hours
              setDrivingSessions((prevSessions) => [
                ...prevSessions,
                elapsedTime,
              ]);
              setStartTime(null);
            }
          }
        } else {
          console.log("Location info not available in logMessage.");
        }
      } else {
        console.log(
          "No match found for logMessage with parsedPacket.id:",
          logMessages.message.parsedPacket.id
        );
      }
    }
  }, [logMessages, isDriving, startTime]);

  useEffect(() => {
    // Calculate total driving time
    const totalDrivingTime = drivingSessions.reduce(
      (acc, session) => acc + session,
      0
    );
    if (totalDrivingTime >= 6 && totalDrivingTime < 9) {
      console.warn("Take a break soon! You've been driving for over 6 hours.");
    } else if (totalDrivingTime >= 9) {
      console.error("Immediate break required! Over 9 hours of driving.");
    }
  }, [drivingSessions]);

  // const processData = (packet) => {
  //   const { dateTime, status } = packet.decodedResponse.locationInfo;

  //   const currentTime = new Date(dateTime);
  //   const accStatus = status.accStatus; // 'ACC ON' or 'ACC OFF'

  //   if (accStatus === "ACC ON" && !isDriving) {
  //     setIsDriving(true);
  //     setStartTime(currentTime);
  //   } else if (accStatus === "ACC OFF" && isDriving) {
  //     setIsDriving(false);
  //     if (startTime) {
  //       const elapsedTime = (currentTime - startTime) / (1000 * 60 * 60); // hours
  //       setDrivingSessions((prev) => [...prev, elapsedTime]);
  //       setStartTime(null);
  //     }
  //   }

  //   checkDrivingTime();
  // };

  // const checkDrivingTime = () => {
  //   const totalDrivingTime = drivingSessions.reduce(
  //     (acc, session) => acc + session,
  //     0
  //   );

  //   if (totalDrivingTime >= 6 && totalDrivingTime < 9) {
  //     console.warn("Take a break soon! You've been driving for over 6 hours.");
  //   } else if (totalDrivingTime >= 9) {
  //     console.error(
  //       "Stop driving immediately! You've been driving for over 9 hours."
  //     );
  //   }
  // };

  useEffect(() => {
    _fetchUnitsByMultipleCriteria();
  }, []);

  const _fetchUnitsByMultipleCriteria = async (
    unitType = "",
    id = "",
    VIN = "",
    licensePlate = "",
    make = "",
    model = "",
    deviceType = "",
    deviceId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingUnits) return; // Prevent multiple calls
    setIsLoadingUnits(true);

    try {
      let allUnits = [];
      let currentPage = page; // Start from the given page
      let totalFetched = 0;

      let data;
      do {
        data = await fetchUnitsByMultipleCriteria(
          unitType,
          id,
          VIN,
          licensePlate,
          make,
          model,
          deviceType,
          deviceId,
          currentPage,
          limit
        );
        const units = data.rows; // Directly use fetched units
        allUnits = [...allUnits, ...units]; // Combine all fetched units
        totalFetched += data.rows.length; // Keep track of the total number of fetched units
        currentPage++; // Move to the next page
      } while (totalFetched < data.count); // Continue until all units are fetched

      setUnits(allUnits); // Set all units after fetching
      setTotalUnits(data.count); // Total count of units
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching units:", errorMessage);
    } finally {
      setIsLoadingUnits(false);
    }
  };

  const handleUnitClick = async (unit) => {
    // Check for matching UID
    if (unit.deviceDetails?.UID === logMessages.message?.parsedPacket?.id) {
      const eLockDetails = { ...logMessages.message };

      // Extract latitude and longitude from eLockDetails
      const latitude = parseFloat(
        eLockDetails?.decodedResponse?.locationInfo?.latitude
      );
      const longitude = parseFloat(
        eLockDetails?.decodedResponse?.locationInfo?.longitude
      );

      // Set selected unit with eLockDetails and coordinates
      setSelectedUnit((prev) => ({
        ...prev,
        ...unit,
        eLockDetails,
        latitude, // Store latitude in selectedUnit
        longitude, // Store longitude in selectedUnit
      }));
    } else {
      // Set selected unit without eLockDetails
      setSelectedUnit(unit);
      console.log(
        "No match found for UID with parsedPacket.id:",
        logMessages.message?.parsedPacket?.id
      );
    }
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setSelectedUnit(null);
  };

  const isEmpty = units.length === 0; // Check if the units array is empty
  useEffect(() => {
    const getDirections = async () => {
      // Check if latitude and longitude are available
      const latitude =
        selectedUnit?.eLockDetails?.decodedResponse?.locationInfo?.latitude;
      const longitude =
        selectedUnit?.eLockDetails?.decodedResponse?.locationInfo?.longitude;

      if (latitude && longitude) {
        const start = {
          lat: latitude,
          lng: longitude,
        };
        setStartPoint(start);

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: new window.google.maps.LatLng(start.lat, start.lng),
            destination: new window.google.maps.LatLng(
              endPoint.lat,
              endPoint.lng
            ),
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error("Error fetching directions:", result);
            }
          }
        );
      } else {
        // If latitude or longitude is missing, clear the directions
        setDirections(null);
      }
    };

    getDirections();
  }, [selectedUnit]); // Re-run if selectedUnit changes

  return (
    <UnitMonitoringContainer>
      <UnitMonitoringSidePanel>
        <UnitMonitoringStyledCard variant="outlined">
          <UnitMonitoringStyledCardContent>
            <UnitMonitoringStyledTitleGrid>
              <Title>Monitoring Units</Title>
              <UnitMonitoringTotalUnits>{totalUnits}</UnitMonitoringTotalUnits>
            </UnitMonitoringStyledTitleGrid>
            <UnitMonitoringSearchFieldBox>
              <UnitMonitoringCustomSearchField
                variant="outlined"
                placeholder="Find Units..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <UnitMonitoringIconBox>
                <UnitMonitoringFilterIconCard variant="outlined">
                  <IconButton>
                    <UnitMonitoringFilterIconImg
                      src={iconMap["FILTERICON"]}
                      alt="filterIcon"
                    />
                  </IconButton>
                </UnitMonitoringFilterIconCard>
              </UnitMonitoringIconBox>
            </UnitMonitoringSearchFieldBox>
          </UnitMonitoringStyledCardContent>
          {isLoadingUnits && <Loader />} {/* Show loader when loading */}
          {isEmpty &&
            !isLoadingUnits && ( // Check if empty and not loading
              <Grid item xs={12}>
                <EmptyState
                  name="UNITS"
                  message="Oops! You have no units for your search criteria."
                />
              </Grid>
            )}
          {units.length > 0 && (
            <div>
              {units.map((unit) => (
                <Unit
                  key={unit.unitId} // Use a unique key for each unit
                  unitdata={unit}
                  handleUnitClick={() => handleUnitClick(unit)} // Call handleUnitClick on click
                  isSelected={selectedUnit?.id === unit.id} // Check if the unit is selected
                />
              ))}
            </div>
          )}
        </UnitMonitoringStyledCard>
      </UnitMonitoringSidePanel>

      <UnitMonitoringGoogleMapContainer>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <GoogleMap
            id="example-map"
            mapContainerStyle={{ height: "450px", width: "700px" }}
            center={startPoint || endPoint}
            zoom={12}
          >
            {/* Render custom start point marker */}
            {startPoint && (
              <Marker
                position={startPoint}
                icon={{
                  url: iconMap["UnlockedTruckMarker"], // Use the icon URL from the iconMap
                  scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
                }}
              />
            )}

            {/* Render custom end point marker */}
            {endPoint && (
              <Marker
                position={endPoint}
                sx={{ width: "15px", height: "15px" }}
                icon={{
                  url: iconMap["CustomMapMarker"], // Use the icon URL from the iconMap
                  // scaledSize: new window.google.maps.Size(15, 15),
                }}
              />
            )}

            {/* Render directions with markers suppressed */}
            {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  suppressMarkers: true, // This removes the default markers
                  polylineOptions: {
                    strokeColor: "#0772B8", // Set your desired color here (e.g., red)
                    strokeOpacity: 1.8, // Set the desired opacity (0.0 to 1.0)
                    strokeWeight: 6, // Set the desired line thickness
                  },
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>

        {selectedUnit && (
          <SelectedUnitDetails
            unit={selectedUnit}
            expanded={expanded}
            handleClose={handleClose}
            handleToggleExpand={handleToggleExpand}
            eLockDetails={selectedUnit.eLockDetails}
          />
        )}
      </UnitMonitoringGoogleMapContainer>
    </UnitMonitoringContainer>
  );
};

export default UnitsMonitoringPage;
