import { Button, Grid, Typography, Link, InputLabel, FormControlLabel, InputAdornment } from '@mui/material';
import bgRight from '../../../../../src/assets/bgRightLogin.png'; // Import the bgRight image
import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 85%;
  background-color: white; /* White background */
  padding: 7.8%;
  position: relative;
  z-index: 1; /* Ensure the form is on top of the image */
`;

export const Title = styled(Typography)`
  && {
    font-family: 'Archivo';
    font-size: 32px;
    line-height: 35.2px;
    letter-spacing: -0.64px;
    color: #0a2c47;
    margin-bottom: 10px;
    text-align: left;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-family: 'Archivo';
    font-weight: regular;
    font-size: 14px;
    line-height: 18.9px;
    color: #6c6c81;
    margin-bottom: 20px;
    text-align: left;
  }
`;

export const InputLabelStyled = styled(InputLabel)`
  && {
    font-family: 'Archivo';
    font-size: 16px;
    font-weight: regular;
    line-height: 21.6px;
    color: #0f446b;
    margin-bottom: 10px;
  }
`;

export const StyledLink = styled(Link)`
  && {
    font-family: 'Archivo';
    font-size: 12px;
    font-weight: 500;
    line-height: 16.2px;
    letter-spacing: 0.48px;
    text-transform: capitalize;
    color: #0772b8;
    cursor:pointer;
  }
`;

export const StyledButton = styled(Button)`
  && {
    height: 52px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07);
    background-color: #0772b8;
    color: #ffffff;
    font-family: 'Archivo';
    font-size: 16px;
    font-weight: 600;
    line-height: 21.6px;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 32px;
    margin-bottom: 8px;

    &:hover {
      background-color: #055c91;
    }
  }
`;

export const TermsWrapper = styled.div`
  font-family: 'Archivo';
  font-size: 12px;
  color: #6c6c81;
  text-align: center;
  padding:3px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiCheckbox-root {
      color: #0772b8;
    }

    .Mui-checked {
      color: #0772b8;
    }
  }
`;

export const LeftSection = styled(Grid)`
  && {
    padding-left: 0 !important;
  }
`;

export const RightSection = styled(Grid)`
  && {
    /* Updated to use background image */
    background-image: url(${bgRight});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 0 !important;
    padding-left: 15% !important;
    padding-right: 15% !important;
    position: relative;
    
    @media (max-width: 1280px) {
      padding-left: 10% !important;
      padding-right: 10% !important;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ErrorText = styled.span`
  font-family: 'Archivo';
  font-size: 12px;
  color: #f44336;
  display: block;
 padding:5px;
`;
export const PasswordAdornment = styled(InputAdornment)`
  && {
    color: #9e9e9e;
    cursor: pointer;
  }
`;

export const ImageOverlay = styled.img`
position: absolute;
bottom: 5%;
right: 22%;
width: 25%;

//   object-fit: cover;
//   opacity: 0.4; /* Adjust opacity as needed */
`;