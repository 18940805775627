import axios from "axios";
import { customerApi } from "../utils/constant";

const api = axios.create({
  baseURL: customerApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Fetch all customers with optional query parameters
export const fetchAllCustomers = async (queryParams) => {
  try {
    const response = await api.get("/", { params: queryParams });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a customer by its ID
export const getCustomerById = async (customerId) => {
  try {
    const response = await api.get(`/${customerId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new customer
export const createCustomer = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing customer by its ID
export const updateCustomer = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a customer by its ID
export const deleteCustomer = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch customer by name with pagination
export const fetchCustomersByNameAndAccountId = async (name, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchCustomersByNameAndAccountId", {
      params: { name, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch customer by account id with pagination
export const fetchCustomersByAccountId = async ({ page, limit }) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchCustomersByAccountId", {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchCustomersByMultipleCriteria = async (id, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchCustomersByMultipleCriteria", {
      params: {
        accountId,
        id,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
