/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { AccountModel } from "../../../../model/accounts.model";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  NextButton,
} from "../../../../styledComponents";
import dayjs from "dayjs";
import {
  validateField,
  validateForm,
  validateForm2,
} from "../../../../utils/yupValidations";
import { accountsSchema } from "../../../../utils/validation-schemas/accountsSchema";
import FormField from "../../../common/FormField";
import FormSelect from "../../../common/FormSelect";
import { fetchAllBillingPlans } from "../../../../services/billingPlanNew.service";
import FormDatepicker from "../../../common/FormDatepicker";
import SubscriptionBox from "../../../common/SubscriptionBox";
import PricingCycleSelector from "../../../common/PricingCycleSelector";
import PaymentSettingsForm from "./PaymentSettingsForm";
import {
  createAccount,
  fetchAllParentAccounts,
  getAccountById,
  updateAccount,
} from "../../../../services/accountNew.service";
import { useNavigate, useParams } from "react-router-dom";
import { updateSimCard } from "../../../../services/simCardNew.service";
import Loader from "../../../common/Loader";
import { getRoute } from "../../../../constants/routes";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import { iconMap } from "../../../../constants/iconsList";
import { formatDate } from "../../../../utils/functions";

const AccountsCreateForm = () => {
  const { id, tab } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [account, setAccount] = useState(AccountModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [billingPlans, setBillingPlans] = useState([]);
  const [limit] = useState(15);
  const today = dayjs();
  const twoDaysAhead = today.add(2, "day");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openInstallationDatePicker, setOpenInstallationDatePicker] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(twoDaysAhead);
  const [selectInstallationDate, setSelectInstallationDate] = useState(
    dayjs().add(2, "day")
  );
  const [formattedRenewalDate, setFormattedRenewalDate] = useState();
  const selectedBillingPlan = billingPlans.find(
    (plan) => plan.id === account.billingPlanId
  );
  const [selectedPaymentSetting, setPaymentSettings] = React.useState(0);
  const [billingPlanChanged, setBillingPlanChanged] = useState(false);
  const [parentAccounts, setParentAccounts] = useState([]);
  const navigate = useNavigate();
  const paymentSettingTypes = [
    { name: "Block on Due", value: 0 },
    { name: "Balance Amount", value: 1 },
    { name: "Grace Days", value: 2 },
  ];
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userData =
      localStorage.getItem("userData") !== ""
        ? JSON.parse(localStorage.getItem("userData"))
        : "";
    if (userData && userData.user.accessRights && defaultAccountId) {
      // Find the access right that matches the default account ID
      const accessRight = userData.user.accessRights.find(
        (right) => right.accountId === defaultAccountId
      );
      // Check if a matching access right was found and log the role name
      if (accessRight) {
        setUserRole(accessRight.roleName);
      } else {
        console.log("No access right found for this account");
      }
    }
  }, [defaultAccountId]);

  useEffect(() => {
    if (id && tab === "details") {
      _getAccountById(id);
    } else {
      // If it's in create mode, set the date two days ahead
      const today = dayjs(); // Get today's date
      const twoDaysAhead = today.add(2, "day"); // Calculate two days ahead

      setSelectedDate(twoDaysAhead);
      setSelectInstallationDate(twoDaysAhead);
    }
  }, [id, tab]);

  const _getAccountById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getAccountById(id);
      let paymentSettingOption;
      setAccount(data);

      // Set Payment Setting
      if (
        data.settings.blockByBalanceAmount === 0 &&
        data.settings.gracePeriod === 0
      ) {
        paymentSettingOption = 0;
      } else if (data.settings.blockByBalanceAmount > 0) {
        paymentSettingOption = 1;
      } else {
        paymentSettingOption = 2;
      }
      setPaymentSettings(paymentSettingOption);

      // Set Subscription start Date
      setSelectedDate(dayjs(data.subscriptions[0].startDate));
      setSelectInstallationDate(dayjs(data.subscriptions[0].installationDate));
      handleResponse(data, "Account fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (billingPlanChanged) {
      setAccount((prevAccount) => ({
        ...prevAccount,
        subscriptions: [
          {
            ...prevAccount.subscriptions[0],
            priceId: "",
            subscriptionStatus: "PENDING",
            pricingCycle: "",
            amount: 0,
            currency: "",
          },
        ],
      }));
    }
    setBillingPlanChanged(false);
  }, [account.billingPlanId, billingPlanChanged]);

  useEffect(() => {
    calculateRenewalDate(
      formatDate(account.subscriptions[0].startDate),
      account.subscriptions[0].pricingCycle
    );
  }, [
    account.subscriptions[0].pricingCycle,
    account.subscriptions[0].startDate,
  ]);

  useEffect(() => {
    setAccount((prevAccount) => ({
      ...prevAccount,
      subscriptions: prevAccount.subscriptions.map((subscription, index) =>
        index === 0
          ? {
              ...subscription,
              installationDate: twoDaysAhead,
              startDate: twoDaysAhead,
            }
          : subscription
      ),
    }));
  }, []);

  const handlePricingCycleChange = (name, price) => {
    setAccount((prevAccount) => ({
      ...prevAccount,
      subscriptions: [
        {
          ...prevAccount.subscriptions[0],
          priceId: price._id,
          amount: price.amount,
          currency: price.currency,
          pricingCycle: price.pricingCycle,
        },
      ],
    }));

    validateField(
      "subscriptions[0].priceId",
      price._id,
      setValidationErrors,
      accountsSchema
    );
  };

  const calculateRenewalDate = (startDate, pricingCycle) => {
    const date = new Date(startDate);

    switch (pricingCycle.toLowerCase()) {
      case "monthly":
        date.setMonth(date.getMonth() + 1); // Add 1 month
        break;
      case "quarterly":
        date.setMonth(date.getMonth() + 3); // Add 3 months
        break;
      case "halfyearly":
        date.setMonth(date.getMonth() + 6); // Add 6 months
        break;
      case "annually":
        date.setFullYear(date.getFullYear() + 1); // Add 1 year
        break;
      default:
        console.error("Invalid pricing cycle");
    }
    setFormattedRenewalDate(formatDate(date));
    setAccount((prevAccount) => ({
      ...prevAccount,
      subscriptions: prevAccount.subscriptions.map((subscription, index) =>
        index === 0 ? { ...subscription, renewalDate: date } : subscription
      ),
    }));
    return formatDate(date); // Return formatted date
  };

  // const handlePaymentSettingsChange = (name, value) => {
  //   setAccount((prevAccount) => ({
  //     ...prevAccount,
  //     settings: {
  //       ...prevAccount.settings,
  //       [name]: value,
  //     },
  //   }));
  // };

  useEffect(() => {
    _fetchAllBillingPlans();
    // if (userRole == 'System Administrator') {
    _fetchAllParentAccounts();
    // } else if (!id) {
    //   _getParentAccountById(defaultAccountId)
    // }
  }, []);

  // const _getParentAccountById = async (id) => {
  //   setIsLoading(true)
  //   try {
  //     const data = await getAccountById(id)
  //     setParentAccounts([data])
  //     setAccount({ ...account, parentAccountId: data.id })
  //     handleResponse(data, 'Account fetched successfully!', 'Get')
  //   } catch (error) {
  //     handleError(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  // const _fetchAllBillingPlans = async () => {
  //   if (isLoading) return
  //   setIsLoading(true)

  //   try {
  //     const data = await fetchAllBillingPlans({ page, limit })
  //     setBillingPlans(data.rows)
  //   } catch (error) {
  //     console.error(
  //       'Error fetching BillingPlans:',
  //       error?.message || 'An unknown error occurred'
  //     )
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  const _fetchAllBillingPlans = async () => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allBillingPlans = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllBillingPlans({ currentPage, limit });
        allBillingPlans = [...allBillingPlans, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setBillingPlans(allBillingPlans);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching billingPlans:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchAllParentAccounts = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      let allParentAccounts = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllParentAccounts({ currentPage, limit });
        allParentAccounts = [...allParentAccounts, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setParentAccounts(allParentAccounts);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching parent accounts:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // const _fetchAllParentAccounts = async () => {
  //   if (isLoading) return
  //   setIsLoading(true)
  //   try {
  //     const data = await fetchAllParentAccounts({ page, limit })
  //     setParentAccounts(data.rows)
  //   } catch (error) {
  //     console.error(
  //       'Error fetching Accounts:',
  //       error?.message || 'An unknown error occurred'
  //     )
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  const handleFieldChange = async (name, value) => {
    try {
      // Update the account state based on the field name
      setAccount((prevAccount) => {
        const updatedAccount = { ...prevAccount };
        if (name.startsWith("settings.")) {
          updatedAccount.settings = {
            ...updatedAccount.settings,
            [name.split(".")[1]]: value,
          };
        } else if (name === "billingPlanId") {
          setBillingPlanChanged(true);
          updatedAccount[name] = value;
        } else {
          updatedAccount[name] = value;
        }
        return updatedAccount;
      });

      // Validate the form field
      await validateForm2(
        { ...account, [name]: value }, // Pass updated value for validation
        name,
        setValidationErrors,
        accountsSchema
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Validate the form fields
      const isValid = await validateForm(
        account,
        setValidationErrors,
        accountsSchema
      );

      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;

      if (id === undefined) {
        response = await createAccount(account);
        handleResponse(response, "Account created successfully!", "Card");

        // if (response) {
        // if (response.simCardId != '') {
        //   var simcardDetails = {
        //     ...response.simcardDetails,
        //     accountId: account.id,
        //     status: 'ALLOCATE'
        //   }
        //   try {
        //     var simCardResponse = await updateSimCard(
        //       response.simCardId,
        //       simcardDetails
        //     )
        //     console.log(simCardResponse)
        //   } catch (error) {
        //     console.error('Failed to update sim card:', error)
        //   }
        // }
        // }
      } else {
        // eslint-disable-next-line no-unused-vars
        response = await updateAccount(id, account);
        if (response) {
          handleResponse(response, "Account updated successfully!", "Card");

          if (response.simCardId != "") {
            var simcardDetails1 = {
              ...response.simcardDetails,
              accountId: account.id,
              status: "ALLOCATE",
            };
            try {
              var simCardResponse1 = await updateSimCard(
                response.simCardId,
                simcardDetails1
              );
            } catch (error) {
              console.error("Failed to update sim card:", error);
            }
          }
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscriptionDateChange = (date) => {
    setSelectedDate(date);
    setAccount((prevAccount) => ({
      ...prevAccount,
      subscriptions: prevAccount.subscriptions.map((subscription, index) =>
        index === 0 ? { ...subscription, startDate: date } : subscription
      ),
    }));
  };

  const handleInstallationDateChange = (date) => {
    setSelectInstallationDate(date);
    setAccount((prevAccount) => ({
      ...prevAccount,
      subscriptions: prevAccount.subscriptions.map((subscription, index) =>
        index === 0 ? { ...subscription, installationDate: date } : subscription
      ),
    }));
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Account", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleBackClick = () => {
    navigate("/management/accounts");
  };

  return (
    <StyledFormContainer>
      {/* <ErrorMessage1 errors={errors} onClose={handleClose} /> */}
      {/* <SuccessMessage message={successMessage} onClose={handleClose} /> */}
      {isLoading && <Loader />}
      {id === undefined ? (
        <>
          <StyledHeader2>
            <Grid item xs={12}>
              <Title>
                <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
                  <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
                </IconButton>
                Create Account
              </Title>
            </Grid>
          </StyledHeader2>
        </>
      ) : null}
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Basic Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormSelect
            label="Choose Parent Account"
            required
            readOnly={userRole == "System Administrator" ? false : true}
            value={account?.parentAccountId}
            onChange={(e) => handleFieldChange("parentAccountId", e)}
            options={parentAccounts?.map((item) => ({
              value: item.id,
              label: `${item.name}`,
            }))}
            error={!!validationErrors.parentAccountId}
            errorMessage={validationErrors.parentAccountId}
          />
          <FormField
            label="Account Name"
            required
            placeholder="Enter account name"
            value={account.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />

          <FormSelect
            label="Choose Billing Plan"
            required
            value={account.billingPlanId}
            onChange={(selected) => {
              handleFieldChange("billingPlanId", selected);
            }}
            options={billingPlans.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            error={!!validationErrors.billingPlanId}
            errorMessage={validationErrors.billingPlanId}
          />
          {account.billingPlanId && (
            <PricingCycleSelector
              label="Choose Pricing Cycle"
              billingPlans={billingPlans}
              account={account}
              validationErrors={validationErrors}
              handlePricingCycleChange={handlePricingCycleChange}
              error={!!validationErrors.subscriptions?.[0]?.pricingCycle}
              errorMessage={validationErrors.subscriptions?.[0]?.pricingCycle}
            />
          )}
          <FormDatepicker
            label="Select Installation Date"
            required
            value={selectInstallationDate}
            onChange={handleInstallationDateChange}
            open={openInstallationDatePicker}
            onOpen={() => setOpenInstallationDatePicker(true)}
            onClose={() => setOpenInstallationDatePicker(false)}
            error={!!validationErrors.installationDate}
            errorMessage={validationErrors.installationDate}
          />
          <FormDatepicker
            label="Select Subscription Start Date"
            required
            value={selectedDate}
            onChange={handleSubscriptionDateChange}
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            error={!!validationErrors.subscriptionStartDate}
            errorMessage={validationErrors.subscriptionStartDate}
          />
          {account.subscriptions[0].pricingCycle != "" && (
            <SubscriptionBox
              selectedBillingPlan={selectedBillingPlan}
              account={account}
              formattedRenewalDate={formattedRenewalDate}
            />
          )}
        </StyledSectionRight>
      </StyledFormSection>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Settings</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          {/* <FormOptionSelector
            label="Payment Settings"
            required
            options={paymentSettingTypes}
            selectedOption={account.paymentSetting}
            handleOptionChange={(value) => handlePaymentSettingsChange('paymentSetting', value)}
          /> */}
          <PaymentSettingsForm
            account={account}
            handlePaymentSettingsChange={handleFieldChange}
            validationErrors={validationErrors}
          />
          {/* Render payment settings fields based on the selected payment setting */}
        </StyledSectionRight>
      </StyledFormSection>
      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};

export default AccountsCreateForm;
