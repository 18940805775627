import * as yup from 'yup'

const sessionsSchema = yup.object().shape({
  loginTime: yup.date().default(() => new Date()),
  expiryTime: yup.date().default(() => new Date(Date.now() + 15 * 60 * 1000)) // 15 minutes from now
})

const settingsSchema = yup.object().shape({
  measurementSystem: yup
    .string()
    .oneOf(['Metric', 'US', 'Imperial', 'Metric with gallons'])
    .default('Metric'),
  timeFormat: yup.string().oneOf(['24 Hrs', '12 Hrs']).default('24 Hrs'),
  dateFormat: yup
    .string()
    .oneOf(['dd/mm/yyyy', 'mm/dd/yyyy', 'yyyy/mm/dd'])
    .default('dd/mm/yyyy'),
  timeZone: yup.string()
})

const accessRightsSchema = yup.object().shape({
  accountId: yup.string(),
  roleId: yup.string().required('Role is required.'),
  permissions: yup.array().of(
    yup.object().shape({
      serviceId: yup.string().required('Service ID is required.'),
      serviceName: yup.string(),
      add: yup.boolean().required('Add permission is required.'),
      edit: yup.boolean().required('Edit permission is required.'),
      view: yup.boolean().required('View permission is required.'),
      delete: yup.boolean().required('Delete permission is required.'),
      serviceValue: yup.boolean(),
      type: yup.string().required('Type is required.')
    })
  )
})

const documentSchema = yup.object().shape({
  file: yup.mixed(),
  fileURL: yup.string().url(),
  isDelete: yup.boolean().default(false),
  documentType: yup
    .string()
    .oneOf(['Id', 'Passport', "Driver's License", 'Employment Letter', 'Other'])
    .default("Driver's License"),
  customerView: yup.boolean(),
  driverView: yup.boolean()
})

const breakSchema = yup.object().shape({
  startTime: yup.date(),
  endTime: yup.date(),
  totalBreakMinutes: yup.number()
})

const shiftSchema = yup.object().shape({
  startTime: yup.date(),
  endTime: yup.date(),
  breaks: yup.array().of(breakSchema),
  overallHoursWorked: yup.number(),
  overallBreakMinutes: yup.number()
})

const dailyLogsSchema = yup.object().shape({
  date: yup.date(),
  shifts: yup.array().of(shiftSchema)
})

export const usersSchema = yup.object().shape({
  selectedUser: yup.string().required('User is required.'),
  firstName: yup
    .string()
    .min(3, 'First name should be at least 3 characters.')
    .max(50, 'First name cannot exceed 50 characters.')
    .required('First name is required.'),
  lastName: yup
    .string()
    .min(3, 'Last name should be at least 3 characters.')
    .max(50, 'Last name cannot exceed 50 characters.')
    .nullable(),
  email: yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is not valid')
    .trim()
    .required('Email is required.'),
  areaCode: yup.string().required('Area code is required.'),
  phone: yup
    .string()
    .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, 'Invalid phone number format.') ///^\+(?:[0-9] ?){6,14}[0-9]$/
    .required('Phone number is required.'),
  userName: yup
    .string()
    .min(3, 'Username should be at least 3 characters.')
    .max(50, 'Username cannot exceed 50 characters.')
    .required('Username is required.'),
  password: yup
    .string()
    .trim()
    .min(3, 'Password should be at least 3 characters.')
    .required('Password is required.'),
    confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'), 
  profileImage: yup.object().shape({
    imageURL: yup.string().url(),
    isDelete: yup.boolean().default(false),
    file: yup.mixed()
  }),
  accessAllAccounts: yup.boolean().required().default(false),
  sessions: yup.array().of(sessionsSchema),
  settings: settingsSchema.required(),
  status: yup.string().oneOf(['ACTIVE', 'INACTIVE']).default('ACTIVE'),
  accessRights: yup.array().of(accessRightsSchema).required(),
  documentsURL: yup.array().of(documentSchema),
  licenseNumber: yup.string().when('accessRights', {
    is: (accessRights) =>
      accessRights.some((accessRight) => accessRight.role === 'Driver'),
    then: yup
      .string()
      .required('License number is required when role is driver.')
  }),
  licenseExpiryDate: yup.date(),
  passportNumber: yup.string(),
  passportExpiryDate: yup.date(),
  // fatiguePlanId: yup.string().when('accessRights', {
  //   is: (accessRights) =>
  //     accessRights.some((accessRight) => accessRight.role === 'Driver'),
  //   then: yup
  //     .string()
  //     .required('Fatigue Plan ID is required when role is driver.')
  // }),
  dailyLogs: yup.array().of(dailyLogsSchema),
  fcmToken: yup.array().of(yup.string()),
  webFcmToken: yup.array().of(yup.string()),
  resetPasswordToken: yup.string(),
  createdBy: yup.string(),
  updatedBy: yup.string()
})

// User schema step 1 validation
export const userSchemaCreateStep1 = yup.object().shape({
  selectedUser: yup.string().required('User is required.'),
  firstName: yup
    .string()
    .min(3, 'First name should be at least 3 characters.')
    .max(50, 'First name cannot exceed 50 characters.')
    .required('First name is required.'),
  lastName: yup
    .string()
    .min(3, 'Last name should be at least 3 characters.')
    .max(50, 'Last name cannot exceed 50 characters.')
    .required('Last name is required.'),
  email: yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is not valid')
    .required('Email is required'),
  areaCode: yup.string().required('Area code is required.'),
  phone: yup
    .string()
    .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, 'Invalid phone number format.') ///^\+(?:[0-9] ?){6,14}[0-9]$/
    .required('Phone number is required.'),
  userName: yup
    .string()
    .trim()
    .min(3, 'Username should be at least 3 characters.')
    .max(50, 'Username cannot exceed 50 characters.')
    .required('Username is required.'),
  password: yup
    .string()
    .trim()
    .min(3, 'Password should be at least 3 characters.')
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .trim()
    .min(3, 'Confirm password should be at least 3 characters.')
    .oneOf([yup.ref('password'), null], 'Confirm password must match password')
    .required('Confirm password is required.')
})

export const userSchemaEditStep1 = yup.object().shape({
  selectedUser: yup.string().required('User is required.'),
  firstName: yup
    .string()
    .min(3, 'First name should be at least 3 characters.')
    .max(50, 'First name cannot exceed 50 characters.')
    .required('First name is required.'),
  lastName: yup
    .string()
    .max(50, 'Last name cannot exceed 50 characters.')
    .nullable(),
  email: yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is not valid')
    .required('Email is required'),
  areaCode: yup.string().required('Area code is required.'),
  phone: yup
    .string()
    .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, 'Invalid phone number format.') ///^\+(?:[0-9] ?){6,14}[0-9]$/
    .required('Phone number is required.'),
  userName: yup
    .string()
    .trim()
    .min(3, 'Username should be at least 3 characters.')
    .max(50, 'Username cannot exceed 50 characters.')
    .required('Username is required.')
})

// User schema step 2 validation
export const userSchemaStep2 = yup.object().shape({
  accessRights: yup.array().of(accessRightsSchema).required(),
  settings: settingsSchema.required()
})
