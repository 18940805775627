import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
// import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledInputControlContainer, StyledTextField2, StyledTimePicker} from '../../styledComponents';
import FormError from './FormError';

const FormTimepicker = ({
  label,
  value,
  onChange,
  readOnly,
  error,
  errorMessage,
  required = false,

}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
      {label}
      {required && ' *'}
      </Typography>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledTimePicker
            value={value}
            onChange={(newValue) => {
              // Only call onChange if newValue is valid
              if (newValue) {
                onChange(newValue); // Keep the picker open until both hour and minute are selected
              }
            }}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            readOnly={readOnly}
            renderInput={(params) => (
              <StyledTextField2
                {...params}
                error={error}
                helperText={errorMessage}
                InputProps={{ ...params.InputProps, readOnly }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormTimepicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormTimepicker;