/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchUsersByRoleNameAndAccountId } from "../../../../services/userNew.service";
import FilterListIcon from "@mui/icons-material/FilterList";

const DriversFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
    userName: "",
  });
  const [drivers, setDrivers] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const roleName = "Driver";

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchUsersByRoleNameAndAccountId();
  }, [page, limit]);

  const _fetchUsersByRoleNameAndAccountId = async () => {
    if (isLoadingDrivers) return; // Prevent multiple calls
    setIsLoadingDrivers(true);

    try {
      let allDrivers = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchUsersByRoleNameAndAccountId(
          roleName,
          currentPage,
          limit
        );
        allDrivers = [...allDrivers, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setDrivers(allDrivers);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching drivers:", errorMessage);
    } finally {
      setIsLoadingDrivers(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={drivers.map((item) => ({
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="User Name"
            value={filters.userName}
            onChange={(selected) => {
              handleFieldChange("userName", selected);
            }}
            options={drivers.map((item) => ({
              value: item.userName,
              label: item.userName,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};

DriversFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default DriversFilters;
