/* eslint-disable no-unused-vars */
import { Grid, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledFormContainer } from '../../billingPlans/styles/BillingPlansStyles.js'
import PropTypes from 'prop-types'
import {
  StyledAddMoreButton,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight
} from '../../../../styledComponents.js'
import { sensorType } from '../../../../utils/constant.js'
import { iconMap } from '../../../../constants/iconsList.js'
import FormField from '../../../common/FormField.js'
import FormSelect from '../../../common/FormSelect.js'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

const UnitsForm4 = ({
  unit,
  onFormValueChange,
  validationErrors,
}) => {
  // const [fields, setFields] = useState(
  //   unit?.vehicle?.sensors || [
  //     {
  //       sensorType: '',
  //       sensorModel: '',
  //       installedLocation: '',
  //       lastReading: '',
  //     }
  //   ]
  // )

  // const handleFieldChange = (index, fieldName, value) => {
  //   const updatedFields = [...fields]
  //   updatedFields[index] = {
  //     ...updatedFields[index],
  //     [fieldName]: value,
  //     // lastReadingTimestamp: new Date().getTime()
  //   }
  //   setFields(updatedFields)
  //   // Update the unit object with the updated sensors array
  //   const updatedUnit = {
  //     ...unit,
  //     vehicle: {
  //       ...unit.vehicle,
  //       sensors: updatedFields // Update the sensors array
  //     }
  //   }

  //   // Update the state with the modified unit object
  //   onFormValueChange(updatedUnit)
  // }

  // const handleFieldChange = async (name, value) => {
  //   try {
  //     let updatedUnit = { ...unit };

  //     updatedUnit[name] = value;

  //     console.log("IN UPDATED UNIT")
  //     console.log(updatedUnit);
  //     onFormValueChange(updatedUnit, name, value, 3); // Pass step index here
  //   } catch (error) {
  //     console.error("Error in handleFieldChange:", error);
  //   }
  // };

  const handleVehicleSensorChange = async (fieldName, fieldValue, index) => {
    let updatedConsignment = { ...unit };
  
    if (fieldName === "vehicle.sensors.sensorType") {
      updatedConsignment.vehicle.sensors[index].sensorType = fieldValue;
      fieldName = `vehicle.sensors[${index}].sensorType`;
    } else if (fieldName === "vehicle.sensors.sensorModel") {
      updatedConsignment.vehicle.sensors[index].sensorModel = fieldValue;
      fieldName = `vehicle.sensors[${index}].sensorModel`;
    } else if (fieldName === "vehicle.sensors.installedLocation") {
      updatedConsignment.vehicle.sensors[index].installedLocation = fieldValue;
      fieldName = `vehicle.sensors[${index}].installedLocation`;
    } else if (fieldName === "vehicle.sensors.lastReading") {
      updatedConsignment.vehicle.sensors[index].lastReading = fieldValue;
      fieldName = `vehicle.sensors[${index}].lastReading`;
    }

    console.log(updatedConsignment);
  
    onFormValueChange(updatedConsignment, fieldName, fieldValue, 4); // Pass step index here if needed
  };
  
  const addSensor = () => {
    let updatedUnit = { ...unit };
    updatedUnit.vehicle.sensors.push({
      sensorType: '',
      sensorModel: '',
      installedLocation: '',
      lastReading: '',
    });
    onFormValueChange(updatedUnit, 'vehicle.sensors', updatedUnit.vehicle.sensors, 4);
  };

  const removeSensor = (index) => {
    // Create a deep copy of consignment to avoid mutating the original object
    let updatedUnit = JSON.parse(JSON.stringify(unit));
    
    // Remove the item at the specified index
    updatedUnit.vehicle.sensors.splice(index, 1);
    
    // Call the onFormValueChange function with the updated values
    onFormValueChange(updatedUnit, 'vehicle.sensors', updatedUnit.vehicle.sensors, 4);
  };

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Sensors</Typography>
        </StyledSectionLeft>

        <StyledSectionRight>
          {unit.vehicle.sensors.map((sensor, index) => (
            <Grid key={index} sx={{ borderBottom: '1px solid #d8d8df' }}>
              {unit.vehicle.sensors.length > 1 && (
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'end',
                    marginTop: '30px'
                  }}
                >
                  <DeleteIcon
                    onClick={() => removeSensor(index)}
                    sx={{ color: '#e71111' }}
                  />
                </Grid>
              )}
              <FormSelect
                label="Sensor Type"
                required
                value={sensor.sensorType}
                onChange={(selected) => handleVehicleSensorChange('vehicle.sensors.sensorType',selected, index)}
                options={sensorType.map((item) => ({
                  value: item.value,
                  label: item.name
                }))}
                error={!!validationErrors[`vehicle.sensors[${index}].sensorType`]}
                errorMessage={ validationErrors[`vehicle.sensors[${index}].sensorType`]}
              />
              <FormField
                label="Sensor Model"
                placeholder="Enter Sensor Model"
                value={sensor.sensorModel}
                onChange={(e) => handleVehicleSensorChange('vehicle.sensors.sensorModel', e.target.value, index)}
                error={!!validationErrors[`vehicle.sensors[${index}].sensorModel`]}
                errorMessage={ validationErrors[`vehicle.sensors[${index}].sensorModel`]}
              />
              <FormField
                label="Installed Location"
                placeholder="Enter Installed Location"
                value={sensor.installedLocation}
                onChange={(e) => handleVehicleSensorChange('vehicle.sensors.installedLocation', e.target.value, index)}
                error={!!validationErrors[`vehicle.sensors[${index}].installedLocation`]}
                errorMessage={ validationErrors[`vehicle.sensors[${index}].installedLocation`]}
              />
              {/* <FormField
                label="Last Reading"
                placeholder="Enter Last Reading"
                value={sensor.lastReading}
                onChange={(e) => handleVehicleSensorChange('vehicle.sensors.lastReading', e.target.value, index)}
                error={!!validationErrors[`vehicle.sensors[${index}].lastReading`]}
                errorMessage={ validationErrors[`vehicle.sensors[${index}].lastReading`]}
              /> */}
            </Grid>
          ))}
          <StyledAddMoreButton
            variant="outlined"
            sx={{ marginTop: '10px' }}
            onClick={addSensor}
          >
            <img src={iconMap['AddMorePlusIcon']} alt="PlusIcon" />
            ADD MORE
          </StyledAddMoreButton>
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  )
}

UnitsForm4.propTypes = {
  unit: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
}

export default UnitsForm4