import React from 'react'
import { iconMap } from '../constants/iconsList'
import dayjs from 'dayjs'

export const getUTCDate = (date) => {
  const localDate = new Date(date)
  const utcDate = new Date(
    Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
  )
  return utcDate
}

// Function for date formatting
export const formatDate2 = (date) => dayjs(date).format('MMM DD, YYYY')

export const formatToLocal = (date) =>
  dayjs.utc(date).local().format('MMM DD, YYYY')

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'short', year: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

export const formatTime = (dateString) => {
  const date = new Date(dateString)
  const options = { hour: '2-digit', minute: '2-digit', hour12: true }
  return date.toLocaleTimeString('en-US', options)
}

//Function to get the UI color for status in Accounts
export const getAccountsSubscriptionColor = (status) => {
  switch (status) {
    case 'ACTIVE':
      return '#26861d'
    case 'PENDING':
      return '#e79111'
    case 'CANCELED':
      return '#e71111'
    case 'EXPIRED':
      return '#6c6c81'
    default:
      return '#555'
  }
}

//Function to get the UI color for status in Users
export const getUsersStatusColor = (status) => {
  switch (status) {
    case 'ACTIVE':
      return '#26861d'
    case 'INACTIVE':
      return '#6c6c81'
    default:
      return '#555'
  }
}

//Function to get the UI color for status in Units
export const getUnitsStatusColor = (status) => {
  switch (status) {
    case 'ACTIVATED':
      return '#26861d'
    case 'DEACTIVATED':
      return '#6c6c81'
    default:
      return '#555'
  }
}

//Function to get the UI color for status in Consignments
export const getConsignmentsStatusColor = (tripStatus) => {
  switch (tripStatus) {
    case 'RECEIVED':
      return '#C1AF08'
    case 'UNIT_ALLOCATION':
      return '#E79111'
    case 'DRIVER_ALLOCATION':
      return '#9F65E2'
    case 'DRIVER_ACCEPTED':
      return '#26861D'
    case 'DRIVER_DECLINED':
      return '#FF3D3D'
    case 'START':
      return '#0C6E96'
    case 'AT_PICKUP':
      return '#EC4899'
    case 'LOADING_DONE':
      return '#795548'
    case 'ENROUTE':
      return '#607D8B'
    case 'ARRIVED':
      return '#18C2B0'
    case 'DELIVERED':
      return '#65A30D'
    default:
      return '#f0f8ff'
  }
}

export const getConsignmentsStatusBgColor = (tripStatus) => {
  switch (tripStatus) {
    case 'RECEIVED':
      return '#FAF5D7'
    case 'UNIT_ALLOCATION':
      return '#F2E9FB'
    case 'DRIVER_ALLOCATION':
      return '#FA1E3'
    case 'DRIVER_ACCEPTED':
      return '#DAF5D7'
    case 'DRIVER_DECLINED':
      return '#FFF0F0'
    case 'START':
      return '#D7F8FD'
    case 'AT_PICKUP':
      return '#F2E9FB'
    case 'LOADING_DONE':
      return '#EFEBE9'
    case 'ENROUTE':
      return '#ECEFF1'
    case 'ARRIVED':
      return '#DCF2EE'
    case 'DELIVERED':
      return '#EAF8CD'
    default:
      return '#f0f8ff'
  }
}

//Function to get the UI color for status in Device
export const getDevicesStatusColor = (status) => {
  switch (status) {
    case 'PROVISIONED':
      return '#26861d'
    case 'UNPROVISIONED':
      return '#6c6c81'
    default:
      return '#555'
  }
}

//Function to get the UI color for status in Sim card
export const getSimCardsStatusColor = (status) => {
  switch (status) {
    case 'ALLOCATED':
      return '#26861d'
    case 'DEALLOCATED':
      return '#6c6c81'
    default:
      return '#555'
  }
}

//Function to get the icon status in Accounts
export const getAccountsSubscriptionIcon = (status) => {
  switch (status) {
    case 'ACTIVE':
      return (
        <img
          src={iconMap['ACTIVE']}
          alt="successIcon"
          style={{ marginRight: '4px' }}
        />
      )
    case 'PENDING':
      return (
        <img
          src={iconMap['PENDING']}
          alt="pendingIcon"
          style={{ marginRight: '4px' }}
        />
      )
    case 'CANCELED':
      return (
        <img
          src={iconMap['CANCELLED']}
          alt="cancelledIcon"
          style={{ marginRight: '4px' }}
        />
      )
    case 'EXPIRED':
      return (
        <img
          src={iconMap['EXPIRED']}
          alt="expiredIcon"
          style={{ marginRight: '4px' }}
        />
      )
    default:
      return null
  }
}

//Function to convert json object to form data
export const appendFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    let value = data
    if (typeof data === 'number') {
      value = `${data}#number` // Append number suffix
    } else if (typeof data === 'boolean') {
      value = `${data}#boolean` // Append boolean suffix
    }
    formData.append(parentKey, value)
  }
}

export const getCurrentUTCDate = () =>
  dayjs().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
