/* eslint-disable no-unused-vars */
import dayjs from "dayjs";
import * as yup from "yup";

const consignmentStage = {
  RECEIVED: "Received",
  UNIT_ALLOCATION: "Allocated a Vehicle",
  DRIVER_ALLOCATION: "Allocated a Driver",
  DRIVER_ACCEPTED: "Driver Accepted",
  DRIVER_DECLINED: "Driver Declined",
  START: "Start",
  AT_PICKUP: "At Pickup",
  LOADING_DONE: "Loading Done",
  ENROUTE: "En Route",
  ARRIVED: "At Destination",
  DELIVERED: "Completed",
};

const returnPalletAddressSchema = yup.object().shape({
  addressType: yup
    .string()
    .nullable()
    .when("palletDetails.returnPallets", {
      is: (value) => value === true,
      then: (schema) =>
        schema
          .required(
            "Return address type is required when return pallets is true."
          )
          .oneOf(
            ["Geofence", "Address"],
            "Address Type must be one of the following values: Geofence, Address"
          ),
    }),
  geofenceId: yup
    .string()
    .when(
      [
        "palletDetails.returnPallets",
        "palletDetails.returnAddress.addressType",
      ],
      {
        is: (returnPallets, addressType) =>
          returnPallets === true && addressType === "Geofence",
        then: (schema) =>
          schema.required("Geofence ID is required.").nullable(false), // Set nullable to false to prevent null values
        otherwise: (schema) => schema.nullable(),
      }
    ),

  streetOne: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) =>
      schema
        .max(200, "Street one cannot exceed 200 characters.")
        .required("Street one is required."),
    otherwise: (schema) => schema.nullable(),
  }),

  streetTwo: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) =>
      schema.max(200, "Street two cannot exceed 200 characters.").nullable(),
    otherwise: (schema) => schema.nullable(),
  }),

  city: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("City is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  state: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("State is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  postalCode: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("Postal Code is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  country: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("Country is required."),
    otherwise: (schema) => schema.nullable(),
  }),
});

const palletDetailsSchema = yup.object().shape({
  returnPallets: yup.boolean().required("Return pallets is required."),
  expectedReturnDate: yup.date().when("returnPallets", {
    is: (value) => value === true,
    then: (schema) =>
      schema.required(
        "Expected return date is required when return pallets is true."
      ),
    otherwise: (schema) => schema.nullable(),
  }),
  returnAddress: returnPalletAddressSchema,
});

const cargoTypeSchema = yup.object().shape({
  name: yup.string().required("Cargo type is required."),
  // info: yup.string().required('Cargo type is required.')
});

const cargoItemSchema = yup.object().shape({
  cargoType: cargoTypeSchema.required("Cargo type is required."),
  quantity: yup
    .number()
    .nullable()
    .typeError("Quantity must be a number.")
    .min(0, "Quantity cannot be negative."),
  weight: yup
    .number()
    .nullable()
    .typeError("Weight must be a number.")
    .min(0, "Weight cannot be negative."),
  length: yup
    .number()
    .nullable()
    .typeError("Length must be a number.")
    .min(0, "Length cannot be negative."),
  breadth: yup
    .number()
    .nullable()
    .typeError("Breadth must be a number.")
    .min(0, "Breadth cannot be negative."),
  height: yup
    .number()
    .nullable()
    .typeError("Height must be a number.")
    .min(0, "Height cannot be negative."),
  cubicMetres: yup
    .number()
    .nullable()
    .typeError("Cubic metres must be a number.")
    .min(0, "Cubic metres cannot be negative."),
  description: yup
    .string()
    .max(400, "Description one cannot exceed 400 characters.")
    .nullable(),
  status: yup.string().nullable(),
  reasons: yup.array().of(yup.string()),
});

const insuranceAndCustomsDocumentSchema = yup.object().shape({
  fileURL: yup.string().url("Invalid file URL format."),
  isDelete: yup.boolean(),
  file: yup.mixed(),
  documentType: yup
    .string()
    .oneOf(["Insurance", "Customs", "Other"])
    .default("Insurance"),
  driverView: yup.boolean(),
  customerView: yup.boolean(),
});

const otherDocumentSchema = yup.object().shape({
  fileURL: yup.string().url("Invalid file URL format."),
  isDelete: yup.boolean(),
  file: yup.mixed(),
  documentType: yup
    .string()
    .oneOf(["License", "Road Permit", "Invoice", "Delivery Note", "Other"]),
  driverView: yup.boolean(),
  customerView: yup.boolean(),
});

const pickupDetailDocumentSchema = yup.object().shape({
  fileURL: yup.string().url("Invalid file URL format."),
  file: yup.mixed(),
});

const signatureSchema = yup.object().shape({
  fileURL: yup.string().url("Invalid file URL format."),
  file: yup.mixed(),
});

const deliveryDetailDocumentSchema = yup.object().shape({
  fileURL: yup.string().url("Invalid file URL format."),
  file: yup.mixed(),
});

const parseTimeString = (timeString) => {
  const [time, modifier] = timeString.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};

const deliveryScheduleSchema = yup.object().shape({
  expectedPickupDate: yup.date().required("Expected Pickup Date is required."),
  expectedDeliveryDate: yup
    .date()
    .required("Expected Delivery Date is required.")
    .test(
      "is-greater-or-equal",
      "Expected Delivery Date must be the same or later than Expected Pickup Date.",
      function (value) {
        const { expectedPickupDate } = this.parent;

        // Convert both pickup and delivery dates to UTC
        const expectedPickupDateUTC = dayjs
          .utc(expectedPickupDate)
          .format("YYYY-MM-DDTHH:mm:ss[Z]");
        const expectedDeliveryDateUTC = dayjs
          .utc(value)
          .format("YYYY-MM-DDTHH:mm:ss[Z]");

        // Compare the two dates
        return expectedDeliveryDateUTC >= expectedPickupDateUTC;
      }
    ),
  checkpoints: yup.array().of(yup.string()),
  pickupTime: yup.object().shape({
    from: yup
      .string()
      .required("The 'From' pickup time is required.")
      .test(
        "is-less-than-to",
        "The 'From' pickup time must be earlier than the 'To' pickup time.",
        function (value) {
          const { to } = this.parent;
          if (!to) return true; // Skip validation if 'to' is null or empty
          const fromMinutes = parseTimeString(value);
          const toMinutes = parseTimeString(to);
          return fromMinutes < toMinutes;
        }
      ),
    to: yup
      .string()
      .required("The 'To' pickup time is required.")
      .test(
        "is-greater-than-from",
        "Pickup time 'To' must be later than pickup time 'From'.",
        function (value) {
          const { from } = this.parent;
          if (!from) return true; // Skip validation if 'to' is null or empty
          const fromMinutes = parseTimeString(from);
          const toMinutes = parseTimeString(value);
          return toMinutes > fromMinutes;
        }
      ),
  }),
  deliveryTime: yup.object().shape({
    from: yup
      .string()
      .required("The 'From' pickup time is required.")
      .test(
        "is-greater-if-same-day",
        "Delivery time 'From' must be later than pickup time 'To' if the dates are the same.",
        function (value) {
          const { expectedPickupDate, expectedDeliveryDate, pickupTime } =
            this.options.context.deliverySchedule;
          if (
            expectedPickupDate &&
            expectedDeliveryDate &&
            expectedPickupDate === expectedDeliveryDate
          ) {
            const deliveryFromMinutes = parseTimeString(value);
            const pickupToMinutes = parseTimeString(pickupTime.to);
            return deliveryFromMinutes > pickupToMinutes;
          }
          return true;
        }
      ),
    to: yup
      .string()
      .required("The 'To' pickup time is required.")
      .test(
        "is-greater-than-from",
        "Delivery time 'To' must be later than delivery time 'From'.",
        function (value) {
          const { from } = this.parent;
          const fromMinutes = parseTimeString(from);
          const toMinutes = parseTimeString(value);
          return toMinutes > fromMinutes;
        }
      ),
  }),
});

const billingInformationSchema = yup.object().shape({
  paymentTerm: yup
    .string()
    .oneOf(
      [
        "Payment in advance",
        "Payment on delivery",
        "Payment via bank transfer",
        "Credit point",
      ],
      "Payment Terms should be one of the options"
    )
    .default("Payment in advance"),
  paymentTermValue: yup.number().nullable(),
  rateFinalized: yup.boolean(),
  currency: yup.string().nullable(),
  amount: yup
    .number()
    .nullable()
    .typeError("Amount must be a number.")
    .min(0, "Amount cannot be negative."),
  creditDays: yup
    .number()
    .nullable()
    .typeError("Credit Days must be a number")
    .min(0, "Credit Days cannot be negative"),
  VAT: yup
    .number()
    .oneOf([10, 18], "VAT has to be either 10% or 18%")
    .nullable(),
  notes: yup.string().max(400, "Notes cannot exceed 400 characters").nullable(),
});

const additionalInformationSchema = yup.object().shape({
  transportationMode: yup
    .string()
    .oneOf(
      ["Truck", "Ship", "Airplane", "Railway"],
      "Transportation Mode must be one of the options"
    )
    .required("Transportation Mode is required"),
  specialInstructions: yup
    .string()
    .max(400, "Special instruction cannot exceed 400 characters.")
    .nullable(),
});

const addressSchema = yup.object().shape({
  addressType: yup
    .string()
    .oneOf(
      ["Geofence", "Address"],
      "Address Type must be one of the following values: Geofence, Address"
    )
    .required("Address type is required"),
  geofenceId: yup.string().when("addressType", {
    is: (value) => value === "Geofence",
    then: (schema) => schema.required("Geofence ID is required."),
    otherwise: (schema) => schema.nullable(),
  }),

  streetOne: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) =>
      schema
        .max(200, "Street one cannot exceed 200 characters.")
        .required("Street one is required"),
    otherwise: (schema) => schema.nullable(),
  }),

  streetTwo: yup
    .string()
    .max(200, "Street one cannot exceed 200 characters.")
    .nullable(),
  city: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("City is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  state: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("State is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  postalCode: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("Postal Code is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  country: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("Country is required."),
    otherwise: (schema) => schema.nullable(),
  }),
});

const deliveryAddressSchema = yup.object().shape({
  addressType: yup
    .string()
    .oneOf(
      ["Geofence", "Address"],
      "Address Type must be one of the following values: Geofence, Address"
    )
    .nullable(), // Allow it to be nullable or optional
  geofenceId: yup.string().nullable(),

  streetOne: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) =>
      schema
        .max(200, "Street one cannot exceed 200 characters.")
        .required("Street one is required"),
    otherwise: (schema) => schema.nullable(),
  }),

  streetTwo: yup
    .string()
    .max(200, "Street one cannot exceed 200 characters.")
    .nullable(),
  city: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("City is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  state: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("State is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  postalCode: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("Postal Code is required."),
    otherwise: (schema) => schema.nullable(),
  }),
  country: yup.string().when("addressType", {
    is: (value) => value === "Address",
    then: (schema) => schema.required("Country is required."),
    otherwise: (schema) => schema.nullable(),
  }),
});

// Define the consignmentSchemaStep1
export const consignmentSchemaStep1 = yup.object().shape({
  customerId: yup.string().required("Customer name is required"),
  pickupDetails: yup.object().shape({
    address: addressSchema,
    name: yup
      .string()
      .min(3, "Name should be at least 3 characters")
      .max(50, "Name cannot exceed 50 characters.")
      .required("Pickup person name is required"),
    areaCode: yup.string().required(),
    phone: yup
      .string()
      .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, "Phone number is not valid")
      .required("Pickup person phone is required"),
  }),
  deliveryDetails: yup.object().shape({
    addressId: yup.string().nullable(),
    address: deliveryAddressSchema,
    name: yup
      .string()
      .min(3, "Name should be at least 3 characters")
      .max(50, "Name cannot exceed 50 characters.")
      .required("Delivery person name is required"),
    areaCode: yup.string().required(),
    phone: yup
      .string()
      .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, "Phone number is not valid")
      .required("Delivery person phone is required"),
  }),
  // optimizeRoute: yup.object().shape({
  //   pickup: yup.string().required("Pickup address is required"),
  //   delivery: yup.string().required("Delivery address is required"),
  //   waypoints: yup
  //     .array()
  //     .of(
  //       yup.object().shape({
  //         address: yup.string().required("Waypoint address is required"),
  //       })
  //     )
  //     .required("At least one waypoint is required"),
  // }),
});

// Define the consignmentSchemaStep2
export const consignmentSchemaStep2 = yup.object().shape({
  cargoDetails: yup.object().shape({
    totalWeight: yup
      .number()
      .min(0, "Total weight cannot be negative.")
      .nullable(),
    items: yup.array().of(cargoItemSchema),
  }),
  deliverySchedule: deliveryScheduleSchema,
  palletDetails: palletDetailsSchema,
});

// Define the consignmentSchemaStep3
export const consignmentSchemaStep3 = yup.object().shape({
  insuranceAndCustomsDocuments: yup
    .array()
    .of(insuranceAndCustomsDocumentSchema),
  otherDocuments: yup.array().of(otherDocumentSchema),
});

// Define the consignmentSchemaStep4
export const consignmentSchemaStep4 = yup.object().shape({
  additionalInformation: additionalInformationSchema,
  billingInformation: billingInformationSchema,
});

// Entire step
export const consignmentSchemaStep = yup.object().shape({
  //Step 1
  customerId: yup.string().required("Customer name is required"),
  pickupDetails: yup.object().shape({
    address: addressSchema,
    name: yup
      .string()
      .min(3, "Name should be at least 3 characters")
      .max(50, "Name cannot exceed 50 characters.")
      .required("Pickup person name is required"),
    areaCode: yup.string().required(),
    phone: yup
      .string()
      .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, "Phone number is not valid")
      .required("Pickup person phone is required"),
  }),
  deliveryDetails: yup.object().shape({
    addressId: yup.string().nullable(),
    address: deliveryAddressSchema,
    name: yup
      .string()
      .min(3, "Name should be at least 3 characters")
      .max(50, "Name cannot exceed 50 characters.")
      .required("Delivery person name is required"),
    areaCode: yup.string().required(),
    phone: yup
      .string()
      .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, "Phone number is not valid")
      .required("Delivery person phone is required"),
  }),
  // optimizeRoute: yup.object().shape({
  //   pickup: yup.string().required("Pickup address is required"),
  //   delivery: yup.string().required("Delivery address is required"),
  //   waypoints: yup
  //     .array()
  //     .of(
  //       yup.object().shape({
  //         address: yup.string().required("Waypoint address is required"),
  //       })
  //     )
  //     .required("At least one waypoint is required"),
  // }),

  // Step 2
  cargoDetails: yup.object().shape({
    totalWeight: yup
      .number()
      .min(0, "Total weight cannot be negative.")
      .nullable(),
    items: yup.array().of(cargoItemSchema),
  }),
  deliverySchedule: deliveryScheduleSchema,
  palletDetails: palletDetailsSchema,

  //Step 3
  insuranceAndCustomsDocuments: yup
  .array()
  .of(insuranceAndCustomsDocumentSchema),
otherDocuments: yup.array().of(otherDocumentSchema),

//Step 4
additionalInformation: additionalInformationSchema,
billingInformation: billingInformationSchema,
});
