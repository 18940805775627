/* eslint-disable no-undef */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import FormField from "./FormField";
import { Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const libraries = ["places", "geometry", "drawing"];

const FormAddressWaypoint = ({
  waypoints,
  handleWaypointChange,
  handleWaypointDelete,
}) => {
  const [autocompleteInstances, setAutocompleteInstances] = useState({});

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const onLoad = (autocomplete, index) => {
    setAutocompleteInstances((prevInstances) => ({
      ...prevInstances,
      [index]: autocomplete,
    }));
  };

  const handlePlaceChanged = (index) => {
    const autocomplete = autocompleteInstances[index];
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const addressComponents = place.address_components;

        // Concatenate all address components into a single string
        let fullAddress = "";
        addressComponents.forEach((component) => {
          fullAddress += `${component.long_name}, `;
        });

        // Remove trailing comma and space
        fullAddress = fullAddress.trim().replace(/,$/, "");

        // Update the waypoint address with the full concatenated address
        handleWaypointChange(index, fullAddress);
      } else {
        console.error("No geometry found for selected place");
      }
    } else {
      console.error("Autocomplete is not initialized");
    }
  };

  const debouncedHandlePlaceChanged = debounce(handlePlaceChanged, 300);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container spacing={2}>
      {waypoints.map((waypoint, index) => (
        <Grid
          item
          xs={12}
          sx={{ marginLeft: "16px" }}
          key={index}
          container
          alignItems="center"
        >
          <Grid item xs={11}>
            <Autocomplete
              onLoad={(autocomplete) => onLoad(autocomplete, index)}
              onPlaceChanged={() => debouncedHandlePlaceChanged(index)}
            >
              <FormField
                label={`Waypoint ${index + 1}`}
                placeholder="Enter Waypoint Address"
                value={waypoint.address}
                onChange={(e) => handleWaypointChange(index, e.target.value)}
              />
            </Autocomplete>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
            {waypoints.length > 0 && (
              <IconButton
                sx={{ color: "red" }}
                onClick={() => handleWaypointDelete(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

FormAddressWaypoint.propTypes = {
  waypoints: PropTypes.array.isRequired,
  handleWaypointChange: PropTypes.func.isRequired,
  handleWaypointDelete: PropTypes.func.isRequired,
};

export default FormAddressWaypoint;

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
