import React, { useEffect, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  IconButton
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Title } from '../styles/DriversStyles'
import ErrorMessage1 from '../../../common/ErrorMessage1'
import SuccessMessage from '../../../common/SuccessMessage'
import DriversForm1 from './DriversForm1'
import DriversForm2 from './DriversForm2'
import { DriversModel } from '../../../../model/drivers.model'
import {
  createUser,
  getUserById,
  updateUser
} from '../../../../services/userNew.service'

const steps = ['Driver Details', 'Roles & Specifications']

import dayjs from 'dayjs'
import { validateForm, validateForm2 } from '../../../../utils/yupValidations'
import {
  driverSchema,
  driverSchemaCreateStep1,
  driverSchemaEditStep1,
  driverSchemaStep2
} from '../../../../utils/validation-schemas/driversSchema'
import Loader from '../../../common/Loader'
import { appendFormData } from '../../../../utils/functions'
import { BackButton, NextButton } from '../../../../styledComponents'
import { getRoute } from '../../../../constants/routes'
import { iconMap } from '../../../../constants/iconsList'

const DriversForm = () => {
  const { id } = useParams()
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [driver, setDriver] = useState(DriversModel)
  const [validationErrors, setValidationErrors] = useState({})
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [defaultsSet, setDefaultsSet] = useState(false)
  const defaultAccountId =
    localStorage.getItem('defaultAccountId') !== ''
      ? JSON.parse(localStorage.getItem('defaultAccountId'))
      : ''
  const validationSchemas = {
    1: driverSchemaCreateStep1,
    2: driverSchemaEditStep1,
    3: driverSchemaStep2
  }

  useEffect(() => {
    if (id) {
      _getDriverById(id)
    } else {
      fetchServices()
      setDefaults()
    } 
  }, [id])

  // useEffect(() => {
  //   if (id == undefined) {
  //     fetchServices()
  //   } else {
  //     fetchDriver()
  //   }
  // }, [])

  //Map Access rights to Billing Plans
  const fetchServices = async () => {
    try {
      if (driver.type == 'New Driver') {
        setDriver({
          ...driver
        })
      } else {
        setDriver({
          ...driver
        })
      }
      console.log(driver)
    } catch (error) {
      // handleFetchError(error);
    }
  }

  const _getDriverById = async (id) => {
    setIsLoading(true)
    try {
      const data = await getUserById(id)
      var driver = {
        ...data,
        selectedDriver: data.firstName + ' ' + data.lastName
      }
      setDriver(driver)
      handleResponse(driver, 'Driver fetched successfully!', 'Get')
      // await fetchService();
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const setDefaults = () => {
    const updatedDriver = {
      ...driver,
      accountId: defaultAccountId
    }
    setDriver(updatedDriver)
    setDefaultsSet(true)
  }
  //To store values changed from form steps
  const handleValueChange = async (
    updatedDriver,
    fieldName,
    fieldValue,
    step
  ) => {
    setDriver((prevPlan) => ({
      ...prevPlan,
      selectedDriver: updatedDriver.firstName + ' ' + updatedDriver.lastName,
      ...updatedDriver
    }))

    const schema = validationSchemas[step]
    try {
      await validateForm2(updatedDriver, fieldName, setValidationErrors, schema)
    } catch (error) {
      handleError(error)
    }
  }

  //To move to next step
  const handleNext = async () => {
    try {
      let isValid
      if (activeStep === 0) {
        if (driver.type == 'New Driver') {
          isValid = await validateForm(
            driver,
            setValidationErrors,
            validationSchemas[1]
          )
        } else {
          isValid = await validateForm(
            driver,
            setValidationErrors,
            validationSchemas[2]
          )
        }
      }
      // Proceed to the next step if there are no errors
      if (isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      } else {
        console.error('Form validation failed')
      }
    } catch (error) {
      handleError(error)
    }
  }

  //To move to previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  //Function to submit form to create or update driver
  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const isValid = await validateForm(
        driver,
        setValidationErrors,
        validationSchemas[3]
      )
      if (!isValid) {
        console.error('Form validation failed')
        setIsLoading(false)
        return
      }

        driver.licenseExpiryDate = dayjs(driver.licenseExpiryDate).toISOString()
        driver.passportExpiryDate = dayjs(
          driver.passportExpiryDate
        ).toISOString()
        const formData = new FormData()
        appendFormData(formData, driver)

        let response
        if (driver.type === 'New Driver') {
          response = await createUser(formData)
          handleResponse(response, 'Driver created successfully!', 'Card')
        } else {
          response = await updateUser(driver.id, formData)
          handleResponse(response, 'Driver updated successfully!', 'Card')
        }
      
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }


  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
      setTimeout(() => {
        if (mode === 'Card') {
          navigate(getRoute('Driver', mode))
        }
      }, 3000) // Closing parentheses and braces for setTimeout
    }
  }

  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <DriversForm1
            driver={driver}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
            validationSchema={
              id == undefined ? driverSchemaCreateStep1 : driverSchemaEditStep1
            }
            setValidationErrors={setValidationErrors}
            // onValidationErrorsChange={handleValidationErrorsChange}
          />
        )
      case 1:
        return (
          <DriversForm2
            driver={driver}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
            validationSchema={driverSchema}
            setValidationErrors={setValidationErrors}
            // onValidationErrorsChange={handleValidationErrorsChange}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const getStepColor = (index) => {
    if (index < activeStep) {
      return '#26861D' // Completed step color
    } else if (index === activeStep) {
      return '#0772B8' // Active step color
    } else {
      return '#EEEEF0' // Inactive step color
    }
  }

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null // or any other fallback UI you want to show
  }

  const handleBackClick = () => {
    navigate(-1);
    };

  return (
    <Box>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <Box sx={{ width: '100%', padding: 'Calc(2.3%-50px) Calc(10.8%-50px)' }}>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #d8d8df',
            paddingBottom: '26.5px'
          }}
        >
          <Grid item xs={12} md={5}>
            <Title item><IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>{id ? 'Update' : 'Create'} Driver</Title>
          </Grid>
          <Grid item xs={12} md={7} sx={{ position: 'relative', right: '8px' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    '& .MuiStepIcon-root': {
                      height: '32px',
                      width: '32px',
                      color: getStepColor(index)
                    },
                    '& .MuiStepIcon-root.Mui-active': {
                      color: '#0772B8' // Active step color
                    },
                    '& .MuiStepIcon-text': {
                      fill: '#5B5B6E' // Change this to your desired number color
                    },
                    '& .MuiStepIcon-root.Mui-active .MuiStepIcon-text': {
                      fill: '#FFFFFF'
                    }
                  }}
                >
                  <StepLabel
                    optional={
                      <Typography
                        style={{
                          fontWeight: index === activeStep ? 600 : 400,
                          color: '#0F446B',
                          fontSize: '16px'
                        }}
                      >
                        {label}
                      </Typography>
                    }
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 3, marginBottom: 3 }}>
          {activeStep === steps.length ? (
            <Box>
              <Typography sx={{ mt: 2 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          ) : (
            <Box>
              {getStepContent(activeStep, validationErrors)}
              <Grid
                xs={12}
                sx={{
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end'
                }}
              >
                <BackButton disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </BackButton>
                <NextButton
                  onClick={
                    activeStep === steps.length - 1 ? handleSubmit : handleNext
                  }
                >
                  {activeStep === steps.length - 1
                    ? 'FINISH'
                    : 'SAVE & CONTINUE'}
                </NextButton>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default DriversForm
