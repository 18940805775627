/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      })
      if (currentToken) {
        localStorage.setItem('fcmToken', currentToken)
        // Send this token to your server to save it for later use
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
      }
    } else {
      console.log('Unable to get permission to notify.')
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error)
  }
}

export { messaging, getToken, onMessage }
