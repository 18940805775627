import axios from 'axios'
import { waypointApi } from '../utils/constant'

const api = axios.create({
  baseURL: waypointApi,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem('access_token'))
    if (access_token) {
      config.headers.Authorization = `${access_token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data)
    throw error.response.data
  } else if (error.request) {
    console.error('No response received:', error.request)
    throw new Error('No response received from server.')
  } else {
    console.error('Request setup error:', error.message)
    throw new Error('Request setup error.')
  }
}

// Create a new waypoint
export const createWayPoint = async (data) => {
  try {
    const response = await api.post('/optimizeRoute', data)
    console.log('API Response Data:', response.data) // Log response data
    return response.data
  } catch (error) {
    console.error('Error in API call:', error) // Log any errors
    handleApiError(error) // Handle the error using centralized function
  }
}

export const fetchAlloptimizedRoutes = async (page, limit) => {
  try {
    const response = await api.get('/optimizeRoute', {
      params: { page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}
