import axios from "axios";
import { accountApi } from "../utils/constant";

const api = axios.create({
  baseURL: accountApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// export const getAccountStatusCounts = async (date, interval) => {
//   const interval1 = interval === "daterange" ? "range" : interval;
//   if (typeof date.from == "object") {
//     date.from = date.from.format("YYYY-MM-DD");
//     date.to = date.to.format("YYYY-MM-DD");
//   }
//   try {
//     const response = await api.get(
//       `/getAccountStatusCounts?from=${date.from}&to=${date.to}&interval=${interval1}`
//     );
//     return response.data;
//   } catch (error) {
//     handleApiError(error);
//   }
// };

export const getAccountStatusCounts = async (date, interval) => {
  const interval1 = interval === "daterange" ? "RANGE" : interval;

  // Ensure date is formatted correctly if it's provided
  if (date && typeof date.from === "object") {
    date.from = date.from.format("YYYY-MM-DD");
    date.to = date.to.format("YYYY-MM-DD");
  }

  // Build the query string based on the presence of date
  const queryString = date
    ? `/getAccountStatusCounts?from=${date.from}&to=${date.to}&interval=${interval1}`
    : `/getAccountStatusCounts?interval=${interval1}`; // No dates for MONTHLY

  try {
    const response = await api.get(queryString);

    return response.data; // Return the fetched data
  } catch (error) {
    handleApiError(error);
    return []; // Return an empty array in case of error
  }
};

// Fetch all units with optional query parameters
export const fetchAllAccounts = async (page, limit) => {
  try {
    const response = await api.get("/", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch all parent accounts
export const fetchAllParentAccounts = async ({ page, limit }) => {
  try {
    const response = await api.get("/fetchAllParentAccounts", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a account by its ID
export const getAccountById = async (accountId) => {
  try {
    const response = await api.get(`/${accountId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new account
export const createAccount = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing account by its ID
export const updateAccount = async (id, data) => {
  try {
    const response = await api.put(`/${id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a account by its ID
export const deleteAccount = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch accounts by account name with pagination
export const fetchAccountsByName = async (name, page, limit) => {
  try {
    const response = await api.get("/fetchAccountsByName", {
      params: { name, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch accounts by billing plan name with pagination
export const fetchAccountsByBillingPlanName = async (
  billingPlanName,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchAccountsByBillingPlanName", {
      params: { billingPlanName, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchAccountsByMultipleCriteria = async (
  accountId,
  billingPlanId,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchAccountsByMultipleCriteria", {
      params: {
        accountId,
        billingPlanId,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
