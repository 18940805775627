/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import debounce from "lodash.debounce";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";
import { formatDate, getUsersStatusColor } from "../../../../utils/functions";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";
import DeactivateAccountModal from "../../../modals/DeactivateAccountModal";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";

// Service and common components
import { updateAccount } from "../../../../services/accountNew.service";
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import { deleteDriver } from "../../../../services/driver.service";

const DriversListCard = () => {
  const [errors, setErrors] = useState([])
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by account ID on open and close 3 dots menu option
  const [openMenuDriverId, setOpenMenuDriverId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  // This is for the deactivate account modal
  const [activateOpen, setActivateOpen] = useState(false);
  // const [accounts, setAccounts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  // Hooks for navigation and outlet context
  const navigate = useNavigate();
  var { drivers, fetchMore, isLoading, isEmpty } = useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [drivers, isLoading, handleScroll]); // Include dependencies that affect the event listener

  // Function that calls the API to perform updateAccount
  const handleUpdateAccount = useCallback(
    async (updatedAccount) => {
      try {
        const response = await updateAccount(
          selectedItem.id,
          updatedAccount
        );
        if (response) {
          setSuccessMessage("Account updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuDriverId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuDriverId(null); // Set the ID of the account whose menu should be open
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
      // const response = await deleteDriver(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Driver deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };


  // // Function that calls the API to perform deleteAccount
  // const deleteAccount = useCallback(() => {
  //   setOpen(false);
  //   setSelectedItem(null);
  // }, [setOpen, setSelectedItem]);

  //Deactivate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  // Function that calls the API to perform deleteAccount
  const deactivateAccount = useCallback(() => {
    // Check if the subscriptionStatus exists before attempting to update it
    if (selectedItem?.subscriptions?.[0]?.subscriptionStatus) {
      const status = "CANCELED";

      // Create a new updated subscriptions array
      const updatedSubscriptions = selectedItem.subscriptions.map(
        (subscription, index) => {
          if (index === 0) {
            // Assuming you only want to update the first subscription
            return { ...subscription, subscriptionStatus: status };
          }
          return subscription;
        }
      );

      // Update the selectedItem with the new subscriptions array
      const updatedAccount = {
        ...selectedItem,
        subscriptions: updatedSubscriptions,
      };

      setSelectedItem(updatedAccount);
      handleUpdateAccount(updatedAccount);

      // Close the modal
      setDeactivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No subscriptionStatus found to update.");
    }
  }, [
    selectedItem,
    handleUpdateAccount,
    setDeactivateOpen,
    setSelectedItem,
  ]);

  //Activate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleActivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  // Function that calls the API to perform deleteAccount
  const activateAccount = useCallback(() => {
    // Check if the subscriptionStatus exists before attempting to update it
    if (selectedItem?.subscriptions?.[0]?.subscriptionStatus) {
      const status = "ACTIVE";

      // Create a new updated subscriptions array
      const updatedSubscriptions = selectedItem.subscriptions.map(
        (subscription, index) => {
          if (index === 0) {
            // Assuming you only want to update the first subscription
            return { ...subscription, subscriptionStatus: status };
          }
          return subscription;
        }
      );

      // Update the selectedItem with the new subscriptions array
      const updatedAccount = {
        ...selectedItem,
        subscriptions: updatedSubscriptions,
      };

      setSelectedItem(updatedAccount);
      handleUpdateAccount(updatedAccount);

      // Close the modal
      setActivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No subscriptionStatus found to update.");
    }
  }, [
    selectedItem,
    handleUpdateAccount,
    setActivateOpen,
    setSelectedItem,
  ]);

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (driverId) => {
      navigate(`/monitoring/drivers/edit/${driverId}`);
      // Your navigation logic here
    },
    [navigate]
  );

  const navigateToConsignmentList = (e, driver) => {
    navigate(`/monitoring/drivers/consignment/${driver.id}`);
  };

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error)
    // }
  }

  return (
    <>
    <Grid sx={{ marginTop: '50px' }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="DRIVERS"
              message="Oops! You have no drivers for your search criteria"
              info="Please feel free to create a new driver by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {drivers.map((driver) => (
          <Grid item xs={12} sm={6} md={4} key={driver.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">
                      {driver.userName}
                    </Typography>
                    <Typography
                      variant="cardStatus"
                      sx={{
                        color: getUsersStatusColor(driver.status),
                      }}
                    >
                      &bull; &nbsp;
                      {driver.status}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${driver.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, driver)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${driver.id}`
                      )}
                      open={openMenuDriverId === driver.id} // The menu is open if the openMenuDriverId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      {driver && driver.status === "ACTIVE" ? (
                        <StyledMenuItem
                          onClick={(e) => handleDeactivateModalOpen(e, driver)}
                        >
                          <BlockOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Deactivate Driver
                        </StyledMenuItem>
                      ) : (
                        <StyledMenuItem
                          onClick={(e) => handleActivateModalOpen(e, driver)}
                        >
                          <VerifiedUserOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Activate Driver
                        </StyledMenuItem>
                      )}
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, driver)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Driver</Typography>
                        </Grid>
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={(e) => {
                          navigateToConsignmentList(e, driver);
                        }}
                      >
                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                          <LocalShippingIcon // You can use an appropriate icon here
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography>Consignment List</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(driver.id)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Name</Typography>
                    <Typography variant="cardValue">
                      {driver?.firstName}
                      {driver?.lastName &&
                        driver.lastName !== "null" &&
                        ` ${driver.lastName}`}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Email</Typography>
                    <Typography variant="cardValue">{driver.email}</Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Phone</Typography>
                    <Typography variant="cardValue">{driver.phone}</Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">License Number</Typography>
                    <Typography variant="cardValue">
                      {driver.licenseNumber ? driver.licenseNumber : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">
                      License Expiry Date
                    </Typography>
                    <Typography variant="cardValue">
                      {driver?.licenseExpiryDate !== null
                        ? formatDate(driver?.licenseExpiryDate)
                        : "No activity found"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Passport Number</Typography>
                    <Typography variant="cardValue">
                      {driver.passportNumber ? driver.passportNumber : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">
                      Passport Expiry Date
                    </Typography>
                    <Typography variant="cardValue">
                      {driver?.passportExpiryDate !== null
                        ? formatDate(driver?.passportExpiryDate)
                        : "No activity found"}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateAccount={activateAccount}
      />
      <DeactivateAccountModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateAccount={deactivateAccount}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default DriversListCard;
