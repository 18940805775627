import axios from 'axios';
import { maintenanceCheckListApi } from "../utils/constant";

// Create an instance of axios with default headers and base URL
const api = axios.create({
  baseURL: maintenanceCheckListApi,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
    (config) => {
      const access_token = JSON.parse(localStorage.getItem('access_token'));
      if (access_token) {
        config.headers.Authorization = `${access_token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
    if (error.response) {
      console.error('API Error:', error.response.data);
      throw error.response.data;
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response received from server.');
    } else {
      console.error('Request setup error:', error.message);
      throw new Error('Request setup error.');
    }
};

// Fetch all Maintenance Check lists with optional query parameters
export const fetchAllMaintenanceChecklists = async (queryParams) => {
    try {
      const response = await api.get('/', { params: queryParams });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
};

// Get a Maintenance Check list by its ID
export const getMaintenanceChecklistById = async (checklistId) => {
    try {
      const response = await api.get(`/${checklistId}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
};

// Create a new Maintenance Check list
export const createMaintenanceChecklist = async (data) => {
    try {
      const response = await api.post('/', data);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
};
  
  // Update an existing Maintenance Check list by its ID
  export const updateMaintenanceChecklist = async (id, data) => {
    try {
      const response = await api.put(`/${id}`, data);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
};
  
  // Delete a Maintenance Check list by its ID
  export const deleteMaintenanceChecklist = async (id) => {
    try {
      const response = await api.delete(`/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
};