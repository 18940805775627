/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  NextButton,
} from "../../../../styledComponents";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import FormField from "../../../common/FormField";
import { simCardsSchema } from "../../../../utils/validation-schemas/simCardSchema";
import { SimCardModel } from "../../../../model/simCards.model";
import { useNavigate, useParams } from "react-router-dom";
import {
  createSimCard,
  updateSimCard,
  getSimCardById,
} from "../../../../services/simCardNew.service";
import Loader from "../../../common/Loader";
import { getRoute } from "../../../../constants/routes";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import { iconMap } from "../../../../constants/iconsList";
import FormPhone from "../../../common/FormPhone";

const SimCardForm = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [simCard, setSimCard] = useState(SimCardModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      _getSimCardById(id);
    }
  }, [id]);

  const _getSimCardById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getSimCardById(id);
      setSimCard(data);
      handleResponse(data, "Sim Card fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = async (name, value) => {
    try {
      setSimCard((prevSimCard) => {
        const updatedSimCard = {
          ...prevSimCard,
          [name]: value,
        };

        // If the field is simCardNumber, split the value into areaCode and phone
        if (name === "simCardNumber") {
          const phoneNumberParts = value.split(" "); // Split the value by space
          updatedSimCard.areaCode = phoneNumberParts[0]; // e.g., +255
          updatedSimCard.simCardNumber = phoneNumberParts.slice(1).join(" "); // e.g., "21 233 44"
        } else if (name === "mssidnNumber") {
          const phoneNumberParts = value.split(" "); // Split the value by space
          updatedSimCard.areaCode = phoneNumberParts[0]; // e.g., +255
          updatedSimCard.mssidnNumber = phoneNumberParts.slice(1).join(" "); // e.g., "21 233 44"
        }

        return updatedSimCard;
      });

      await validateForm2(
        { ...simCard, [name]: value },
        name,
        setValidationErrors,
        simCardsSchema
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Validate the form fields
      const isValid = await validateForm(
        simCard,
        setValidationErrors,
        simCardsSchema
      );
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;

      // Perform API call based on whether id is defined or not
      if (id === undefined) {
        response = await createSimCard(simCard);
        handleResponse(response, "Sim Card created successfully!", "Card");
      } else {
        response = await updateSimCard(simCard);
        handleResponse(response, "Sim Card updated successfully!", "Card");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Sim Card", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title>
            {" "}
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>
            {id ? "Update" : "Create"} Sim Card
          </Title>
        </Grid>
      </StyledHeader2>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">SimCard Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormPhone
            label="SIM Card #"
            required
            placeholder="Enter SIM card number"
            value={simCard.areaCode + " " + simCard.simCardNumber}
            onChange={(newValue) =>
              handleFieldChange("simCardNumber", newValue)
            }
            error={!!validationErrors.simCardNumber}
            errorMessage={validationErrors.simCardNumber}
          />
          <FormField
            label="IMEI #"
            required
            placeholder="Enter IMEI number"
            value={simCard.imeiNumber}
            onChange={(e) => handleFieldChange("imeiNumber", e.target.value)}
            error={!!validationErrors.imeiNumber}
            errorMessage={validationErrors.imeiNumber}
          />
          {/* <FormField
            label="MSSIDN #"
            placeholder="Enter MSSIDN number"
            value={simCard.mssidnNumber}
            onChange={(e) => handleFieldChange("mssidnNumber", e.target.value)}
            error={!!validationErrors.mssidnNumber}
            errorMessage={validationErrors.mssidnNumber}
          /> */}
          <FormPhone
            label="MSSIDN #"
            required
            placeholder="Enter MSSIDN number"
            value={simCard.areaCode + " " + simCard.mssidnNumber}
            onChange={(newValue) => handleFieldChange("mssidnNumber", newValue)}
            error={!!validationErrors.mssidnNumber}
            errorMessage={validationErrors.mssidnNumber}
          />
          <FormField
            label="ICCD #"
            placeholder="Enter ICCD number"
            value={simCard.iccdNumber}
            onChange={(e) => handleFieldChange("iccdNumber", e.target.value)}
            error={!!validationErrors.iccdNumber}
            errorMessage={validationErrors.iccdNumber}
          />
           <FormField
            label="APN"
            required
            placeholder="Enter APN"
            value={simCard.APN}
            onChange={(e) => handleFieldChange("APN", e.target.value)}
            error={!!validationErrors.APN}
            errorMessage={validationErrors.APN}
          />
            <FormField
            label="APN Username"
            placeholder="Enter APNUsername (optional)"
            value={simCard.APNUsername}
            onChange={(e) => handleFieldChange("APNUsername", e.target.value)}
            error={!!validationErrors.APNUsername}
            errorMessage={validationErrors.APNUsername}
          />
          <FormField
            label="APN Password"
            placeholder="Enter APNPassword (optional)"
            value={simCard.APNPassword}
            onChange={(e) => handleFieldChange("APNPassword", e.target.value)}
            error={!!validationErrors.APNPassword}
            errorMessage={validationErrors.APNPassword}
          />
        </StyledSectionRight>
      </StyledFormSection>

      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};

export default SimCardForm;
