/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import {
  Modal,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import CloseIcon from "@mui/icons-material/Close"
// import html2canvas from 'html2canvas';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from "../../../../assets/logo/Off@3x.png";
import Barcode from "react-barcode"; // Import the Barcode component
import { StyledModal } from "../../../../styledComponents";
import { formatDate, formatTime } from "../../../../utils/functions";

const ConsignmentSummeryPage = ({ consignment }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const modalContentRef = useRef(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === "Download PDF") {
      downloadPDF();
    } else if (action === "Print Order") {
      printOrder();
    }
    // Handle other actions here
    console.log(action); // Replace this with actual handling logic
  };
  const downloadPDF = () => {
    const element = modalContentRef.current;
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Find the image element and ensure it's loaded
    const imageElement = element.querySelector("img");
    if (imageElement && !imageElement.complete) {
      imageElement.onload = () => generatePDF(element);
    } else {
      generatePDF(element);
    }
  };

  const generatePDF = (element) => {
    html2canvas(element, { scale: 2, useCORS: true })
      .then((canvas) => {
        const imgWidth = 210; // A4 paper width in mm
        const pageHeight = 295; // A4 paper height in mm
        const imgWidthPx = canvas.width;
        const imgHeightPx = canvas.height;
        const imgHeightMm = (imgHeightPx * imgWidth) / imgWidthPx; // Convert image height to mm
        const heightLeft = imgHeightMm;

        const pdf = new jsPDF("p", "mm", "a4");
        let position = 0;
        let remainingHeight = heightLeft;
        let imgData = canvas.toDataURL("image/png");

        // Add the first page
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightMm);
        remainingHeight -= pageHeight;
        position -= pageHeight;

        // Add additional pages if needed
        while (remainingHeight > 0) {
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightMm);
          remainingHeight -= pageHeight;
          position -= pageHeight;
        }

        pdf.save("consignment-details.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
    const imageElement = element.querySelector("img");
    if (imageElement) {
      console.log("Image source:", imageElement.src);
      console.log("Image complete:", imageElement.complete);
    }
  };

  const printOrder = () => {
    const element = modalContentRef.current;
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Delay to ensure the content is fully rendered
    setTimeout(() => {
      html2canvas(element, { scale: 2, useCORS: true })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const printWindow = window.open("", "", "height=600,width=800");
          if (printWindow) {
            printWindow.document.open();
            printWindow.document.write(`
            <html>
            <head>
              <title>Print Order</title>
              <style>
                body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
                img { width: 100%; }
              </style>
            </head>
            <body>
              <img src="${imgData}" />
            </body>
            </html>
          `);
            printWindow.document.close();
            printWindow.focus();
            // Print after the document is closed
            printWindow.addEventListener(
              "load",
              () => {
                printWindow.print();
              },
              { once: true }
            );
          } else {
            console.error("Failed to open print window");
          }
        })
        .catch((error) => {
          console.error("Error generating print preview:", error);
        });
    }, 100); // Delay in milliseconds
  };

  return (
    <Modal aria-labelledby="modal-title" aria-describedby="modal-description">
      <StyledModal
        sx={{
          height: "40rem",
          overflow: "auto",
          width: { xs: "100%", sm: 600 },
        }}
      >
        <Grid
          ref={modalContentRef}
          sx={{
            p: 2,
            pageBreakAfter: "always",
            pageBreakBefore: "always",
            breakInside: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 1, // Padding bottom to space content from the border
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ textAlign: "start", margin: "20px 0px" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "50%", height: "auto" }}
                />
              </Box>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              {/* <IconButton onClick={handleConsignmentModalClose}>
                <CloseIcon />
              </IconButton> */}
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("Download PDF")}>
                Download PDF
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Print Order")}>
                Print Order
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Mark as Delivered")}
              >
                Mark as Delivered
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ddd", // Border bottom for the header
              pb: 1, // Padding bottom to space content from the border
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h6" id="modal-title">
                Order #: {consignment?.id}
              </Typography>
              <Typography variant="h6" id="modal-title">
                Status: {consignment?.tripStatus}
              </Typography>
            </Grid>
          </Box>
          <Box
            sx={{
              border: "1px solid #ddd", // Border box around the content
              borderRadius: 2,
              p: 2,
              mt: 2,
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderRight: "1px solid #ddd", // Border between Delivery and Pickup address
                pr: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // Align text to the top
              }}
            >
              <Typography
                id="modal-title"
                sx={{ mb: 1, fontSize: "14px", fontWeight: 600 }}
              >
                Delivery Address
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                }}
              >
                {consignment?.deliveryDetails?.address && (
                  <>
                    {consignment?.deliveryDetails?.address?.streetOne && (
                      <div>
                        {consignment?.deliveryDetails?.address?.streetOne}
                      </div>
                    )}
                    {consignment?.deliveryDetails?.address && (
                      <div>
                        {`${consignment?.deliveryDetails?.address?.city} 
                        ${consignment?.deliveryDetails?.address?.state}
                        ${consignment?.deliveryDetails?.address?.country} 
                        ${consignment?.deliveryDetails?.address?.postalCode}`}
                      </div>
                    )}
                  </>
                )}
                {consignment?.deliveryDetails?.geofenceId && (
                  <>{`${consignment?.deliveryDetails?.geofenceName}`}</>
                )}
                <Typography sx={{ mt: 1 }}>
                  {consignment?.deliveryDetails?.areaCode}{" "}
                  {consignment?.deliveryDetails?.phone}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                pl: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // Align text to the top
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ mb: 1, fontSize: "14px", fontWeight: 600 }}
              >
                Pickup Address
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {consignment?.pickupDetails?.address?.addressType !==
                "Geofence" ? (
                  <>
                    {consignment?.pickupDetails?.address?.streetOne ? (
                      <Typography component="div" sx={{ textAlign: "start" }}>
                        {consignment?.pickupDetails?.address?.streetOne}
                      </Typography>
                    ) : (
                      consignment?.pickupDetails?.address?.addressType ===
                        "Geofence" && (
                        <Typography component="div" sx={{ textAlign: "start" }}>
                          {consignment?.pickupDetails?.geofenceName ?? ""}
                        </Typography>
                      )
                    )}
                    <Typography component="div" sx={{ textAlign: "start" }}>
                      {`${consignment?.pickupDetails?.address?.city} ${consignment?.pickupDetails?.address?.state} ${consignment?.pickupDetails?.address?.country} ${consignment?.pickupDetails?.address?.postalCode}`}
                    </Typography>
                  </>
                ) : (
                  <Typography component="div" sx={{ textAlign: "start" }}>
                    {consignment?.pickupDetails?.address?.geofenceName ?? ""}
                  </Typography>
                )}
                <Typography sx={{ mt: 1 }} component="div">
                  {consignment?.pickupDetails?.areaCode}{" "}
                  {consignment?.pickupDetails?.phone}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #ddd", // Border box around the content
              borderRadius: 2,
              p: 2,
              mt: 2,
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
                pr: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  margin: "0px 0px 10px 0px",
                  borderBottom: "1px solid #ddd",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Order
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>Amount</Typography>
                  <Typography>
                    {consignment?.billingInformation.amount
                      ? `USD ${new Intl.NumberFormat().format(
                          consignment.billingInformation.amount
                        )} `
                      : "--"}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>VAT</Typography>
                  <Typography>
                    {consignment?.billingInformation.VAT
                      ? `${consignment.billingInformation.VAT}%`
                      : "--"}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>Total</Typography>
                  <Typography>
                    {consignment?.billingInformation.amount &&
                    consignment?.billingInformation.VAT
                      ? `USD ${new Intl.NumberFormat().format(
                          (
                            consignment.billingInformation.amount *
                            (1 + consignment.billingInformation.VAT / 100)
                          ).toFixed(2)
                        )} `
                      : "--"}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>Payment Term</Typography>
                  <Typography>
                    {consignment?.billingInformation.paymentTerm
                      ? `${consignment.billingInformation.paymentTerm}`
                      : "--"}
                  </Typography>
                </Grid>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #ddd", // Border box around the content
              borderRadius: 2,
              p: 2,
              mt: 2,
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
                pr: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  margin: "0px 0px 10px 0px",
                  borderBottom: "1px solid #ddd",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Delivery Details
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                  margin: "5px 0px",
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ mr: 1 }}>Received Date:</Typography>
                  <Typography>
                    {formatDate(consignment?.receivedDateTime)} (
                    {formatTime(consignment?.receivedDateTime)})
                  </Typography>
                </Grid>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ mr: 1 }}>Unit Allocated Date:</Typography>
                  <Typography>
                    {consignment?.unitAllocationDateTime
                      ? `${formatDate(
                          consignment.unitAllocationDateTime
                        )} (${formatTime(consignment.unitAllocationDateTime)})`
                      : "--"}
                  </Typography>
                </Grid>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  margin: "5px 0px",
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ mr: 1 }}>Driver Allocated Date:</Typography>
                  <Typography>
                    {consignment?.driverAllocationDateTime
                      ? `${formatDate(
                          consignment.driverAllocationDateTime
                        )} (${formatTime(
                          consignment.driverAllocationDateTime
                        )})`
                      : "--"}
                  </Typography>
                </Grid>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                  margin: "5px 0px",
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ mr: 1 }}>Pickup Date:</Typography>
                  <Typography>
                    {formatDate(
                      consignment?.deliverySchedule?.expectedDeliveryDate
                    )}{" "}
                    ({consignment?.deliverySchedule?.pickupTime?.to})
                  </Typography>
                </Grid>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                  margin: "5px 0px",
                }}
              >
                <Grid sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ mr: 1 }}>Delivered Date:</Typography>
                  <Typography>
                    {consignment?.deliveredDateTime
                      ? `${formatDate(
                          consignment.deliveredDateTime
                        )} (${formatTime(consignment.deliveredDateTime)})`
                      : "--"}
                  </Typography>
                </Grid>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #ddd", // Border box around the content
              borderRadius: 2,
              p: 2,
              mt: 2,
              display: "flex",
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderRight: "1px solid #ddd", // Border between Delivery and Pickup address
                pr: 2,
                display: "flex",
                flexDirection: "column",
                // Align text to the top
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  fontWeight: 600,
                  alignItems: "flex-start",
                  textAlign: "start",
                }}
              >
                Payment Details
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: "start" }}>
                      Payment Method:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: "end" }}>
                      {consignment?.billingInformation.paymentTerm}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                }}
              >
                <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: "start" }}>
                      Delivery Note:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: "end" }}>
                      {consignment?.billingInformation.notes}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                pl: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start", // Align text to the top
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ mb: 1, fontSize: "14px", fontWeight: 600 }}
              >
                Proof of Delivery
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align text to the top
                }}
              >
                {consignment?.deliverySignature?.fileURL ? (
                  <img
                    src={consignment.deliverySignature.fileURL}
                    alt="Proof of Delivery"
                    style={{ maxWidth: "50%", height: "auto" }}
                  />
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Box>
            </Box>
          </Box>

          {/* Barcode Section */}
          {consignment?.id && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Barcode
                value={consignment.id.toString()}
                width={1}
                height={50}
                displayValue={false} // Set to true if you want to display the value below the barcode
              />
            </Box>
          )}
        </Grid>
      </StyledModal>
    </Modal>
  );
};

export default ConsignmentSummeryPage;
