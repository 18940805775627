import axios from 'axios'
import { billingPlanApi } from '../utils/constant'

const api = axios.create({
  baseURL: billingPlanApi,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem('access_token'))
    if (access_token) {
      config.headers.Authorization = `${access_token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data)
    throw error.response.data
  } else if (error.request) {
    console.error('No response received:', error.request)
    throw new Error('No response received from server.')
  } else {
    console.error('Request setup error:', error.message)
    throw new Error('Request setup error.')
  }
}
// Fetch all billing plan with optional query parameters
export const fetchAllBillingPlans = async ({ page, limit }) => {
  try {
    const response = await api.get('/', {
      params: { page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Get a billing plan by its ID
export const getBillingPlanById = async (billingPlanId) => {
  try {
    const response = await api.get(`/${billingPlanId}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Create a new billing plan
export const createBillingPlan = async (data) => {
  try {
    const response = await api.post('/', data)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Update an existing billing plan by its ID
export const updateBillingPlan = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Delete a billing plan by its ID
export const deleteBillingPlan = async (id) => {
  try {
    const response = await api.delete(`/${id}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}
// Fetch billing plan by name with pagination
export const fetchBillingPlansByName = async (name, page, limit) => {
  try {
    const response = await api.get('/fetchBillingPlansByName', {
      params: { name, page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}
// Fetch billing plan by name with pagination
export const fetchBillingPlansByAccountName = async (
  accountName,
  page,
  limit
) => {
  try {
    const response = await api.get('/fetchBillingPlansByAccountName', {
      params: { accountName, page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

export const fetchBillingPlansByMultipleCriteria = async (
  id,
  accountId,
  page,
  limit
) => {
  try {
    const response = await api.get('/fetchBillingPlansByMultipleCriteria', {
      params: {
        id,
        accountId,
        page,
        limit
      }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}
