export const ConsigneeModel = {
  accountId: null,
  name: null,
  areaCode: null,
  phone: null,
  email: null,
  taxId: null,
  address: [
    {
      addressType: null,
      geofenceId: null,
      streetOne: null,
      streetTwo: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
    },
  ],
};
