import React, { useEffect, useState } from "react";
import {
  StyledInputControlContainer,
  StyledSelect,
} from "../../styledComponents";
import { MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormSelect = ({
  label,
  value,
  onChange,
  options,
  error,
  errorMessage,
  required = false,
  disabled,
  readOnly = false,
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    if (
      options.length === 0 ||
      (options.length === 1 && options[0].value === undefined)
    ) {
      setFilteredOptions([]); // Set to empty array if conditions are met
    } else {
      setFilteredOptions(options); // Otherwise, keep the original options
    }
  }, [options]); // Dependency on options prop

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption && selectedOption.item) {
      onChange(selectedOption.item); // Pass the entire item object if available
    } else {
      onChange(selectedValue); // Pass just the value
    }
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <StyledSelect
        defaultValue={""}
        value={value}
        onChange={handleChange}
        error={!!error}
        required={required}
        readOnly={readOnly}
        disabled={filteredOptions.length === 0 || disabled}
      >
        {options.length === 0 ? (
          <MenuItem value="" disabled>
            No options available
          </MenuItem>
        ) : (
          filteredOptions.map((item, index) =>
            item.value !== undefined ? ( // Check if item.value is not undefined
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ) : null
          )
        )}
      </StyledSelect>
      {/* <StyledSelect
        defaultValue={''}
        value={value}
        onChange={handleChange}
        error={!!error}
        required={required}
      >
        
        {options.map((item, index) => (
         item.value !== undefined ? (  // Check if item.value is not undefined
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ) :  null
        ))}
      </StyledSelect> */}
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      item: PropTypes.object,
    })
  ).isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FormSelect;
