import axios from 'axios';
import { paymentApi } from "../utils/constant";

// Create an instance of axios with default headers and base URL
const api = axios.create({
    baseURL: paymentApi,
    headers: {
      'Content-Type': 'application/json',
    },
  });

// Intercept requests to add the authorization token
api.interceptors.request.use(
    (config) => {
      const access_token = JSON.parse(localStorage.getItem('access_token'));
      if (access_token) {
        config.headers.Authorization = `${access_token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
    if (error.response) {
      console.error('API Error:', error.response.data);
      throw error.response.data;
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response received from server.');
    } else {
      console.error('Request setup error:', error.message);
      throw new Error('Request setup error.');
    }
  };
  
// Fetch all payments with optional query parameters
export const fetchAllPayments = async (queryParams) => {
    try {
      const response = await api.get('/', { params: queryParams });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
  
  // Get a payment by its ID
  export const getPaymentById = async (paymentId) => {
    try {
      const response = await api.get(`/${paymentId}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };  

// Create a new payment
export const createPayment = async (data) => {
    try {
      const response = await api.post('/', data);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
  
  // Update an existing payment by its ID
  export const updatePayment = async (data) => {
    try {
      const response = await api.put(`/${data.id}`, data);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
  
  // Delete a payment by its ID
  export const deletePayment = async (id) => {
    try {
      const response = await api.delete(`/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };  

// Fetch unit group by account id with pagination
export const fetchPaymentsByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'));
  try {
    const response = await api.get('/fetchPaymentsByAccountId', {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};  
// Fetch units by name and account ID with pagination
export const fetchPaymentsByDateRange = async (startDate, endDate, page, limit) => {
    const accountId = JSON.parse(localStorage.getItem('defaultAccountId'));
    try {
      const response = await api.get('/fetchPaymentsByDateRange', {
        params: { startDate, endDate, accountId, page, limit },
      });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };  