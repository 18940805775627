import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Modal } from '@mui/material';
import { StyledModal, StyledModalContent, StyledModalHr, StyledDeleteButton, StyledCancelButton } from '../../styledComponents'; 

const DeleteModal = ({ open, handleDeleteModalClose, selectedItem, deleteItem }) => {
  return (
    <Modal
      open={open}
      onClose={(e) => handleDeleteModalClose(e)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <StyledModal>
        <Typography variant="modalTitle" id="child-modal-title">
          Delete?
        </Typography>
        <StyledModalContent>
          <Typography variant="modalContent">
            Are you sure you want to delete <strong>{selectedItem?.name || selectedItem?.simCardNumber || selectedItem?.userName || selectedItem?.customerName}</strong>?
          </Typography>
        </StyledModalContent>
          <StyledModalHr />
          <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledDeleteButton item fullWidth onClick={(e) => deleteItem(e)}>
              YES, DELETE
            </StyledDeleteButton>
            </Grid>
            <Grid item>
            <StyledCancelButton item fullWidth onClick={(e) => handleDeleteModalClose(e)}>
              CANCEL
            </StyledCancelButton>
            </Grid>
          </Grid>
      </StyledModal>
    </Modal>
  );
};

// PropTypes validation
DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDeleteModalClose: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  deleteItem: PropTypes.func.isRequired,
};

export default DeleteModal;
