/* eslint-disable no-unused-vars */
import { COUNTRY_ISO_OPTIONS } from '@mui/x-data-grid-generator/services/static-data'
import * as yup from 'yup'

const addressSchema = yup.object().shape({
  addressType: yup
    .string()
    .oneOf(
      ['Geofence', 'Address'],
      'Address type must be either Geofence or Address'
    )
    .required('Address type is required.'),

  geofenceId: yup.string().when('addressType', {
    is: 'Geofence',
    then: (schema) =>
      schema.required('Geofence ID is required'),
    otherwise: (schema) => schema.nullable()
  }),

  streetOne: yup.string().when('addressType', {
    is: 'Address',
    then: (schema) =>
      schema.required('Street One is required'),
    otherwise: (schema) => schema.nullable()
  }),


  streetTwo: yup.string().nullable(),

  city: yup.string().when('addressType', {
    is: 'Address',
    then: (schema) =>
      schema.required('City is required'),
    otherwise: (schema) => schema.nullable()
  }),

  state: yup.string().when('addressType', {
    is: 'Address',
    then: (schema) =>
      schema.required('State is required'),
    otherwise: (schema) => schema.nullable()
  }),

  postalCode: yup.string().when('addressType', {
    is: 'Address',
    then: (schema) =>
      schema.required('Postal Code is required'),
    otherwise: (schema) => schema.nullable()
  }),

  country: yup.string().when('addressType', {
    is: 'Address',
    then: (schema) =>
      schema.required('Country is required'),
    otherwise: (schema) => schema.nullable()
  }),
})

export const customersSchema = yup.object().shape({
  accountId: yup.string().required('Account ID is required.'),

  name: yup
    .string()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, 'Name cannot exceed 50 characters.')
    .required('Name is required'),

  areaCode: yup.string().required('Area code is required'),

  phone: yup
    .string()
    .matches(/^\+?(?:[0-9] ?){7,14}[0-9]$/, 'Phone number is not valid')
    .required('Phone number is required'),

  email: yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is not valid')
    // .email('Email is not valid')
    .required('Email is required'),

  address: yup.array().of(addressSchema).required('Address is required'),

  taxId: yup.string()
  .max(20, 'Tax Id cannot exceed 20 characters.')
  .required('Tax ID is required'),
})
