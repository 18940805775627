import React from 'react';
import { StyledInputControlContainer, StyledMuiTelInput } from '../../styledComponents';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import FormError from './FormError';

const FormPhone = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  required = false,
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <StyledMuiTelInput
        defaultCountry="TZ"
        value={value}
        onChange={onChange}
        error={error}
      />
      {error && (
        <FormError errorMessage={errorMessage} />
      )}
    </StyledInputControlContainer>
  );
};

FormPhone.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormPhone;