import * as yup from 'yup';

// Units schema
const unitsSchema = yup.object().shape({
  unitId: yup
    .string()
    .required("Unit ID is required.")
});

// UnitGroups schema
export const unitGroupSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, "Name should be at least 3 characters.")
    .max(50, "Name cannot exceed 50 characters.")
    .required("Group name is required."),
  accountId: yup
    .string()
    .required("Account ID is required."),
  units: yup
    .array()
    .of(unitsSchema)
    .required("Units are required."),
  createdBy: yup
    .string()
    .nullable(),
  updatedBy: yup
    .string()
    .nullable(),
});
