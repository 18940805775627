/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";

import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import { deleteGeofence } from "../../../../services/geofencesNew.service";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const GeofencesListCard = () => {
  const [errors, setErrors] = useState([])
  const [openMenuGeofenceId, setOpenMenuGeofenceId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const navigate = useNavigate();
  const { geofences, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [geofences, isLoading, handleScroll]); // Include dependencies that affect the event listener

   // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuGeofenceId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback(() => {
    setOpenMenuGeofenceId(null);
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setOpen(true);
    },
    [handleActionsMenuClose]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, []);

  
  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteGeofence(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Geofence deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  // const deleteAccount = useCallback(() => {
  //   setOpen(false);
  //   setSelectedItem(null);
  // }, []);

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (geofenceId) => {
      // const tab = "details";
      navigate(`/monitoring/geofences/edit/${geofenceId}`);
      // Your navigation logic here
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error)
    // }
  }

  return (
    <>
     <Grid sx={{ marginTop: '50px' }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="GEOFENCES"
              message="Oops! You have no geofences for your search criteria"
              info="Please feel free to create a new geofence by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {geofences.map((geofence) => (
          <Grid item xs={12} sm={6} md={4} key={geofence.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">
                      {geofence.name}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${geofence.id}`}
                      onClick={(event) =>
                        handleActionsMenuOpen(event, geofence)
                      }
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${geofence.id}`
                      )}
                      open={openMenuGeofenceId === geofence.id} // The menu is open if the openMenuGeofenceId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, geofence)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Geofence</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(geofence.id)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Radius</Typography>
                    <Typography variant="cardValue">
                      {geofence?.radius}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Center</Typography>
                    <Typography variant="cardValue">
                      {geofence?.center?.lat}, {geofence?.center?.lng}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Description</Typography>
                    <Typography variant="cardValue">
                      {geofence?.description}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default GeofencesListCard;
