/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react'
import {
  FlexContainerSB,
  StyledIconButton,
  StyledListCard,
  StyledListCardContainer,
  StyledListCardContent,
  StyledListCardHeader,
  StyledMenuItem
} from '../../../../styledComponents'
import { useOutletContext } from 'react-router-dom'
import Loader from '../../../common/Loader'
import { Grid, Menu, Typography } from '@mui/material'
import EmptyState from '../../../common/EmptyState'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import OptimizedRouteModal from '../../../modals/OptimizedRouteModal'

export default function RouteOptimzationListCard() {
  const containerRef = useRef(null)
  const [selectedOptimizedRoute, setSelectedOptimizedRoute] = useState({})
  const [routeOpen, setRouteOpen] = useState(false)
  const { optimizedRoutes, isLoading, isEmpty } = useOutletContext()

  const handleCardClick = useCallback((consignment) => {
    setSelectedOptimizedRoute(consignment)
    setRouteOpen(true)
  }, [])

  const handleRouteOpenModalClose = useCallback(() => {
    setRouteOpen(false)
    // setSelectedConsignment(null)
  }, [setSelectedOptimizedRoute, setRouteOpen])

  return (
    <>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="ROUTE_OPTIMIZATION"
              message="Oops! You have no optimized route"
              info="Please feel free to create a new optimzed route by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {optimizedRoutes?.map((route) => (
          <Grid item xs={12} sm={6} md={4} key={route.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="cardTitle1">
                      {route.routeName}
                    </Typography>
                  </Grid>
                }
              />
              <StyledListCardContent onClick={() => handleCardClick(route)}>
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Pickup Address</Typography>
                    <Typography variant="cardValue">{route?.pickup}</Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">
                      Delivery Address
                    </Typography>
                    <Typography variant="cardValue">
                      {route?.delivery}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>
      <OptimizedRouteModal
        routeOpen={routeOpen}
        handleRouteOpenModalClose={handleRouteOpenModalClose}
        optimizedRoute={selectedOptimizedRoute}
      />
    </>
  )
}
