import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import {
  StyledViewModeGrid,
  StyledIconButton1
} from '../../../src/styledComponents'

// Import your icons
import listViewIcon from '../../assets/icons/listViewIcon.svg'
import cardViewIcon from '../../assets/icons/cardViewIcon.svg'
import { getRoute } from '../../constants/routes'
import { NavLink } from 'react-router-dom'

const ViewModeSwitch = (props) => {
  const [selectedView, setSelectedView] = useState('list')

  const handleViewChange = (view) => {
    setSelectedView(view)
  }

  return (
    <StyledViewModeGrid item sx={{ marginRight: '24px', height:'50px' }}>
      <Tooltip title="List View">
        <NavLink to={getRoute(props.name, 'List')}>
          <StyledIconButton1
            selected={selectedView === 'card'}
            onClick={() => handleViewChange('card')}
          >
            <img src={listViewIcon} alt="List View" />
          </StyledIconButton1>
        </NavLink>
      </Tooltip>
      <Tooltip title="Card View">
        <NavLink to={getRoute(props.name, 'Card')}>
          <StyledIconButton1
            selected={selectedView === 'list'}
            onClick={() => handleViewChange('list')}
          >
            <img src={cardViewIcon} alt="Card View" />
          </StyledIconButton1>
        </NavLink>
      </Tooltip>
    </StyledViewModeGrid>
  )
}
ViewModeSwitch.propTypes = {
  name: PropTypes.string.isRequired
}

export default ViewModeSwitch
