import * as yup from 'yup'

// Services schema
export const servicesSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, 'Name cannot exceed 50 characters.')
    .required('Service name is required.'),
  type: yup
    .string()
    .oneOf(
      [
        'Management',
        'Monitoring',
        'General',
        'Administrative',
        'Sensors',
        'Features',
        'Reports',
        'Alerts'
      ],
      'Invalid type.'
    )
    .default('General')
    .required('Type is required.'),
  fieldType: yup
    .string()
    .oneOf(
      ['Checkbox', 'Amount', 'Limit', 'Days', 'MB', 'Frequency'],
      'Invalid field type.'
    )
    .required('Field type is required.'),
  amount: yup.number().nullable(),
  days: yup
  .number()
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .typeError('Days must be a number')
  .min(0, 'Days cannot be less than 0')
  .max(999999.99, 'Days cannot exceed 999999.99')
  .nullable()
  .when('fieldType', {
    is: 'Days',
    then: (schema) => schema.required('Days is required.'),
    otherwise: (schema) => schema.nullable()
  }),
MB: yup
  .number()
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .typeError('MB must be a number')
  .min(0, 'MB cannot be less than 0')
  .max(999999.99, 'MB cannot exceed 999999.99')
  .nullable()
  .when('fieldType', {
    is: 'MB',
    then: (schema) => schema.required('MB is required.'),
    otherwise: (schema) => schema.nullable()
  }),
  frequency: yup
    .string()
    .oneOf(['Daily', 'Weekly', 'Monthly'], 'Invalid frequency.')
    .default('Monthly')
    .nullable(),
  currency: yup
    .string()
    .oneOf(['TZS', 'AUD'], 'Invalid currency.')
    .default('TZS')
    .required('Currency is required.'),
  createdBy: yup.string().nullable(),
  updatedBy: yup.string().nullable()
})
