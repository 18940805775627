import React from "react";
import {
  StyledInputControlContainer,
  StyledSearchFormSelectTextField,
} from "../../styledComponents";
import { Autocomplete, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const SearchFormSelect = ({
  label,
  value,
  onChange,
  options,
  error,
  errorMessage,
  required = false,
  disabled,
  placeholder,
  readOnly = false,
}) => {
  const handleChange = (event, newValue) => {
    if (newValue && newValue.item) {
      onChange(newValue.item); // Pass the entire item object if available
    } else if (newValue) {
      onChange(newValue.value); // Pass just the value
    } else {
      onChange(""); // Clear the value if no selection
    }
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <Autocomplete
        value={options.find((option) => option.value === value) || null}
        onChange={handleChange}
        options={options}
        readOnly={readOnly}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => (
          <StyledSearchFormSelectTextField
            {...params}
            placeholder={placeholder}
            error={!!error}
            required={required}
            disabled={disabled}
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value}
        disabled={disabled}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

SearchFormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      item: PropTypes.object,
    })
  ).isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SearchFormSelect;
