/* eslint-disable react/prop-types */
// src/NotificationBell.js
import React, { useState } from 'react'
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  Badge,
  Tabs,
  Tab,
  Grid,
  IconButton
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CloseIcon from '@mui/icons-material/Close'
import {
  deleteNotification,
  updateNotification
} from '../../services/notification.services'

const NotificationBell = ({
  notifications,
  unreadCount,
  setNotifications,
  setUnreadCount
}) => {
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)

  const handleNotificationOpen = (event) => {
    setAnchorNotificationEl(event.currentTarget)
    // Mark all notifications as read
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification }))
    )
    setUnreadCount(0)
  }

  const handleNotificationClose = () => {
    setAnchorNotificationEl(null)
    // window.location.reload()
  }

  const handleNotificationClick = async (notification) => {
    const body = {
      ...notification,
      read: true
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await updateNotification(notification.id, body)
      // if (response) {
      //   window.location.reload()
      // }
    } catch (e) {
      console.log(e)
    }
  }

  const handleNotificationDelete = async (notification) => {
    try {
      const response = await deleteNotification(notification.id)
      if (response) {
        window.location.reload()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const filteredNotifications =
    selectedTab === 0
      ? notifications.filter((notification) => !notification.read)
      : notifications.filter((notification) => notification.read)

  return (
    <div>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleNotificationOpen}
        color="inherit"
      >
        <Badge badgeContent={unreadCount} sx={{
        '& .MuiBadge-badge': {
          backgroundColor: 'red',
        },
      }}>
          <NotificationsIcon />
        </Badge>
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorNotificationEl}
        open={Boolean(anchorNotificationEl)}
        onClose={handleNotificationClose}
        PaperProps={{
          style: {
            width: '300px',
            borderRadius: '8px',
            padding: '10px 0',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <Tab label="Unread" />
          <Tab label="Read" />
        </Tabs>

        {filteredNotifications.length === 0 ? (
          <MenuItem disabled>
            <Typography variant="body2">No notifications</Typography>
          </MenuItem>
        ) : (
          filteredNotifications.map((notification, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleNotificationClick(notification)
              }}
            >
              <Grid
                container
                sx={{
                  alignItems: 'center',
                  backgroundColor:
                    notification.read === false ? '#e6e6e6' : 'transparent',
                  padding: '10px',
                  overflow: 'hidden' // Ensure no overflow
                }}
              >
                <Grid container sx={{ marginBottom: '5px' }}>
                  <Grid item xs={11} sx={{ overflow: 'hidden' }}>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {notification.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {notification.body}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleNotificationDelete(notification)
                      }}
                    >
                      <CloseIcon
                        style={{ color: '#6c6c81', fontSize: '13px' }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  )
}

export default NotificationBell
