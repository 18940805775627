import * as yup from 'yup';

// AccessRights schema
const accessRightsSchema = yup.object().shape({
  serviceId: yup
    .string()
    .required("Service ID is required."),
  serviceName: yup
    .string(),
  add: yup
    .boolean()
    .required("Add permission is required."),
  edit: yup
    .boolean()
    .required("Edit permission is required."),
  view: yup
    .boolean()
    .required("View permission is required."),
  delete: yup
    .boolean()
    .required("Delete permission is required."),
  serviceValue: yup
    .boolean(),
  type: yup
    .string()
    .required("Type is required.")
});

// Roles schema
export const rolesSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, "Name should be at least 3 characters.")
    .max(50, "Name cannot exceed 50 characters.")
    .required("Name is required."),
  order: yup
    .number(),
  accessRights: yup
    .array()
    .of(accessRightsSchema)
    .required("Access rights are required."),
    createdBy: yup
    .string()
    .nullable(),
  updatedBy: yup
    .string()
    .nullable(),
});
