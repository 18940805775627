/* eslint-disable react/prop-types */
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { createTheme } from '@mui/system'
import { getAccountById } from '../../../../services/accountNew.service'
import { useParams } from 'react-router-dom'
import { iconMap } from '../../../../constants/iconsList'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
})

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      marginTop: '5rem'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: '32px',
    color: '#0A2C47',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  backButton: {
    padding: '10px 24px',
    border: '1px solid #D8D8DF',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    fontSize: '13px',
    fontWeight: '600',
    color: '#6C6C81',
    opacity: '30%',
    [theme.breakpoints.down('md')]: {
      padding: '0 5px',
      marginRight: '10px'
    }
  },
  saveButton: {
    padding: '5px 24px',
    border: '1px solid #0772B8',
    borderRadius: '8px',
    backgroundColor: '#0772B8',
    fontSize: '13px',
    fontWeight: '600',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      padding: '0 5px'
    }
  },
  activeButton: {
    height: '35px',
    padding: '8px 12px',
    border: '1px solid #E6FAEA',
    borderRadius: '6px',
    backgroundColor: 'rgb(38, 134, 29)',
    fontSize: '14px',
    fontWeight: '700',
    color: '#ffffff',
    lineHeight: '18.9px',
    margin: '0px 0px 0px 5px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 12px'
    }
  },
  deactiveButton: {
    height: '35px',
    padding: '8px 12px',
    border: '1px solid #E6FAEA',
    borderRadius: '6px',
    backgroundColor: 'rgb(243, 32, 19)',
    fontSize: '14px',
    fontWeight: '700',
    color: '#ffffff',
    lineHeight: '18.9px',
    margin: '0px 0px 0px 5px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 12px'
    }
  },
  buttonBox: {
    display: 'flex'
  }
}
export default function AccountsHeader() {
  const { id } = useParams()
  const [accountDetails, setAccountDetails] = useState()
  useEffect(() => {
    getAccountId()
  }, [])

  const getAccountId = async () => {
    const res = await getAccountById(id)
    setAccountDetails(res)
  }
  function getBackgroundColor(status) {
    switch (status) {
      case 'ACTIVE':
        return '#26861d'
      case 'PENDING':
        return '#e79111'
      case 'CANCELED':
        return '#e71111'
      case 'EXPIRED':
        return '#6c6c81'
      default:
        return '#555'
    }
  }
  function getSubscriptionIcon(status) {
    switch (status) {
      case 'ACTIVE':
        return (
          <img
            src={iconMap['ACTIVE']}
            alt="successIcon"
            style={{ marginRight: '4px' }}
          />
        )
      case 'PENDING':
        return (
          <img
            src={iconMap['PENDING']}
            alt="pendingIcon"
            style={{ marginRight: '4px' }}
          />
        )
      case 'CANCELED':
        return (
          <img
            src={iconMap['CANCELLED']}
            alt="cancelledIcon"
            style={{ marginRight: '4px' }}
          />
        )
      case 'EXPIRED':
        return (
          <img
            src={iconMap['EXPIRED']}
            alt="expiredIcon"
            style={{ marginRight: '4px' }}
          />
        )
      default:
        return null
    }
  }
  return (
    <Box sx={styles.header}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>Edit Account</Typography>
        <span
          style={{
            backgroundColor: getBackgroundColor(
              accountDetails?.subscriptions[0]?.subscriptionStatus
            ),
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            marginLeft: '10px'
          }}
        >
          {getSubscriptionIcon(
            accountDetails?.subscriptions[0]?.subscriptionStatus
          )}
          {accountDetails?.subscriptions[0]?.subscriptionStatus}
        </span>
        {/* {accountDetails?.subscriptions[0].subscriptionStatus === "ACTIVE" ? (
          <Button style={styles.activeButton}>
            <CheckCircleIcon sx={{ mr: "5px" }} />
            {accountDetails?.subscriptions[0].subscriptionStatus}
          </Button>
        ) : (
          <Button style={styles.deactiveButton}>
            <CancelIcon sx={{ mr: "5px" }} />
            {accountDetails?.subscriptions[0].subscriptionStatus}
          </Button>
        )} */}
      </Box>
      {/* {tabValue !== '1' && (
              <Box sx={styles.buttonBox}>
                <Button sx={styles.backButton}>CANCEL</Button>
                <Button sx={styles.saveButton}>UPDATE CHANGE</Button>
              </Box>
            )} */}
    </Box>
  )
}
