/* eslint-disable no-unused-vars */
import { Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import CreateButton from './CreateButton'
import { getRoute } from '../../constants/routes';
import { iconMap } from '../../constants/iconsList';
import { StyledEmptyStateContainer } from '../../styledComponents';

export default function EmptyState({ name, message, info }) {
  const iconKey = `${name.toUpperCase()} EMPTY STATE`;
  console.log(iconKey)
  // const IconComponent = iconMap[iconKey];
  return (
    <StyledEmptyStateContainer container style={{height: 'calc(100vh - 390px)'}}>
      <Grid item>
      <IconButton>
        <img src={iconMap[iconKey]} alt="SearchIcon" style={{height: '100px'}} />
      </IconButton>
        {/* {IconComponent && <IconComponent sx={{ fontSize: '45px' }} />} */}
      </Grid>
      <Typography sx={{ fontSize: '26px', fontWeight: '600', color: '#0A2C47', py: 1 }}>
       {message}
      </Typography>
      <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#6C6C81', pb: 1 }}>
        {info}
      </Typography>
      {/* <CreateButton name={name} navigate={getRoute(name, 'Create')} /> */}
    </StyledEmptyStateContainer>
  );
}

EmptyState.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  };
