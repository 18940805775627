/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchAllServices } from "../../../../services/serviceNew.services";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ServiceType } from "../../../../utils/constant";

const ServicesFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
    type: "",
  });
  const [services, setServices] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingServices, setIsLoadingServices] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchAllServices();
  }, [page, limit]);

  const _fetchAllServices = async () => {
    if (isLoadingServices) return; // Prevent multiple calls
    setIsLoadingServices(true);

    try {
      let allServices = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllServices(currentPage, limit);
        allServices = [...allServices, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setServices(allServices);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching services:", errorMessage);
    } finally {
      setIsLoadingServices(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={[
              ...new Map(
                services.map((item) => [
                  item.name,
                  { value: item.id, label: item.name },
                ])
              ).values(),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Type"
            value={filters.type}
            onChange={(selected) => {
              handleFieldChange("type", selected);
            }}
            options={ServiceType.map((item) => ({
              value: item.value,
              label: item.name,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};

ServicesFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default ServicesFilters;
