import { Box } from '@mui/system'
import React from 'react'
import ComingSoon from '../../../common/ComingSoon'

export default function TermsAndConditions() {
    return (
        <Box sx={{ margin: '8% 2% 2%' }}>
            <ComingSoon name='Terms and Conditions' />
        </Box>
    )
}
