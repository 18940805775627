/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

// Material-UI imports
import { Grid, IconButton, Menu, Tabs, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

// Styled components and utility functions
import {
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
  StyledCard,
  StyledCardHeader,
  CardLabel,
} from "../../../../styledComponents";
import {
  formatDate,
  getConsignmentsStatusColor,
  getConsignmentsStatusBgColor,
  getCurrentUTCDate,
  appendFormData,
  formatDate2,
  formatToLocal,
} from "../../../../utils/functions";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";

// Service and common components
import Loader from "../../../common/Loader";
import lineIcon from "../../../../assets/icons/lineIcon.svg";
import UnitAllocationModal from "../../../modals/UnitAllocationModal";
import {
  fetchConsignmentsByDriverId,
  fetchConsignmentsByDriverIdAndAccountIdAndCustomerName,
  fetchConsignmentsByDriverIdAndAccountIdAndTripStatus,
  fetchConsignmentsByDriverIdAndAccountIdAndUnitName,
  updateConsignment,
} from "../../../../services/consignmentNew.service";
import DriverAllocationModal from "../../../modals/DriverAllocationModal";
import EmptyState from "../../../common/EmptyState";
import ConsignmentModal from "../../../modals/ConsignmentModal";
import SearchField from "../../../common/SearchField";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { iconMap } from "../../../../constants/iconsList";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const DriversConsignmentsListCard = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by consignment ID on open and close 3 dots menu option
  const [openMenuConsignmentId, setOpenMenuConsignmentId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  const [consignmentOpen, setConsignmentOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [shouldReload, setShouldReload] = useState(false);
  const [unitOpen, setUnitOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const [consignments, setConsignments] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useState(15);
  const [count, setCount] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false); // Add empty state
  const [searchPage, setSearchPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Hooks for navigation and outlet context
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, consignment) => {
    setOpenMenuConsignmentId(consignment.id); // Set the ID of the consignment whose menu should be open
    setSelectedItem(consignment);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuConsignmentId(null); // Set the ID of the consignment whose menu should be open
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, consignmentId) => {
      handleActionsMenuClose();
      setSelectedItem(consignmentId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    // setSelectedItem(null)
  }, [setSelectedItem, setOpen]);

  const handleConsignmentModalClose = useCallback(() => {
    setConsignmentOpen(false);
    // setSelectedItem(null)
  }, [setSelectedItem, setConsignmentOpen]);

  // Function that calls the API to perform deleteConsignment
  const deleteConsignment = useCallback(() => {
    setOpen(false);
    // setSelectedItem(null)
  }, [setOpen, setSelectedItem]);

  // Function that calls the API to perform deleteConsignment
  const handleDriverAllocationModalOpen = useCallback(() => {
    setDriverOpen(true);
    setUnitOpen(false);
  }, []);

  const handleUnitModalOpen = useCallback(
    (event, consignmentId) => {
      handleActionsMenuClose();
      // setSelectedItem(consignmentId)
      setUnitOpen(true);
    },
    [handleActionsMenuClose]
  );

  const handleUnitAllocationModalClose = useCallback(() => {
    setUnitOpen(false);
    // setSelectedItem(null)
  }, []);

  const allocateUnit = useCallback(
    (unit) => {
      // Update consignment with unitId = unit.id
      // Update consignment with tripStatus = 'UNIT_ALLOCATION'
      // Update consignment unitAllocationDateTime = new Date()

      if (selectedItem) {
        const updatedConsignment = {
          ...selectedItem,
          unitId: unit.id,
          tripStatus: "UNIT_ALLOCATION",
          unitAllocationDateTime: getCurrentUTCDate(),
        };

        setSelectedItem(updatedConsignment);
        _updateConsignmentStatusDetails(updatedConsignment);
      } else {
        console.log("No consignment selected");
      }
    },
    [selectedItem]
  );

  const allocateDriver = useCallback(
    (driver) => {
      // Update consignment with driverId = driver.id
      // Update consignment with tripStatus = 'UNIT_ALLOCATION'
      // Update consignment unitAllocationDateTime = new Date()

      if (selectedItem) {
        const updatedConsignment = {
          ...selectedItem,
          driverId: driver.id,
          tripStatus: "DRIVER_ALLOCATION",
          driverAllocationDateTime: getCurrentUTCDate(),
        };

        setSelectedItem(updatedConsignment);
        _updateConsignmentStatusDetails(updatedConsignment);
      } else {
        console.log("No consignment selected");
      }
    },
    [selectedItem]
  );

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected Consignment has been set:", selectedItem);
    }
  }, [selectedItem]);

  const _updateConsignmentStatusDetails = useCallback(
    async (formData) => {
      // setIsLoading(true);
      const formData1 = new FormData();
      appendFormData(formData1, formData);
      try {
        const response = await updateConsignment(formData1);
        if (response) {
          // setIsLoading(false);
          // setSuccessMessage('Consignment updated successfully!');
          // setErrorMessage('');
          // OPEN DRIVER MODAL
          if (response.tripStatus === "UNIT_ALLOCATION") {
            setUnitOpen(false);
            setDriverOpen(true);
          } else {
            setDriverOpen(false);
          }
          // window.scrollTo(0, 0);
          // setTimeout(() => {
          //   navigate('/monitoring/consignments');
          // }, 3000);
        }
      } catch (error) {
        // setIsLoading(false);
        // setErrorMessage('Failed to update consignment. Please try again.');
        console.error("Error updating consignment:", error);
      }
    },
    [navigate]
  );

  //handleDriverModalOpen AND ETC...

  const handleDriverAllocationModalClose = useCallback(() => {
    setDriverOpen(false);
    // setSelectedItem(null)
  }, []);

  const handleDriverAllocationModalBack = useCallback(() => {
    setDriverOpen(false);
    setUnitOpen(true);
  }, []);

  const handleCardClick = useCallback((consignment) => {
    setSelectedItem(consignment);
    setConsignmentOpen(true);
  }, []);

  // useEffect(() => {
  //   _fetchConsignmentsByDriverId();
  // }, []);

  useEffect(() => {
    if (!searchValue) {
      _fetchConsignmentsByDriverId();
    } else if (filterOption === "Customer Name") {
      _fetchConsignmentsByDriverIdAndAccountIdAndCustomerName(
        id,
        searchValue,
        searchPage,
        limit
      );
    } else if (filterOption === "Unit Name") {
      _fetchConsignmentsByDriverIdAndAccountIdAndUnitName(
        id,
        searchValue,
        searchPage,
        limit
      );
    } else if (filterOption === "Trip Status") {
      _fetchConsignmentsByDriverIdAndAccountIdAndTripStatus(
        id,
        searchValue,
        searchPage,
        limit
      );
    }
    // Reset the trigger
    setTrigger(false);
  }, [page, trigger, searchPage]);

  const _fetchConsignmentsByDriverId = async () => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchConsignmentsByDriverId(id, page, limit);
      setCount(response.count);

      const formattedRows = response.rows?.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to,
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to,
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          ),
        },
        checkpoints: element.checkpoints,
      }));

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows,
      ]);
      setIsEmpty(formattedRows.length === 0); // Set empty state
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching consignments:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchConsignmentsByDriverIdAndAccountIdAndCustomerName = async (
    id,
    searchValue,
    searchPage,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response =
        await fetchConsignmentsByDriverIdAndAccountIdAndCustomerName(
          id,
          searchValue,
          searchPage,
          limit
        );
      setCount(response.count);

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to,
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to,
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          ),
        },
        checkpoints: element.checkpoints,
      }));

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows,
      ]);
      setIsEmpty(formattedRows.length === 0); // Set empty state
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching consignments:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchConsignmentsByDriverIdAndAccountIdAndUnitName = async (
    id,
    searchValue,
    searchPage,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchConsignmentsByDriverIdAndAccountIdAndUnitName(
        searchValue,
        id,
        searchPage,
        limit
      );
      setCount(response.count);

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to,
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to,
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          ),
        },
        checkpoints: element.checkpoints,
      }));

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows,
      ]);
      setIsEmpty(formattedRows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      console.log(consignments);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchConsignmentsByDriverIdAndAccountIdAndTripStatus = async (
    id,
    searchValue,
    searchPage,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response =
        await fetchConsignmentsByDriverIdAndAccountIdAndTripStatus(
          id,
          searchValue,
          searchPage,
          limit
        );
      setCount(response.count);

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to,
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to,
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          ),
        },
        checkpoints: element.checkpoints,
      }));

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows,
      ]);
      setIsEmpty(formattedRows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      console.log(consignments);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteConsignment(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Consignment deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate("/monitoring/drivers");
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ marginBottom: "30px" }}
        container
        alignItems="center"
      >
        <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
          {/* <ArrowBackIcon sx={{ color: "#0a2c47" }} /> */}
          <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
        </IconButton>
        <Typography
          sx={{ fontSize: "32px", fontWeight: 600, color: "#0a2c47" }}
        >
          Driver Consignments
        </Typography>
      </Grid>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      {/* <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="consignment tabs"
      >
        <Tab label="Received" />
        <Tab label="In Progress" />
        <Tab label="Delivered" />
      </Tabs> */}

      <StyledListCardContainer container spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="CONSIGNMENTS"
              message="Oops! You have no consignments for your search criteria"
              info="Please feel free to create a new consignment by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}

        {consignments.map((consignment) => (
          <Grid item xs={12} sm={6} md={4} key={consignment.id}>
            <StyledCard>
              <StyledCardHeader
                sx={{
                  backgroundColor: getConsignmentsStatusBgColor(
                    consignment.tripStatus
                  ),
                }}
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">
                      {consignment.customerName}
                    </Typography>
                    <Typography
                      variant="cardStatus"
                      sx={{
                        color: getConsignmentsStatusColor(
                          consignment.tripStatus
                        ),
                      }}
                    >
                      &bull; &nbsp;
                      {consignment.tripStatus}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${consignment.id}`}
                      onClick={(event) =>
                        handleActionsMenuOpen(event, consignment)
                      }
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${consignment.id}`
                      )}
                      open={openMenuConsignmentId === consignment.id}
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      {(consignment.tripStatus === "RECEIVED" ||
                        consignment.tripStatus === "UNIT_ALLOCATION" ||
                        consignment.tripStatus === "DRIVER_DECLINED") && (
                        <StyledMenuItem
                          onClick={(e) => handleUnitModalOpen(e, consignment)}
                        >
                          <LocalShippingIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography>Assign Unit and Driver</Typography>
                        </StyledMenuItem>
                      )}
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, consignment)}
                      >
                        <DeleteOutlineIcon
                          sx={{ marginRight: "10px", color: "#0772B8" }}
                        />
                        <Typography>Delete Consignment</Typography>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => handleCardClick(consignment)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Unit</Typography>
                    <Typography variant="cardValue">
                      {consignment.unitName === "" ||
                      consignment.unitName == null
                        ? "--"
                        : consignment.unitName}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Driver</Typography>
                    <Typography variant="cardValue">
                      {consignment.driverName === "" ||
                      consignment.driverName == null
                        ? "--"
                        : consignment.driverName}
                    </Typography>
                  </FlexContainerSB>
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#F7F7F8",
                      width: "100%",
                      alignItems: "center",
                      padding: "12px",
                      marginBottom: "16px",
                      borderRadius: "8px",
                      border: "1px solid #E0E0E0",
                    }}
                  >
                    {/* Pickup and Delivery Locations */}
                    <div style={{ marginRight: "28px" }}>
                      <img src={lineIcon} alt="line icon" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* Pickup Location */}
                      <Grid
                        item
                        container
                        sx={{ flexDirection: "column", marginBottom: "12px" }}
                      >
                        <CardLabel variant="cardLabel">
                          Pick Up Location
                        </CardLabel>
                        <Typography
                          variant="cardValue"
                          style={{ marginBottom: "3px" }}
                        >
                          {consignment?.pickupDetails.address.addressType ===
                            "Address" && (
                            <>
                              {consignment?.pickupDetails?.address
                                ?.streetOne && (
                                <div>
                                  {
                                    consignment?.pickupDetails?.address
                                      ?.streetOne
                                  }
                                </div>
                              )}
                              {consignment?.pickupDetails?.address
                                ?.streetTwo && (
                                <div>
                                  {
                                    consignment?.pickupDetails?.address
                                      ?.streetTwo
                                  }
                                </div>
                              )}
                              {consignment?.pickupDetails?.address && (
                                <div>
                                  {consignment?.pickupDetails?.address?.city}
                                </div>
                              )}
                              {consignment?.pickupDetails?.address && (
                                <div>
                                  {`${consignment?.pickupDetails?.address?.state}
                                ${consignment?.pickupDetails?.address?.country} 
                                ${consignment?.pickupDetails?.address?.postalCode}`}
                                </div>
                              )}
                            </>
                          )}
                          {consignment?.pickupDetails.address.addressType ===
                            "Geofence" && (
                            <>
                              {consignment?.pickupDetails?.address
                                .geofenceName && (
                                <div>
                                  {
                                    consignment?.pickupDetails?.address
                                      .geofenceName
                                  }
                                </div>
                              )}
                            </>
                          )}
                        </Typography>
                        <Typography>
                          {formatDate(
                            consignment?.deliverySchedule?.expectedPickupDate
                          )}{" "}
                          ({consignment?.deliverySchedule?.pickupTime?.from})
                        </Typography>
                      </Grid>

                      {/* Delivery Location */}
                      <Grid item container sx={{ flexDirection: "column" }}>
                        <CardLabel variant="cardLabel">
                          Delivery Location
                        </CardLabel>
                        <Typography
                          variant="cardValue"
                          style={{ marginBottom: "3px" }}
                        >
                          {consignment?.deliveryDetails.address.addressType ===
                            "Address" && (
                            <>
                              {consignment?.deliveryDetails?.address
                                ?.streetOne && (
                                <div>
                                  {
                                    consignment?.deliveryDetails?.address
                                      ?.streetOne
                                  }
                                </div>
                              )}
                              {consignment?.deliveryDetails?.address
                                ?.streetTwo && (
                                <div>
                                  {
                                    consignment?.deliveryDetails?.address
                                      ?.streetTwo
                                  }
                                </div>
                              )}
                              {consignment?.deliveryDetails?.address && (
                                <div>
                                  {consignment?.deliveryDetails?.address?.city}
                                </div>
                              )}
                              {consignment?.deliveryDetails?.address && (
                                <div>
                                  {`${consignment?.deliveryDetails?.address?.state}
                                ${consignment?.deliveryDetails?.address?.country} 
                                ${consignment?.deliveryDetails?.address?.postalCode}`}
                                </div>
                              )}
                            </>
                          )}
                          {consignment?.deliveryDetails.address.addressType ===
                            "Geofence" && (
                            <>
                              {consignment?.deliveryDetails?.address
                                .geofenceName && (
                                <div>
                                  {
                                    consignment?.deliveryDetails?.address
                                      .geofenceName
                                  }
                                </div>
                              )}
                            </>
                          )}
                        </Typography>
                        <Typography>
                          {formatDate(
                            consignment?.deliverySchedule?.expectedDeliveryDate
                          )}{" "}
                          ({consignment?.deliverySchedule?.deliveryTime?.from})
                        </Typography>
                      </Grid>
                    </div>
                  </div>
                  <Grid item container sx={{ flexDirection: "column" }}>
                    <Typography item variant="cardLabel">
                      Special Instructions
                    </Typography>
                    <Typography item variant="cardValue">
                      {consignment?.additionalInformation
                        ?.specialInstructions || "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </StyledListCardContent>
            </StyledCard>
          </Grid>
        ))}
      </StyledListCardContainer>
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
      <UnitAllocationModal
        unitOpen={unitOpen}
        handleUnitAllocationModalClose={handleUnitAllocationModalClose}
        handleDriverAllocationModalOpen={handleDriverAllocationModalOpen}
        allocateUnit={allocateUnit}
        consignment={selectedItem}
      />
      <DriverAllocationModal
        driverOpen={driverOpen}
        handleDriverAllocationModalClose={handleDriverAllocationModalClose}
        allocateDriver={allocateDriver}
        handleDriverAllocationModalBack={handleDriverAllocationModalBack}
      />
      <ConsignmentModal
        consignmentOpen={consignmentOpen}
        handleConsignmentModalClose={handleConsignmentModalClose}
        consignment={selectedItem}
      />
    </>
  );
};

export default DriversConsignmentsListCard;
