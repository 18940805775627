/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import SearchField from "../../../common/SearchField";
import { Grid } from "@mui/material";
import { fetchBillingPlansByMultipleCriteria } from "../../../../services/billingPlanNew.service";
import { formatDate2 } from "../../../../utils/functions";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import BillingPlanFilters from "../components/BillingPlanFilter";

export default function BillingPlansPage() {
  // State hooks for data storage
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [billingPlans, setBillingPlans] = useState([]);
  const [limit] = useState(15);
  const [filters, setFilters] = useState({
    id: "",
    accountId: "",
  });
  // Introduce a new state to act as a trigger for useEffect
  const [trigger, setTrigger] = useState(false);

  // Additional states for search value and filter option
  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("billing-plans") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchBillingPlansByMultipleCriteria(
      filters.id,
      filters.accountId,
      page,
      limit
    );
  }, [page, filters]);

  const _fetchBillingPlansByMultipleCriteria = async (
    id,
    accountId,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchBillingPlansByMultipleCriteria(
        id,
        accountId,
        page,
        limit
      );
      setCount(response.count);
      setBillingPlans((prevBillingPlans) => [
        ...prevBillingPlans,
        ...response.rows,
      ]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setBillingPlans([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (billingPlans.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All billingPlans have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Billing Plan" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <BillingPlanFilters onFiltersChange={handleFiltersChange} />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Billing Plan" />
              <ExportButton
                name="Billing Plans"
                accounts={billingPlans}
              ></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ billingPlans, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}
