/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { styled } from '@mui/system'

import DeleteModal from '../../../modals/DeleteModal'
import Loader from '../../../common/Loader'
import {
  appendFormData,
  formatDate,
  formatDate2,
  formatToLocal,
  getConsignmentsStatusColor,
  getCurrentUTCDate
} from '../../../../utils/functions'
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableBody
} from '../../../../styledComponents'
import { consignmentTableColumnNew } from '../../../../utils/constant'
import EmptyState from '../../../common/EmptyState'
import UnitAllocationModal from '../../../modals/UnitAllocationModal'
import DriverAllocationModal from '../../../modals/DriverAllocationModal'
import {
  fetchConsignmentsByDriverId,
  fetchConsignmentsByDriverIdAndAccountIdAndCustomerName,
  fetchConsignmentsByDriverIdAndAccountIdAndTripStatus,
  fetchConsignmentsByDriverIdAndAccountIdAndUnitName,
  updateConsignment
} from '../../../../services/consignmentNew.service'
import SearchField from '../../../common/SearchField'
import ViewModeSwitch from '../../../common/ViewModeSwitch'
import ExportButton from '../../../common/ExportButton'
import ConsignmentModal from '../../../modals/ConsignmentModal'

const StyledTableContainer = styled(TableContainer)`
  height: calc(100vh - 200px); /* Adjust height as needed */
  overflow-y: auto;
`

const TableWrapper = styled('div')`
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
`

const StyledTable = styled(Table)`
  min-width: 1100px; /* Adjust min-width based on your table content */
`

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    background-color: #f0f8ff;
    font-weight: bold;
  }
`

const DriversConsignmentsListTable = () => {
  const { id } = useParams()
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by consignment ID on open and close 3 dots menu option
  const [openMenuConsignmentId, setOpenMenuConsignmentId] = useState(null)
  // This is for the delete modal
  const [open, setOpen] = useState(false)
  const [consignmentOpen, setConsignmentOpen] = useState(false)
  const [selectedConsignment, setSelectedConsignment] = useState(null)
  const [shouldReload, setShouldReload] = useState(false)
  const [unitOpen, setUnitOpen] = useState(false)
  const [driverOpen, setDriverOpen] = useState(false)
  const [consignments, setConsignments] = useState([])
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [limit] = useState(15)
  const [count, setCount] = useState(0)
  const [isEmpty, setIsEmpty] = useState(false) // Add empty state
  const [searchPage, setSearchPage] = useState(1)
  const [searchResults, setSearchResults] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [filterOption, setFilterOption] = useState('')
  const [trigger, setTrigger] = useState(false)

  // Hooks for navigation and outlet context
  const navigate = useNavigate()
  //   const { consignments, fetchMore, isLoading, isEmpty } = useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null)

  // Scroll event handler
  //   const handleScroll = useCallback(() => {
  //     const container = containerRef.current;
  //     // Check if the consignment is at the bottom of the container and if we are not already loading
  //     if (
  //       container.scrollHeight - container.scrollTop <= container.clientHeight &&
  //       !isLoading
  //     ) {
  //       // setIsLoading(true); // Set loading to true to prevent multiple calls
  //       fetchMore();
  //     }
  //   }, [fetchMore, isLoading]);

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload()
    }
  }, [shouldReload])

  // Attach the scroll event listener
  //   useEffect(() => {
  //     const container = containerRef.current;
  //     if (container) {
  //       container.addEventListener("scroll", handleScroll);
  //     }
  //     // Remove the event listener on cleanup
  //     return () => {
  //       if (container) {
  //         container.removeEventListener("scroll", handleScroll);
  //       }
  //     };
  //   }, [consignments, isLoading, handleScroll]); // Include dependencies that affect the event listener

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, consignment) => {
    setOpenMenuConsignmentId(consignment.id) // Set the ID of the consignment whose menu should be open
    setSelectedConsignment(consignment)
  }, [])

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuConsignmentId(null) // Set the ID of the consignment whose menu should be open
  }, [])

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, consignmentId) => {
      handleActionsMenuClose()
      setSelectedConsignment(consignmentId)
      setOpen(true)
    },
    [handleActionsMenuClose, setSelectedConsignment, setOpen]
  )

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false)
    // setSelectedConsignment(null)
  }, [setSelectedConsignment, setOpen])

  const handleConsignmentModalClose = useCallback(() => {
    setConsignmentOpen(false)
    // setSelectedConsignment(null)
  }, [setSelectedConsignment, setConsignmentOpen])

  // Function that calls the API to perform deleteConsignment
  const deleteConsignment = useCallback(() => {
    setOpen(false)
    // setSelectedConsignment(null)
  }, [setOpen, setSelectedConsignment])

  // Function that calls the API to perform deleteConsignment

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (consignmentId) => {
      // const tab = "details";
      navigate(`/monitoring/consignments/edit/${consignmentId}`)
      // Your navigation logic here
    },
    [navigate]
  )

  const handleUnitModalOpen = useCallback(
    (event, consignmentId) => {
      handleActionsMenuClose()
      // setSelectedConsignment(consignmentId)
      setUnitOpen(true)
    },
    [handleActionsMenuClose]
  )

  const handleUnitAllocationModalClose = useCallback(() => {
    setUnitOpen(false)
    // setSelectedConsignment(null)
  }, [])

  const allocateUnit = useCallback(
    (unit) => {
      // Update consignment with unitId = unit.id
      // Update consignment with tripStatus = 'UNIT_ALLOCATION'
      // Update consignment unitAllocationDateTime = new Date()

      if (selectedConsignment) {
        const updatedConsignment = {
          ...selectedConsignment,
          unitId: unit.id,
          tripStatus: 'UNIT_ALLOCATION',
          unitAllocationDateTime: getCurrentUTCDate()
        }

        setSelectedConsignment(updatedConsignment)
        _updateConsignmentStatusDetails(updatedConsignment)
      } else {
        console.log('No consignment selected')
      }
    },
    [selectedConsignment]
  )

  const allocateDriver = useCallback(
    (driver) => {
      // Update consignment with driverId = driver.id
      // Update consignment with tripStatus = 'UNIT_ALLOCATION'
      // Update consignment unitAllocationDateTime = new Date()

      if (selectedConsignment) {
        const updatedConsignment = {
          ...selectedConsignment,
          driverId: driver.id,
          tripStatus: 'DRIVER_ALLOCATION',
          driverAllocationDateTime: getCurrentUTCDate()
        }

        setSelectedConsignment(updatedConsignment)
        _updateConsignmentStatusDetails(updatedConsignment)
      } else {
        console.log('No consignment selected')
      }
    },
    [selectedConsignment]
  )

  useEffect(() => {
    if (selectedConsignment) {
      console.log('Selected Consignment has been set:', selectedConsignment)
    }
  }, [selectedConsignment])

  const _updateConsignmentStatusDetails = useCallback(
    async (formData) => {
      // setIsLoading(true);
      const formData1 = new FormData()
      appendFormData(formData1, formData)
      try {
        const response = await updateConsignment(formData1)
        if (response) {
          // setIsLoading(false);
          // setSuccessMessage('Consignment updated successfully!');
          // setErrorMessage('');
          // OPEN DRIVER MODAL
          if (response.tripStatus === 'UNIT_ALLOCATION') {
            setUnitOpen(false)
            setDriverOpen(true)
          } else {
            setDriverOpen(false)
          }
          // window.scrollTo(0, 0);
          // setTimeout(() => {
          //   navigate('/monitoring/consignments');
          // }, 3000);
        }
      } catch (error) {
        // setIsLoading(false);
        // setErrorMessage('Failed to update consignment. Please try again.');
        console.error('Error updating consignment:', error)
      }
    },
    [navigate]
  )

  //handleDriverModalOpen AND ETC...

  const handleDriverAllocationModalClose = useCallback(() => {
    setDriverOpen(false)
    // setSelectedConsignment(null)
  }, [])

  const _updateDriverDetails = useCallback(
    async (id, formData) => {
      // setIsLoading(true);
      try {
        const response = await updateConsignment(id, formData)
        if (response) {
          // setIsLoading(false);
          // setSuccessMessage('Consignment updated successfully!');
          // setErrorMessage('');
          // window.scrollTo(0, 0);
          // setTimeout(() => {
          //   navigate('/monitoring/consignments');
          // }, 3000);
        }
      } catch (error) {
        // setIsLoading(false);
        // setErrorMessage('Failed to update consignment. Please try again.');
        console.error('Error updating consignment:', error)
      }
    },
    [navigate]
  )

  const handleDriverAllocationModalBack = useCallback(() => {
    setDriverOpen(false)
    setUnitOpen(true)
  }, [])

  const handleCardClick = useCallback((consignment) => {
    setSelectedConsignment(consignment)
    setConsignmentOpen(true)
  }, [])

  // useEffect(() => {
  //   _fetchConsignmentsByDriverId();
  // }, []);

  useEffect(() => {
    if (!searchValue) {
      _fetchConsignmentsByDriverId()
    } else if (filterOption === 'Customer Name') {
      _fetchConsignmentsByDriverIdAndAccountIdAndCustomerName(
        id,
        searchValue,
        searchPage,
        limit
      )
    } else if (filterOption === 'Unit Name') {
      _fetchConsignmentsByDriverIdAndAccountIdAndUnitName(
        id,
        searchValue,
        searchPage,
        limit
      )
    } else if (filterOption === 'Trip Status') {
      _fetchConsignmentsByDriverIdAndAccountIdAndTripStatus(
        id,
        searchValue,
        searchPage,
        limit
      )
    }
    // Reset the trigger
    setTrigger(false)
  }, [page, trigger, searchPage])

  const _fetchConsignmentsByDriverId = async () => {
    if (isLoading) return // Prevent multiple calls
    setIsLoading(true)

    try {
      const response = await fetchConsignmentsByDriverId(id, page, limit)
      setCount(response.count)

      const formattedRows = response.rows?.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          )
        },
        checkpoints: element.checkpoints
      }))

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows
      ])
      setIsEmpty(formattedRows.length === 0) // Set empty state
    } catch (error) {
      const errorMessage = error?.message || 'An unknown error occurred'
      console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const _fetchConsignmentsByDriverIdAndAccountIdAndCustomerName = async (
    id,
    searchValue,
    searchPage,
    limit
  ) => {
    if (isLoading) return // Prevent multiple calls
    setIsLoading(true)

    try {
      const response =
        await fetchConsignmentsByDriverIdAndAccountIdAndCustomerName(
          id,
          searchValue,
          searchPage,
          limit
        )
      setCount(response.count)

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          )
        },
        checkpoints: element.checkpoints
      }))

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows
      ])
      setIsEmpty(formattedRows.length === 0) // Set empty state
    } catch (error) {
      const errorMessage = error?.message || 'An unknown error occurred'
      console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const _fetchConsignmentsByDriverIdAndAccountIdAndUnitName = async (
    id,
    searchValue,
    searchPage,
    limit
  ) => {
    if (isLoading) return // Prevent multiple calls
    setIsLoading(true)

    try {
      const response = await fetchConsignmentsByDriverIdAndAccountIdAndUnitName(
        searchValue,
        id,
        searchPage,
        limit
      )
      setCount(response.count)

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          )
        },
        checkpoints: element.checkpoints
      }))

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows
      ])
      setIsEmpty(formattedRows.length === 0) // Set empty state
    } catch (error) {
      console.log(error)
      console.log(consignments)
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const _fetchConsignmentsByDriverIdAndAccountIdAndTripStatus = async (
    id,
    searchValue,
    searchPage,
    limit
  ) => {
    if (isLoading) return // Prevent multiple calls
    setIsLoading(true)

    try {
      const response =
        await fetchConsignmentsByDriverIdAndAccountIdAndTripStatus(
          id,
          searchValue,
          searchPage,
          limit
        )
      setCount(response.count)

      const formattedRows = response.rows.map((element) => ({
        ...element,
        deliverySchedule: {
          ...element.deliverySchedule,
          pickupTime: {
            from: element.deliverySchedule.pickupTime.from,
            to: element.deliverySchedule.pickupTime.to
          },
          deliveryTime: {
            from: element.deliverySchedule.deliveryTime.from,
            to: element.deliverySchedule.deliveryTime.to
          },
          expectedPickupDate: formatToLocal(
            element.deliverySchedule.expectedPickupDate
          ),
          expectedDeliveryDate: formatToLocal(
            element.deliverySchedule.expectedDeliveryDate
          )
        },
        checkpoints: element.checkpoints
      }))

      setConsignments((prevConsignments) => [
        ...prevConsignments,
        ...formattedRows
      ])
      setIsEmpty(formattedRows.length === 0) // Set empty state
    } catch (error) {
      console.log(error)
      console.log(consignments)
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchChange = async (searchValue, filterOption) => {
    try {
      let response
      // Reset accounts and search results before fetching new data
      setConsignments([])
      setSearchResults([])
      setSearchValue(searchValue)
      setFilterOption(filterOption)

      if (searchValue === '') {
        setPage(1)
        setTrigger(true)
      } else {
        setSearchPage(1) // Reset search page for new search
        if (filterOption === 'Customer Name') {
          response =
            await _fetchConsignmentsByDriverIdAndAccountIdAndCustomerName(
              id,
              searchValue,
              searchPage,
              limit
            )
        } else if (filterOption === 'Unit Name') {
          response = await _fetchConsignmentsByDriverIdAndAccountIdAndUnitName(
            id,
            searchValue,
            searchPage,
            limit
          )
        } else if (filterOption === 'Trip Status') {
          response =
            await _fetchConsignmentsByDriverIdAndAccountIdAndTripStatus(
              id,
              searchValue,
              searchPage,
              limit
            )
        }
        setSearchResults(response.rows) // Update the state with the search results
        setConsignments(response.rows)
        setCount(response.count)
        setIsEmpty(response.rows.length === 0)
      }
    } catch (error) {
      // const errorMessage = error?.message || 'An unknown error occurred'
      console.error('Error searching accounts:', error)
    }
  }

  return (
    <>
      <div className="container">
        <Grid container flexDirection="column">
          <Grid item xs={12} container>
            <Typography
              sx={{ fontSize: '32px', fontWeight: 600, color: '#0a2c47' }}
            >
              Driver Consignments
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: '30px',
              marginBottom: '30px',
              justifyContent: 'space-between'
            }}
          >
            <Grid item xs={12} sm={6}>
              <SearchField
                name="DriverConsignment"
                onSearchChange={handleSearchChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <ViewModeSwitch name="DriverConsignment" />
              <ExportButton name="Consignment"></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="CONSIGNMENTS"
              message="Oops! You have no consignments for your search criteria"
              info="Please feel free to create a new consignment by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableWrapper>
            <StyledTable>
              <TableHead>
                <TableRow>
                  {consignmentTableColumnNew.map((column) => (
                    <StyledTableCell key={column}>{column}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {consignments?.map((consignment) => (
                  <TableRow
                    key={consignment?.id}
                    hover
                    onClick={() => navigateToEditScreen(consignment.id)}
                  >
                    <StyledTableBody>
                      {consignment.customerName}
                    </StyledTableBody>
                    <StyledTableBody>
                      <Typography
                        variant="cardStatus"
                        sx={{
                          color: getConsignmentsStatusColor(
                            consignment.tripStatus
                          )
                        }}
                      >
                        &bull; &nbsp;
                        {consignment.tripStatus}
                      </Typography>
                    </StyledTableBody>
                    {/* <StyledTableBody>
                      {consignment?.cargoDetails?.cargoType?.name}
                    </StyledTableBody> */}
                    <StyledTableBody>
                      {consignment.unitName == '' ||
                      consignment.unitName == null
                        ? '--'
                        : consignment.unitName}
                    </StyledTableBody>
                    <StyledTableBody>
                      {consignment.driverName == null ||
                      consignment.driverName == ''
                        ? '--'
                        : consignment.driverName}
                    </StyledTableBody>
                    <StyledTableBody>
                      {consignment?.pickupDetails?.address && (
                        <>
                          {consignment?.pickupDetails?.address?.streetOne && (
                            <div>
                              {consignment?.pickupDetails?.address?.streetOne}
                            </div>
                          )}
                          {consignment?.pickupDetails?.address && (
                            <div>
                              {`${consignment?.pickupDetails?.address?.city} 
                                    ${consignment?.pickupDetails?.address?.state}
                                    ${consignment?.pickupDetails?.address?.country} 
                                    ${consignment?.pickupDetails?.address?.postalCode}`}
                            </div>
                          )}
                        </>
                      )}
                      {consignment?.pickupDetails?.geofenceId && (
                        <>{`${consignment?.pickupDetails?.geofenceName}`}</>
                      )}
                    </StyledTableBody>
                    <StyledTableBody>
                      {formatDate(
                        consignment?.deliverySchedule?.expectedPickupDate
                      )}{' '}
                      ({consignment?.deliverySchedule?.deliveryTime?.from})
                    </StyledTableBody>
                    <StyledTableBody>
                      {consignment?.deliveryDetails?.address && (
                        <>
                          {consignment?.deliveryDetails?.address?.streetOne && (
                            <div>
                              {consignment?.deliveryDetails?.address?.streetOne}
                            </div>
                          )}
                          {consignment?.deliveryDetails?.address && (
                            <div>
                              {`${consignment?.deliveryDetails?.address?.city} 
                                    ${consignment?.deliveryDetails?.address?.state}
                                    ${consignment?.deliveryDetails?.address?.country} 
                                    ${consignment?.deliveryDetails?.address?.postalCode}`}
                            </div>
                          )}
                        </>
                      )}
                      {consignment?.deliveryDetails?.geofenceId && (
                        <>{`${consignment?.deliveryDetails?.geofenceName}`}</>
                      )}
                    </StyledTableBody>
                    <StyledTableBody>
                      {formatDate(
                        consignment?.deliverySchedule?.expectedDeliveryDate
                      )}{' '}
                      ({consignment?.deliverySchedule?.deliveryTime?.to})
                    </StyledTableBody>
                    <StyledTableBody>
                      {consignment?.additionalInformation
                        ?.specialInstructions || '--'}
                    </StyledTableBody>
                    <StyledTableCell>
                      <StyledIconButton
                        id={`menu-button-${consignment.id}`}
                        onClick={(event) => {
                          event.stopPropagation()
                          handleActionsMenuOpen(event, consignment)
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${consignment.id}`
                        )}
                        open={openMenuConsignmentId === consignment.id} // The menu is open if the openMenuAccountId
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation() // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, consignment)
                          }}
                        >
                          <Grid sx={{ display: 'flex', alignItems: 'block' }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: '10px', color: '#0772B8' }}
                            />
                            <Typography> Delete Consignment</Typography>
                          </Grid>
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation()
                            handleUnitModalOpen(event, consignment)
                          }}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            Assign Unit and Driver
                          </Typography>
                        </StyledMenuItem>
                      </Menu>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableWrapper>
        )}
      </StyledTableContainer>
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedAccount={selectedConsignment}
        deleteAccount={deleteConsignment}
      />
      <UnitAllocationModal
        unitOpen={unitOpen}
        handleUnitAllocationModalClose={handleUnitAllocationModalClose}
        allocateUnit={allocateUnit}
      />
      <DriverAllocationModal
        driverOpen={driverOpen}
        handleDriverAllocationModalClose={handleDriverAllocationModalClose}
        allocateDriver={allocateDriver}
        handleDriverAllocationModalBack={handleDriverAllocationModalBack}
      />
      <ConsignmentModal
        consignmentOpen={consignmentOpen}
        handleConsignmentModalClose={handleConsignmentModalClose}
        consignment={selectedConsignment}
      />
    </>
  )
}

export default DriversConsignmentsListTable
