import React from 'react';
import { StyledInputControlContainer, StyledEndAdornmentTextfield } from '../../styledComponents';
import { Typography, Grid, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import FormError from './FormError';

const FormFieldWithEndAdornment = ({
  label,
  placeholder,
  value,
  onChange,
  type = 'text',
  error,
  errorMessage,
  required = false,
  endAdornmentText,
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <StyledEndAdornmentTextfield
        variant="outlined"
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        type={type}
        error={!!error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Grid className="inputAdornmentContainer">
                <Typography variant="endAdornment">{endAdornmentText}</Typography>
              </Grid>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f44336", // Red color for error border
          },
        }}
      />
      {error && (
        <FormError errorMessage={errorMessage} />
      )}
    </StyledInputControlContainer>
  );
};

FormFieldWithEndAdornment.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  endAdornmentText: PropTypes.string.isRequired,
};

export default FormFieldWithEndAdornment;