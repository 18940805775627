/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import EditTabs from '../../../../layouts/accounts/editTabs'
import { UsersModel } from '../../../../model/users.model'
import { fetchUsersByAccountId } from '../../../../services/userNew.service'
import ErrorMessage from '../../../common/ErrorMessage'
import { useParams } from 'react-router-dom'
// import { CircularProgress } from '@mui/material';

// const styles = {
//     overlay: {
//       position: "fixed",
//       top: "0",
//       left: "0",
//       right: "0",
//       bottom: "0",
//       backgroundColor: "rgba(0, 0, 0, 0)" /* transparent */,
//       zIndex: "9999" /* ensure that the overlay appears on top */,
//       transition: "background-color 0.5s ease-in-out 0.2", // Fade in/out transition
//     },
//     spinner: {
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       transform: "translate(-50%, -50%)",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       opacity: 0, // Initially hidden
//       transition: "opacity 0.5s ease-in-out 0.2s", // Fade in/out transition with a slight delay
//       zIndex: "99999",
//     },
//     spinnerActive: {
//       opacity: 1, // Show spinner when active
//     },
//   };

export default function AccountsUser() {
  const [userData, setUserData] = useState([UsersModel])
  const { id, tab } = useParams()
  const [page, setPage] = useState(1)
  const [limit] = useState(15)
  const [errorMessage, setErrorMessage] = useState('')
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const _fetchUsersByAccountId = async () => {
      // setIsLoading(true);
      try {
        const data = await fetchUsersByAccountId(page, limit, id)
        setUserData(data.rows)
        // setIsLoading(false);
      } catch (error) {
        if (error.response.data) {
          setErrorMessage(error.response.data.message)
          // setIsLoading(false);
        } else {
          // setIsLoading(false);
          setErrorMessage(error.message)
        }
      }
    }
    if (tab == 'users') {
      _fetchUsersByAccountId()
    }
  }, [])

  return (
    <div style={{ marginTop: '38px' }}>
      <ErrorMessage message={errorMessage} />
      {/* {isLoading && (
        <div
          style={{ ...styles.overlay, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          <div style={{ ...styles.spinner, ...styles.spinnerActive }}>
            <CircularProgress style={{ color: "#0762a8" }} />
          </div>
        </div>
      )} */}
      <EditTabs
        name="User"
        navigate="/management/users/create"
        items={userData}
      />
    </div>
  )
}
