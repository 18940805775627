/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { deviceColumn } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import { getDevicesStatusColor } from "../../../../utils/functions";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import ProvisionDeviceModal from "../../../modals/ProvisionDeviceModel";
import UnprovisionDeviceModal from "../../../modals/UnprovisionDeviceModel";
import { updateDevice } from "../../../../services/deviceNew.services";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";

const DevicesListTable = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuDeviceId, setOpenMenuDeviceId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activateOpen, setActivateOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const navigate = useNavigate();
  const { devices, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [devices, isLoading, handleScroll]);

  // const handleActionsMenuOpen = useCallback((event, device) => {
  //   event.stopPropagation(); // Prevents the click from bubbling up
  //   setOpenMenuDeviceId(device.id);
  //   setSelectedItem(device);
  // }, []);

  // Function that calls the API to perform updateDevice
  const handleUpdateDevice = useCallback(
    async (updatedDevice) => {
      try {
        const response = await updateDevice(updatedDevice);
        if (response) {
          setSuccessMessage("Device updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuDeviceId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuDeviceId(null);
    event?.stopPropagation();
  }, []);

  const handleActivateModalOpen = useCallback(
    (event, deviceId) => {
      handleActionsMenuClose();
      setSelectedItem(deviceId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  const provisionDevice = useCallback(() => {
    const status = "PROVISIONED";

    // Update the selectedItem with the new subscriptions array
    const updatedDevice = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedDevice);
    handleUpdateDevice(updatedDevice);

    // Close the modal
    setActivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateDevice, setActivateOpen, setSelectedItem]);

  const handleDeactivateModalOpen = useCallback(
    (event, deviceId) => {
      handleActionsMenuClose();
      setSelectedItem(deviceId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  // const unProvisionDevice = useCallback(() => {
  //   // Check if the status exists before attempting to update it
  //   console.log(selectedItem);
  //   if (selectedItem?.status) {
  //     const updatedStatus = "UNPROVISIONED";

  //     // Update the selectedItem with the new status
  //     const updatedDevice = {
  //       ...selectedItem,
  //       status: updatedStatus, // Set status to INACTIVE
  //     };

  //     // Update the selectedItem and call the update handler
  //     setSelectedItem(updatedDevice);
  //     handleUpdateDevice(updatedDevice);

  //     // Close the modal and reset selected item
  //     setDeactivateOpen(false);
  //     setSelectedItem(null);
  //   } else {
  //     console.error("No status found to update.");
  //   }
  // }, [selectedItem, handleUpdateDevice, setDeactivateOpen, setSelectedItem]);

  const unProvisionDevice = useCallback(() => {
    const status = "UNPROVISIONED";

    // Update the selectedItem with the new subscriptions array
    const updatedDevice = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedDevice);
    handleUpdateDevice(updatedDevice);

    // Close the modal
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateDevice, setDeactivateOpen, setSelectedItem]);

  const handleDeleteModalOpen = useCallback(
    (event, deviceId) => {
      handleActionsMenuClose();
      setSelectedItem(deviceId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteItem(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Device deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected Device has been set:", selectedItem);
    }
  }, [selectedItem]);

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const navigateToEditScreen = useCallback(
    (deviceId) => {
      navigate(`/management/devices/edit/${deviceId}`);
    },
    [navigate]
  );

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer container ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="DEVICES"
              message="Oops! You have no devices for your search criteria"
              info="Please feel free to create a new device by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {deviceColumn.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {devices?.map((device) => (
                  <TableRow
                    key={device.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(device.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                        {device.name}
                      </Typography>
                      <Typography
                        variant="cardStatus2"
                        sx={{
                          color: getDevicesStatusColor(device.status),
                        }}
                      >
                        &bull; &nbsp;
                        {device.status}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {device.deviceType || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {device.UID || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {device.make || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {device.model || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {device.simCardId
                          ? `${device?.simCardDetails?.areaCode} ${device?.simCardDetails?.simCardNumber}`
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${device.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, device);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${device.id}`
                        )}
                        open={openMenuDeviceId === device.id} // The menu is open if the openMenuAccountId matches
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        {device && device.status === "PROVISIONED" ? (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click event from reaching the TableRow
                              handleDeactivateModalOpen(event, device);
                            }}
                          >
                            <BlockOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Unprovision Device
                          </StyledMenuItem>
                        ) : (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click event from reaching the TableRow
                              handleActivateModalOpen(event, device);
                            }}
                          >
                            <VerifiedUserOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Provision Device
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, device);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Delete Device</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <ProvisionDeviceModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={provisionDevice}
      />
      <UnprovisionDeviceModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateItem={unProvisionDevice}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default DevicesListTable;
