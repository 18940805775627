/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  fetchUnitsByAccountId,
  getUnitById
} from '../../../../services/unitNew.service.js'
import { fetchAllAccounts } from '../../../../services/accountNew.service.js'
import {
  createUnitGroup,
  getUnitGroupById,
  updateUnitGroup
} from '../../../../services/unitGroupNew.service.js'
import { UnitGroupModel } from '../../../../model/unitGroups.model.js'
import SearchIcon from '@mui/icons-material/Search'
import { iconMap } from '../../../../constants/iconsList.js'
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSelectAllButton,
  StyledFormControlLabel,
  StyledSearchTextField,
  StyledIconButton3,
  NextButton
} from '../../../../styledComponents.js'
import FormField from '../../../common/FormField.js'
import { validateForm, validateForm2 } from '../../../../utils/yupValidations.js'
import { unitGroupSchema } from '../../../../utils/validation-schemas/unitGroupsSchema.js'
import Loader from '../../../common/Loader.js'
import { getRoute } from '../../../../constants/routes.js'
import ErrorMessage1 from '../../../common/ErrorMessage1.js'
import SuccessMessage from '../../../common/SuccessMessage.js'

export default function UnitGroupsForm() {
  const { id } = useParams()
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('')
  const [unitGroup, setUnitGroup] = useState(UnitGroupModel)
  const [accounts, setAccounts] = useState([])
  const navigate = useNavigate()
  const [unitData, setUnitData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [filteredSelectItems, setFilteredSelectItems] = useState([])
  const [selectedGroupItems, setSelectedGroupItems] = useState([])
  const [activeUnits, setActiveUnits] = useState([])
  const [groupUnits, setGroupUnits] = useState([])
  const [validationErrors, setValidationErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [handleForward, setHandleForward] = useState(false)
  const [defaultsSet, setDefaultsSet] = useState(false);
  const defaultAccountId = localStorage.getItem('defaultAccountId') !== ''
    ? JSON.parse(localStorage.getItem('defaultAccountId'))
    : '';

  //Function to get unit group by id
  useEffect(() => {
    if (id) {
      _getUnitGroupById(id)
    } else {
      setDefaults();
    }
  }, [id])

  const _getUnitGroupById = async (id) => {
    setIsLoading(true)
    try {
      const data = await getUnitGroupById(id)
      setUnitGroup(data)
      // setShowSelectedUnits(true)
      const promises = data.units.map((unit) => getUnitById(unit.unitId)) // Create an array of promises to fetch unit details
      const groupUnits = await Promise.all(promises) // Execute all promises concurrently
      setGroupUnits(groupUnits)
      setSelectedItems(groupUnits)
      handleResponse(data, 'Unit Group fetched successfully!', 'Get');
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const setDefaults = () => {
    const updatedUnitGroup = {
      ...unitGroup,
      accountId: defaultAccountId
    };
    setUnitGroup(updatedUnitGroup);
    setDefaultsSet(true);
  };

  //Function to get all units and get all accounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = await fetchAllAccounts()
        const units = await fetchUnitsByAccountId()
        setAccounts(accounts.rows)
        setUnitData(units.rows)
      } catch (error) {
        handleError(error);
      }
    }
    fetchData()
  }, [])

  //Fetch units by account id
  useEffect(() => {
    const _fetchUnitsByAccountId = async () => {
      try {
        const units = await fetchUnitsByAccountId()
        // Filter units where status is 'ACTIVATED'
        const filteredUnits = units.rows.filter(
          (unit) => unit.status === 'ACTIVATED'
        )
        setActiveUnits(filteredUnits)
        handleResponse(units, 'Units fetched successfully!', 'Get');
      } catch (error) {
        handleError(error);
      }
    }
    _fetchUnitsByAccountId()
  }, [])

  const handleFieldChange = async (name, value) => {
    try {
      // Update the unitGroup state based on the field name
      setUnitGroup((prevUnitGroup) => {
        const updatedUnitGroup = { ...prevUnitGroup, [name]: value };
        return updatedUnitGroup;
      });
  
      // Validate the form field
      await validateForm2(
        { ...unitGroup, [name]: value }, // Pass updated value for validation
        name,
        setValidationErrors,
        unitGroupSchema
      );
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  //Function to search units in left side of select units
  const handleExistingSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  //Function to search units in right side of select units
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  }

  // Function to set units to units group when there is change in selected units
  useEffect(() => {
    setUnitGroup((prevState) => ({
      ...prevState,
      units: filteredSelectItems.map((item) => ({ id: item.id }))
    }))
  }, [filteredSelectItems])

  const [selectedItems, setSelectedItems] = useState([])

  // Function to handle checkbox change
  const handleCheckBox = (value) => {
    // Check if the item is already selected
    const itemIndex = selectedItems.findIndex((item) => item.id === value.id)
    setHandleForward(true)
    if (itemIndex === -1) {
      // If not selected, add it to the array
      setSelectedItems([...selectedItems, value])
    } else {
      // If already selected, remove it from the array
      const updatedItems = [...selectedItems]
      updatedItems.splice(itemIndex, 1)
      setSelectedItems(updatedItems)
    }
  }

  //Function to select all units in left side
  const handleSelectAllCheckBox = () => {
    // If all items are already selected, deselect all items
    if (selectedItems.length === activeUnits.length) {
      setSelectedItems([])
    } else {
      // If not all items are selected, select all items
      setSelectedItems(activeUnits)
    }
  }

  // Function to handle checkbox change
  const handleGroupCheckBox = (value) => {
    // Check if the item is already selected
    const itemIndex = selectedGroupItems.findIndex(
      (item) => item.id === value.id
    )
    if (itemIndex === -1) {
      // If not selected, add it to the array
      setSelectedGroupItems([...selectedGroupItems, value])
    } else {
      // If already selected, remove it from the array
      const updatedItems = [...selectedGroupItems]
      updatedItems.splice(itemIndex, 1)
      setSelectedGroupItems(updatedItems)
    }
  }

  //Function to select all units in right side
  const handleSelectAllGroupCheckBox = () => {
    // If the condition of the ternary operator is false, deselect all items
    if (selectedGroupItems.length !== 0) {
      setSelectedGroupItems([])
    } else {
      // If all items are already selected, select all items
      setSelectedGroupItems(groupUnits)
    }
  }

  //Function to move slected units from left side to right side
  const handleForwardButtonClick = () => {
    if (handleForward) {
      setGroupUnits(selectedItems)
      // Assuming unitGroup is a state containing an object with a 'units' array
      setUnitGroup((prevState) => {
        // Filter out items from selectedItems that are already in the units array
        const filteredSelectedItems = selectedItems.filter(
          (item) => !prevState.units.some((unit) => unit.id === item.id)
        )

        // Map filteredSelectedItems to get only the id as unitId
        const unitsWithIdOnly = filteredSelectedItems.map((item) => ({
          unitId: item.id
        }))
        // Concatenate the filteredSelectedItems array with the existing units array
        if (id) {
          return {
            ...prevState,
            units: [...unitsWithIdOnly]
          }
        } else {
          return {
            ...prevState,
            units: [...prevState.units, ...unitsWithIdOnly]
          }
        }
      })
    }
    // handleSubmit();
  }

  //Function to move slected units from right side to left side
  const handleRewindButtonClick = () => {
    // Filter out items from groupUnits that are present in selectedGroupItems
    const updatedGroupUnits = groupUnits.filter(
      (unit) => !selectedGroupItems.includes(unit)
    )
    // Now updatedGroupUnits contains groupUnits without selectedGroupItems
    setGroupUnits(updatedGroupUnits)
    setSelectedItems(updatedGroupUnits)

    setUnitGroup((prevState) => ({
      ...prevState,
      units: prevState.units.filter((unit) => {
        // Check if the id of the current unit exists in the selectedGroupItems array
        return !selectedGroupItems.some((item) => item.id === unit.unitId)
      })
    }))
    // setGroupUnits(groupUnits)
  }

  //Function to call create/update api on submit
  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const isValid = await validateForm(
        unitGroup,
        setValidationErrors,
        unitGroupSchema
      );

      if (!isValid) {
        console.error('Form validation failed');
        setIsLoading(false);
        return;
      }
      let response

      if (id === undefined) {
        response = await createUnitGroup(unitGroup)
        handleResponse(response, 'Unit Group created successfully!', 'Card');
      } else {
        response = await updateUnitGroup(unitGroup)
        handleResponse(response, 'Unit Group updated successfully!', 'Card');
      }
    } catch (error) {
      handleError(error);
    }finally {
      setIsLoading(false);
    }
  }

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Unit Group", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === 'ValidationError') {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage('');
  };

  const handleBackClick = () => {
    navigate(-1);
    };

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null; // or any other fallback UI you want to show
  }

  return (
    <StyledFormContainer>
    <ErrorMessage1 errors={errors} onClose={handleClose} />
    <SuccessMessage message={successMessage} onClose={handleClose} />
    {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title item> <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton> {id ? 'Update' : 'Create'} Unit Group</Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Group Name"
            required
            placeholder="Enter Group name"
            value={unitGroup.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <Typography item xs variant="label" >
            Select Units *
          </Typography>
          <Grid container sx={{ height: '50vh' }}>
            <Grid
              item
              xs={12}
              sm={6} 
              md={3}
              sx={{
                height: '50vh',
                border: '1px solid #d8d8df',
                backgroundColor: '#f7f7f8',
                padding: '12px',
                borderRadius: '4px',
                position: 'relative',
              }}
            >
              <Grid
                sx={{
                  marginBottom: '12px'
                }}
              >
                <Typography
                  variant="subTitle1"
                  sx={{
                    fontWeight: 500,
                    letterSpacing: '-0.02em',
                    color: '#0A2C47'
                  }}
                >
                  Active Units
                </Typography>
                <StyledSearchTextField
                  variant="outlined"
                  margin="normal"
                  placeholder={'Find Unit...'}
                  value={searchTerm}
                  onChange={handleExistingSearchChange}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <img src={iconMap['SearchIcon']} alt="SearchIcon" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <hr
                  style={{
                    border: 'none',
                    borderTop: '1px solid #D8D8DF',
                    width: '100%',
                    marginBottom: '10px'
                  }}
                />
                <Box
                sx={{
                      maxHeight: '150px', // Set the desired max height
                      overflow: 'auto',
                      padding: '10px'
                    }}
                >
                {activeUnits
                  .filter(
                    (activeUnit) =>
                      !groupUnits.some(
                        (groupUnit) => groupUnit.id === activeUnit.id
                      ) &&
                      activeUnit.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                  .map((value, index) => (
                    <Box key={index}>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            checked={selectedItems.some(
                              (item) => item.id === value.id
                            )}
                            icon={
                              <img
                                src={iconMap['CheckBox']}
                                alt="CheckBox"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                            checkedIcon={
                              <img
                                src={iconMap['CheckBoxChecked']}
                                alt="CheckBoxChecked"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                          />
                        }
                        label={value.name}
                        onChange={() => handleCheckBox(value)}
                      />
                    </Box>
                  ))}
                </Box>
                <StyledSelectAllButton
                  variant="outlined"
                  onClick={handleSelectAllCheckBox}
                >
                  {selectedItems.length == 0 ? 'Select All' : 'Unselect All'}
                </StyledSelectAllButton>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0px'
              }}
            >
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <StyledIconButton3
                  sx={{
                  transform: 'rotate(180deg)', 
                  }}
                  onClick={handleForwardButtonClick}
                >
                  <img src={iconMap['fastforward2']} alt="fastforward1" />
                </StyledIconButton3>
                <StyledIconButton3
                  onClick={handleRewindButtonClick}
                >
                  <img src={iconMap['fastforward2']} alt="fastforward2" />
                </StyledIconButton3>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                height: '50vh',
                border: '1px solid #d8d8df',
                padding: '12px',
                borderRadius: '4px'
              }}
            >
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // borderBottom: "1px solid #d8d8df",
                  marginBottom: '12px'
                }}
              >
                <Typography
                  item
                  variant="subTitle1"
                  sx={{
                    fontWeight: 500,
                    letterSpacing: '-0.02em',
                    color: '#0A2C47'
                  }}
                >
                  Group Units
                </Typography>
                <StyledSearchTextField
                  item
                  variant="outlined"
                  margin="normal"
                  placeholder={'Find Unit...'}
                  value={searchValue}
                  onChange={handleSearchChange}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <hr
                  style={{
                    border: 'none',
                    borderTop: '1px solid #D8D8DF',
                    width: '100%',
                    marginBottom: '10px'
                  }}
                />
                <Box
                sx={{
                  overflow: 'auto',
                  maxHeight: '150px',
                  padding: '10px'
                }}>
                {groupUnits
                  .filter((unit) =>
                    unit.name.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map((value, index) => (
                    <Box key={index}>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            checked={selectedGroupItems.some(
                              (item) => item.id === value.id
                            )}
                            icon={
                              <img
                                src={iconMap['CheckBox']}
                                alt="CheckBox"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                            checkedIcon={
                              <img
                                src={iconMap['CheckBoxChecked']}
                                alt="CheckBoxChecked"
                                style={{ width: 20, height: 20 }}
                              />
                            }
                          />
                        }
                        label={value.name}
                        onChange={() => handleGroupCheckBox(value)}
                      />
                    </Box>
                  ))}
                </Box>
                <StyledSelectAllButton
                  variant="outlined"
                  onClick={handleSelectAllGroupCheckBox}
                >
                  {selectedGroupItems.length == 0
                    ? 'Select All'
                    : 'Unselect All'}
                </StyledSelectAllButton>
              </Grid>
            </Grid>
          </Grid>
        </StyledSectionRight>
      </StyledFormSection>
      <Grid
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  )
}