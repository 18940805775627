import axios from "axios";
import { awsApi } from "../utils/constant";

// Create an instance of axios with default headers and base URL
const api = axios.create({
  baseURL: awsApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

export const setIpAndPort = async (device) => {
  try {
    const response = await api.post("/setIPandPort", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const setApn = async (device) => {
  try {
    const response = await api.post("/setAPN", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const setLogin = async (device) => {
  try {
    const response = await api.post("/login", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const checkIpIdAndApn = async (device) => {
  try {
    const response = await api.post("/checkIPIDAPN", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const requestOneTimeTrackOnDemand = async (device) => {
  try {
    const response = await api.post("/oneTimeTrackOnDemand", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const setGeoFencing = async (device) => {
  try {
  const response = await api.post("/setGeoFencing", device);
  return response.data;
  } catch (error) {
  handleApiError(error);
  }
  };

export const cancelGeoFencing = async (device) => {
  try {
    const response = await api.post("/cancelGeoFencing", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const inquireGeoFencing = async (device) => {
  try {
    const response = await api.post("/inquireGeoFencing", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const checkDeviceParameters = async (device) => {
  try {
    const response = await api.post("/checkDeviceParameters", device);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

