import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Modal } from "@mui/material";
import {
  StyledModal,
  StyledModalContent,
  StyledModalHr,
  StyledDeleteButton,
  StyledCancelButton,
} from "../../styledComponents";

const ProvisionDeviceModal = ({
  activateOpen,
  handleActivateModalClose,
  selectedItem,
  activateItem,
}) => {
  return (
    <Modal
      open={activateOpen}
      onClose={(e) => handleActivateModalClose(e)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <StyledModal>
        <Typography variant="modalTitle" id="child-modal-title">
          Provision?
        </Typography>
        <StyledModalContent>
          <Typography variant="modalContent">
            Are you sure you want to provision{" "}
            <strong>{selectedItem?.name}</strong>?
          </Typography>
        </StyledModalContent>
        <StyledModalHr />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledDeleteButton item fullWidth onClick={(e) => activateItem(e)}>
              YES, Provision
            </StyledDeleteButton>
          </Grid>
          <Grid item>
            <StyledCancelButton
              item
              fullWidth
              onClick={(e) => handleActivateModalClose(e)}
            >
              CANCEL
            </StyledCancelButton>
          </Grid>
        </Grid>
      </StyledModal>
    </Modal>
  );
};

// PropTypes validation
ProvisionDeviceModal.propTypes = {
  activateOpen: PropTypes.bool.isRequired,
  handleActivateModalClose: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  activateItem: PropTypes.func.isRequired,
};

export default ProvisionDeviceModal;
