const defaultAccountId =
localStorage.getItem('defaultAccountId') !== ''
  ? JSON.parse(localStorage.getItem('defaultAccountId'))
  : ''

export const GeofencingModel = {
  accountId: defaultAccountId,
  name: '',
  description: '',
  type: 'POLYGON', // Default value set to 'POLYGON'
  coordinates: [], // Array of objects with lat and lng properties
  radius: null, // Only required for 'CIRCLE'
  area: null, // Calculated property, default to 0
  perimeter: null, // Calculated property, default to 0
  color: '#000000', // Default value set to '#000000'
  createdBy: '', // ObjectId as a string
  updatedBy: '' // ObjectId as a string
}
