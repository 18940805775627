/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { customerTableColumnNew } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const ConsigneesListTable = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuCustomerId, setOpenMenuCustomerId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { consignees, fetchMore, isLoading, isEmpty } = useOutletContext();
  console.log("consignees", consignees);
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [consignees, isLoading, handleScroll]);

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    event.stopPropagation(); // Prevents the click from bubbling up
    setOpenMenuCustomerId(selectedItem.id);
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuCustomerId(null);
    event?.stopPropagation();
  }, []);

  const handleDeleteModalOpen = useCallback(
    (event, customer) => {
      handleActionsMenuClose();
      setSelectedItem(customer);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteItem
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteCustomer(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Customer deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected Customer has been set:", selectedItem);
    }
  }, [selectedItem]);

  const navigateToEditScreen = useCallback(
    (customerId) => {
      navigate(`/monitoring/consignees/edit/${customerId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer container ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="CONSIGNEES"
              message="Oops! You have no consignees for your search criteria"
              info="Please feel free to create a new customer by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {customerTableColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {consignees?.map((customer) => (
                  <TableRow
                    key={customer.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(customer.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                        {customer.name}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {customer.areaCode}&nbsp;{customer.phone}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {customer.email}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      {/* <Typography variant="tableValue">
                        {customer?.address?.map((addr, index) => (
                          <Box key={index} mb={2}>
                            {addr?.addressType === "Address" && (
                              <>
                                {addr?.streetOne && <div>{addr.streetOne}</div>}
                                {addr?.streetTwo && <div>{addr.streetTwo}</div>}
                                {addr?.city && <div>{addr.city}</div>}
                                {(addr?.state ||
                                  addr?.country ||
                                  addr?.postalCode) && (
                                  <div>
                                    {`${addr.state} ${addr.country} ${addr.postalCode}`}
                                  </div>
                                )}
                              </>
                            )}
                            {addr?.addressType === "Geofence" && (
                              <>
                                {addr?.geofenceName && (
                                  <div>{addr.geofenceName}</div>
                                )}
                              </>
                            )}
                          </Box>
                        ))}
                      </Typography> */}
                      <Typography variant="cardValue">
                        {customer.address[0]?.streetOne ||
                          customer.address[0]?.geofenceId}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${customer.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, customer);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${customer.id}`
                        )}
                        open={openMenuCustomerId === customer.id} // The menu is open if the openMenuAccountId matches
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, customer);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Delete Customer</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default ConsigneesListTable;
