/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  DrawingManager,
  Circle,
  Polyline,
} from "@react-google-maps/api";

const mapContainerStyle = {
  width: "auto",
  height: "75vh",
};

const defaultCenter = {
  lat: 9.602144,
  lng: 78.097951,
};

export default function GoogleMaps({
  coordinates,
  type,
  center,
  radius,
  onChange,
  markers = [],
  id,
  polylinePath = [],
}) {
  const [polygonPath, setPolygonPath] = useState([]);
  const [circleData, setCircleData] = useState(null);
  const [googleMap, setGoogleMap] = useState(null);
  const [geofenceType, setGeofenceType] = useState(type);
  const [mode, setMode] = useState(false);
  const [isOnchange, setIsOnchange] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(center);
  const firstRender = useRef(true);
  const path = window.location.pathname;
  useEffect(() => {
    setGeofenceType(type);
  }, [type]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      resetGeofence();
    }
  }, [geofenceType]);

  useEffect(() => {
    if (googleMap) {
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: geofenceType?.toLowerCase(),
        drawingControl: false,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON,
            window.google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        polygonOptions: {
          fillColor: "lightblue",
          fillOpacity: 0.4,
          strokeColor: "blue",
          strokeOpacity: 0.8,
          editable: true,
          draggable: true,
        },
        circleOptions: {
          fillColor: "lightyellow",
          fillOpacity: 0.4,
          strokeColor: "yellow",
          strokeOpacity: 0.8,
          editable: true,
          draggable: true,
        },
      });

      window.google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        handleOverlayComplete
      );

      window.google.maps.event.addListener(
        drawingManager,
        "drawingmode_changed",
        () => {
          const mode = drawingManager.getDrawingMode();
          if (mode) {
            resetGeofence();
          }
        }
      );

      drawingManager.setMap(googleMap);

      return () => {
        drawingManager.setMap(null);
      };
    }
  }, [geofenceType, googleMap]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (!id && path != "/monitoring/units") {
      getCurrentLocation();
    } else {
      setCurrentLocation({
        lat: center?.lat,
        lng: center?.lng,
      });
    }
  }, [center]);
  useEffect(() => {
    if (coordinates && coordinates.length > 0) {
      setMode(true);
    }
  }, [coordinates]);

  useEffect(() => {
    // Ensure radius is validated and set properly
    if (
      type === "CIRCLE" &&
      center &&
      radius !== null &&
      radius !== undefined
    ) {
      const validatedRadius = radius ?? circleData?.radius ?? 0; // Use fallback if necessary
      console.log("Updating circle data with center and radius:", {
        center,
        validatedRadius,
      });
      setCircleData({
        center,
        radius: validatedRadius,
      });
    }
  }, [type, center, radius]);

  useEffect(() => {
    if (type === "CIRCLE" && center && radius) {
      setCircleData({
        center,
        radius,
      });
    }
  }, [type, center, radius]);

  useEffect(() => {
    console.log("Updated Props:", { center, radius, type });
  }, [center, radius, type]);

  useEffect(() => {
    console.log("Circle Data State Updated:", circleData);
  }, [circleData]);

  useEffect(() => {
    if (isOnchange) {
      console.log("onChange triggered with:", circleData);
      onChange(circleData, geofenceType); // Make sure `radius` is part of `circleData`
      setIsOnchange(false);
    }
  }, [circleData, isOnchange, onChange, geofenceType]);

  // Log updates for debugging
  useEffect(() => {
    console.log("Props Received:", { radius, center });
    console.log("Circle Data State:", circleData);
  }, [radius, center, circleData]);

  useEffect(() => {
    console.log("Current Radius:", radius);
    console.log("Circle Data State:", circleData);
  }, [radius, circleData]);

  useEffect(() => {
    if (
      type === "CIRCLE" &&
      center &&
      radius !== null &&
      radius !== undefined
    ) {
      const validatedRadius = radius ?? circleData?.radius ?? 0; // Use fallback if necessary
      if (validatedRadius > 0) {
        setCircleData({ center, radius: validatedRadius });
      }
    }
  }, [type, center, radius]);

  useEffect(() => {
    if (circleData?.center && circleData?.radius !== undefined) {
      onChange(circleData, geofenceType);
    }
  }, [circleData, geofenceType]);

  useEffect(() => {
    if (center) {
      setCurrentLocation(center);
    } else {
      getCurrentLocation();
    }
  }, [center]);

  useEffect(() => {
    if (isOnchange) {
      onChange(polygonPath, geofenceType);
      setIsOnchange(false);
    }
  }, [polygonPath, isOnchange, onChange, geofenceType]);

  useEffect(() => {
    if (isOnchange && circleData?.center && circleData?.radius) {
      console.log("onChange triggered with:", circleData);
      onChange(circleData, geofenceType); // Make sure `radius` is part of `circleData`
      setIsOnchange(false);
    }
  }, [circleData, isOnchange, onChange, geofenceType]);

  const handleMapClick = (event) => {
    const clickedPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    const isExistingPoint = polygonPath.some((point) =>
      arePointsClose(point, clickedPoint)
    );

    if (isExistingPoint) {
      const updatedPath = polygonPath.filter(
        (point) => !arePointsClose(point, clickedPoint)
      );
      setPolygonPath(updatedPath);
    } else {
      setPolygonPath((current) => [...current, clickedPoint]);
    }
  };

  const arePointsClose = (point1, point2) => {
    const tolerance = 0.0001;
    return (
      Math.abs(point1.lat - point2.lat) < tolerance &&
      Math.abs(point1.lng - point2.lng) < tolerance
    );
  };

  const handleOverlayComplete = (event) => {
    if (event.type === window.google.maps.drawing.OverlayType.CIRCLE) {
      const center = event.overlay.getCenter();
      const radius = event.overlay.getRadius();

      if (center && radius != null) {
        // Validate radius
        setCircleData({
          center: { lat: center.lat(), lng: center.lng() },
          radius: Math.max(radius, 0), // Ensure radius is non-negative
        });
        setIsOnchange(true);
      } else {
        console.warn("Invalid circle data: Missing center or radius");
      }

      event.overlay.setMap(null); // Remove overlay
    }
  };

  useEffect(() => {
    console.log("Circle Data State Updated:", circleData);
  }, [circleData]);

  useEffect(() => {
    console.log("Props Received:", { radius, center });
  }, [radius, center]);

  const resetGeofence = () => {
    if (geofenceType === "CIRCLE") {
      setPolygonPath([]);
    } else {
      setCircleData(null);
      setPolygonPath([]);
    }
    setMode(false); // Reset mode to prevent conditional logic issues.
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={["geometry", "drawing"]}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={currentLocation}
        zoom={13}
        onDblClick={handleMapClick}
        onLoad={setGoogleMap}
      >
        {currentLocation && <Marker position={currentLocation} />}
        {polygonPath.length > 0 && (
          <Polygon
            paths={polygonPath}
            options={{
              fillColor: "lightblue",
              fillOpacity: 0.4,
              strokeColor: "blue",
              strokeOpacity: 0.8,
            }}
          />
        )}
        {circleData && circleData.center && circleData.radius && (
          <Circle
            center={circleData.center}
            radius={circleData.radius}
            options={{
              fillColor: "lightyellow",
              fillOpacity: 0.4,
              strokeColor: "yellow",
              strokeOpacity: 0.8,
            }}
          />
        )}

        {/* {currentPolylinePath.length > 1 && (
          <Polyline
            path={currentPolylinePath}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        )} */}
        {polylinePath.length > 1 && (
          <Polyline
            path={polylinePath}
            options={{
              strokeColor: "blue",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }}
          />
        )}

        {googleMap && (
          <DrawingManager
            onOverlayComplete={handleOverlayComplete}
            options={{
              drawingControl: false,
            }}
          />
        )}
        {polygonPath.map((point, index) => (
          <Marker key={index} position={point} />
        ))}
        {markers.map((marker, index) => (
          <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} />
        ))}
      </GoogleMap>
    </LoadScript>
  );
}
