/* eslint-disable no-unused-vars */
import {
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  fetchAllAccounts,
  getAccountById
} from '../../../../services/accountNew.service.js'

import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSwitch2,
  StyledTable2,
  StyledTableBody2,
  StyledTableCell2,
  StyledTableHead2,
  StyledTableRow2,
  StyledUploadContainer,
  StyledUploadIconContainer,
  StyledUploadText
} from '../../../../styledComponents.js'
import {
  fetchAllRoles,
  getRoleById
} from '../../../../services/roleNew.service.js'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { iconMap } from '../../../../constants/iconsList.js'
import { StyledFormContainer } from '../../../management/billingPlans/styles/BillingPlansStyles.js'
import { checkboxTypes, documentColumns } from '../../../../utils/constant.js'
import { useDropzone } from 'react-dropzone'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import FormOptionSelector from '../../../common/FormOptionSelector.js'
import FormField from '../../../common/FormField.js'
import FormDatepicker from '../../../common/FormDatepicker.js'
import FileLink from '../../../common/FileLink.js'
import FormSelect from '../../../common/FormSelect.js'
dayjs.extend(utc)

export const documentTypeOptions = ["Driver's License", 'Employment Letter']

const DriversForm2 = ({
  driver,
  onFormValueChange,
  validationErrors,
  setValidationErrors
}) => {
  const today = dayjs()
  const [accounts, setAccounts] = React.useState([{ name: '', value: '' }])
  const [roles, setRoles] = React.useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [firstRoleChange, setFirstRoleChange] = useState(true)
  const { id } = useParams()
  const userId = id
  const [defaultRoleId, setDefaultRoleId] = useState('')
  const [drivers, setDrivers] = useState(driver)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [openLicenseDatePicker, setOpenLicenseDatePicker] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    driver?.passportExpiryDate ? dayjs(driver.passportExpiryDate) : today
  )
  const [selectedLicenceDate, setSelectedLicenceDate] = useState(
    driver?.licenseExpiryDate ? dayjs(driver.licenseExpiryDate) : today
  )

  // Find the role with the name "driver"
  useEffect(() => {
    const driverRole = roles.find((role) => role.name === 'Driver')
    if (driverRole) {
      setSelectedRole(driverRole.id)
      setDefaultRoleId(driverRole.id)
    }
  }, [roles])

  // Fetch accounts for dropdown
  const _getRoleById = async () => {
    if (userId === undefined) {
      if (selectedRole !== '') {
        try {
          const data = await getRoleById(selectedRole)
          const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
          var accountDetail = await getAccountById(accountId)
          if (driver.type === 'New Driver') {
            onFormValueChange({
              ...driver,
              accessRights: [
                {
                  accountId: accountId,
                  accountName: accountDetail.name,
                  roleId: selectedRole,
                  role: data.name,
                  permissions: data.accessRights
                }
              ]
            })
            setDrivers({
              ...driver,
              accessRights: [
                {
                  accountId: accountId,
                  accountName: accountDetail.name,
                  roleId: selectedRole,
                  role: data.name,
                  permissions: data.accessRights
                }
              ]
            })
          } else {
            if (firstRoleChange) {
              // Update the last item's role, roleId, and permissions
              const lastItemIndex = driver.accessRights.length
              const updatedAccessRightsArray = [...driver.accessRights]
              updatedAccessRightsArray[lastItemIndex] = {
                ...updatedAccessRightsArray[lastItemIndex],
                roleId: selectedRole,
                role: data.name,
                permissions: data.accessRights
              }
              onFormValueChange({
                ...driver,
                accessRights: updatedAccessRightsArray
              })
              setDrivers({
                ...driver,
                accessRights: updatedAccessRightsArray
              })
              setFirstRoleChange(false)
            } else {
              // Update the last item directly without creating a new array
              const lastItemIndex = driver.accessRights.length
              driver.accessRights[lastItemIndex] = {
                ...driver.accessRights[lastItemIndex],
                roleId: selectedRole,
                role: data.name,
                permissions: data.accessRights
              }
              onFormValueChange({ ...driver })
              setDrivers({
                ...driver
              })
            }
          }
        } catch (error) {
          if (error.response.data) {
            console.log(error.response.data.message)
          } else {
            console.log(error.message)
          }
        }
      }
    } else {
      if (selectedRole !== '') {
        try {
          const data = await getRoleById(selectedRole)
          const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
          const updatedAccessRightsArray = driver.accessRights.map(
            (accessRight) => {
              if (accessRight.accountId === accountId) {
                // Update the access right for the matching account id
                return {
                  ...accessRight,
                  roleId: selectedRole,
                  role: data.name,
                  permissions: data.accessRights
                }
              } else {
                // Keep other access rights unchanged
                return accessRight
              }
            }
          )
          setDrivers({
            ...driver,
            accessRights: updatedAccessRightsArray
          })
          onFormValueChange({
            ...driver,
            accessRights: updatedAccessRightsArray
          })
        } catch (error) {
          if (error.response.data) {
            console.log(error.response.data.message)
          } else {
            console.log(error.message)
          }
        }
      }
    }
  }

  useEffect(() => {
    _getRoleById()
  }, [selectedRole])

  // Set default passport and license expiry date
  useEffect(() => {
    onFormValueChange({
      ...driver,
      passportExpiryDate: today,
      licenseExpiryDate: today
    })
  }, [])

  // To upload files
  const onDrop = (type) => (acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) => ({
      fileURL: '',
      file: file,
      isDelete: false, // Using file name as fileURL
      documentType: "Driver's License", // Default document type
      driverView: false,
      customerView: false
    }))

    const newDrivers = {
      ...drivers,
      [type]: [
        ...drivers[type].filter((doc) => doc.fileURL || doc.file !== ''),
        ...updatedFiles
      ]
    }

    setDrivers(newDrivers)
    onFormValueChange(newDrivers)
  }

  // Handle file removal by filtering out the deleted file
  const handleRemoveFile = (docType, index) => {
    const updatedDocuments = drivers[docType].filter((_, i) => i !== index)

    const newDocuments = { ...drivers, [docType]: updatedDocuments }
    setDrivers(newDocuments)
    onFormValueChange(newDocuments, '', '', 2)
  }

  const handlePickupDateChange = (date) => {
    const formattedDate = date
      ? dayjs(date).startOf('day').add(18, 'hours').toISOString()
      : dayjs().startOf('day').add(18, 'hours').toISOString()

    setSelectedDate(date || dayjs().startOf('day').add(18, 'hours'))
    handleFieldChange('passportExpiryDate', formattedDate)
  }

  const handleLicenseDateChange = (date) => {
    const formattedDate = date
      ? dayjs(date).startOf('day').add(18, 'hours').toISOString()
      : dayjs().startOf('day').add(18, 'hours').toISOString()

    setSelectedLicenceDate(date || dayjs().startOf('day').add(18, 'hours'))
    handleFieldChange('licenseExpiryDate', formattedDate)
  }

  // Function to handle user document typee
  const handleDocumentTypeChange = (type, index, fieldName, value) => {
    const updatedDocuments = drivers[type].map((doc, i) =>
      i === index ? { ...doc, [fieldName]: value } : doc
    )
    const newDrivers = { ...drivers, [type]: updatedDocuments }
    setDrivers(newDrivers)
    onFormValueChange(newDrivers, '', '', 2)
  }

  // Switch for customer/driver  switch
  // const handleSwitchChange = (type, index, fieldName, value) => {
  //   const updatedDocuments = drivers[type].map((doc, i) =>
  //     i === index ? { ...doc, [fieldName]: value } : doc
  //   )
  //   const newDrivers = { ...drivers, [type]: updatedDocuments }
  //   setDrivers(newDrivers)
  //   onFormValueChange(newDrivers)
  // }

  const renderDocuments = (
    type,
    documentTypeOptions,
    defaultDocumentType = ''
  ) => (
    <StyledTable2>
      <StyledTableHead2>
        <StyledTableRow2>
          {documentColumns.map((column, index) => (
            <StyledTableCell2 key={index}>{column.label}</StyledTableCell2>
          ))}
        </StyledTableRow2>
      </StyledTableHead2>
      <StyledTableBody2>
        {drivers[type].map((item, index) =>
          item.fileURL || (item.file && !item.isDelete) ? (
            <StyledTableRow2 key={index}>
              <StyledTableCell2>
                <FileLink
                  href={item.fileURL || item.file.name}
                  fileName={item.fileURL || item.file.name}
                />
              </StyledTableCell2>
              <StyledTableCell2>
                <FormSelect
                  sx={{
                    marginBottom: '0px',
                    marginTop: '-5px',
                    borderRadius: '16px'
                  }}
                  value={item.documentType || defaultDocumentType}
                  onChange={(e) =>
                    handleDocumentTypeChange(type, index, 'documentType', e)
                  }
                  options={documentTypeOptions.map((option) => ({
                    value: option,
                    label: option
                  }))}
                />
              </StyledTableCell2>
              <StyledTableCell2 align="center">
                <StyledSwitch2
                  checked={item.driverView}
                  onChange={(e) =>
                    handleDocumentTypeChange(
                      type,
                      index,
                      'driverView',
                      e.target.checked
                    )
                  }
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#0772B8'
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#0772B8'
                    }
                  }}
                />
              </StyledTableCell2>
              <StyledTableCell2 align="center">
                <StyledSwitch2
                  checked={item.customerView}
                  onChange={(e) =>
                    handleDocumentTypeChange(
                      type,
                      index,
                      'customerView',
                      e.target.checked
                    )
                  }
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#0772B8'
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#0772B8'
                    }
                  }}
                />
              </StyledTableCell2>
              <StyledTableCell2 align="center">
                <IconButton
                  onClick={() => handleRemoveFile(type, index)}
                  sx={{ color: 'red' }}
                >
                  <img src={iconMap['DeleteIcon']} alt="DeleteIcon" />
                </IconButton>
              </StyledTableCell2>
            </StyledTableRow2>
          ) : null
        )}
      </StyledTableBody2>
    </StyledTable2>
  )

  const {
    getRootProps: getRootPropsInsurance,
    getInputProps: getInputPropsInsurance
  } = useDropzone({
    onDrop: onDrop('documentsURL'),
    accept: 'image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx',
    multiple: true
  })

  // Set default account and role(driver)
  useEffect(() => {
    const getAccounts = async () => {
      try {
        const data = await fetchAllAccounts()
        setAccounts(data.rows)
      } catch (error) {
        if (error.response.data) {
          console.log(error.response.data.message)
        } else {
          console.log(error.message)
        }
      }
    }
    const getRoles = async () => {
      try {
        const data = await fetchAllRoles()
        setRoles(data.rows)
      } catch (error) {
        if (error.response.data) {
          console.log(error.response.data.message)
        } else {
          console.log(error.message)
        }
      }
    }
    getAccounts()
    getRoles()
  }, [])

  const handleFieldChange = async (name, value, index = null) => {
    try {
      let updatedUser = { ...driver, [name]: value }
      if (name === 'role') {
        setSelectedRole(value)
      } else if (name == 'measurementSystem') {
        updatedUser = {
          ...driver,
          settings: {
            ...driver.settings,
            measurementSystem: value,
            timeFormat: '24 Hrs',
            dateFormat: 'dd/mm/yyyy'
          }
        }
      } else {
        updatedUser = { ...driver, [name]: value }
      }
      setDrivers(updatedUser)
      onFormValueChange(updatedUser, name, value, 3)
    } catch (error) {
      // No need to update validationErrors here, as it's done in the validateField function
    }
  }

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">
            Account, Role & Specifications
          </Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormOptionSelector
            label="Set Measurement System"
            required
            options={checkboxTypes}
            selectedOption={driver.settings.measurementSystem}
            handleOptionChange={(value) =>
              handleFieldChange('measurementSystem', value)
            }
          />
          <FormField
            label="License Number"
            placeholder="Enter license number"
            value={driver.licenseNumber}
            onChange={(e) => handleFieldChange('licenseNumber', e.target.value)}
            error={!!validationErrors.licenseNumber}
            errorMessage={validationErrors.licenseNumber}
            required
          />
          <FormDatepicker
            label="License Expiry Date"
            value={selectedLicenceDate}
            onChange={handleLicenseDateChange}
            open={openLicenseDatePicker}
            onOpen={() => setOpenLicenseDatePicker(true)}
            onClose={() => setOpenLicenseDatePicker(false)}
            error={!!validationErrors.licenseExpiryDate}
            errorMessage={validationErrors.licenseExpiryDate}
          />
          <FormField
            label="Passport Number"
            placeholder="Enter Passport number"
            value={driver.passportNumber}
            onChange={(e) =>
              handleFieldChange('passportNumber', e.target.value)
            }
            error={!!validationErrors.passportNumber}
            errorMessage={validationErrors.passportNumber}
          />
          <FormDatepicker
            label="Passport Expiry Date"
            value={selectedDate}
            onChange={handlePickupDateChange}
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            error={!!validationErrors.passportExpiryDate}
            errorMessage={validationErrors.passportExpiryDate}
          />
        </StyledSectionRight>
      </StyledFormSection>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Upload Documents</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <Grid {...getRootPropsInsurance()}>
            <input {...getInputPropsInsurance()} type="file" />
            <StyledUploadContainer>
              <StyledUploadIconContainer>
                <IconButton>
                  <img src={iconMap['DocUploadIcon']} alt="DocUploadIcon" />
                </IconButton>
              </StyledUploadIconContainer>
              <StyledUploadText>Drag and Drop or Browse files</StyledUploadText>
            </StyledUploadContainer>
          </Grid>
          <div>
            {driver.documentsURL.length > 0
              ? renderDocuments('documentsURL', documentTypeOptions)
              : null}
          </div>
        </StyledSectionRight>
        {/* <StyledSectionRight>
          <Grid>
            <Typography variant="subtitle1" gutterBottom>
              Upload Driver&apos;s License and Employment Letter
            </Typography>
          </Grid>
          <div {...getRootPropsInsurance()}>
            <input {...getInputPropsInsurance()} type="file" />
            <div>
              <Grid>
                <Grid
                  sx={{
                    width: '95%',
                    height: '110px',
                    border: '2px dashed #D3D3D3',
                    borderRadius: '20px',
                    padding: '10px 0px',
                    cursor: 'pointer',
                    backgroundColor: '#F7F7F8'
                  }}
                >
                  <Grid
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#F7F7F8'
                    }}
                  >
                    <IconButton>
                      <img src={iconMap['DocUploadIcon']} alt="DocUploadIcon" />
                    </IconButton>
                  </Grid>
                  <div>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#09154b',
                        padding: '4px 5px',
                        textAlign: 'center'
                      }}
                    >
                      Drag and Drop or Browse files
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div>{renderDocuments('documentsURL', documentTypeOptions)}</div>
        </StyledSectionRight> */}
      </StyledFormSection>
    </StyledFormContainer>
  )
}

DriversForm2.propTypes = {
  driver: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired
}

export default DriversForm2
