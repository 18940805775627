/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Typography
} from '@mui/material'
import React from 'react'
import { StyledModal } from '../../styledComponents'

export default function OptimizedRouteModal({
  routeOpen,
  handleRouteOpenModalClose,
  optimizedRoute
}) {
  return (
    <Modal
      open={routeOpen}
      onClose={handleRouteOpenModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModal
        sx={{
          height: '40rem',
          overflow: 'auto',
          width: { xs: '100%', sm: 600 },
          border: 'none !important'
        }}
      >
        {optimizedRoute && (
          <Grid sx={{ textAlign: 'left' }}>
            <Typography variant="h5" gutterBottom>
              Optimized Route
            </Typography>
            <div style={{ marginBottom: '16px' }}>
              <Typography variant="h6" gutterBottom>
                Start Address:
              </Typography>
              <Typography variant="body1">{optimizedRoute.pickup}</Typography>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <Typography variant="h6" gutterBottom>
                End Address:
              </Typography>
              <Typography variant="body1">{optimizedRoute.delivery}</Typography>
            </div>
            {optimizedRoute && optimizedRoute.legs && (
              <div>
                <div style={{ marginBottom: '16px' }}>
                  <Typography variant="h6" gutterBottom>
                    Distance:
                  </Typography>
                  <Typography variant="body1">
                    {optimizedRoute.legs[0].distance.text}
                  </Typography>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <Typography variant="h6" gutterBottom>
                    Duration:
                  </Typography>
                  <Typography variant="body1">
                    {optimizedRoute.legs[0].duration.text}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Steps:
                  </Typography>
                  <List>
                    {optimizedRoute.legs[0].steps.map((step, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: step.html_instructions
                                }}
                              />
                            }
                            secondary={`Distance: ${step.distance.text}, Duration: ${step.duration.text}`}
                          />
                        </ListItem>
                        {index < optimizedRoute.legs[0].steps.length - 1 && (
                          <Divider />
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </div>
              </div>
            )}
          </Grid>
        )}
      </StyledModal>
    </Modal>
  )
}
