/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material'
import React from 'react'
// import CreateButton from './CreateButton'

export default function EditScreenActivateState(props) {
    const { iconTop, iconBottom } = props;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', // center vertically
            height: '65vh', // full viewport height
        }}>
            <Box sx={{ position: "relative", left: "10rem" }}>
                <img src={iconBottom} alt="iconBottom" />
            </Box>
            <Box sx={{ background: 'linear-gradient(90deg, rgba(225,242,240,1) 0%, rgba(188,226,251,1) 100%)', padding: '8px 10px 0', borderRadius: '8px' }}>
                <img src={iconTop} alt="iconTop" />
            </Box>
            <Typography sx={{ fontSize: '23px', fontWeight: '600', color: '#0A2C47', py: 1 }}>Activate your {props.name}!</Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#6C6C81', pb: 1 }}>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. </Typography>
            {/* <CreateButton buttonName={props.name} navigate={props.name === "Billing Plan" ? 'management/billing-plans/create' : props.name === 'User' ? 'management/users/create' : props.name === 'Account' ? 'management/accounts/create' : props.name === 'Role' ? 'management/roles/create' : props.name === 'Service' ? 'management/services/create' : props.name === 'Payment' ? 'management/payments/create' : props.name === 'Unit' ? 'management/units/create' : props.name === 'Device' ? 'management/devices/create' : props.name === 'Unit Group' ? 'management/unit-groups/create' : '#'} /> */}
        </Box>
    )
}
