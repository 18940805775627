import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccountsService from "./AccountsService";
import AccountsUser from "./AccountsUser";
import AccountsHeader from "./AccountsHeader";
import AccountsUnit from "./AccountsUnits";
import AccountsPayment from "./AccountsPayment";

import AccountsUnitGroupsCreate from "./AccountsUnitGroupsCreate";
import AccountsCreateFormNew from "./AccountCreateForm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const styles = {
  activeTab: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#0772B8 !important",
    borderBottom: "2px solid #0772B8",
    lineHeight: "18.9px",
  },
  inactiveTab: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#0A2C47",
    lineHeight: "18.9px",
  },
  tabItem: {
    margin: "0 1.5rem",
  },
};

export default function AccountsEditForm() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  var { id, tab } = useParams();

  useEffect(() => {
    if (tab == "details") {
      setValue("1");
    } else if (tab == "users") {
      setValue("2");
    } else if (tab == "units") {
      setValue("3");
    } else if (tab == "unitsGroup") {
      setValue("4");
    }
    // else if(tab == "devices"){
    //   setValue("5")
    // }
    else if (tab == "services") {
      setValue("5");
    } else if (tab == "payments") {
      setValue("6");
    }
  }, [tab]);

  const handleChange = (event, newValue) => {
    if (newValue == "1") {
      tab = "details";
    } else if (newValue == "2") {
      tab = "users";
    } else if (newValue == "3") {
      tab = "units";
    } else if (newValue == "4") {
      tab = "unitsGroup";
    }
    // else if(newValue == "5"){
    //   tab = "devices"
    // }
    else if (newValue == "5") {
      tab = "services";
    } else if (newValue == "6") {
      tab = "payments";
    }
    navigate(`/management/accounts/edit/${id}/${tab}`);
  };

  const indicatorStyle = {
    backgroundColor: "0772B8", // Change this to the color you want for the indicator
  };

  return (
    <Box>
      <AccountsHeader />
      <Box sx={{ margin: "0px", padding: "0px" }}>
        <TabContext value={value} sx={{ margin: "0px", padding: "0px" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="0772B8"
              TabIndicatorProps={{ style: indicatorStyle }}
            >
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <Tab
                  key={index}
                  sx={
                    value === index.toString()
                      ? [styles.activeTab, styles.tabItem]
                      : [styles.inactiveTab, styles.tabItem]
                  }
                  label={
                    index === 1
                      ? "ACCOUNTS & PLANS"
                      : index === 2
                      ? "USERS"
                      : index === 3
                      ? "UNITS"
                      : index === 4
                      ? "UNITS GROUP"
                      : // index === 5 ? 'DEVICES' :
                      index === 5
                      ? "SERVICES & LIMITS"
                      : index === 6
                      ? "PAYMENTS"
                      : ""
                  }
                  value={index.toString()}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0px" }}>
            <AccountsCreateFormNew />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0px" }}>
            <AccountsUser />
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "0px" }}>
            <AccountsUnit />
          </TabPanel>
          <TabPanel value="4" sx={{ padding: "0px" }}>
            <AccountsUnitGroupsCreate />
          </TabPanel>
          {/* <TabPanel value="5" sx={{ padding: '0px' }}>
            <AccountsDevices id={id} />
          </TabPanel> */}
          <TabPanel value="5" sx={{ padding: "0px" }}>
            <AccountsService />
          </TabPanel>
          <TabPanel value="6" sx={{ padding: "0px" }}>
            <AccountsPayment />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
