import React from 'react';
import { StyledError, StyledAlertIcon } from '../../styledComponents';
import { iconMap } from '../../constants/iconsList';
import PropTypes from 'prop-types';

const FormError = ({ errorMessage }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
      <StyledAlertIcon src={iconMap['ALERTICON']} alt="alertIcon" />
      <StyledError>{errorMessage}</StyledError>
    </div>
  );
};

FormError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default FormError;