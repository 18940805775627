import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import { AppBar, CssBaseline, useMediaQuery } from '@mui/material'
import NavigationBar from './NavigationBar'
import LeftNavBar from './LeftNavBar'
import { routes } from '../../../../src/routes'
import { LogMessagesProvider } from '../WebsocketContext'

const drawerWidth = 240

const MainContainer = styled('div')({
  display: 'flex'
})

const Content = styled('div')(({ shouldShowNavBars }) => ({
  flexGrow: 1,
  marginTop: shouldShowNavBars ? '72px' : 0,
  padding: shouldShowNavBars ? '50px' : 0
}))

const MainNavContainer = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: drawerWidth,
  padding: '0px',
  transition: 'width 0.3s ease-in-out, margin-left 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0
  }
}))

const MainLayout = () => {
  const location = useLocation()
  const [shouldShowNavBars, setShouldShowNavBars] = useState(true)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    setShouldShowNavBars(!['/', '/login'].includes(location.pathname))
  }, [location])

  // useEffect(() => {
  //   const path = location.pathname;
  //   const lastPart = path.split('/').pop(); // Get the last part of the path
  //   const shouldHideNavBar = !['/', '/login','card', 'list'].includes(lastPart);
  //   setShouldShowNavBars(!shouldHideNavBar);
  //   }, [location]);

  return (
    <MainContainer>
      <CssBaseline />
      <LogMessagesProvider>
        {shouldShowNavBars && isMobile && (
          <MainNavContainer position="fixed">
            <NavigationBar LeftNavBar={LeftNavBar} />
          </MainNavContainer>
        )}
        {shouldShowNavBars && !isMobile && (
          <MainNavContainer position="fixed">
            <NavigationBar />
          </MainNavContainer>
        )}
        {shouldShowNavBars && !isMobile && <LeftNavBar />}
        <Content
          sx={{ backgroundColor: '#FFFFFF' }}
          shouldShowNavBars={shouldShowNavBars}
        >
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element}>
                {route.children?.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  ></Route>
                ))}
              </Route>
            ))}
          </Routes>
        </Content>
      </LogMessagesProvider>
    </MainContainer>
  )
}

export default MainLayout
