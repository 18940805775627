/* eslint-disable react/prop-types */
import { Grid, Typography } from "@mui/material";
import React from "react";
import { UnitMonitoringLable } from "../../../../styledComponents";
import { iconMap } from "../../../../constants/iconsList";

const UnitStatus = ({ unit }) => (
  <>
  
    <Grid item xs={5}>
      <UnitMonitoringLable>Unit Status</UnitMonitoringLable>
      <Typography
        sx={{
          color: unit.unitStatus === "ONLINE" ? "#26861D" : "#E79111",
          fontSize: "10px",
          fontWeight: 600,
        }}
      >
        HARDCODED
      </Typography>
    </Grid>
    <Grid item xs={5}>
      <UnitMonitoringLable>Lock Status</UnitMonitoringLable>
      <Typography
        sx={{
          color: unit.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus === "Open" ? "red" : "#26861D",
          fontSize: "10px",
          fontWeight: 600,
        }}
      >
        <img
          src={
            unit.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus === "Open"
              ? iconMap["LOCKOPENICON"]
              : iconMap["LOCKICON"]
          }
          alt="lockStatusIcon"
          style={{ marginRight: "4px", width: "12px" }}
        />
        {unit.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus}
      </Typography>
    </Grid>
  </>
);

export default UnitStatus;
