/* eslint-disable react/prop-types */
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function ComingSoon(props) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', // center vertically
            height: '65vh', // full viewport height
        }}>
            <Typography sx={{ fontSize: '23px', fontWeight: '600', color: '#0A2C47', py: 1 }}>{props.name}</Typography>
            <Typography sx={{ fontSize: '32px', fontWeight: '400', color: '#6C6C81', pb: 1 }}>Coming Soon !!!</Typography>
        </Box>
    )
}
