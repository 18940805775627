import * as yup from 'yup'

export const paymentsSchema = yup.object().shape({
  accountId: yup.string().required('Account ID is required.'),
  billingDate: yup.date().required('Billing date is required.'),
  amountCurrency: yup
    .string()
    .oneOf(['TZS', 'AUD'], "Currency must be either 'TZS' or 'AUD'.")
    .required('Amount currency is required.')
    .default('TZS'),
  amount: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Amount must be a number')
    .min(1, 'Amount must be at least 1.')
    .max(9999.99, 'Amount cannot exceed 9999.99')
    .nullable()
    .required('Amount is required.'),
  VATPercent: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('VAT must be a number')
    .min(0, 'VAT be less than 0')
    .max(9999.99, 'VAT cannot exceed 9999.99')
    .nullable()
    .required('VAT percent is required.'),
  lateFeeCurrency: yup
    .string()
    .oneOf(['TZS', 'AUD'], "Currency must be either 'TZS' or 'AUD'.")
    .required('Late fee currency is required.')
    .default('TZS'),
  lateFeeAmount: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Late Fee amount must be a number')
    .min(0, 'Late Fee amount be less than 0')
    .max(9999.99, 'Late Fee amount cannot exceed 9999.99')
    .nullable(),
  description: yup
    .string()
    .trim()
    .min(3, 'Description should be at least 3 characters.')
    .max(200, 'Description cannot exceed 200 characters.'),
  paymentMode: yup
    .string()
    .oneOf(
      ['Cash', 'Credit Card', 'Debit Card'],
      "Payment mode must be either 'Cash', 'Credit Card', or 'Debit Card'."
    )
    .required('Payment mode is required.')
    .default('Cash'),
  createdBy: yup.string().nullable(),
  updatedBy: yup.string().nullable()
})
