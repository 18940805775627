import { styled } from '@mui/material/styles';
import { Typography, Button, Divider, CardContent, Box, Grid } from '@mui/material';

export const PlanTitle = styled('div')({
    display: 'flex',
    alignItems: 'center',

});

export const AccessText = styled(Typography)({
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: 1.35,
    letterSpacing: 'normal',
    color: '#0a2c47',
    textTransform: 'titleCase',
    marginRight: '10px'

});

export const PriceText = styled(Typography)({
    marginLeft: 'auto',
    fontFamily: 'Archivo',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: 'normal',
    color: '#0a2c47',
});

export const ServiceItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    borderRadius: '8px',
    border: 'solid 1px #d8d8df',
    flexShrink: 0,
});
export const ServiceIcon = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});




export const ServiceName = styled(Typography)({
    fontFamily: 'Archivo',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: 'normal',
    color: '#0a2c47',
});

export const ServiceLimit = styled('div')({
    display: 'flex',
    gap: '8px',
    padding: '0 4px',
    borderRadius: '4px',
    backgroundColor: '#eeeef0',
});

export const PricingCycleItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    borderRadius: '8px',
    border: 'solid 1px #d8d8df',
    flexShrink: 0,
});

export const PricingCycleText = styled(Typography)({
    fontFamily: 'Archivo',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: 'normal',
    color: '#0a2c47',
});

export const AccountsButton = styled(Button)({
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    borderRadius: '8px',
    boxShadow: '0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07)',
    border: 'solid 1px #d8d8df',
    backgroundColor: 'var(--color-white)',
});

export const StyledDivider = styled(Divider)({
    height: '1px',
    alignSelf: 'stretch',
    flexGrow: 0,
    backgroundColor: '#d8d8df',
    marginTop: '15px',
    marginBottom: '15px',
});
export const StyledCardContent = styled(CardContent)`
  height: 456px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: '24px 24px 16px';
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--color-white);
`;

//Forms
// export const StyledBox = styled(Box)`
//   margin: 0 2%;
// `;

// export const HeaderTypography = styled(Typography)`
//   font-size: 20px;
//   color: #272560;
//   font-weight: 600;
//   padding: 10px 0;
// `;

// export const SubHeaderTypography = styled(Typography)`
//   font-size: 14px;
//   color: #6c6c81;
//   font-weight: 400;  
// `;


export const FlexContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ColorBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  border: ${(props) => (props.selected ? '1px solid black' : 'none')};
`;
///nilling plan form
export const BillingFormContainer = styled(Box)`
  padding: 2%;
`;

export const Title = styled(Typography)`
  color: #0a2c47;
  font-size: 32px;
  font-weight: 600;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

// export const StepperContainer = styled(Box)`
//   display: flex;
//   width: 21rem;
//   @media (max-width: 900px) {
//     width: auto;
//   }
// `;

export const ErrorContainer = styled(Box)`
  margin: 2% 0 0 2%;
`;

// export const StepperWrapper = styled(StepperContainer)`
//   display: flex;
//   align-items: center;
// `;

export const StyledButtonContainer = styled(Box)`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  margin: 5% 0;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;

//Form 1

export const StyledFormContainer = styled(Box)`
  padding: 32px 0px;
`;

export const StyledInputControlContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const DoubleArrowIconContainer = styled(Grid)`
  text-align: center;
  align-self:center;
`;

// export const HeaderTypography = styled(Typography)`
//   font-size: 20px;
//   font-weight: bold;
// `;

// export const SubHeaderTypography = styled(Typography)`
//   font-size: 16px;
//   margin-top: 10px;
// `;

// export const ContentTypography = styled(Typography)`
//   font-size: 14px;
// `;

export const FlexContainerRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// export const PlanTypeGrid = styled(Grid)`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// export const PricingCycleGrid = styled(Grid)`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

// export const PricingCycleItemGrid = styled(Grid)`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin-bottom: 10px;
// `;

export const RemovePriceGrid = styled(Grid)`
  text-align: center;
  background-color: white;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07);
`;
