import * as yup from 'yup'

// Custom HEX color regex validator
const HEX_COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

// Engine Parameters Schema
const engineParametersSchema = yup.object().shape({
  engineModel: yup
    .string()
    .trim()
    .nullable()
    .typeError('Engine model must be a string'),
  enginePower: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Engine power must be a number')
    .min(0, 'Engine power cannot be less than 0')
    .max(99999.99, 'Engine power cannot exceed 99999.99')
    .nullable(),
  engineDisplacement: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Engine displacement must be a number')
    .min(0, 'Engine displacement cannot be less than 0')
    .max(99999.99, 'Engine displacement cannot exceed 99999.99')
    .nullable(),
  fuelType: yup
    .string()
    .oneOf(
      ['Diesel', 'Petrol', 'Electric'],
      'Fuel type must be one of: Diesel, Petrol, Electric'
    )
    .default('Diesel')
    .nullable()
    .typeError('Fuel type must be a string'),
  averageCO2emission: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Average CO2 emission must be a number')
    .min(0, 'Average CO2 emission cannot be less than 0')
    .max(9999.99, 'Average CO2 emission cannot exceed 9999.99')
    .nullable()
})

// Cargo Parameters Schema
const cargoParametersSchema = yup.object().shape({
  cargoType: yup.string().nullable(),
  carryingCapacity: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Carrying capacity must be a number')
    .min(0, 'Carrying capacity cannot be less than 0')
    .max(9999.99, 'Carrying capacity cannot exceed 9999.99')
    .nullable(),
  width: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Width must be a number')
    .min(0, 'Width cannot be less than 0')
    .max(9999.99, 'Width cannot exceed 9999.99')
    .nullable(),
  height: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Height must be a number')
    .min(0, 'Height cannot be less than 0')
    .max(9999.99, 'Height cannot exceed 9999.99')
    .nullable(),
  depth: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Depth must be a number')
    .min(0, 'Depth cannot be less than 0')
    .max(9999.99, 'Depth cannot exceed 9999.99')
    .nullable(),
  usefulVolume: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Useful volume must be a number')
    .min(0, 'Useful volume cannot be less than 0')
    .max(99999.99, 'Useful volume cannot exceed 99999.99')
    .nullable(),
  grossVehicleWeight: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Gross vehicle weight must be a number')
    .min(0, 'Gross vehicle weight cannot be less than 0')
    .max(999999.99, 'Gross vehicle weight cannot exceed 999999.99')
    .nullable(),
  numberOfAxles: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Number of axles must be a number')
    .min(0, 'Number of axles  cannot be less than 0')
    .max(99999.99, 'Number of axles  cannot exceed 99999.99')
    .nullable()
})

// Sensors Schema
const sensorsSchema = yup.object().shape({
  sensorType: yup
    .string()
    .oneOf(['GPS', 'Fuel Level', 'Temperature', 'Speed', 'Odometer'])
    .default('GPS')
    .nullable(),
  sensorModel: yup
    .string()
    .max(50, 'Sensor model cannot exceed 50 characters.')
    .trim()
    .nullable(),
  installedLocation: yup
    .string()
    .max(150, 'Installed location cannot exceed 150 characters.')
    .trim()
    .nullable()
  // lastReading: yup.string().nullable(),
  // lastReadingTimestamp: yup.date().nullable()
})

const vehicleSchemaStep2 = yup.object().shape({
  VIN: yup
    .string()
    .test(
      'check-unit-type',
      'Unit type must be Vehicle to provide a VIN',
      function (value) {
        const { unitType } = this.parent // Access the parent object to get unitType

        if (unitType === 'Vehicle') {
          // Perform validation only if unitType is 'Vehicle'
          return !!value // Check if value is provided
        }
        return true // If unitType is not 'Vehicle', skip validation
      }
    )
    .min(17, 'VIN should be at least 17 characters.')
    .max(17, 'VIN cannot exceed 17 characters.')
    .required('VIN is required when unit type is Vehicle'),
  licensePlate: yup
    .string()
    .test(
      'check-unit-type',
      'Unit type must be Vehicle to provide a License Plate',
      function (value) {
        const { unitType } = this.parent // Access the parent object to get unitType

        if (unitType === 'Vehicle') {
          // Perform validation only if unitType is 'Vehicle'
          return !!value // Check if value is provided
        }
        return true // If unitType is not 'Vehicle', skip validation
      }
    )
    .max(13, 'License Plate cannot exceed 13 characters.')
    .required('License Plate is required when unit type is Vehicle'),
  make: yup
    .string()
    .trim()
    .max(50, 'Make cannot exceed 50 characters.')
    .nullable(),
  model: yup
    .string()
    .trim()
    .max(50, 'Model cannot exceed 50 characters.')
    .nullable(),
  year: yup.string().nullable(),
  vehicleTypeId: yup.string().nullable(),
  color: yup
    .string()
    .matches(HEX_COLOR_REGEX, 'Invalid hex color code')
    .nullable(),
  engineParameters: engineParametersSchema,
  cargoParameters: cargoParametersSchema,
  sensors: yup.array().of(sensorsSchema).nullable()
})

// E Lock Schema
// const eLockSchema = yup.object().shape({
//   lockId: yup
//     .string()
//     .max(100, 'Lock Id cannot exceed 100 characters.')
//     .trim()
//     .nullable(),
//   serialNumber: yup
//     .string()
//     .min(6, 'Serial number should be at least 6 characters.')
//     .max(50, 'Serial number cannot exceed 50 characters.')
//     .trim()
//     .test(
//       'check-unit-type',
//       'Serial Number is required for E Locks',
//       function (value) {
//         const { unitType } = this.parent // Access the parent object to get unitType

//         if (unitType === 'E Lock') {
//           // Perform validation only if unitType is 'E Lock'
//           return !!value // Check if value is provided
//         }
//         return true // If unitType is not 'E Lock', skip validation
//       }
//     )
//     .required('Serial Number is required for E Locks'),
//   manufacturer: yup
//     .string()
//     .test(
//       'is-valid-length',
//       'Manufacturer should be between 2 and 100 characters.',
//       (value) => {
//         return value ? value.length >= 2 && value.length <= 100 : true
//       }
//     )
//     .trim()
//     .nullable(),
//   firmwareVersion: yup
//     .string()
//     .test(
//       'is-valid-length',
//       'Firmware version should be between 3 and 30 characters.',
//       (value) => {
//         return value ? value.length >= 3 && value.length <= 30 : true
//       }
//     )
//     .trim()
//     .nullable(),
//   batteryLevel: yup
//     .number()
//     .transform((value, originalValue) => (originalValue === '' ? null : value))
//     .typeError('Battery level must be a number')
//     .min(0, 'Battery level cannot be less than 0')
//     .max(100, 'Battery level cannot exceed 100')
//     .nullable(),
//   activationDate: yup.date().required('Activation date is required.')
// })

// Step 1 Schema for Units
export const unitsSchemaStep1 = yup.object().shape({
  accountId: yup.string().required('Account id is required.'),
  name: yup
    .string()
    .trim()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, 'Name cannot exceed 50 characters.')
    .required('Name is required.'),
  unitType: yup
    .string()
    .oneOf(['Vehicle', 'Container'], 'Unit type should be one of the options')
    .default('Vehicle')
    .required('Unit Type is required.'),
  deviceId: yup
    .string()
    .trim()
    .nullable()
    .test(
      'check-deviceId-required',
      'Device id is required for container',
      function (value) {
        const { unitType } = this.parent
        if (unitType === 'Container') {
          return !!value // Validate presence if unitType is 'Container'
        }
        return true // Skip validation if unitType is not 'Container'
      }
    ),
  deviceType: yup
    .string()
    .default('E-lock')
    .test(
      'check-deive-type',
      'Device type is required for container',
      function (value) {
        const { unitType } = this.parent // Access the parent object to get unitType
        if (unitType === 'Container') {
          // Perform validation only if unitType is 'Container'
          return !!value // Check if value is provided
        }
        return true // If unitType is not 'Container', skip validation
      }
    )
    .test(
      'check-device-type-oneOf',
      'Device type should be one of the options',
      function (value) {
        const { unitType } = this.parent
        if (unitType === 'Container') {
          return ['E-lock','OBD Tracker/GPS', 'Wired GPS'].includes(value) // Perform oneOf validation only if unitType is 'Container'
        }
        return true // If unitType is not 'Container', skip validation
      }
    )
})

// Step 2 Schema for Vehicle
export const unitsSchemaStep2 = yup.object().shape({
  vehicle: vehicleSchemaStep2
})

// Step 2 Schema for E Lock
// export const unitsSchemaStep3 = yup.object().shape({
//   eLock: eLockSchema
// })

// Step 3 Schema for Sensors
export const unitsSchemaStep4 = yup.object().shape({})
