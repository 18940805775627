const defaultAccountId =
localStorage.getItem('defaultAccountId') !== ''
  ? JSON.parse(localStorage.getItem('defaultAccountId'))
  : ''

export const UnitModel = {
  unitId: ''
};

export const UnitGroupModel = {
  name: '',
  accountId: defaultAccountId,
  units: [UnitModel],
  createdBy: '',
  updatedBy: '',
  createdAt: '',
  updatedAt: '',
  accountName: ''
};
