/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import { fetchDevicesByMultipleCriteria } from "../../../../services/deviceNew.services";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import DevicesFilters from "../components/DevicesFilter";

export default function DevicesPage() {
  // State hooks for data storage
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [limit] = useState(15);
  const [filters, setFilters] = useState({
    id: "",
    make: "",
    model: "",
  });

  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("devices") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchDevicesByMultipleCriteria(
      filters.deviceType,
      filters.id,
      filters.make,
      filters.model,
      page,
      limit
    );
  }, [page, filters]);

  const _fetchDevicesByMultipleCriteria = async (
    deviceType,
    id,
    make,
    model,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchDevicesByMultipleCriteria(
        deviceType,
        id,
        make,
        model,
        page,
        limit
      );
      setCount(response.count);
      setDevices((prevDevices) => [...prevDevices, ...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setDevices([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (devices.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All devices have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Device" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <DevicesFilters onFiltersChange={handleFiltersChange} />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Device" />
              <ExportButton name="Devices" accounts={devices}></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ devices, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}
