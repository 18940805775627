/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledInputControlContainer } from '../../../management/billingPlans/styles/BillingPlansStyles.js'
import {
  StyledAddMoreButton,
  StyledCargoButtonGrid,
  StyledCargoGridItem,
  StyledCargoInfoTypography,
  StyledCargoInnerGrid,
  StyledCargoItemGrid,
  StyledCargoNameTypography,
  StyledCargoRadio,
  StyledCargoTypography,
  // StyledFormContainer,
  StyledFormContainer1,
  StyledFormSection,
  StyledFormSection1,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSectionHeader
} from '../../../../styledComponents.js'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { iconMap } from '../../../../constants/iconsList.js'
import PropTypes from 'prop-types'
import { consignmentCargoType } from '../../../../utils/constant.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { DoubleArrowIconContainer } from '../../drivers/styles/DriversStyles.js'
import FormDatepicker from '../../../common/FormDatepicker.js'
import FormOptionSelector from '../../../common/FormOptionSelector.js'
import FormFieldWithEndAdornment from '../../../common/FormFieldWithEndAdornment.js'
import FormField from '../../../common/FormField.js'
import FormAddress from '../../../common/FormAddress.js'
import FormAddress2 from '../../../common/FormAddress2.js'
import FormFieldMultiline from '../../../common/FormFieldMultiline.js'
import FormError from '../../../common/FormError.js'
import FormTimepicker from '../../../common/FormTimepicker.js'
import { getUTCDate } from '../../../../utils/functions.js'

const palletsReturnModeOption = [
  { name: 'No', value: false, control: 'fieldType' },
  { name: 'Yes, Return', value: true, control: 'fieldType' }
]

const ConsignmentsForm2 = ({
  consignment,
  onFormValueChange,
  validationErrors
}) => {
  const today = dayjs()
  const [pickupFromTime, setPickupFromTime] = useState(null)
  const [pickupToTime, setpickupToTime] = useState(null)
  const [fromTime, setFromTime] = useState(null)
  const [toTime, setToTime] = useState(null)
  const [expectedPickupDate, setExpectedPickupDate] = useState(null)
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null)

  useEffect(() => {
    if (consignment?.deliverySchedule) {
      const {
        expectedPickupDate,
        expectedDeliveryDate,
        pickupTime,
        deliveryTime
      } = consignment.deliverySchedule
      setExpectedPickupDate(
        expectedPickupDate ? dayjs(expectedPickupDate) : null
      )
      setExpectedDeliveryDate(
        expectedDeliveryDate ? dayjs(expectedDeliveryDate) : null
      )

      setPickupFromTime(
        pickupTime?.from ? dayjs(pickupTime.from, 'hh:mm A') : null
      )
      setpickupToTime(pickupTime?.to ? dayjs(pickupTime.to, 'hh:mm A') : null)

      setFromTime(
        deliveryTime?.from ? dayjs(deliveryTime.from, 'hh:mm A') : null
      )
      setToTime(deliveryTime?.to ? dayjs(deliveryTime.to, 'hh:mm A') : null)
    }
  }, [consignment])

  const calculateCargoVolume = (length, breadth, height) => {
    return (length * breadth * height) / 1000000 // Convert to cubic meters
  }

  const handleCargoItemChange = async (fieldName, fieldValue, index) => {
    let updatedConsignment = { ...consignment }

    if (fieldName === 'cargoDetails.items.cargoType') {
      updatedConsignment.cargoDetails.items[index] = {
        ...updatedConsignment.cargoDetails.items[index],
        cargoType: {
          ...updatedConsignment.cargoDetails.items[index].cargoType,
          name: fieldValue.name,
          info: fieldValue.info
        }
      }
      fieldName = `cargoDetails.items[${index}].cargoType.name`
    } else if (fieldName === 'cargoDetails.items.quantity') {
      updatedConsignment.cargoDetails.items[index].quantity = fieldValue
      fieldName = `cargoDetails.items[${index}].quantity`
    } else if (fieldName === 'cargoDetails.items.weight') {
      updatedConsignment.cargoDetails.items[index].weight = fieldValue
      fieldName = `cargoDetails.items[${index}].weight`

      // Calculate total weight
      const totalWeight = updatedConsignment.cargoDetails.items.reduce(
        (total, item) => total + (item.weight || 0),
        0
      )
      updatedConsignment.cargoDetails.totalWeight = totalWeight
    } else if (
      fieldName === 'cargoDetails.items.length' ||
      fieldName === 'cargoDetails.items.breadth' ||
      fieldName === 'cargoDetails.items.height'
    ) {
      const dimension = fieldName.split('.')[2] // Extract dimension (length, breadth, height)
      updatedConsignment.cargoDetails.items[index][dimension] = fieldValue

      const { length, breadth, height } =
        updatedConsignment.cargoDetails.items[index]
      updatedConsignment.cargoDetails.items[index].cubicMetres =
        calculateCargoVolume(length, breadth, height)

      fieldName = `cargoDetails.items[${index}].${dimension}`
    } else if (fieldName === 'cargoDetails.items.description') {
      updatedConsignment.cargoDetails.items[index].description = fieldValue
      fieldName = `cargoDetails.items[${index}].description`
    }

    onFormValueChange(updatedConsignment, fieldName, fieldValue, 1) // Pass step index here if needed
  }

  const addCargoItem = () => {
    let updatedConsignment = { ...consignment }
    updatedConsignment.cargoDetails.items.push({
      cargoType: { name: '', info: '' },
      quantity: null,
      weight: null,
      length: null,
      breadth: null,
      height: null,
      cubicMetres: null,
      description: null
    })
    onFormValueChange(
      updatedConsignment,
      'cargoDetails.items',
      updatedConsignment.cargoDetails.items,
      1
    )
  }

  const removeCargoItem = (index) => {
    // Create a deep copy of consignment to avoid mutating the original object
    let updatedConsignment = JSON.parse(JSON.stringify(consignment))

    // Remove the item at the specified index
    updatedConsignment.cargoDetails.items.splice(index, 1)

    // Recalculate the total weight
    const totalWeight = updatedConsignment.cargoDetails.items.reduce(
      (total, item) => total + (item.weight || 0),
      0
    )
    updatedConsignment.cargoDetails.totalWeight = totalWeight

    // Call the onFormValueChange function with the updated values
    onFormValueChange(
      updatedConsignment,
      'cargoDetails.items',
      updatedConsignment.cargoDetails.items,
      1
    )
  }

  const handleDateChange = (fieldName, newValue) => {
    let updatedConsignment = { ...consignment }

    switch (fieldName) {
      case 'deliverySchedule.expectedPickupDate':
        updatedConsignment.deliverySchedule.expectedPickupDate = dayjs(newValue)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
        setExpectedPickupDate(newValue)
        break
      case 'deliverySchedule.expectedDeliveryDate':
        updatedConsignment.deliverySchedule.expectedDeliveryDate = dayjs(
          newValue
        )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
        setExpectedDeliveryDate(newValue)
        break
      case 'palletDetails.expectedReturnDate':
        updatedConsignment.palletDetails.expectedReturnDate = dayjs(newValue)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
        break
      default:
        break
    }

    onFormValueChange(updatedConsignment, fieldName, newValue, 1)
  }

  const handleTimeChange = (fieldName, newValue) => {
    let updatedConsignment = { ...consignment }
    if (fieldName === 'deliverySchedule.pickupTime.from') {
      updatedConsignment.deliverySchedule.pickupTime.from =
        newValue.format('hh:mm A')
      setPickupFromTime(newValue)
    } else if (fieldName === 'deliverySchedule.pickupTime.to') {
      updatedConsignment.deliverySchedule.pickupTime.to =
        newValue.format('hh:mm A')
      setpickupToTime(newValue)
    } else if (fieldName === 'deliverySchedule.deliveryTime.from') {
      updatedConsignment.deliverySchedule.deliveryTime.from =
        newValue.format('hh:mm A')
      setFromTime(newValue)
    } else if (fieldName === 'deliverySchedule.deliveryTime.to') {
      updatedConsignment.deliverySchedule.deliveryTime.to =
        newValue.format('hh:mm A')
      setToTime(newValue)
    }
    console.log('updatedConsignment', updatedConsignment.deliverySchedule)
    onFormValueChange(updatedConsignment, fieldName, newValue, 1)
  }

  const handleFieldChange = async (fieldName, value) => {
    let updatedConsignment = { ...consignment }

    if (fieldName === 'palletDetails.returnPallets') {
      updatedConsignment.palletDetails.returnPallets = value
    }

    if (fieldName.startsWith('palletDetails.returnAddress.')) {
      const addressField = fieldName.split('palletDetails.returnAddress.')[1]
      updatedConsignment.palletDetails.returnAddress[addressField] = value
      if (
        updatedConsignment.palletDetails.returnAddress.addressType === 'Address'
      ) {
        if (updatedConsignment.palletDetails.returnAddress.geofenceId != null) {
          handleFieldChange('palletDetails.returnAddress.geofenceId', null)
        }
      }
      if (
        updatedConsignment.palletDetails.returnAddress.addressType ===
        'Geofence'
      ) {
        if (
          updatedConsignment.palletDetails.returnAddress.streetOne != null &&
          updatedConsignment.palletDetails.returnAddress.streetOne != ''
        ) {
          handleFieldChange('palletDetails.returnAddress.streetOne', null)
        }
        if (
          updatedConsignment.palletDetails.returnAddress.streetTwo != null &&
          updatedConsignment.palletDetails.returnAddress.streetTwo != ''
        ) {
          handleFieldChange('palletDetails.returnAddress.streetTwo', null)
        }
        if (
          updatedConsignment.palletDetails.returnAddress.city != null &&
          updatedConsignment.palletDetails.returnAddress.city != ''
        ) {
          handleFieldChange('palletDetails.returnAddress.city', null)
        }
        if (
          updatedConsignment.palletDetails.returnAddress.state != null &&
          updatedConsignment.palletDetails.returnAddress.state != ''
        ) {
          handleFieldChange('palletDetails.returnAddress.state', null)
        }
        if (
          updatedConsignment.palletDetails.returnAddress.country != null &&
          updatedConsignment.palletDetails.returnAddress.country != ''
        ) {
          handleFieldChange('palletDetails.returnAddress.country', null)
        }
        if (
          updatedConsignment.palletDetails.returnAddress.postalCode != null &&
          updatedConsignment.palletDetails.returnAddress.postalCode != ''
        ) {
          handleFieldChange('palletDetails.returnAddress.postalCode', null)
        }
      }
    }
    onFormValueChange(updatedConsignment, fieldName, value, 1)
  }

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Cargo Items</Typography>
        </StyledSectionHeader>
        <Grid>
          <Grid xs={12}>
            {consignment.cargoDetails.items?.map((item, index) => (
              <StyledCargoGridItem key={index}>
                <StyledCargoInnerGrid>
                  {consignment.cargoDetails.items.length > 1 && (
                    <StyledCargoButtonGrid>
                      <DeleteIcon
                        onClick={() => removeCargoItem(index)}
                        sx={{ color: '#e71111', cursor: 'pointer' }}
                      />
                    </StyledCargoButtonGrid>
                  )}
                  <Grid>
                    <Typography variant="label" sx={{ marginBottom: '15px' }}>
                      Choose a Cargo Type*
                    </Typography>
                    {validationErrors[
                      `cargoDetails.items[${index}].cargoType.name`
                    ] && (
                      <FormError
                        errorMessage={
                          validationErrors[
                            `cargoDetails.items[${index}].cargoType.name`
                          ]
                        }
                      />
                    )}
                  </Grid>

                  <Grid container spacing={1}>
                    {consignmentCargoType.map((cargo, index1) => (
                      <Grid item key={index1} xs={12} sm={4} md={4}>
                        <Grid>
                          <StyledCargoItemGrid
                            item
                            key={index1}
                            isSelected={item?.cargoType?.name === cargo.value}
                            onClick={() =>
                              handleCargoItemChange(
                                'cargoDetails.items.cargoType',
                                cargo,
                                index
                              )
                            }
                          >
                            <Box>
                              <StyledCargoNameTypography>
                                {cargo.name}
                              </StyledCargoNameTypography>
                              <StyledCargoInfoTypography>
                                {cargo.info}
                              </StyledCargoInfoTypography>
                            </Box>
                            <StyledCargoRadio
                              value={cargo.value}
                              checked={item?.cargoType?.name === cargo.value}
                              onClick={() =>
                                handleCargoItemChange(
                                  'cargoDetails.items.cargoType',
                                  cargo,
                                  index
                                )
                              }
                            />
                          </StyledCargoItemGrid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid></Grid>
                </StyledCargoInnerGrid>

                {/* <StyledInputControlContainer> */}
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <FormField
                      type="number"
                      label="Quantity"
                      placeholder="Enter Quantity"
                      value={item.quantity || ''}
                      onChange={(event) => {
                        const value =
                          event.target.value === ''
                            ? null
                            : parseFloat(event.target.value)
                        handleCargoItemChange(
                          'cargoDetails.items.quantity',
                          value,
                          index
                        )
                      }}
                      error={
                        !!validationErrors[
                          `cargoDetails.items[${index}].quantity`
                        ]
                      }
                      errorMessage={
                        validationErrors[
                          `cargoDetails.items[${index}].quantity`
                        ]
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormFieldWithEndAdornment
                      type="number"
                      label="Weight"
                      placeholder="Enter Weight"
                      value={item.weight || ''}
                      onChange={(event) => {
                        const value =
                          event.target.value === ''
                            ? null
                            : parseFloat(event.target.value)
                        handleCargoItemChange(
                          'cargoDetails.items.weight',
                          value,
                          index
                        )
                      }}
                      endAdornmentText="Kg"
                      error={
                        !!validationErrors[
                          `cargoDetails.items[${index}].weight`
                        ]
                      }
                      errorMessage={
                        validationErrors[`cargoDetails.items[${index}].weight`]
                      }
                    />
                  </Grid>
                </Grid>
                {/* </StyledInputControlContainer> */}
                <Typography variant="label">Volume (LxBxH), CBM.</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={2}>
                    <FormField
                      type="number"
                      placeholder="L"
                      value={item.length || ''}
                      onChange={(event) => {
                        const value =
                          event.target.value === ''
                            ? null
                            : parseFloat(event.target.value)
                        handleCargoItemChange(
                          'cargoDetails.items.length',
                          value,
                          index
                        )
                      }}
                      error={
                        !!validationErrors[
                          `cargoDetails.items[${index}].length`
                        ]
                      }
                      errorMessage={
                        validationErrors[`cargoDetails.items[${index}].length`]
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormField
                      type="number"
                      placeholder="B"
                      value={item.breadth || ''}
                      onChange={(event) => {
                        const value =
                          event.target.value === ''
                            ? null
                            : parseFloat(event.target.value)
                        handleCargoItemChange(
                          'cargoDetails.items.breadth',
                          value,
                          index
                        )
                      }}
                      error={
                        !!validationErrors[
                          `cargoDetails.items[${index}].breadth`
                        ]
                      }
                      errorMessage={
                        validationErrors[`cargoDetails.items[${index}].breadth`]
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormField
                      type="number"
                      placeholder="H"
                      value={item.height || ''}
                      onChange={(event) => {
                        const value =
                          event.target.value === ''
                            ? null
                            : parseFloat(event.target.value)
                        handleCargoItemChange(
                          'cargoDetails.items.height',
                          value,
                          index
                        )
                      }}
                      error={
                        !!validationErrors[
                          `cargoDetails.items[${index}].height`
                        ]
                      }
                      errorMessage={
                        validationErrors[`cargoDetails.items[${index}].height`]
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <DoubleArrowIconContainer
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        top: '1.5rem',
                        justifyContent: 'center'
                      }}
                    >
                      <img src={iconMap['Arrow']} alt="arrow" />
                    </DoubleArrowIconContainer>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <FormFieldWithEndAdornment
                      label=""
                      placeholder="Enter CubicMetres"
                      value={item.cubicMetres || ''}
                      type="number"
                      endAdornmentText="CBM"
                      readOnly
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormFieldMultiline
                    label="Description"
                    placeholder="Enter description"
                    value={item.description}
                    onChange={(e) =>
                      handleCargoItemChange(
                        'cargoDetails.items.description',
                        e.target.value,
                        index
                      )
                    }
                    multiline
                    rows={4}
                    error={
                      !!validationErrors[
                        `cargoDetails.items[${index}].description`
                      ]
                    }
                    errorMessage={
                      validationErrors[
                        `cargoDetails.items[${index}].description`
                      ]
                    }
                  />
                </Grid>
              </StyledCargoGridItem>
            ))}
          </Grid>
          <Typography variant="heading">
            Total Weight = {consignment?.cargoDetails.totalWeight} Kgs
          </Typography>

          <Grid xs={12}>
            <StyledAddMoreButton
              variant="outlined"
              sx={{
                marginTop: '10px'
              }}
              onClick={addCargoItem}
            >
              <img src={iconMap['AddMorePlusIcon']} alt="PlusIcon" />
              ADD MORE
            </StyledAddMoreButton>
          </Grid>
        </Grid>
      </StyledFormSection1>

      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Delivery Schedule</Typography>
        </StyledSectionHeader>
        <Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormDatepicker
                label="Expected Pickup Date"
                required
                value={expectedPickupDate}
                onChange={(newValue) =>
                  handleDateChange(
                    'deliverySchedule.expectedPickupDate',
                    newValue
                  )
                }
                error={
                  !!validationErrors['deliverySchedule.expectedPickupDate']
                }
                errorMessage={
                  validationErrors['deliverySchedule.expectedPickupDate']
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTimepicker
                label="From"
                required
                value={pickupFromTime}
                onChange={(newValue) => {
                  console.log('From Time Changed:', newValue) // Debugging log
                  handleTimeChange('deliverySchedule.pickupTime.from', newValue)
                }}
                error={!!validationErrors['deliverySchedule.pickupTime.from']}
                errorMessage={
                  validationErrors['deliverySchedule.pickupTime.from']
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTimepicker
                required
                label="To"
                value={pickupToTime}
                onChange={(newValue) => {
                  console.log('To Time Changed:', newValue) // Debugging log
                  handleTimeChange('deliverySchedule.pickupTime.to', newValue)
                }}
                error={!!validationErrors['deliverySchedule.pickupTime.to']}
                errorMessage={
                  validationErrors['deliverySchedule.pickupTime.to']
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormDatepicker
                label="Expected Delivery Date"
                required
                value={expectedDeliveryDate}
                onChange={(newValue) =>
                  handleDateChange(
                    'deliverySchedule.expectedDeliveryDate',
                    newValue
                  )
                }
                error={
                  !!validationErrors['deliverySchedule.expectedDeliveryDate']
                }
                errorMessage={
                  validationErrors['deliverySchedule.expectedDeliveryDate']
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTimepicker
                label="From"
                required
                value={fromTime}
                onChange={(newValue) =>
                  handleTimeChange(
                    'deliverySchedule.deliveryTime.from',
                    newValue
                  )
                }
                error={!!validationErrors['deliverySchedule.deliveryTime.from']}
                errorMessage={
                  validationErrors['deliverySchedule.deliveryTime.from']
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTimepicker
                label="To"
                required
                value={toTime}
                onChange={(newValue) =>
                  handleTimeChange('deliverySchedule.deliveryTime.to', newValue)
                }
                error={!!validationErrors['deliverySchedule.deliveryTime.to']}
                errorMessage={
                  validationErrors['deliverySchedule.deliveryTime.to']
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledFormSection1>

      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Empty Container / Pallets</Typography>
        </StyledSectionHeader>
        <Grid>
          <FormOptionSelector
            label="To be Returned?"
            required
            options={palletsReturnModeOption}
            selectedOption={consignment?.palletDetails?.returnPallets}
            handleOptionChange={(value) =>
              handleFieldChange('palletDetails.returnPallets', value)
            }
            error={!!validationErrors['palletDetails.returnPallets']}
            errorMessage={validationErrors['palletDetails.returnPallets']}
          />
          {consignment?.palletDetails?.returnPallets === true && (
            <>
              <FormDatepicker
                label="Select Pallets Return Date"
                required
                value={
                  consignment?.palletDetails?.expectedReturnDate
                    ? dayjs(consignment.palletDetails.expectedReturnDate)
                    : today
                }
                onChange={(newValue) =>
                  handleDateChange('palletDetails.expectedReturnDate', newValue)
                }
                error={!!validationErrors['palletDetails.expectedReturnDate']}
                errorMessage={
                  validationErrors['palletDetails.expectedReturnDate']
                }
              />
              <FormAddress2
                address={consignment.palletDetails.returnAddress}
                addressKey="-1"
                handleFieldChange={(name, value) =>
                  handleFieldChange(
                    'palletDetails.returnAddress.' + name,
                    value
                  )
                }
                validationErrors={validationErrors}
              />
            </>
          )}
        </Grid>
      </StyledFormSection1>
    </StyledFormContainer1>
  )
}

ConsignmentsForm2.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired
}

export default ConsignmentsForm2
