/* eslint-disable no-unused-vars */
import { Grid, Icon, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConsigneeModel } from "../../../../model/consignee.model";
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledAddMoreButton,
  NextButton,
  StyledInputControlContainer,
  StyledCargoGridItem,
  StyledCargoButtonGrid,
} from "../../../../styledComponents";
import FormField from "../../../common/FormField";
import { customersSchema } from "../../../../utils/validation-schemas/customersSchema";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import FormPhone from "../../../common/FormPhone";
import FormAddress from "../../../common/FormAddress";
import {
  createCustomer,
  getCustomerById,
  updateCustomer,
} from "../../../../services/customerNew.service";
import Loader from "../../../common/Loader";
import { iconMap } from "../../../../constants/iconsList";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { getRoute } from "../../../../constants/routes";

export default function ConsigneesForm() {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [consignee, setConsignee] = useState(ConsigneeModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [defaultsSet, setDefaultsSet] = useState(false);
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    if (id) {
      _getCustomerById(id);
    } else {
      setDefaults();
    }
  }, [id]);

  const _getCustomerById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getCustomerById(id);
      setConsignee(data);
      handleResponse(data, "Consignee fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaults = () => {
    const updatedCustomer = {
      ...consignee,
      accountId: defaultAccountId,
    };
    setConsignee(updatedCustomer);
    setDefaultsSet(true);
  };

  const handleFieldChange = async (name, value) => {
    try {
      let updatedCustomer = { ...consignee };

      if (name.startsWith("address[")) {
        const match = name.match(/address\[(\d+)\]\.(.+)/);
        if (match) {
          const index = parseInt(match[1], 10);
          const field = match[2];
          updatedCustomer.address[index] = {
            ...updatedCustomer.address[index],
            [field]: value,
          };

          if (field === "addressType") {
            if (value === "Geofence") {
              updatedCustomer.address[index] = {
                ...updatedCustomer.address[index],
                streetOne: "",
                streetTwo: "",
                state: "",
                country: "",
                city: "",
                postalCode: "",
              };
            } else if (value === "Address") {
              updatedCustomer.address[index].geofenceId = null;
            }
          }
        }
      } else if (name === "phone") {
        const phoneNumberParts = value.split(" ");
        const countryCode = phoneNumberParts[0];
        let phoneNumber = phoneNumberParts.slice(1).join(" ");
        phoneNumber = phoneNumber.replace(/\s+/g, "");
        console.log("wah");
        console.log(phoneNumber);
        updatedCustomer.areaCode = countryCode;
        updatedCustomer.phone = phoneNumber;
      } else {
        updatedCustomer[name] = value;
      }

      console.log(updatedCustomer);
      handleValueChange(updatedCustomer, name); // Pass step index here

      // return updatedCustomer;

      // Call validateForm2 with the updated consignee data
      // validateForm2(
      //   updatedCustomer,
      //   name,
      //   setValidationErrors,
      //   customersSchema
      // );
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  const handleValueChange = async (updatedCustomer, fieldName) => {
    setConsignee((prevCustomer) => ({
      ...prevCustomer,
      ...updatedCustomer,
    }));

    const schema = customersSchema;
    try {
      await validateForm2(
        updatedCustomer,
        fieldName,
        setValidationErrors,
        schema
      );
    } catch (error) {
      console.error("Field validation error:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const isValid = await validateForm(
        consignee,
        setValidationErrors,
        customersSchema
      );
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;
      if (id === undefined) {
        response = await createCustomer(consignee);
        handleResponse(response, "Consignee created successfully!", "Card");
      } else {
        response = await updateCustomer(consignee);
        handleResponse(response, "Consignee updated successfully!", "Card");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Consignee", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
    setSuccessMessage("");
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const addNewAddress = () => {
    setConsignee((prevCustomer) => ({
      ...prevCustomer,
      address: [...prevCustomer.address, {}],
    }));
  };

  const removeAddress = (index) => {
    setConsignee((prevCustomer) => ({
      ...prevCustomer,
      address: prevCustomer.address.filter((_, i) => i !== index),
    }));
  };

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null; // or any other fallback UI you want to show
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title item>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>
            {id ? "Update" : "Create"} Consignee
          </Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Consignee Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Name"
            required
            placeholder="Enter Name"
            value={consignee.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormPhone
            label="Phone"
            value={consignee.areaCode + " " + consignee.phone}
            onChange={(newValue) => handleFieldChange("phone", newValue)}
            error={!!validationErrors.phone}
            errorMessage={validationErrors.phone}
            required
          />
          <FormField
            label="Email"
            required
            placeholder="Enter Email"
            value={consignee.email}
            onChange={(e) => handleFieldChange("email", e.target.value)}
            type="email"
            error={!!validationErrors.email}
            errorMessage={validationErrors.email}
          />
          <FormField
            label="Tax ID"
            required
            placeholder="Enter Tax ID"
            value={consignee.taxId}
            onChange={(e) => handleFieldChange("taxId", e.target.value)}
            error={!!validationErrors.taxId}
            errorMessage={validationErrors.taxId}
          />
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Address Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <StyledInputControlContainer>
            <Grid>
              {consignee.address.map((address, index) => (
                <StyledCargoGridItem
                  item
                  xs={12}
                  md={12}
                  key={index}
                  sx={{ padding: "20px", marginLeft: "0px" }}
                >
                  <StyledCargoButtonGrid item>
                    {consignee.address.length > 1 && (
                      <DeleteIcon
                        onClick={() => removeAddress(index)}
                        sx={{ color: "#e71111", cursor: "pointer" }}
                      />
                    )}
                  </StyledCargoButtonGrid>
                  <FormAddress
                    address={address}
                    key={index}
                    addressKey={index}
                    handleFieldChange={(name, value) =>
                      handleFieldChange(name, value)
                    }
                    validationErrors={validationErrors}
                  />
                </StyledCargoGridItem>
              ))}
            </Grid>
            <StyledAddMoreButton
              variant="outlined"
              sx={{ marginTop: "10px" }}
              onClick={addNewAddress}
            >
              <img
                src={iconMap["AddMorePlusIcon"]}
                alt="PlusIcon"
                style={{ height: "14px", width: "14px" }}
              />
              ADD MORE
            </StyledAddMoreButton>
          </StyledInputControlContainer>
        </StyledSectionRight>
      </StyledFormSection>
      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
