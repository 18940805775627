import { SET_USER_DATA, LOGIN_SUCCESS, LOGIN_FAILURE } from './userActionTypes';

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});

export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  payload: response,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const resetLoginForm = () => ({
  type: 'RESET_LOGIN_FORM'
});


// // actions.js
// export const setUserData = (formData) => ({
//   type: 'SET_USER_DATA',
//   payload: formData,
// });

// export const updateForm1 = (formData) => ({
//   type: 'UPDATE_FORM1',
//   payload: formData
// });

// export const updateForm2 = (formData) => ({
//   type: 'UPDATE_FORM2',
//   payload: formData
// });

// export const updateForm3 = (formData) => ({
//   type: 'UPDATE_FORM3',
//   payload: formData
// });

// export const loginForm = (formData) => ({
//   type: 'LOGIN_FORM',
//   payload: formData
// });

// export const resetForm1 = () => ({
//   type: 'RESET_FORM1'
// });

// export const resetForm2 = () => ({
//   type: 'RESET_FORM2'
// });

// export const resetForm3 = () => ({
//   type: 'RESET_FORM3'
// });

// export const resetLoginForm = () => ({
//   type: 'RESET_LOGIN_FORM'
// });