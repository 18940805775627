/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */ 
import React, { useCallback, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowModes,
} from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { StyledInputControlContainer, StyledSelect, StyledTextField } from "../../../../styledComponents";
import { MenuItem } from "@mui/material";
import * as yup from "yup";
import { iconMap } from "../../../../constants/iconsList";
import { createVehicleType, deleteVehicleType, fetchAllVehicleTypes, updateVehicleType } from "../../../../services/vehicleTypeNew.service";
import FormField from "../../../common/FormField";
import FormSelect from "../../../common/FormSelect";
import { validateForm2 } from "../../../../utils/yupValidations";
import Loader from "../../../common/Loader";
import DeleteModal from "../../../modals/DeleteModal";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const styles = {
  nextButton: {
    width: "140px",
    height: "44px",
    padding: "16px 24px",
    backgroundColor: "#0772b8",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "8px",
    boxShadow:
      " 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07)",
    gap: "8px",
  },
};

const typeOptions = [
  { name: 'Trailer', value: 'Trailer', control: 'type' },
  { name: 'Truck', value: 'Truck', control: 'type' },
];

const typeOptionsValues = typeOptions.map(option => option.value);

const vehicleTypeSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name cannot exceed 50 characters")
    .required("Name is required"),
  type: yup
    .string()
    .required("Type is required"),
});

function EditToolbar({ setRows, setRowModesModel, handleAdd }) {
  const [vehicleType, setVehicleType] = useState({ name: '', type: '' });
  const defaultAccountId = localStorage.getItem("defaultAccountId") ? JSON.parse(localStorage.getItem("defaultAccountId")) : "";
  const [validationErrors, setValidationErrors] = useState({});

  const handleFieldChange = async (name, value) => {
    try {
      // Update the vehicleType state based on the field name
      setVehicleType((prevVehicleType) => {
        const updatedVehicleType = { ...prevVehicleType, [name]: value };
        return updatedVehicleType; // Return the updated vehicleType object
      });
  
      // Validate the form field using validateForm2
      await validateForm2(
        { ...vehicleType, [name]: value }, // Pass updated value for validation
        name,
        setValidationErrors,
        vehicleTypeSchema
      );
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  const handleClick = async () => {
    const newVehicleType = { ...vehicleType, accountId: defaultAccountId };

    try {
      await vehicleTypeSchema.validate(newVehicleType, { abortEarly: false });
      handleAdd(newVehicleType);
      setVehicleType({ name: '', type: '' });
      setValidationErrors({});
    } catch (error) {
      if (error.inner) {
        const errors = error.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setValidationErrors(errors);
      }
    }
  };

  return (
    <GridToolbarContainer sx={{padding: '10px'}}>
      <Grid item container xs={12} md={12}>
        <Grid item xs={12} md={8} gap={3} sx={{display: 'flex', flexDirection: 'row'}}>
          <FormField
            label="Name"
            required
            placeholder="Enter name"
            value={vehicleType.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormSelect
            label="Type"
            required
            value={vehicleType.type}
            onChange={(selected) => handleFieldChange('type',selected)}
            options={typeOptions.map((item) => ({
              value: item.value,
              label: item.name,
            }))}
            error={!!validationErrors.type}
            errorMessage={validationErrors.type}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{display: 'flex', justifyContent: 'end', position: 'relative', top: '30px'}}>
          <Button sx={styles.nextButton} variant="contained" onClick={handleClick}>
            Save
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default function VehicleTypeForm() {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('')
  const [errors, setErrors] = useState([])
  const [validationErrors, setValidationErrors] = useState({})
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const defaultAccountId = localStorage.getItem("defaultAccountId") ? JSON.parse(localStorage.getItem("defaultAccountId")) : "";
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    const _fetchAllVehicleTypes = async () => {
      try {
        const data = await fetchAllVehicleTypes();
        setVehicleTypeData(data.rows);
      } catch (error) {
        console.error("Error fetching vehicle types:", error);
      } finally {
        setIsLoading(false);
      }
    };
    _fetchAllVehicleTypes();
  }, []);

  const handleSubmit = async (newVehicleType, id) => {
    try {
      let response;
      if (id) {
        response = await updateVehicleType(id, newVehicleType);
        handleResponse(response, 'VehicleType updated successfully!',)
        if (response) {
          setRows((oldRows) =>
            oldRows.map((row) => (row.id === id ? { ...row, ...newVehicleType } : row))
          );
        }
      } else {
        response = await createVehicleType(newVehicleType);
        handleResponse(response, 'VehicleType created successfully!',)
        if (response) {
          // setRows((oldRows) => [
          //   ...oldRows,
          //   { id: Math.random().toString(36).substr(2, 9), ...newVehicleType, isNew: true },
          // ]);
          setRows((oldRows) => [
            ...oldRows,
            { id: response.id, ...newVehicleType },
          ]);
        }
      }
    } catch (error) {
      handleError(error)
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }
  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }

  const handleAdd = (newVehicleType) => {
    handleSubmit({ ...newVehicleType, accountId: defaultAccountId });
  };

  useEffect(() => {
    setRows(vehicleTypeData);
  }, [vehicleTypeData]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === 'rowFocusOut') {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const row = rows.find((row) => row.id === id);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => async () => {
    const updatedRow = rows.find((row) => row.id === id);
    if (updatedRow) {
      setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.View } }));
      await handleSubmit(updatedRow, id);
    }
  };

  const [updatedRow, setUpdatedRow] = useState(null);

  useEffect(() => {
    if (updatedRow && updatedRow.id) {
      handleSubmit(updatedRow, updatedRow.id);
    }
  }, [updatedRow]);

  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
    };
    setUpdatedRow(updatedRow);
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleDeleteClick = (id) => () => {
    const selectedRow = rows.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setDeleteId(id);
    setOpen(true);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedAccount(null);
  }, [setSelectedAccount, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteAccount = useCallback(() => {
    setOpen(false);
    setSelectedAccount(null);
  }, [setOpen, setSelectedAccount]);

  const columns = [
    { field: 'name', headerName: 'Name', width: 180, editable: true },
    {
      field: 'type',
      headerName: 'Type',
      width: 220,
      editable: true,
      type: 'singleSelect',
      valueOptions: typeOptionsValues,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`save-${id}`}
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`cancel-${id}`}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            icon={<DeleteIcon sx={{ color: '#e71111' }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <SuccessMessage message={successMessage} onClose={handleClose} />
      <ErrorMessage1 errors={errors} onClose={handleClose} />
    {isLoading ? (<Loader />) :(
      <>
      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '16px',
              fontWeight: 600,
              color: '#0A2C47',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '14px',
              color: '#0A2C47',
            },
            '& .MuiDataGrid-cell--editing': {
              fontSize: '14px',
              color: '#0A2C47',
              '& .MuiInputBase-root': {
                fontSize: '14px',
                color: '#0A2C47',
              },
            },
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, handleAdd },
          }}
        />
      </Box>
        <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedAccount={selectedRow}
        deleteAccount={deleteAccount}
      />
      </>
    ) }
  </>
  );
}
