export const DeviceModel = {
  deviceImageUrl: "",
  deviceType: "",
  name: "",
  make: "",
  model: "",
  SSID: "",
  simCardId: "null",
  UID: "",
  serverAddress: "",
  rfids: [],
  description: "",
  setupInstructions: "",
  status: "PROVISIONED", // Default value set to 'PROVISIONED'
  createdBy: "",
  updatedBy: "",
};
