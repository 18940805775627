/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import ExportButton from "../../../common/ExportButton";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import { fetchUnitGroupsByMultipleCriteria } from "../../../../services/unitGroupNew.service";
import UnitGroupsFilter from "../components/UnitGroupsFilter";
export default function UnitGroupsPage() {
  // State hooks for data storage
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [unitGroups, setUnitGroups] = useState([]);
  const [limit] = useState(15);
  const [filters, setFilters] = useState({
    id: "",
  });

  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("unit-groups") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchUnitGroupsByMultipleCriteria(filters.id, page, limit);
  }, [page, filters]);

  const _fetchUnitGroupsByMultipleCriteria = async (id, page, limit) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchUnitGroupsByMultipleCriteria(id, page, limit);
      setCount(response.count);
      setUnitGroups((prevUnitGroups) => [...prevUnitGroups, ...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setUnitGroups([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (unitGroups.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All unitGroups have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Unit Group" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <UnitGroupsFilter onFiltersChange={handleFiltersChange} />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Unit Group" />
              <ExportButton
                name="Unit Groups"
                accounts={unitGroups}
              ></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ unitGroups, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}
