export const ServiceModel = {
  name: '',
  type: 'General', // Default value set to 'General'
  fieldType: 'Checkbox', // Default value set to 'Checkbox'
  amount: null,
  days: null,
  MB: null,
  frequency: 'Monthly', // Default value set to 'Monthly'
  currency: 'TZS', // Default value set to 'TZS'
  createdBy: '',
  updatedBy: ''
}
