import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FormOptionSelector from '../../../common/FormOptionSelector';
import FormFieldWithEndAdornment from '../../../common/FormFieldWithEndAdornment';
import { accountsSchema } from '../../../../utils/validation-schemas/accountsSchema';

const PaymentSettingsForm = ({
  account,
  handlePaymentSettingsChange,
  validationErrors,
}) => {
  const paymentSettingTypes = [
    { name: 'Block on Due', value: '0' },
    { name: 'Balance Amount', value: '1' },
    { name: 'Grace Days', value: '2' },
  ];
  const [selectedPaymentSetting, setSelectedPaymentSetting] = useState('0');
  const hasAccountIdChanged = useRef(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!hasAccountIdChanged.current && account?.id) {
      console.log('Current account object:', account);
      setSelectedPaymentSetting(
        account.settings.blockByDue === true &&
        account.settings.blockByBalanceAmount == null &&
        account.settings.gracePeriod == null
          ? '0'
          : account.settings.blockByDue === false &&
            account.settings.blockByBalanceAmount != null &&
            account.settings.gracePeriod == null
          ? '1'
          : account.settings.blockByDue === false &&
            account.settings.blockByBalanceAmount == null &&
            account.settings.gracePeriod != null
          ? '2'
          : ''
      );
  
      hasAccountIdChanged.current = true;
    }
  }, [account?.id]);
  

  const handlePaymentSettingsFieldChange = async (name, value) => {
    const parsedValue = value ? parseInt(value, 10) : null;

    const fieldToValidate = {
      settings: {
        ...account.settings,
        [name]: parsedValue,
      },
    };

    try {
      await accountsSchema.validateAt(`settings.${name}`, fieldToValidate);
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (err) {
      console.error('Validation error:', err);
      setErrors((prev) => ({ ...prev, [name]: err.message }));
    }

    if (selectedPaymentSetting === '0') {
      handlePaymentSettingsChange('settings.blockByDue', true);
      handlePaymentSettingsChange('settings.blockByBalanceAmount', null);
      handlePaymentSettingsChange('settings.gracePeriod', null);
    } else if (selectedPaymentSetting === '1') {
      handlePaymentSettingsChange('settings.blockByBalanceAmount', parsedValue);
    } else if (selectedPaymentSetting === '2') {
      handlePaymentSettingsChange('settings.gracePeriod', parsedValue);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedPaymentSetting(value);
    if (value === '0') {
      handlePaymentSettingsChange('settings.blockByDue', true);
      handlePaymentSettingsChange('settings.blockByBalanceAmount', null);
      handlePaymentSettingsChange('settings.gracePeriod', null);
    } else if (value === '1') {
      handlePaymentSettingsChange('settings.blockByDue', false);
      handlePaymentSettingsChange('settings.blockByBalanceAmount', null);
      handlePaymentSettingsChange('settings.gracePeriod', null);
    } else if (value === '2') {
      handlePaymentSettingsChange('settings.blockByDue', false);
      handlePaymentSettingsChange('settings.blockByBalanceAmount', null);
      handlePaymentSettingsChange('settings.gracePeriod', null);
    }
  };

  return (
    <>
      <FormOptionSelector
        label="Payment Settings"
        options={paymentSettingTypes}
        selectedOption={selectedPaymentSetting}
        handleOptionChange={handleOptionChange}
        required

      />
      {selectedPaymentSetting === '1' && (
        <FormFieldWithEndAdornment
          variant="outlined"
          margin="normal"
          type="number"
          value={account.settings.blockByBalanceAmount || ''}
          onChange={(event) =>
            handlePaymentSettingsFieldChange('blockByBalanceAmount', event.target.value)
          }
          error={!!errors.blockByBalanceAmount || !!validationErrors['settings.blockByBalanceAmount']}
          errorMessage={errors.blockByBalanceAmount || validationErrors['settings.blockByBalanceAmount']}
          endAdornmentText="Balance amount before account is blocked"
        />
      )}
      {selectedPaymentSetting === '2' && (
        <FormFieldWithEndAdornment
          variant="outlined"
          margin="normal"
          type="number"
          value={account.settings.gracePeriod || ''}
          onChange={(event) =>
            handlePaymentSettingsFieldChange('gracePeriod', event.target.value)
          }
          error={!!errors.gracePeriod || !!validationErrors['settings.gracePeriod']}
          errorMessage={errors.gracePeriod || validationErrors['settings.gracePeriod']}
          endAdornmentText="Grace period before account is blocked"
        />
      )}
    </>
  );
};

PaymentSettingsForm.propTypes = {
  account: PropTypes.object.isRequired,
  handlePaymentSettingsChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default PaymentSettingsForm;
