const routes = {
  Account: {
    Create: '/management/accounts/create',
    Edit: '/accounts/edit/:id',
    Card: '/management/accounts/card',
    List: '/management/accounts/list'
  },
  Unit: {
    Create: '/management/units/create',
    Edit: '/units/edit/:id',
    Card: '/management/units/card',
    List: '/management/units/list'
  },
  'Billing Plan': {
    Create: '/management/billing-plans/create',
    Edit: '/billing-plans/edit/:id',
    Card: '/management/billing-plans/card',
    List: '/management/billing-plans/list'
  },
  'Unit Group': {
    Create: '/management/unit-groups/create',
    Edit: '/unit-groups/edit/:id',
    Card: '/management/unit-groups/card',
    List: '/management/unit-groups/list'
  },
  Service: {
    Create: '/management/services/create',
    Edit: '/services/edit/:id',
    Card: '/management/services/card',
    List: '/management/services/list'
  },
  Consignee: {
    Create: '/monitoring/consignees/create',
    Edit: '/consignees/edit/:id',
    Card: '/monitoring/consignees/card',
    List: '/monitoring/consignees/list'
  },
  Role: {
    Create: '/management/roles/create',
    Edit: '/roles/edit/:id',
    Card: '/management/roles/card',
    List: '/management/roles/list'
  },
  User: {
    Create: '/management/users/create',
    Edit: '/users/edit/:id',
    Card: '/management/users/card',
    List: '/management/users/list'
  },
  Device: {
    Create: '/management/devices/create',
    Edit: '/devices/edit/:id',
    Card: '/management/devices/card',
    List: '/management/devices/list'
  },
  'Sim Card': {
    Create: '/management/sim-cards/create',
    Edit: '/sim-cards/edit/:id',
    Card: '/management/sim-cards/card',
    List: '/management/sim-cards/list'
  },
  Geofence: {
    Create: '/monitoring/geofences/create',
    Edit: '/geofences/edit/:id',
    Card: '/monitoring/geofences/card',
    List: '/monitoring/geofences/list'
  },
  Driver: {
    Create: '/monitoring/drivers/create',
    Edit: '/drivers/edit/:id',
    Card: '/monitoring/drivers/card',
    List: '/monitoring/drivers/list'
  },
  Consignment: {
    Create: '/monitoring/consignments/create',
    Edit: '/consignments/edit/:id',
    Card: '/monitoring/consignments/card',
    List: '/monitoring/consignments/list'
  },
  DriverConsignment: {
    Card: 'consignment-list/card/:id',
    List: 'consignment-list/list/:id'
  },
  'Route Optimization': {
    Create: '/monitoring/route-optimization/create',
    Card: '/monitoring/route-optimization/card',
    List: '/monitoring/route-optimization/list'
  },
  Report: {
    // Create: '/monitoring/consignments/create',
    // Edit: '/consignments/edit/:id',
    Card: '/monitoring/reports/card',
    List: '/monitoring/reports/list'
  },
  // Add more routes as needed
}

//Function to get route by feature name and mode (create/edit)
export const getRoute = (name, mode) => {
  if (routes[name] && routes[name][mode]) {
    return routes[name][mode]
  } else {
    return null // or some error handling
  }
}
