/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";
import { formatDate, formatTime, getUsersStatusColor } from "../../../../utils/functions";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";

// Service and common components
import { updateAccount } from "../../../../services/accountNew.service";
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import { deleteUser, updateUser } from "../../../../services/userNew.service";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import ActivateUserModal from "../../../modals/ActiveUserModel";
import InactiveUserModal from "../../../modals/InactiveUserModel";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";

const UsersListCard = () => {
  const [errors, setErrors] = useState([]);
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by account ID on open and close 3 dots menu option
  const [openMenuAccountId, setOpenMenuAccountId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  // This is for the deactivate account modal
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  // This is for the deactivate account modal
  const [activateOpen, setActivateOpen] = useState(false);
  // const [accounts, setAccounts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  // Hooks for navigation and outlet context
  const navigate = useNavigate();
  const { users, fetchMore, isLoading, isEmpty } = useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [users, isLoading, handleScroll]); // Include dependencies that affect the event listener

  // Function that calls the API to perform updateUser
  const handleUpdateUser = useCallback(
    async (updatedUser) => {
      try {
        const response = await updateUser(selectedItem.id, updatedUser);
        if (response) {
          setSuccessMessage("User updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuAccountId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuAccountId(null); // Set the ID of the account whose menu should be open
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteItem
  // const deleteItem = useCallback(() => {
  //   setOpen(false);
  //   setSelectedItem(null);
  // }, [setOpen, setSelectedItem]);

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteUser(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, "User deleted successfully.");
    //     handleError([]);
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error);
    // } finally {
    // setOpen(false);
    // setSelectedItem(null);
    // }
  };

  //Deactivate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  // Function that calls the API to perform deleteItem
  const deactivateUser = useCallback(() => {
    // Check if the status exists before attempting to update it
    if (selectedItem?.status) {
      const updatedStatus = "INACTIVE";

      // Update the selectedItem with the new status
      const updatedUser = {
        ...selectedItem,
        status: updatedStatus, // Set status to INACTIVE
      };

      // Update the selectedItem and call the update handler
      setSelectedItem(updatedUser);
      handleUpdateUser(updatedUser);

      // Close the modal and reset selected item
      setDeactivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No status found to update.");
    }
  }, [selectedItem, handleUpdateUser, setDeactivateOpen, setSelectedItem]);

  //Activate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleActivateModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  // Function that calls the API to perform deleteItem
  const activateUser = useCallback(() => {
    // Check if the status exists before attempting to update it
    if (selectedItem?.status) {
      const updatedStatus = "ACTIVE";

      // Update the selectedItem with the new status
      const updatedUser = {
        ...selectedItem,
        status: updatedStatus, // Set status to ACTIVE
      };

      // Update the selectedItem and call the update handler
      setSelectedItem(updatedUser);
      handleUpdateUser(updatedUser);

      // Close the modal and reset selected item
      setActivateOpen(false);
      setSelectedItem(null);
    } else {
      console.error("No status found to update.");
    }
  }, [selectedItem, handleUpdateUser, setActivateOpen, setSelectedItem]);

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (accountId) => {
      navigate(`/management/users/edit/${accountId}`);
      // Your navigation logic here
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="USERS"
              message="Oops! You have no users for your search criteria"
              info="Please feel free to create a new user by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {users.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">
                      {user.userName}
                    </Typography>
                    <Typography
                      variant="cardStatus"
                      sx={{
                        color: getUsersStatusColor(user.status),
                      }}
                    >
                      &bull; &nbsp;
                      {user.status}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${user.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, user)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${user.id}`
                      )}
                      open={openMenuAccountId === user.id} // The menu is open if the openMenuAccountId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      {user && user.status === "ACTIVE" ? (
                        <StyledMenuItem
                          onClick={(e) => handleDeactivateModalOpen(e, user)}
                        >
                          <BlockOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Deactivate User
                        </StyledMenuItem>
                      ) : (
                        <StyledMenuItem
                          onClick={(e) => handleActivateModalOpen(e, user)}
                        >
                          <VerifiedUserOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Activate User
                        </StyledMenuItem>
                      )}
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, user)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete User</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(user.id)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Name</Typography>
                    <Typography variant="cardValue">
                      {user?.firstName}
                      {user?.lastName &&
                        user.lastName !== "null" &&
                        ` ${user.lastName}`}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Account</Typography>
                    <Typography variant="cardValue">
                      {user?.accessRights.find(
                        (access) => access.accountId === accountId
                      )?.accountName || ""}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Role</Typography>
                    <Typography variant="cardValue">
                      {user?.accessRights.find(
                        (access) => access.accountId === accountId
                      )?.roleName || "Not Found"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Last Visited</Typography>
                    <Typography variant="cardValue">
                    {user?.sessions[0]?.loginTime
                        ? formatDate(user?.sessions[user?.sessions?.length -1]?.loginTime) + ', ' + formatTime(user?.sessions[user?.sessions?.length -1]?.loginTime)
                        : "No activity found"}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={activateUser}
      />
      <InactiveUserModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        inactivateUser={deactivateUser}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default UsersListCard;
