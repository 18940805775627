import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Title, StyledImage } from '../../styledComponents';
import { iconMap } from '../../constants/iconsList';
import CreateButton from './CreateButton';

const ListHeaderNew = (props) => {
  return (
    <Container container>
      <Content>
        <StyledImage src={iconMap[`${props.name}s Blue`]} alt="icon" />
        <Title>{props.name +'s'}</Title>
      </Content>
      <CreateButton name={props.name} mode={props.mode}></CreateButton>
    </Container>
  );
};

ListHeaderNew.propTypes = {
  name: PropTypes.string.isRequired,
  //Mode is passed to get the navigateTo URL
  mode: PropTypes.string.isRequired,
};

export default ListHeaderNew;