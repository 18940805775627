/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import debounce from "lodash.debounce";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
  CardTitle,
} from "../../../../styledComponents";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";

// Service and common components
import Loader from "../../../common/Loader";
import { iconMap } from "../../../../constants/iconsList";
import {
  AccessText,
  AccountsButton,
  PriceText,
  PricingCycleItem,
  PricingCycleText,
  ServiceIcon,
  ServiceItem,
  ServiceLimit,
  ServiceName,
} from "../styles/BillingPlansStyles";
import EmptyState from "../../../common/EmptyState";
import { deleteBillingPlan } from "../../../../services/billingPlanNew.service";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const BillingPlansListCard = () => {
  const [errors, setErrors] = useState([]);
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by account ID on open and close 3 dots menu option
  const [openMenuBillingPlanId, setOpenMenuBillingPlanId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  // const [accounts, setAccounts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  // Hooks for navigation and outlet context
  const navigate = useNavigate();
  const { billingPlans, fetchMore, isLoading, isEmpty } = useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [billingPlans, isLoading, handleScroll]); // Include dependencies that affect the event listener
  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuBillingPlanId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuBillingPlanId(null); // Set the ID of the account whose menu should be open
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteBillingPlan(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, "Billing Plan deleted successfully.");
    //     handleError([]);
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error);
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (accountId) => {
      //   const tab = "details";
      navigate(`/management/billing-plans/edit/${accountId}`);
      // Your navigation logic here
    },
    [navigate]
  );

  const navigateToAccount = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/management/accounts`);
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="BILLING PLANS"
              message="Oops! You have no billing plan for your search criteria"
              info="Please feel free to create a new billing plan by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {billingPlans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <StyledListCard>
              <StyledListCardHeader
                sx={{
                  backgroundColor: plan.color + "99",
                  color: "#fff",
                }}
                title={
                  <CardTitle variant="cardTitle1">
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "row",
                      }}
                    >
                      <div
                        style={{
                          height: "32px",
                          width: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: plan.color,
                        }}
                      >
                        <img src={iconMap["Tag"]} alt="Tag" />
                      </div>
                      <Typography
                        variant="cardTitle1"
                        sx={{ marginLeft: "10px" }}
                      >
                        {plan.name}
                      </Typography>
                    </Grid>
                    <Typography variant="cardTitle2">
                      {plan.description}
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "8px",
                        height: "30px",
                      }}
                    >
                      <AccessText>Access</AccessText>
                      {plan.servicesLimit
                        .filter(
                          (service) =>
                            service.type === "General" && service.value == true
                        )
                        .map((service) => (
                          <ServiceIcon key={service.serviceId}>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                backgroundColor: "white",
                                padding: "8px 4px",
                                borderRadius: "8px",
                                marginRight: "8px",
                              }}
                            >
                              <img
                                src={iconMap[service.serviceName]}
                                alt={service.serviceName}
                                style={{ height: "15px" }}
                              />
                            </div>
                          </ServiceIcon>
                        ))}
                      <PriceText variant="h6">
                        {plan.prices.map((price) => {
                          return (
                            <>
                              {price.pricingCycle === "Monthly"
                                ? `${price.currency} ${price.amount.toFixed(
                                    2
                                  )} / ${price.pricingCycle}`
                                : `${price.currency} ${price.amount.toFixed(
                                    2
                                  )}`}
                            </>
                          );
                        })}
                      </PriceText>
                    </Grid>
                  </CardTitle>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${plan.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, plan)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${plan.id}`
                      )}
                      open={openMenuBillingPlanId === plan.id} // The menu is open if the openMenuBillingPlanId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, plan)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Billing Plan</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(plan.id)}
              >
                <div
                  style={{
                    padding: "16px 0px",
                    borderBottom: "1px solid #d8d8df",
                  }}
                >
                  <Typography variant="cardValue">Limits</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {plan.servicesLimit
                      .filter(
                        (service) => service.limit !== -1 && service.limit !== 0
                        // service.value === false
                      )
                      .map((service) => (
                        <ServiceItem key={service.serviceId}>
                          <ServiceIcon>
                            <img
                              src={iconMap[service.serviceName]}
                              alt={service.serviceName}
                              style={{ height: "25px" }}
                            />
                            <ServiceName>{service.serviceName}</ServiceName>
                            <ServiceLimit>
                              {service.limit}{" "}
                              {service.serviceName == "Data Bundle" ? "MB" : ""}
                            </ServiceLimit>
                          </ServiceIcon>
                        </ServiceItem>
                      ))}
                  </div>
                </div>
                <div
                  style={{
                    padding: "16px 0px",
                    borderBottom: "1px solid #d8d8df",
                  }}
                >
                  <Typography variant="cardValue">Features</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {plan.servicesLimit
                      .filter(
                        (service) =>
                          service.limit === -1 &&
                          service.type === "Features" &&
                          service.value === true
                      )
                      .map((service) => (
                        <ServiceItem key={service.serviceId}>
                          <ServiceIcon>
                            <img
                              src={iconMap[service.serviceName]}
                              alt={service.serviceName}
                              style={{ height: "25px" }}
                            />
                            <ServiceName>{service.serviceName}</ServiceName>
                          </ServiceIcon>
                        </ServiceItem>
                      ))}
                  </div>
                </div>
                <div
                  style={{
                    padding: "16px 0px",
                    borderBottom: "1px solid #d8d8df",
                  }}
                >
                  <Typography variant="cardValue">Alerts</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {plan.servicesLimit
                      .filter(
                        (service) =>
                          service.limit === -1 &&
                          service.type === "Alerts" &&
                          service.value === true
                      )
                      .map((service) => (
                        <ServiceItem key={service.serviceId}>
                          <ServiceIcon>
                            <img
                              src={iconMap[service.serviceName]}
                              alt={service.serviceName}
                              style={{ height: "25px" }}
                            />
                            <ServiceName>{service.serviceName}</ServiceName>
                          </ServiceIcon>
                        </ServiceItem>
                      ))}
                  </div>
                </div>
                <div
                  style={{
                    padding: "16px 0px",
                    borderBottom: "1px solid #d8d8df",
                  }}
                >
                  <Typography variant="cardValue">Reports</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {plan.servicesLimit
                      .filter(
                        (service) =>
                          service.limit === -1 &&
                          service.type === "Reports" &&
                          service.value === true
                      )
                      .map((service) => (
                        <ServiceItem key={service.serviceId}>
                          <ServiceIcon>
                            <img
                              src={iconMap[service.serviceName]}
                              alt={service.serviceName}
                              style={{ height: "18px" }}
                            />
                            <ServiceName>{service.serviceName}</ServiceName>
                          </ServiceIcon>
                        </ServiceItem>
                      ))}
                  </div>
                </div>
                <div
                  style={{
                    padding: "16px 0px",
                    borderBottom: "1px solid #d8d8df",
                  }}
                >
                  <Typography variant="cardValue">Pricing Cycle</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {plan.prices.map((price) => (
                      <PricingCycleItem key={price.priceId}>
                        <PricingCycleText>
                          {price.pricingCycle}
                        </PricingCycleText>
                      </PricingCycleItem>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    padding: "16px 0px",
                    borderBottom: "1px solid #d8d8df",
                  }}
                >
                  <Typography variant="cardValue">Add-ons</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {plan.servicesLimit
                      .filter(
                        (service) =>
                          service.limit === -1 &&
                          service.type === "Sensors" &&
                          service.value === true
                      )
                      .map((service) => (
                        <ServiceItem key={service.serviceId}>
                          <ServiceIcon>
                            <img
                              src={iconMap[service.serviceName]}
                              alt={service.serviceName}
                              style={{ height: "25px" }}
                            />
                            <ServiceName>{service.serviceName}</ServiceName>
                          </ServiceIcon>
                        </ServiceItem>
                      ))}
                  </div>
                </div>
                <Grid>
                  <Grid
                    style={{
                      padding: "16px 0px",
                      // display: 'flex',
                      // flexDirection: 'column'
                    }}
                  >
                    <Typography variant="cardValue">
                      Accounts ({plan.accountNames?.length})
                    </Typography>
                    {plan.accountNames?.length > 0 && (
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          flexWrap: "wrap-reverse",
                          marginTop: "8px",
                        }}
                      >
                        {plan.accountNames?.map((account) => (
                          <ServiceItem key={account}>
                            <ServiceName>{account}</ServiceName>
                          </ServiceItem>
                        ))}
                      </Grid>
                    )}
                    <Grid sx={{ display: "flex", justifyContent: "right" }}>
                      <AccountsButton
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: "100px",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#6C6C81",
                          marginTop: "15px",
                        }}
                        onClick={(e) => navigateToAccount(e)}
                      >
                        <img src={iconMap["AddMorePlusIcon"]} alt="PlusIcon" />
                        ADD
                      </AccountsButton>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default BillingPlansListCard;
