/* eslint-disable no-unused-vars */
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledFormContainer } from "../../billingPlans/styles/BillingPlansStyles.js";
import PropTypes from "prop-types";
import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
} from "../../../../styledComponents.js";
import FormField from "../../../common/FormField.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import FormSelect from "../../../common/FormSelect.js";
import { deviceType } from "../../../../utils/constant.js";
import { fetchDevicesByDeviceType } from "../../../../services/deviceNew.services.js";

const unitTypes = [
  { name: "Container", value: "Container", control: "unitType" },
  { name: "Vehicle", value: "Vehicle", control: "unitType" },
 
];

const UnitsForm1 = ({ unit, onFormValueChange, validationErrors }) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [limit] = useState(15);
  const [filters, setFilters] = useState({
    id: "",
    make: "",
    model: "",
  });

  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  const handleFieldChange = async (name, value) => {
    try {
      let updatedUnit = { ...unit };

      updatedUnit[name] = value;

      // if (name === 'pickupDetails.name') {
      //   updatedConsignment.pickupDetails = {
      //     ...updatedConsignment.pickupDetails,
      //     name: value
      //   };
      // } else if (name === 'pickupDetails.phone') {
      //   const { phone, areaCode } = splitPhoneNumber(value);
      //   updatedConsignment.pickupDetails = {
      //     ...updatedConsignment.pickupDetails,
      //     phone: phone,
      //     areaCode: areaCode
      //   };
      // } else if (name === 'deliveryDetails.addressId') {
      //   updatedConsignment.deliveryDetails = {
      //     ...updatedConsignment.deliveryDetails,
      //     addressId: value
      //   };
      // } else if (name === 'deliveryDetails.name') {
      //   updatedConsignment.deliveryDetails = {
      //     ...updatedConsignment.deliveryDetails,
      //     name: value
      //   };
      // } else if (name === 'deliveryDetails.phone') {
      //   const { phone, areaCode } = splitPhoneNumber(value);
      //   updatedConsignment.deliveryDetails = {
      //     ...updatedConsignment.deliveryDetails,
      //     phone: phone,
      //     areaCode: areaCode
      //   };
      // } else if (name === 'customerId') {
      //   setSelectedCustomer(value)
      //   updatedConsignment[name] = value.id;
      //   // await _getCustomerById(value);
      // } else {
      //   updatedConsignment[name] = value;
      // }

      onFormValueChange(updatedUnit, name, value, 1); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  useEffect(() => {
    if (unit.deviceType != "") {
      _fetchDevicesByDeviceType();
    }
  }, [unit.deviceType]);

  const _fetchDevicesByDeviceType = async (id, make, model, page, limit) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchDevicesByDeviceType(
        unit.deviceType,
        page,
        limit
      );
      setCount(response.count);
      setDevices((prevDevices) => [...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Name"
            required
            placeholder="Enter name"
            value={unit?.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormOptionSelector
            label="Unit Type"
            required
            options={unitTypes}
            selectedOption={unit?.unitType}
            handleOptionChange={(value) => handleFieldChange("unitType", value)}
            error={!!validationErrors.unitType}
            errorMessage={validationErrors.unitType}
          />
          {unit?.unitType === "Container" && (
            <>
              <FormSelect
                label="Choose Device Type"
                required
                value={unit?.deviceType}
                onChange={(e) => handleFieldChange("deviceType", e)}
                options={deviceType.map((item) => ({
                  value: item.value,
                  label: `${item.name}`,
                }))}
                error={!!validationErrors.deviceType}
                errorMessage={validationErrors.deviceType}
              />
              <FormSelect
                label="Choose Device"
                required
                value={unit?.deviceId}
                onChange={(e) => handleFieldChange("deviceId", e)}
                options={devices.map((item) => ({
                  value: item.id,
                  label: `${item.make + " " + item.model + " " + item.name }`,
                }))}
                error={!!validationErrors.deviceId}
                errorMessage={validationErrors.deviceId}
              />
            </>
          )}
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  );
};

UnitsForm1.propTypes = {
  unit: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default UnitsForm1;
