import { Box } from '@mui/material'
import React from 'react'
import ComingSoon from '../../../common/ComingSoon'

export default function PrivacyPolicy() {
    return (
        <Box sx={{ margin: '8% 2% 2%' }}>
            <ComingSoon name='Privacy Policy' />
        </Box>
    )
}
