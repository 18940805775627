import axios from "axios";
import { vehicleTypeApi } from "../utils/constant";

const api = axios.create({
  baseURL: vehicleTypeApi,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    // Something happened in setting up the request that triggered an error
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};


// Fetch all vehicleType with optional query parameters
export const fetchAllVehicleTypes = async (queryParams) => {
    try {
      const response = await api.get('/', { params: queryParams });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };
    
 // Get a vehicleType by its ID
export const getVehicleTypeById = async (vehicleTypeId) => {
    try {
      const response = await api.get(`/${vehicleTypeId}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }; 

  

  // Create a new VehicleType
export const createVehicleType = async (data) => {
    try {
      const response = await api.post('/', data);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  
  // Update an existing VehicleType by its ID
export const updateVehicleType = async (id, data) => {
    try {
      const response = await api.put(`/${id}`, data);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };


  
  // Delete a VehicleType by its ID
export const deleteVehicleType = async (id) => {
    try {
      const response = await api.delete(`/${id}`);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

