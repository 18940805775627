import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { Button, List, ListItem, Drawer, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { routes } from "../../../routes";

const drawerWidth = 200;

const LeftNavContainer = styled("nav")(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  paddingTop: "50px", // Adjust based on your app's header height
  backgroundColor: "blue",
  minHeight: "90vh",
  marginTop: "72px",
  backgroundImage: "linear-gradient(91deg, #1a1946 61%, #0772b8 135%)",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const MobileNavContainer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    paddingTop: "64px", // Adjust based on your app's header height
    backgroundColor: "blue",
    minHeight: "100vh",
    marginTop: "72px",
    backgroundImage: "linear-gradient(91deg, #1a1946 61%, #0772b8 135%)",
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const LeftNavBar = () => {
  const location = useLocation();
  const [screenType, setScreenType] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    setScreenType(location.pathname.split("/")[1]);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderManagementMenuItems = () => {
    return routes
      .filter(
        (route) =>
          route.label !== "Login" &&
          route.label !== "Privacy Policy" &&
          route.label !== "Terms and Conditions" &&
          route.label !== "Payments" &&
          route.type !== "settings" &&
          route.type !== "monitoring"
      )
      .map((route, index) => (
        <ListItem
          key={index}
          button
          component={Link}
          to={route.path}
          sx={{
            "&:hover": {
              color: "white",
            },
          }}
        >
          <img
            src={
              location.pathname === route.path ? route.activeIcon : route.icon
            }
          />
          <Button
            component={Link}
            to={route.path}
            sx={{
              color: location.pathname === route.path ? "white" : "#8e8ea2",
              fontFamily: "Archivo",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "18.9px",
              "&:hover": {
                color: "white",
              },
            }}
          >
            {route.label}
          </Button>
        </ListItem>
      ));
  };

  const renderMonitoringMenuItems = () => {
    return routes
      .filter(
        (route) =>
          route.label !== "Login" &&
          route.label !== "Privacy Policy" &&
          route.label !== "Terms and Conditions" &&
          route.label !== "Payments" &&
          route.type !== "settings" &&
          route.type !== "management"
      )
      .map((route, index) => (
        <ListItem
          key={index}
          button
          component={Link}
          to={route.path}
          sx={{
            "&:hover": {
              color: "white",
            },
          }}
        >
          <img
            src={
              location.pathname === route.path ? route.activeIcon : route.icon
            }
          />
          <Button
            component={Link}
            to={route.path}
            sx={{
              color: location.pathname === route.path ? "white" : "#8e8ea2",
              fontFamily: "Archivo",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "18.9px",
              "&:hover": {
                color: "white",
              },
            }}
          >
            {route.label}
          </Button>
        </ListItem>
      ));
  };

  const renderSettingMenuItems = () => {
    return routes
      .filter(
        (route) =>
          route.label !== "Login" &&
          route.label !== "Privacy Policy" &&
          route.label !== "Terms and Conditions" &&
          route.label !== "Payments" &&
          route.type !== "management" &&
          route.type !== "monitoring"
      )
      .map((route, index) => (
        <ListItem
          key={index}
          button
          component={Link}
          to={route.path}
          sx={{
            "&:hover": {
              color: "white",
            },
          }}
        >
          <img
            src={
              location.pathname === route.path ? route.activeIcon : route.icon
            }
          />
          <Button
            component={Link}
            to={route.path}
            sx={{
              color: location.pathname === route.path ? "white" : "#8e8ea2",
              fontFamily: "Archivo",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "18.9px",
              "&:hover": {
                color: "white",
              },
            }}
          >
            {route.label}
          </Button>
        </ListItem>
      ));
  };

  const renderDrawerContent = () => (
    <>
      {screenType === "management" && <List>{renderManagementMenuItems("management")}</List>}
      {screenType === "monitoring" && <List>{renderMonitoringMenuItems("monitoring")}</List>}
      {screenType === "settings" && <List>{renderSettingMenuItems("settings")}</List>}
    </>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'start', margin: '0px 10px' }}
      >
        <MenuIcon />
      </IconButton>
      <LeftNavContainer>
        {renderDrawerContent()}
      </LeftNavContainer>
      <MobileNavContainer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {renderDrawerContent()}
      </MobileNavContainer>
    </>
  );
};

export default LeftNavBar;
