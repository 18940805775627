/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import EditTabs from '../../../../layouts/accounts/editTabs'
import { UnitsModel } from '../../../../model/units.model'
import ErrorMessage from '../../../common/ErrorMessage'
import { useParams } from 'react-router-dom'
import { fetchUnitsByAccountId } from '../../../../services/unitNew.service'

export default function AccountsUnit() {
  const [unitData, setUnitData] = useState([UnitsModel])
  const [errorMessage, setErrorMessage] = useState('')
  const [page, setPage] = useState(1)
  const [limit] = useState(15)
  var { id } = useParams()

  useEffect(() => {
    const _fetchUnitsByAccountId = async () => {
      try {
        const data = await fetchUnitsByAccountId(page, limit, id)
        setUnitData(data.rows)
      } catch (error) {
        if (error?.response?.data) {
          setErrorMessage(error.response.data.message)
        } else {
          setErrorMessage(error.message)
        }
      }
    }
    _fetchUnitsByAccountId()
  }, [])

  return (
    <div style={{ marginTop: '38px' }}>
      <ErrorMessage message={errorMessage} />
      <EditTabs
        name="Unit"
        navigate="/management/units/create"
        items={unitData}
      />
    </div>
  )
}
