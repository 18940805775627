import React from 'react'
import { Typography, Grid, Radio } from '@mui/material'
import PropTypes from 'prop-types'
import FormError from './FormError'
import {
  StyledInputControlContainer,
  StyledPricingCycleContainer,
  PricingCycleOption,
  CurrencyLabel2,
  AmountLabel2,
  PricingCycleLabel2
} from '../../styledComponents'

const PricingCycleSelector = ({
  label,
  billingPlans,
  account,
  validationErrors,
  handlePricingCycleChange
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">{label} *</Typography>
      <StyledPricingCycleContainer>
        <Grid container spacing={2}>
          {billingPlans
            .find((plan) => plan.id === account.billingPlanId)
            ?.prices.map((price, index) => (
              <Grid item key={index} md={6} xs={12}>
                <PricingCycleOption
                  error={validationErrors['subscriptions[0].priceId']}
                  onClick={() =>
                    handlePricingCycleChange('subscriptions', price)
                  }
                >
                  <Grid container direction="column" gap={1}>
                    <PricingCycleLabel2>
                      {price.pricingCycle}
                    </PricingCycleLabel2>
                    <Grid container alignItems="center" gap={1}>
                      <CurrencyLabel2>{price.currency}</CurrencyLabel2>
                      <AmountLabel2>
                        {price.amount.toFixed(2).toString()}
                      </AmountLabel2>
                    </Grid>
                  </Grid>
                  <Radio
                    sx={{
                      padding: '0px',
                      color: '#0772B8',
                      '&.Mui-checked': {
                        color: '#0772B8'
                      }
                    }}
                    checked={price._id === account.subscriptions[0].priceId}
                    value={price._id}
                    name={`radio-buttons-${index}`}
                    inputProps={{ 'aria-label': price.pricingCycle }}
                  />
                </PricingCycleOption>
              </Grid>
            ))}
        </Grid>
      </StyledPricingCycleContainer>
      {validationErrors['subscriptions[0].priceId'] && (
        <FormError
          errorMessage={validationErrors['subscriptions[0].priceId']}
        />
      )}
    </StyledInputControlContainer>
  )
}

PricingCycleSelector.propTypes = {
  label: PropTypes.string.isRequired,
  billingPlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.any.isRequired,
          pricingCycle: PropTypes.string.isRequired,
          currency: PropTypes.string.isRequired,
          amount: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  account: PropTypes.shape({
    billingPlanId: PropTypes.any.isRequired,
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        priceId: PropTypes.any.isRequired
      })
    ).isRequired
  }).isRequired,
  validationErrors: PropTypes.object.isRequired,
  handlePricingCycleChange: PropTypes.func.isRequired
}

export default PricingCycleSelector
