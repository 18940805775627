export const DriversModel = {
  id: '',
  type: 'New Driver',
  selectedDriver: '',
  firstName: '',
  lastName: '',
  email: '',
  areaCode: '',
  phone: '',
  userName: '',
  password: '',
  confirmPassword: '',
  sessions: [
    {
      loginTime: new Date(),
      expiryTime: new Date(new Date().getTime() + 15 * 60 * 1000) // 15 minutes from now
    }
  ],
  settings: {
    measurementSystem: 'Metric',
    timeFormat: '24 Hrs',
    dateFormat: 'dd/mm/yyyy',
    timeZone: ''
  },
  status: 'ACTIVE',
  assignedTo: '',
  accountId: '',
  roleId: '',
  documents: [
    {
      fileURL: '',
      documentType: "Driver's License",
      customerView: false,
      driverView: false
    }
  ],
  licenseNumber: '',
  dailyLogs: [
    {
      date: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      breaks: [
        {
          startTime: new Date(),
          endTime: new Date(),
          totalBreakMinutes: null
        }
      ],
      overallHoursWorked: null,
      overallBreakMinutes: null
    }
  ],
  imageUrl: [],
  profileImage: {
    isDelete: false,
    imageURL: ''
  },
  customFields: [
    {
      name: '',
      value: ''
    }
  ],
  isParentAccount: false,
  accessAllAccounts: false,
  accessRights: [],
  role: '',
  documentsURL: [],
  createdBy: '',
  updatedBy: ''
}
