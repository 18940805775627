import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";
import { StyledDateRangePicker } from "../../styledComponents";

const SelectedDateRange = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  required = false,
  placeholder,
}) => {
  const [startDate, setStartDate] = React.useState(value ? value[0] : null);
  const [endDate, setEndDate] = React.useState(value ? value[1] : null);

  // This function is called whenever the date range changes
  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    // Pass startDate and endDate as individual parameters
    onChange({
      startDate: start ? start.toISOString() : "",
      endDate: end ? end.toISOString() : "",
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} sx={{ marginBottom: "30px" }}>
        <Typography variant="label">
          {label}
          {required && " *"}
        </Typography>
        <StyledDateRangePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          selectsRange
          placeholderText={placeholder}
          dateFormat="dd-MMM-yyyy"
          isClearable={true} // Allows clearing the date range
          className="custom-datepicker"
        />
        {error && <FormError errorMessage={errorMessage} />}
      </Grid>
    </Grid>
  );
};

SelectedDateRange.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SelectedDateRange;
