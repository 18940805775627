import React from 'react';
import { Container } from '@mui/material';
import Home from '../components/Home';

const HomePage = () => {
  return (
    <Container maxWidth="xl">
      <Home />
    </Container>
  );
};

export default HomePage;