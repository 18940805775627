import axios from "axios";
import { unitApi } from "../utils/constant";

// Create an instance of axios with default headers and base URL
const api = axios.create({
  baseURL: unitApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Get the count of unit statuses within a specified date range and interval
// export const getUnitStatusCounts = async (filters) => {
//   try {
//     const response = await api.get("/getUnitStatusCounts", { params: filters });
//     return response.data;
//   } catch (error) {
//     return handleApiError(error);
//   }
// };

export const getUnitStatusCounts = async (date, interval) => {
  const interval1 = interval === "daterange" ? "RANGE" : interval;

  // Ensure date is formatted correctly if it's provided
  if (date && typeof date.from === "object") {
    date.from = date.from.format("YYYY-MM-DD");
    date.to = date.to.format("YYYY-MM-DD");
  }

  // Build the query string based on the presence of date
  const queryString = date
    ? `/getUnitStatusCounts?from=${date.from}&to=${date.to}&interval=${interval1}`
    : `/getUnitStatusCounts?interval=${interval1}`; // No dates for MONTHLY

  try {
    const response = await api.get(queryString);

    return response.data; // Return the fetched data
  } catch (error) {
    handleApiError(error);
    return []; // Return an empty array in case of error
  }
};

// Fetch all units with optional query parameters
export const fetchAllUnits = async (queryParams) => {
  try {
    const response = await api.get("/", { params: queryParams });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a unit by its ID
export const getUnitById = async (unitId) => {
  try {
    const response = await api.get(`/${unitId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new unit
export const createUnit = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing unit by its ID
export const updateUnit = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a unit by its ID
export const deleteUnit = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by account id with pagination
export const fetchUnitsByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByAccountId", {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by account id with pagination
export const fetchAvailableUnitsByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchAvailableUnitsByAccountId", {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by name and account ID with pagination
export const fetchUnitsByNameAndAccountId = async (name, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByNameAndAccountId", {
      params: { name, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by Vehicle Identification Number (VIN) and account ID with pagination
export const fetchUnitsByVINAndAccountId = async (vin, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByVINAndAccountId", {
      params: { vin, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by vehicle make and account ID with pagination
export const fetchUnitsByMakeAndAccountId = async (make, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByMakeAndAccountId", {
      params: { make, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by vehicle model and account ID with pagination
export const fetchUnitsByModelAndAccountId = async (model, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByModelAndAccountId", {
      params: { model, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by lock ID and account ID with pagination, specifically for 'E Lock' units
export const fetchUnitsByLockIdAndAccountId = async (lockId, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByLockIdAndAccountId", {
      params: { lockId, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by serial number and account ID with pagination, specifically for 'E Lock' units
export const fetchUnitsBySerialNumberAndAccountId = async (
  serialNumber,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsBySerialNumberAndAccountId", {
      params: { serialNumber, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchUnitsByMultipleCriteria = async (
  unitType,
  id,
  VIN,
  licensePlate,
  make,
  model,
  deviceType,
  deviceId,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitsByMultipleCriteria", {
      params: {
        accountId,
        unitType,
        id,
        VIN,
        licensePlate,
        make,
        model,
        deviceType,
        deviceId,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
