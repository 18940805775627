/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import { createTheme } from "@mui/system";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const styles = {
    card: {
        width: "400px",
        border: "1px solid #E3E9EA",
        borderRadius: "8px",
        backgroundColor: "rgba(7, 114, 184, 0.08)",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    cardHeader: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "rgba(7, 114, 184, 0.08)"
    },
    cardContent: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderTop: "1px solid rgba(227, 233, 234, 1)",
        borderRadius: "0px 8px 0px 0px",
        paddingBottom: "16px !important"
    },
    cardContentRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px 0px 5px 0px",
        borderBottom: "1px solid rgba(227, 233, 234, 1)",
    },
    box: {
        display: "flex",
        alignItems: "center"
    },
    userName: {
        fontSize: "18px",
        fontWeight: "600",
        color: "rgba(23, 22, 57, 1)",
        lineHeight: "24.3px",
        padding: "0px 10px"
    },
    userContent: {
        fontSize: "14px",
        fontWeight: "400",
        color: "rgba(39, 37, 96, 1)",
        lineHeight: "18.9px"
    },
    userContentValue: {
        fontSize: "14px",
        fontWeight: "500",
        color: "rgba(39, 37, 96, 1)",
        lineHeight: "18.9px"
    },
    line: {
        padding: "5px 0px 0px 0px",
        borderBottom: "1px solid rgba(227, 233, 234, 1)"
    }
}

export default function CustomCard(props) {
    const { header, content, value } = props;
    return (
        <Grid item xs={12} sm={12} md={12} key={props.content.id} sx={{ display: 'flex' }}>
            <Card sx={styles.card}>
                <CardHeader sx={styles.cardHeader} title={<span style={styles.userName}>{header}</span>} />
                <CardContent sx={styles.cardContent}>
                    {content.map((item, index) => (
                        <Grid key={index} sx={styles.cardContentRow}>
                            <Box sx={styles.box}>
                                <IconButton aria-label="settings">
                                    {item.icon}
                                </IconButton>
                                <Typography sx={styles.userContent}>{item.serviceName}</Typography>
                            </Box>
                            {item.type === 'General' ?
                                <Typography sx={styles.userContentValue}>{item.value === true ? "Access" : "No - Access"}</Typography>
                                : (item.type === 'Management' && item.limit === -1) ? <Typography sx={styles.userContentValue}>Unlimited</Typography>
                                    : (item.type === 'Management' && item.limit !== -1) ? <Typography sx={styles.userContentValue}>{item.limit} Max. Limit</Typography>
                                        : (item.type === 'Monitoring' && item.limit === -1) ? <Typography sx={styles.userContentValue}>Unlimited</Typography>
                                            : (item.type === 'Monitoring' && item.limit !== -1) ? <Typography sx={styles.userContentValue}>{item.limit} Max. Limit</Typography>
                                                : (item.type === 'Sensors') ? <Typography sx={styles.userContentValue}>{item.value === true ? item.cost : 0}</Typography>
                                                    : (item.type === 'Features') ? <Typography sx={styles.userContentValue}>{item.value === true ? "Access" : "No - Access"}</Typography>
                                                       : (item.type === 'Reports') ? <Typography sx={styles.userContentValue}>{item.value === true ? "Access" : "No - Access"}</Typography>
                                                           : (item.type === 'Alerts') ? <Typography sx={styles.userContentValue}>{item.value === true ? "Access" : "No - Access"}</Typography>
                                                        : null
                            }
                        </Grid>
                    ))}
                </CardContent>
            </Card>
        </Grid>
    );
}
