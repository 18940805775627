import dayjs from 'dayjs'

const today = dayjs() // Get today's date
const twoDaysAhead = today.add(2, 'day') // Calculate two days ahead

export const AccountModel = {
  name: '',
  isParentAccount: false,
  isTopAccount: false,
  parentAccountId: null,
  billingPlanId: null,
  simCardId: null,
  subscriptions: [
    {
      priceId: '',
      subscriptionStatus: 'PENDING',
      startDate: twoDaysAhead,
      installationDate: twoDaysAhead,
      renewalDate: '',
      pricingCycle: '',
      amount: 0,
      currency: ''
    }
  ],
  settings: {
    blockByDue: true,
    blockByBalanceAmount: null,
    gracePeriod: null
  }
}
