/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  MenuItem,
  Radio,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import RangeDatePicker from "react-datepicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { MuiTelInput } from "mui-tel-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Grid as MuiGrid,
  IconButton as MuiIconButton,
  Modal as MuiModal,
  Typography as MuiTypography,
  Button as MuiButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from "@mui/material";
export const StyledFormControlLabel = styled(FormControlLabel)({
  color: "#0A2C47",
  "& .MuiFormControlLabel-label": {
    fontSize: "16px",
    fontWeight: "400",
    LineHeight: "21.6px",
  },
});

export const StyledSelectAllButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "32px",
  borderRadius: "8px",
  marginTop: "30px",
  backgroundColor: "#FFF",
  color: "#6C6C81",
  border: "1px solid #d8d8df",
  boxShadow: `
    0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11),
    0px 0.3px 0.9px 0px rgba(38, 54, 0, 0.07)
  `,
  "&:hover": {
    backgroundColor: "#f5f5f5", // Optional: Change background color on hover
  },
}));

export const StyledSearchTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  marginTop: "12px",
  marginBottom: "12px",
  backgroundColor: "#FFF",
  "& .MuiOutlinedInput-root": {
    height: "44px",
    borderRadius: "8px",
    padding: "0",
  },
}));

export const StyledCheckboxButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: "#6C6C81",
  fontSize: "16px",
  fontWeight: "500",
  borderColor: "transparent",
  textTransform: "none",
  height: "42px",
  "&.MuiButton-contained": {
    backgroundColor: "white",
    color: "#0A2C47",
    fontSize: "16px",
    fontWeight: "500",
    borderColor: "transparent",
    textTransform: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.08)",
    padding: "8px 16px",
  },
  "&.MuiButton-outlined": {
    borderColor: "transparent",
    color: "#6C6C81",
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "none",
    backgroundColor: "transparent",
  },
}));
//Old
export const StyledCardContent = styled(CardContent)`
  ${"" /* border-radius: 16px; */}
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.04);
  height: auto;
  border-radius: 0px 16px 0px 0px;
  padding: 16px 24px;
`;

///List Cards
//old
export const StyledCard = styled(Card)`
  cursor: pointer;
  gap: 16px;
  border: 1px solid #d8d8df;
  background-color: white;
  border-radius: 8px;
`;

//old
export const StyledCardHeader = styled(CardHeader)`
  background-color: #f0f8ff;
  padding: 26px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -10px;
`;

export const StyledInputControlContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const CardTitle = styled(Typography)({
  fontsize: "18px",
  fontWeight: "600,",
  color: "#0A2C47",
});

export const CardLabel = styled(Typography)({
  color: "#666",
});

export const CardFooter = styled(Grid)({
  borderTop: "1px solid #ccc",
  paddingTop: "16px",
  marginTop: "16px",
});

export const DeleteButton = styled(Button)({
  backgroundColor: "white",
  border: "1px solid green",
  borderRadius: "8px",
  width: "25%",
  margin: "15px",
  color: "green",
});

export const CancelButton = styled(Button)({
  backgroundColor: "white",
  border: "1px solid red",
  borderRadius: "8px",
  width: "25%",
  margin: "15px",
  color: "red",
});

export const ModalHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #ccc",
});

//Modals
export const StyledModal = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-30%, -50%)",
  width: "30%",
  borderRadius: "16px",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  color: "#272560",
  backgroundColor: theme.palette.background.paper,
}));

export const ConsignmentStyledModal = styled(Box)(({ theme }) => ({
  breakInside: "avoid",
}));

export const ModalFooter = styled(Box)({
  borderTop: "1px solid #ccc",
  marginTop: "20px",
  paddingTop: "10px",
  display: "flex",
  justifyContent: "flex-end",
});

export const LoaderContainer = styled("Grid")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 100,
  // backgroundColor: 'rgba(240, 240, 240, 0.8)',
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  zIndex: 9999,
});

export const LoaderContainer2 = styled("Grid")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 100,
  // backgroundColor: 'rgba(240, 240, 240, 0.8)',
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  zIndex: 9999,
});
export const LoaderConfig = {
  color: "#0772B8",
  size: 60,
  loading: true,
  speedMultiplier: 1.5,
};

export const FlexContainerSB = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const FlexContainer = styled(Grid)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const StyledTable = styled(MuiTable)`
  width: 100%;
`;

export const StyledTableRow = styled(MuiTableRow)`
  // background: pink;
`;

export const StyledTableCellHead = styled(MuiTableCell)`
  // border-bottom: none;
  // font-size: 14px;
  // color: #F7F7F8;
  // padding: 5px 10px;

  border-bottom: none;
  font-size: 14px;
  color: #4f636b;
  padding: 10px; // Ensure consistent padding
  text-align: center; // Center align text if needed
  & > span {
    display: inline-block;
    width: 100%; // Make sure the span takes the full width
  }
`;

// export const StyledTableCell1 = styled(MuiTableCell)`
//   border-bottom: none;
//   font-size: 14px;
//   color: #4f636b;
//   padding: 5px 10px;
// `;

export const StyledTableBody = styled(MuiTableCell)`
  font-size: 16px;
  color: #272560;
`;

export const StyledEndAdornmentTextfield = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    paddingRight: "0px",
    height: "46px", //set textfield height
    borderRadius: "8px",
    // '& .MuiOutlinedInput-root': {
    "& fieldset": {
      borderColor: "#d8d8df",
    },
    "&:hover fieldset": {
      borderColor: "#0772b8", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0772b8", // Border color on focus
    },
    // }
  },

  //Textfield text
  "& .MuiInputBase-input": {
    paddingRight: "0px",
    fontFamily: "Archivo",
    fontSize: "16px",
    color: "#0a2c47ed",
  },
  "& input": {
    padding: "12px 16px",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#f7f7f8",
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .inputAdornmentContainer": {
    backgroundColor: "#f7f7f8",
    borderLeft: "1px solid #d8d8df",
    padding: "12px 8px 12px 12px",
    display: "flex",
    alignItems: "center",
    height: "46px",
    // width:'200px'
  },
}));

export const StyledStartAdornmentTextfield = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    paddingLeft: "0px",
    height: "46px", //set textfield height
  },

  //Textfield text
  "& .MuiInputBase-input": {
    paddingRight: "0px",
    fontFamily: "Archivo",
    fontSize: "16px",
    color: "#0a2c47ed",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#f7f7f8",
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .startAdornmentContainer": {
    backgroundColor: "#f7f7f8",
    borderRight: "1px solid #d8d8df",
    padding: "12px 8px 12px 12px",
    display: "flex",
    alignItems: "center",
    height: "45px",
    // width:'200px'
  },
}));

export const StyledStartAdornmentTextfield2 = styled(TextField)(
  ({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      paddingLeft: "0px",
      height: "46px", //set textfield height
      "& fieldset": {
        borderColor: "#D8D8DF",
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: "#0772B8",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0772B8",
      },
      "& input": {
        padding: "12px 16px",
      },
    },

    //Textfield text
    "& .MuiInputBase-input": {
      paddingRight: "0px",
      fontFamily: "Archivo",
      fontSize: "16px",
      color: "#0a2c47ed",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#f7f7f8",
    },
    "& .MuiInputAdornment-root": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& .startAdornmentContainer": {
      backgroundColor: "#f7f7f8",
      borderRight: "1px solid #d8d8df",
      padding: "12px 8px 12px 12px",
      display: "flex",
      alignItems: "center",
      height: "45px",
      // width:'200px'
    },
  })
);

export const StyledMuiTelInput = styled(MuiTelInput)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: "46px", // Adjust the height as needed
    padding: "8px",
    backgroundColor: "white",
    "& fieldset": {
      borderColor: "#D8D8DF",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#0772B8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0772B8",
    },
    "& .MuiInputBase-input": {
      // padding: '12px 16px'
    },
  },
}));

export const StyledAddMoreButton = styled(Button)(({ theme }) => ({
  height: "32px",
  width: "130px",
  borderRadius: "8px",
  fontWeight: "600",
  padding: "8px 12px 8px 12px",
  color: "#6C6C81",
  borderColor: "#D8D8DF",
  backgroundColor: "#FFFFFF",
  gap: "8px",
  marginBottom: "20px",
  fontSize: "14px",
  boxShadow:
    "0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11), 0px 0.3px 0.9px 0px rgba(38, 54, 0, 0.07)",
  "&:hover": {
    borderColor: theme.palette.primary.dark,
  },
}));

export const StyledEditTabsAddMoreButton = styled(Button)(({ theme }) => ({
  height: "32px",
  width: "240px",
  borderRadius: "8px",
  fontWeight: "600",
  padding: "8px 12px 8px 12px",
  color: "#6C6C81",
  borderColor: "#D8D8DF",
  backgroundColor: "#FFFFFF",
  gap: "8px",
  marginBottom: "20px",
  fontSize: "14px",
  boxShadow:
    "0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11), 0px 0.3px 0.9px 0px rgba(38, 54, 0, 0.07)",
  "&:hover": {
    borderColor: theme.palette.primary.dark,
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    height: "46px",
    "& fieldset": {
      borderColor: "#D8D8DF",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#0772B8",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0772B8",
    },
    "& input": {
      padding: "12px 16px",
    },
  },
}));

export const StyledSearchFormSelectTextField = styled(TextField)(
  ({ theme }) => ({
    backgroundColor: "white",
    "& .MuiInputBase-root": {
      height: "46px", // Adjust this value to reduce the height
      borderRadius: "8px", // Adjust border radius

      "&:hover fieldset": {
        borderColor: "#0772B8",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0772B8",
      },
      "& input": {
        padding: "12px 16px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 0", // Adjust padding inside the input field
    },
  })
);

export const StyledDescriptionTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    height: "92px", // Set the height to 92px
    "& fieldset": {
      borderColor: "#D8D8DF",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#0772b8", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0772b8",
    },
  },
}));

export const StyledTimePicker = styled(MobileTimePicker)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "46px",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d8d8df",
    },
    "&:hover fieldset": {
      borderColor: "#0772b8", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0772b8", // Border color on focus
    },
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  backgroundColor: "white",
  height: "46px",
  borderRadius: "8px",
  borderColor: "#D8D8DF",
  "&:hover": {
    "&& fieldset": {
      // target the fieldset which controls the border in MUI Select
      borderColor: "#0772B8",
    },
  },
  "&.Mui-focused": {
    "&& fieldset": {
      borderColor: "#0772B8", // Change this to your desired focus color
    },
  },
  "&.Mui-disabled": {
    borderColor: "#D8D8DF",
    backgroundColor: "#f7f7f8", // Set the background color when disabled
    color: "#a0a0a0", // Optional: Change text color when disabled
  },
}));
export const StyledSelect2 = styled(Select)(({ theme }) => ({
  width: "100px",
  backgroundColor: "#f7f7f8",
  height: "46px",
  borderRadius: "8px",
  borderColor: "#D8D8DF",
  // padding:'12px',
  // '&:hover': {
  //   '&& fieldset': {
  //     // target the fieldset which controls the border in MUI Select
  //     borderColor: '#0772B8'
  //   }
  // },
  // '&.Mui-focused': {
  //   '&& fieldset': {
  //     borderColor: '#0772B8' // Change this to your desired focus color
  //   }
  // }
}));

export const StyledModalContent = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
}));

export const StyledModalHr = styled("hr")(({ theme }) => ({
  height: "0.5px",
  width: "100%",
  color: "#eee",
  borderStyle: "solid",
  marginTop: "15px",
  marginBottom: "15px",
}));

export const StyledModalListCard = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-bottom: 8px;
  align-items: center;
  &:hover {
    background-color: #f0f8ff;
  }
`;

export const StyledDeleteButton = styled(MuiButton)`
  padding: 16px 24px;
  border-radius: 8px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #e71111;
  border: 1px solid #e71111;
  box-shadow: "0 1.6px 3.6px 0 rgba(10, 27, 40, 0.11), 0 0.3px 0.9px 0 rgba(10, 27, 40, 0.07)";
  &:hover {
    background-color: #b80d0d;
  }
`;

export const StyledCancelButton = styled(MuiButton)`
  padding: 16px 24px;
  border-radius: 8px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  color: #cccccc;
  background-color: #fff;
  border: 1px solid #d8d8df;
  box-shadow: 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11),
    0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07);
  &:hover {
    background-color: #cccccc;
    color: #a3a3a3;
  }
`;

export const StyledAllocateButton = styled(MuiButton)`
  padding: 8px 12px;
  border-radius: 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  color: #6c6c81;
  background-color: #fff;
  border: 1px solid #d8d8df;
  &:hover {
    background-color: #f7f7f7;
    color: #565667;
  }
`;

export const ModalButton = styled(MuiButton)`
  padding: 16px 24px;
  border-radius: 8px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #d8d8df;
  box-shadow: 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11),
    0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07);
`;

// Styled Container using Material-UI's Grid
//List Header
export const Container = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledImage = styled("img")`
  height: 30px;
  margin-right: 10px;
  // margin-top:10px;
`;
export const StyledImage24 = styled("img")`
  height: 30px;
  margin-right: 10px;
  // margin-top:10px;
`;

// Styled Content using Material-UI's Grid
export const Content = styled(Grid)`
  display: flex;
  align-items: center;
`;

// Styled Title using Material-UI's Typography
export const Title = styled(Typography)`
  font-size: 32px;
  color: #0a2c47;
  font-weight: 600;
`;

// Styled Tagline using Material-UI's Typography
export const Tagline = styled(Typography)`
  font-size: 14px;
  color: #6c6c81;
  // margin-left: 10px;
`;

// Styled Button using Material-UI's Button
export const StyledButton = styled(MuiButton)`
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #0772b8;
  &:hover {
    background-color: #055b93;
  }
`;

export const BackButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11),
    0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07);
  border: solid 1px #d8d8df;
  margin-right: 16px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const NextButton = styled(MuiButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 24px;
  background-color: #0772b8;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11),
    0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07);
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #065a8e;
  }
`;
////// End of List Header

// Search Input Box
export const StyledSearch = styled(TextField)`
  background-color: #f7f7f8;
  height: 50px;
  padding-right: 16px;
  padding-left: 3px;
  padding-top: 10.5px;
  border: none;
  border-radius: 8px;
`;

export const FilterSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow:
    "0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11),0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07)",
  height: "42px",
  "& .MuiOutlinedInput-root": {
    border: "none", // Remove border
  },
  "& .MuiOutlinedInput-root:hover": {
    border: "none", // Remove border on hover
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove notched outline
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px", // Optional: Add padding to input
  },
}));

export const SearchButton = styled(Button)`
  height: 100%;
  border-radius: 8px;
`;

// End of Search Input Box

// List Cards for List Pages
export const StyledListCardContainer = styled(Grid)`
  display: flex;
  align-items: start;
  height: calc(100vh - 375px);
  overflow-y: auto;
  justifycontent: start;
`;

export const StyledListCard = styled(Card)`
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #d8d8df;
  border-radius: 8px;
  background-color: #f0f8ff;
`;

export const StyledListCardHeader = styled(CardHeader)`
  padding: 16px 24px;
  background-color: #f0f8ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledListCardContent = styled(CardContent)`
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.04);
  height: auto;
  border-radius: 0px 16px 0px 0px;
  padding: 16px 16px;
  background-color: white;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: right;
  padding-right: 0px;
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 1rem;
  &:not(:first-child) {
    border-top: 1px solid #eee;
  }
`;

///List Tables for List Pages

export const StyledTableContainer = styled(Grid)`
  display: flex;
  align-items: start;
  height: calc(100vh - 375px);
  overflow-y: auto;
  justifycontent: start;
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #f7f7f8;
  whitespace: nowrap;
`;
export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

////////////////////////////////////////////////////////////////
// New for View Mode switch
export const StyledViewModeGrid = styled(Grid)({
  backgroundColor: "#F7F7F8",
  width: "fit-content",
  padding: "4px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "end",
});

export const StyledIconButton1 = styled(IconButton)(({ theme, selected }) => ({
  height: "40px",
  width: "40px",
  padding: "8px",
  borderRadius: "8px",
  gap: "2",
  ...(selected && {
    backgroundColor: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
  }),
}));

// Export Button
export const StyledExportButton = styled(Grid)({
  border: "1px solid #D8D8DF",
  backgroundColor: "white",
  color: "#6C6C81",
  padding: "8px 16px",
  borderRadius: "8px",
  fontWeight: "600",
  cursor: "pointer",
  alignItems: "center",
  // height: '50px',
});

export const StyledIconButton2 = styled(IconButton)(({ theme }) => ({
  // backgroundColor: '#f7f7f8',
  // '&:hover': {
  //   backgroundColor: theme.palette.primary.main,
  //   color: theme.palette.common.white,
  // },
}));

export const StyledIconButton3 = styled(IconButton)(({ theme }) => ({
  gap: "8px",
  padding: "24px 16px",
  borderRadius: "8px",
  boxShadow:
    "0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07)",
  border: "solid 1px #d8d8df",
  backgroundColor: "white",
  marginBottom: "20px",
  img: {
    filter:
      "invert(22%) sepia(9%) saturate(16%) hue-rotate(204deg) brightness(89%) contrast(88%)", // #5B5B6E
  },
  ":hover img": {
    filter:
      "invert(53%) sepia(3%) saturate(0%) hue-rotate(178deg) brightness(55%) contrast(94%)", // #8E8EA2
  },
}));

////////////////////////////////////////////////////////////////////////

///Create Header

export const StyledHeader2 = styled(Grid)({
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #d8d8df",
  paddingBottom: "26.5px",
});

////////////////////////////////////////////////////////////////////////

// Field Error Messages

export const StyledError = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#e71111",
}));

export const StyledAlertIcon = styled("img")(({ theme }) => ({
  marginRight: "5",
  width: "25",
  height: "25",
}));

////////////////////////////////////////////////////////////////////////

// Form Container
export const StyledFormContainer = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  // borderRadius: theme.shape.borderRadius,
  // boxShadow: theme.shadows[1],
  display: "flex",
  flexDirection: "column",
  // gap: theme.spacing(1),
}));

export const StyledFormContainer1 = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  // borderRadius: theme.shape.borderRadius,
  // boxShadow: theme.shadows[1],
  // display: 'flex',
  flexDirection: "row",
  // gap: theme.spacing(1),
}));

export const StyledFormSection = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 15px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    border-bottom: ${(props) =>
      props.noBorderBottom ? "none" : "1px solid #ccc"};
  }
`;
export const StyledFormSection1 = styled(Grid)`
  padding-top: 32px;
  padding-bottom: 15px;
  position: relative;
  border-bottom: 1px solid #d8d8df;
`;

export const StyledSectionHeader = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledSectionLeft = styled(Grid)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 40%;
  }
`;

export const StyledSectionRight = styled(Grid)`
  background-color: "pink";
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 60%;
    background-color: "pink";
  }
`;

////////////////////////////////////////////////////////////////////////

//Pricing Cycle Container
export const PricingCycleContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  borderRadius: "8px",
  padding: "12px 16px",
  cursor: "pointer",
  border: "#0772B8",
  // border: `1px solid ${validationErrors.pricingCycle ? "#e71111" : "#0772B8"}`,
}));

export const PricingCycleLabel = styled(Typography)({
  color: "#0A2C47",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21.6px",
  "& .MuiFormControlLabel-label": {},
});

export const CurrencyLabel = styled(Typography)({
  color: "#272560",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18.9px",
  "& .MuiFormControlLabel-label": {},
});

export const AmountLabel = styled(Typography)({
  color: "#0A2C47",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "18.9px",
  // '& .MuiFormControlLabel-label': {
  //   fontSize: '14px',
  //   fontWeight: '600',
  //   LineHeight: '18.9px'
  // },
});

////////////////////////////////

//Datepicker
export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputBase-root": {
    width: "100%",
    backgroundColor: "#f7f7f8",
    height: "46px",
    borderRadius: "8px",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d8d8df",
    },
    "&:hover fieldset": {
      borderColor: "#0772b8", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0772b8", // Border color on focus
    },
  },
}));

export const StyledTextField2 = styled(TextField)`
  & .MuiInputBase-root {
    width: 100%;
    background-color: #f7f7f8;
    height: 46px;
    border-radius: 8px;
    cursor: pointer;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #d8d8df;
    }

    &:hover fieldset {
      border-color: #0772b8; /* Border color on hover */
    }

    &.Mui-focused fieldset {
      border-color: #0772b8; /* Border color on focus */
    }
  }
`;

export const StyledDateRangePicker = styled(RangeDatePicker)`
  width: 230px;
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ced4da;
  font-size: 13px;

  ::placeholder {
    font-size: 13px;
    color: #c0c0c0;
    font-weight: 500;
  }

  /* Directly target the input element */
  input {
    border-radius: 8px;
    padding: 0 10px;
    border: 1px solid #ced4da;

    &:hover {
      border-color: #0772b8; /* Border color on hover */
    }

    &:focus {
      border-color: #0772b8; /* Border color on focus */
      outline: none; /* Remove the default outline */
    }
  }
`;

////////////////////////////////////////////////////////////////

// Subscription Box
export const StyledSubscriptionPreviewContainer = styled(Grid)`
  // background-color: #f7f7f8;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
`;

export const StyledSubscriptionPreviewTitle = styled(Grid)`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #0a2c47;
  margin-bottom: 16px;

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
`;
export const StyledSubscriptionBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  border: 1px solid #0772b8;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(to left, #0772b8, #1a1946);
  margin: 2px 0px;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const StyledSubscriptionContainer = styled(Box)`
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
`;

export const StyledSubscriptionValue = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18.9px;
  padding: 5px 0px;
`;

export const StyledSubscriptionDetail = styled(Typography)`
  font-size: 11px;
  font-weight: 400;
  color: #d8d8df;
  line-height: 14.85px;
  padding: 5px 0px;
`;

export const StyledVerticalLine = styled(Box)`
  border-left: 1px solid #ffffff;
  height: 20px;
  margin-top: 20px;
  @media (max-width: 960px) {
    display: none;
  }
`;
////////////////////////////////////////////////////////////////

// Pricing Cycle Selector

export const StyledPricingCycleContainer = styled(Grid)`
  background-color: #f7f7f8;
  border-radius: 8px;
  padding: 16px;
`;

export const PricingCycleLabel2 = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #0a2c47;
`;

export const CurrencyLabel2 = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #0a2c47;
`;

export const AmountLabel2 = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #0a2c47;
`;

export const PricingCycleOption = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  padding: 24px 16px;
  cursor: pointer;
  border: 1px solid ${({ error }) => (error ? "#e71111" : "#0772B8")};
`;
////////////////////////////////////////////////////////////////

export const StyledEmptyStateContainer = styled(Grid)`
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(225, 242, 240, 1) 0%,
    rgba(188, 226, 251, 1) 100%
  );
  padding: 8px 10px 0;
  border-radius: 8px;
`;

////////////////////////////////////////////////////////////////

// Styled Cargo Items Container in Consignments
export const StyledCargoGridItem = styled(Grid)`
  background-color: #f7f7f8;
  border: 1px solid #d8d8df;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
`;

export const StyledCargoInnerGrid = styled(Grid)`
  margin-bottom: 25px;
  // margin: 0px 0px 0px 30px;
`;

export const StyledCargoTypography = styled(Typography)`
  font-weight: 600;
  color: #8e8ea2;
  margin: 15px 0px;
`;

export const StyledCargoButtonGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledCargoItemGrid = styled(Grid)`
  height: 75px;
  display: flex;
  justify-content: space-between;
  backgroundcolor: "red";
  border: 1px solid #d8d8df;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  box-shadow: ${({ isSelected }) => (isSelected ? "0 0 0 1px #0772B8" : "")};
  border-style: ${({ isSelected }) => (isSelected ? "solid" : "")};
`;

export const StyledCargoNameTypography = styled(Typography)`
  color: #0a2c47;
  font-size: 16px;
`;

export const StyledCargoInfoTypography = styled(Typography)`
  color: #6c6c81;
  font-size: 14px;
`;

export const StyledCargoRadio = styled(Radio)`
  padding: 0px;
  color: #0772b8;
  &.Mui-checked {
    color: #0772b8;
  }
`;
////////////////////////////////////////////////////////////////

// File Upload Grid

export const StyledUploadContainer = styled(Grid)(({ theme }) => ({
  height: "110px",
  border: "2px dashed #D3D3D3",
  borderRadius: "20px",
  backgroundColor: "white",
  padding: "10px 0px",
  cursor: "pointer",
}));

export const StyledUploadIconContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#F7F7F8",
}));

export const StyledUploadText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#09154b",
  textAlign: "center",
}));

////////////////////////////////////////////////////////////////

//RENDER DOCUMETNS TABLE

// Styled Components
export const StyledTable2 = styled(Table)(({ theme }) => ({
  minWidth: 650,
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

export const StyledTableHead2 = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#eeeef0",
}));

export const StyledTableBody2 = styled(TableBody)(({ theme }) => ({}));

export const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  alignItems: "center",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.secondary,
  padding: theme.spacing(1),
}));

export const StyledFileLink2 = styled(Typography)(({ theme }) => ({
  color: "#0772B8",
  borderRadius: "16px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
}));

export const StyledSwitch2 = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#0772B8",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#0772B8",
  },
}));

////////////////////////////////
// File Link
export const StyledFileLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#0772B8",
  borderRadius: "16px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
}));

export const StyledIcon = styled(AttachFileIcon)(({ theme }) => ({
  verticalAlign: "middle",
  marginRight: "8px",
  color: "#0A2C47",
}));

export const StyledLink = styled("a")(({ theme }) => ({
  color: "#0772B8",
  textDecoration: "none",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
////////////////////////////////
// Consignment step 4
export const StyledPaymentTermContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  border: 1px solid #d8d8df;
  border-radius: 8px;
  cursor: pointer;
  height: 47px;
  align-items: center;
  padding: 10px;
  box-shadow: ${({ selected }) => (selected ? "0 0 0 1px #0772B8" : "")};
`;
////////////////////////////////

// Error Message 1 List
export const StyledErrorContainer = styled(Grid)`
  background-color: #fcebeb;
  color: #26861d;
  padding: 16px 32px;
  border-radius: 5px;
  // display: flex;
  // align-items: start;
  margin-left: -50px;
  margin-right: -50px;
  margin-top: -50px;
  margin-bottom: 50px;
`;

export const StyledErrorList = styled(List)`
  list-style-type: none;
  padding: 0;
`;

export const StyledErrorItem = styled(ListItem)`
  font-size: 16px;
  font-weight: 600;
  color: #e71111;
`;
/////////////

//Consignment Modal Style

export const ModalContainer = styled(StyledModal)`
  height: 40rem;
  overflow: auto;
  width: 100%;
  max-width: 600px;
`;

export const ModalContent = styled(Grid)`
  padding: 16px;
  page-break-after: always;
  page-break-before: always;
  break-inside: avoid;
  page-break-inside: avoid;
`;

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #ddd",
  paddingBottom: theme.spacing(1), // equivalent to pb: 1
}));

export const OrderInfoContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const SummeryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700, // Bold text
  color: theme.palette.text.primary, // Darker text color
}));

export const SummeryValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500, // Normal font weight
  color: theme.palette.text.secondary, // Lighter text color
}));

export const ContainerBox = styled(Box)(({ theme }) => ({
  border: "1px solid #ddd",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  display: "flex",
}));

export const AddressBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const DeliveryAddressBox = styled(AddressBox)({
  borderRight: "1px solid #ddd",
});
export const PickupAddressBox = styled(AddressBox)({
  // borderRight: "1px solid #ddd",
  paddingLeft: "20px",
});

export const AddressTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: "14px",
  fontWeight: 700, // Bold text
  color: theme.palette.text.primary, // Darker text color
}));

export const AddressText = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  fontWeight: 500, // Normal font weight
  color: theme.palette.text.secondary, // Lighter text color
}));

export const PhoneText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const SectionBox = styled(Box)({
  flex: 1,
  paddingRight: "16px", // equivalent to pr: 2
});

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  margin: "0 0 10px 0",
  borderBottom: "1px solid #ddd",
  fontSize: "14px",
  fontWeight: 700, // Bold text
  color: theme.palette.text.primary, // Darker text color
}));

export const BillingInfoContainer = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const InfoRow = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

export const DetailsBox = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingRight: theme.spacing(2),
}));

export const DetailRow = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "5px 0",
}));

export const RowGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const DetailLabel = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontWeight: 600, // Bold text
  color: theme.palette.text.primary, // Darker text color
}));

export const CargoItemBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  border: "1px solid #eee",
  borderRadius: theme.spacing(2),
  textAlign: "start",
}));

export const ItemDetail = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600, // Bold text
  color: theme.palette.text.primary, // Darker text color
  textAlign: "start",
}));

export const DimensionsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

export const DimensionsLabel = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "start",
}));

export const TotalWeight = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "green",
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 600, // Darker and bold title
  color: "rgba(0,0,0,0.87)", // Darker color for title
  fontSize: "14px",
  marginBottom: theme.spacing(0.5),
}));

export const ValueText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: "rgba(0,0,0,0.6)", // Lighter color for value
  fontSize: "14px",
}));

export const DescriptionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: "rgba(0,0,0,0.87)",
  fontSize: "14px",
  marginBottom: theme.spacing(0.5),
}));

export const DescriptionValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: "rgba(0,0,0,0.6)",
  fontSize: "14px",
  overflowWrap: "break-word", // Ensures long words are broken and wrapped to the next line
  wordBreak: "break-word",
  hyphens: "auto",
  maxWidth: "100%",
  textAlign: "left",
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight: 150,
  objectFit: "cover",
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

export const ProofBox = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingLeft: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const ProofImage = styled("img")(({ theme }) => ({
  maxWidth: "50%",
  height: "auto",
}));

export const UnitMonitoringContainer = styled(Grid)(({ theme }) => ({
  position: "relative",
  height: "75vh",
}));

export const UnitMonitoringSidePanel = styled(Grid)(({ theme }) => ({
  zIndex: 2,
  position: "absolute",
  top: 0,
  left: 0,
  height: "75vh",
  width: "26.5rem",
}));

export const UnitMonitoringStyledCard = styled(Card)(({ theme }) => ({
  width: "482px",
  height: "75vh",
  overflow: "auto",
}));

export const UnitMonitoringUnitCard = styled(Card)(({ selected }) => ({
  width: "404px",
  margin: "10px 18px",
  cursor: "pointer",
  border: selected ? "3px solid #0772B8" : "1px solid #D8D8DF",
  borderRadius: "8px",
}));

export const UnitMonitoringCustomSearchField = styled(TextField)(
  ({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      height: "44px",
      padding: "0 14px",
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  })
);

// Styled component for the Grid item
export const UnitMonitoringStyledGridItem = styled(Grid)(({ theme }) => ({
  backgroundColor: "#f7f7f8",
  marginRight: "2px",
  marginBottom: "2px",
  padding: "8px",
  flexBasis: "24% !important",
}));

export const UnitMonitoringStyledTitleGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 2,
}));

export const UnitMonitoringStyledButton = styled(Button)(({ theme }) => ({
  border: "1px solid #D8D8DF",
  color: "#0772B8", // Default color if color prop is not passed
  borderRadius: "8px",
  padding: "10px 14px",
  width: "auto",
  marginRight: "5px",
  boxShadow:
    "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  "&:hover": {
    backgroundColor: "#f5f5f5", // Optional hover effect
  },
}));

export const UnitMonitoringStyledCardContent = styled(CardContent)({
  borderBottom: "1px solid #D8D8DF",
});

export const UnitMonitoringSearchFieldBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
});

export const UnitMonitoringIconBox = styled(Box)({
  marginLeft: "8px",
  display: "flex",
  alignItems: "center",
});

export const UnitMonitoringFilterIconCard = styled(Card)({
  display: "flex",
  alignItems: "center",
  width: "44px",
  height: "44px",
});

export const UnitMonitoringFilterIconImg = styled("img")({
  width: "20px",
  position: "relative",
  left: "4px",
});

export const UnitMonitoringGoogleMapContainer = styled(Grid)({
  width: "60%",
  marginLeft: "auto",
});

export const StatusTypography = styled(Typography)(({ status }) => ({
  fontSize: "14px",
  color: status === "Moving" ? "#0A2C47" : "red",
  display: "flex",
  alignItems: "center",
}));

export const UnitMonitoringValue = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  color: #0a2c47;
`;
export const UnitMonitoringLable = styled(Typography)`
  font-size: 12px;
  color: #6c6c81;
`;
export const UnitMonitoringTotalUnits = styled(Typography)`
  border: 1px solid #d0d5dd;
  background-color: #d0d5dd;
  color: #272560;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
`;

//Dashboard Graph styles

export const StyledChartContainer = styled(Grid)`
  margin: 0 25px 0 0;
  background-color: white;
  padding: 0 20px;
  border-radius: 12px;
  border: 1px solid #d8d8df;
  overflow-x: auto;
`;

export const StyledChartHeader = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;

export const StyledChartTitle = styled(Grid)`
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 600;
`;

export const StyledGraphIconButton = styled(IconButton)`
  cursor: pointer;
`;

export const StyledDateButtonsContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 21px;
`;

export const StyledDateButton = styled(Button)`
  border-color: ${({ active }) => (active ? "#0A2C47" : "inherit")};
  background-color: ${({ active }) => (active ? "#e1f0fd" : "inherit")};
  &:hover {
    background-color: #e1f0fd;
    cursor: pointer;
  }
`;

export const StyledDatePickerContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBox = styled(Box)`
  padding: 10px 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  @media (min-width: 600px) {
    height: 298px;
    width: 596px;
  }
  @media (max-width: 600px) {
    height: 50vh;
    width: 90vw;
  }
`;

export const StyledPdfContainer = styled(Grid)`
          padding: "20px",
          background: "white",
          width: "100%",
          height: "auto",
`;

export const StyledLink2 = styled(Link)`
  && {
    font-family: "Archivo";
    font-size: 12px;
    font-weight: 500;
    line-height: 16.2px;
    letter-spacing: 0.48px;
    text-transform: capitalize;
    color: #0772b8;
    cursor: pointer;
  }
`;

export const StatsContainer = styled(Grid)`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const StatItem = styled("span")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

export const StatBox = styled("span")(({ bgColor, borderColor }) => ({
  display: "inline-block",
  width: "20px",
  height: "20px",
  backgroundColor: bgColor || "transparent",
  border: `1px solid ${borderColor || "transparent"}`,
}));
