/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material'
import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ErrorMessage(props) {
    return (
        <Box >
            {
                props.message != ""
                    ? <Typography sx={{ display: 'flex', color: "red", ...props.sx }}><ErrorOutlineIcon sx={{ margin: "0 5px", }} />{props.message}</Typography>
                    : <></>
            }

        </Box>
    )
}

export default ErrorMessage
