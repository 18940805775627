/* eslint-disable no-undef */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
// LogMessagesContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react'
import ReconnectingWebSocket from 'reconnecting-websocket'

const LogMessagesContext = createContext([])

export const useLogMessages = () => useContext(LogMessagesContext)

export const LogMessagesProvider = ({ children }) => {
  const ws = useRef(null)
  const [logMessages, setLogMessages] = useState('')
  useEffect(() => {
    const connectWebSocket = () => {
      ws.current = new ReconnectingWebSocket(
        process.env.REACT_APP_WEBSOCKET_URL
      )

      ws.current.onopen = () => {
        ws.current.send('Hello from client')
      }

      ws.current.onclose = (e) => {
        console.log('WebSocket connection closed', e)
      }

      ws.current.onmessage = (message) => {
        try {
          if (message.data) {
            const receivedData = JSON.parse(message.data) // Parse only if data is present
            setLogMessages(receivedData) // Use the parsed data
          } else {
            console.warn('Empty message received')
          }
        } catch (error) {
          console.error('Error parsing message:', error)
        }
      }

      ws.current.onerror = (error) => {
        console.log('WebSocket error', error)
      }
    }

    connectWebSocket()

    return () => {
      if (ws.current) {
        ws.current.close()
      }
    }
  }, [])

  return (
    <LogMessagesContext.Provider value={{ logMessages, setLogMessages }}>
      {children}
    </LogMessagesContext.Provider>
  )
}
