import React from 'react';
import LoginForm from '../components/LoginForm2';

const LoginPage = () => {
  return (
    <div className="container">
      <LoginForm />
    </div>
  );
};
export default LoginPage;