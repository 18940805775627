/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import {
  AddressText,
  AddressTitle,
  BillingInfoContainer,
  CargoItemBox,
  ContainerBox,
  DeliveryAddressBox,
  DescriptionTitle,
  DescriptionValue,
  DetailLabel,
  DetailRow,
  DetailsBox,
  HeaderContainer,
  ImageBox,
  InfoRow,
  ItemDetail,
  OrderInfoContainer,
  PhoneText,
  PickupAddressBox,
  ProofImage,
  RowGrid,
  SectionBox,
  SectionTitle,
  StyledModal,
  SummeryTitle,
  SummeryValue,
  TitleText,
  TotalWeight,
  ValueText,
} from "../../styledComponents";
// import html2canvas from 'html2canvas';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate, formatTime } from "../../utils/functions";
import logo from "../../assets/logo/Off@3x.png";
import { QRCodeCanvas } from "qrcode.react";

const ConsignmentModal = ({
  consignment,
  consignmentOpen,
  handleConsignmentModalClose,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const modalContentRef = useRef(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === "Download PDF") {
      downloadPDF();
    } else if (action === "Print Order") {
      printOrder();
    }
    // Handle other actions here
    console.log(action); // Replace this with actual handling logic
  };
  const downloadPDF = () => {
    const element = modalContentRef.current;
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Temporarily hide the icons
    const iconButtons = element.querySelectorAll(".pdf-hide");
    iconButtons.forEach((icon) => (icon.style.display = "none"));

    // Generate the PDF
    html2canvas(element, { scale: 2, useCORS: true })
      .then((canvas) => {
        const imgWidth = 210; // A4 paper width in mm
        const pageHeight = 295; // A4 paper height in mm
        const imgWidthPx = canvas.width;
        const imgHeightPx = canvas.height;
        const imgHeightMm = (imgHeightPx * imgWidth) / imgWidthPx; // Convert image height to mm
        const heightLeft = imgHeightMm;

        const pdf = new jsPDF("p", "mm", "a4");
        let position = 0;
        let remainingHeight = heightLeft;
        let imgData = canvas.toDataURL("image/png");

        // Add the first page
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightMm);
        remainingHeight -= pageHeight;
        position -= pageHeight;

        // Add additional pages if needed
        while (remainingHeight > 0) {
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightMm);
          remainingHeight -= pageHeight;
          position -= pageHeight;
        }

        pdf.save("consignment-details.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      })
      .finally(() => {
        // Restore the icons after generating the PDF
        iconButtons.forEach((icon) => (icon.style.display = ""));
      });
  };

  const generatePDF = (element) => {
    html2canvas(element, { scale: 2, useCORS: true })
      .then((canvas) => {
        const imgWidth = 210; // A4 paper width in mm
        const pageHeight = 295; // A4 paper height in mm
        const imgWidthPx = canvas.width;
        const imgHeightPx = canvas.height;
        const imgHeightMm = (imgHeightPx * imgWidth) / imgWidthPx; // Convert image height to mm
        const heightLeft = imgHeightMm;

        const pdf = new jsPDF("p", "mm", "a4");
        let position = 0;
        let remainingHeight = heightLeft;
        let imgData = canvas.toDataURL("image/png");

        // Add the first page
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightMm);
        remainingHeight -= pageHeight;
        position -= pageHeight;

        // Add additional pages if needed
        while (remainingHeight > 0) {
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightMm);
          remainingHeight -= pageHeight;
          position -= pageHeight;
        }

        pdf.save("consignment-details.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
    const imageElement = element.querySelector("img");
    if (imageElement) {
      console.log("Image source:", imageElement.src);
      console.log("Image complete:", imageElement.complete);
    }
  };

  const printOrder = () => {
    const element = modalContentRef.current;
    if (!element) {
      console.error("Element not found");
      return;
    }

    // Delay to ensure the content is fully rendered
    setTimeout(() => {
      html2canvas(element, { scale: 2, useCORS: true })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const printWindow = window.open("", "", "height=600,width=800");
          if (printWindow) {
            printWindow.document.open();
            printWindow.document.write(`
            <html>
            <head>
              <title>Print Order</title>
              <style>
                body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
                img { width: 100%; }
              </style>
            </head>
            <body>
              <img src="${imgData}" />
            </body>
            </html>
          `);
            printWindow.document.close();
            printWindow.focus();
            // Print after the document is closed
            printWindow.addEventListener(
              "load",
              () => {
                printWindow.print();
              },
              { once: true }
            );
          } else {
            console.error("Failed to open print window");
          }
        })
        .catch((error) => {
          console.error("Error generating print preview:", error);
        });
    }, 100); // Delay in milliseconds
  };

  return (
    <Modal
      open={consignmentOpen}
      onClose={handleConsignmentModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModal
        sx={{
          height: "40rem",
          overflow: "auto",
          width: { xs: "100%", sm: 600 },
        }}
      >
        <Grid
          ref={modalContentRef}
          sx={{
            p: 2,
            pageBreakAfter: "always",
            pageBreakBefore: "always",
            breakInside: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 1, // Padding bottom to space content from the border
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ textAlign: "start", margin: "20px 0px" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "50%", height: "auto" }}
                />
              </Box>
            </Grid>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton className="pdf-hide" onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <IconButton
                className="pdf-hide"
                onClick={handleConsignmentModalClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick("Download PDF")}>
                Download PDF
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("Print Order")}>
                Print Order
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("Mark as Delivered")}
              >
                Mark as Delivered
              </MenuItem>
            </Menu>
          </Box>
          <HeaderContainer>
            <OrderInfoContainer>
              <Typography
                variant="h6"
                sx={{ display: "flex", flexDirection: "row", mt: 1 }}
              >
                <SummeryTitle>ORDER #&nbsp;</SummeryTitle>
                <SummeryValue>{consignment?.orderNumber}</SummeryValue>
              </Typography>
              <Typography
                variant="h6"
                sx={{ display: "flex", flexDirection: "row", mt: 1 }}
              >
                <SummeryTitle>STATUS&nbsp;</SummeryTitle>
                <SummeryValue>{consignment?.tripStatus}</SummeryValue>
              </Typography>
            </OrderInfoContainer>
          </HeaderContainer>
          <ContainerBox>
            {/* Delivery Address Section */}
            <DeliveryAddressBox>
              <AddressTitle>DELIVERY ADDRESS</AddressTitle>
              <AddressText>
                {consignment?.deliveryDetails?.address?.streetOne ? (
                  <>
                    <div>{consignment?.customerName}</div>
                    <div>
                      {consignment?.deliveryDetails?.address?.streetOne}
                    </div>
                    <div>
                      {`${consignment?.deliveryDetails?.address?.city} 
      ${consignment?.deliveryDetails?.address?.state} 
      ${consignment?.deliveryDetails?.address?.country} 
      ${consignment?.deliveryDetails?.address?.postalCode}`}
                    </div>
                  </>
                ) : (
                  <>
                    {consignment?.deliveryDetails?.geofenceId && (
                      <>{consignment?.deliveryDetails?.geofenceName}</>
                    )}
                  </>
                )}

                <PhoneText>
                  {consignment?.deliveryDetails?.areaCode}{" "}
                  {consignment?.deliveryDetails?.phone}
                </PhoneText>
              </AddressText>
            </DeliveryAddressBox>

            {/* Pickup Address Section */}
            <PickupAddressBox>
              <AddressTitle>PICKUP ADDRESS</AddressTitle>
              <AddressText>
                {consignment?.pickupDetails?.address?.addressType !==
                "Geofence" ? (
                  <>
                    {consignment?.pickupDetails?.address?.streetOne ? (
                      <Typography component="div" sx={{ textAlign: "start" }}>
                        {consignment?.pickupDetails?.address?.streetOne}
                      </Typography>
                    ) : (
                      consignment?.pickupDetails?.address?.addressType ===
                        "Geofence" && (
                        <Typography component="div" sx={{ textAlign: "start" }}>
                          {consignment?.pickupDetails?.geofenceName ?? ""}
                        </Typography>
                      )
                    )}
                    <Typography component="div" sx={{ textAlign: "start" }}>
                      {`${consignment?.pickupDetails?.address?.city} ${consignment?.pickupDetails?.address?.state} ${consignment?.pickupDetails?.address?.country} ${consignment?.pickupDetails?.address?.postalCode}`}
                    </Typography>
                  </>
                ) : (
                  <Typography component="div" sx={{ textAlign: "start" }}>
                    {consignment?.pickupDetails?.address?.geofenceName ?? ""}
                  </Typography>
                )}
                <Typography sx={{ mt: 1 }} component="div">
                  {consignment?.pickupDetails?.areaCode}{" "}
                  {consignment?.pickupDetails?.phone}
                </Typography>
              </AddressText>
            </PickupAddressBox>
          </ContainerBox>
          <ContainerBox>
            <SectionBox>
              <SectionTitle>PAYMENT DETAILS</SectionTitle>
              <BillingInfoContainer>
                <InfoRow>
                  <Typography
                    sx={{
                      fontWeight: 600, // Bold text
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    Amount
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.billingInformation.amount
                      ? `USD ${new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(consignment.billingInformation.amount)}`
                      : "--"}
                  </Typography>
                </InfoRow>
                <InfoRow>
                  <Typography
                    sx={{
                      fontWeight: 600, // Bold text
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    VAT
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.billingInformation.VAT
                      ? `${consignment.billingInformation.VAT}%`
                      : "--"}
                  </Typography>
                </InfoRow>
                <InfoRow>
                  <Typography
                    sx={{
                      fontWeight: 600, // Bold text
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.billingInformation.amount &&
                    consignment?.billingInformation.VAT
                      ? `USD ${new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                          consignment.billingInformation.amount *
                            (1 + consignment.billingInformation.VAT / 100)
                        )}`
                      : "--"}
                  </Typography>
                </InfoRow>
                <InfoRow>
                  <Typography
                    sx={{
                      fontWeight: 600, // Bold text
                      color: "rgba(0,0,0,0.87)",
                    }}
                  >
                    Payment Term
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.billingInformation.paymentTerm
                      ? `${consignment.billingInformation.paymentTerm}`
                      : "--"}
                  </Typography>
                </InfoRow>
              </BillingInfoContainer>
            </SectionBox>
          </ContainerBox>
          <ContainerBox>
            <DetailsBox>
              <SectionTitle>ORDER TRACKING DETAILS</SectionTitle>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Received On:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {formatDate(consignment?.receivedDateTime)} (
                    {formatTime(consignment?.receivedDateTime)})
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Unit Allocated:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.unitAllocationDateTime
                      ? `${formatDate(
                          consignment.unitAllocationDateTime
                        )} (${formatTime(consignment.unitAllocationDateTime)})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Driver Allocated:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.driverAllocationDateTime
                      ? `${formatDate(
                          consignment.driverAllocationDateTime
                        )} (${formatTime(
                          consignment.driverAllocationDateTime
                        )})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Driver Accepted:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.driverAcceptedDateTime
                      ? `${formatDate(
                          consignment.driverAcceptedDateTime
                        )} (${formatTime(consignment.driverAcceptedDateTime)})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Trip Started:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.startDateTime
                      ? `${formatDate(consignment.startDateTime)} (${formatTime(
                          consignment.startDateTime
                        )})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>At Pickup:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.pickupDateTime
                      ? `${formatDate(
                          consignment.pickupDateTime
                        )} (${formatTime(consignment.pickupDateTime)})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Loading Completed:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.loadingCompletedDateTime
                      ? `${formatDate(
                          consignment.loadingCompletedDateTime
                        )} (${formatTime(
                          consignment.loadingCompletedDateTime
                        )})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Enroute:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.enrouteDateTime
                      ? `${formatDate(
                          consignment.enrouteDateTime
                        )} (${formatTime(consignment.enrouteDateTime)})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Arrived at Destination:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.arrivedDateTime
                      ? `${formatDate(
                          consignment.arrivedDateTime
                        )} (${formatTime(consignment.arrivedDateTime)})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
              <DetailRow>
                <RowGrid>
                  <DetailLabel>Delivered:</DetailLabel>
                  <Typography
                    sx={{
                      fontWeight: 500, // Bold text
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {consignment?.deliveredDateTime
                      ? `${formatDate(
                          consignment.deliveredDateTime
                        )} (${formatTime(consignment.deliveredDateTime)})`
                      : "--"}
                  </Typography>
                </RowGrid>
              </DetailRow>
            </DetailsBox>
          </ContainerBox>
          <ContainerBox>
            <DetailsBox>
              <SectionTitle>
                CARGO ITEMS
                {consignment?.cargoDetails?.totalWeight !== undefined && (
                  <TotalWeight>
                    Total Weight:&nbsp;
                    {consignment?.cargoDetails?.totalWeight
                      ? `${new Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(consignment?.cargoDetails?.totalWeight)}`
                      : "--"}
                    &nbsp; Kgs
                  </TotalWeight>
                )}
              </SectionTitle>
              {consignment?.cargoDetails?.items?.map((item, index) => (
                <CargoItemBox key={item._id || index}>
                  <ItemDetail>Cargo Item #{index + 1}</ItemDetail>
                  <ValueText sx={{ marginBottom: "10px" }}>
                    {item.cargoType?.name}
                  </ValueText>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TitleText>Volume&nbsp;</TitleText>
                    <ValueText>{item.cubicMetres} CBM</ValueText>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TitleText>Quantity&nbsp;</TitleText>
                    <ValueText>{item.quantity}</ValueText>
                  </Box>
                  {item.description && (
                    <Box sx={{ margin: "10px 0px" }}>
                      <DescriptionTitle>Description</DescriptionTitle>
                      <DescriptionValue>{item.description}</DescriptionValue>
                    </Box>
                  )}
                </CargoItemBox>
              ))}
            </DetailsBox>
          </ContainerBox>
          <ContainerBox>
            <DetailsBox>
              <SectionTitle>SPECIAL INSTRUCTIONS</SectionTitle>
              <DescriptionValue>
                {consignment?.billingInformation.notes}
              </DescriptionValue>
            </DetailsBox>
          </ContainerBox>
          <ContainerBox>
            <DetailsBox>
              <SectionTitle>PICKUP PICTURES</SectionTitle>

              {/* Check if pickupDetailDocument is available and has valid images */}
              {consignment?.pickupDetailDocument?.some((doc) => doc.fileURL) ? (
                <Grid container spacing={2}>
                  {consignment.pickupDetailDocument
                    .filter((doc) => doc.fileURL) // Filter out documents with invalid or empty fileURL
                    .map((doc, index) => (
                      <Grid item xs={6} key={doc._id || index}>
                        <ImageBox
                          component="img"
                          alt={`Pickup document ${index + 1}`}
                          src={doc.fileURL || "path/to/placeholder-image.png"} // Fallback if fileURL is missing
                        />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <p>No Images Are Available</p>
              )}
            </DetailsBox>
          </ContainerBox>

          <ContainerBox>
            <DetailsBox>
              <SectionTitle>DELIVERY PICTURES</SectionTitle>

              {/* Check if deliveryDetailDocument is available and has valid images */}
              {consignment?.deliveryDetailDocument?.some(
                (doc) => doc.fileURL
              ) ? (
                <Grid container spacing={2}>
                  {consignment.deliveryDetailDocument
                    .filter((doc) => doc.fileURL) // Filter out documents with invalid or empty fileURL
                    .map((doc, index) => (
                      <Grid item xs={6} key={doc._id || index}>
                        <ImageBox
                          component="img"
                          alt={`Delivery document ${index + 1}`}
                          src={doc.fileURL || "path/to/placeholder-image.png"} // Fallback if fileURL is missing
                        />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <p>No Images Are Available</p>
              )}
            </DetailsBox>
          </ContainerBox>

          <ContainerBox>
            <AddressTitle>Proof of Delivery</AddressTitle>
            {consignment?.deliverySignature?.fileURL ? (
              <ProofImage
                src={consignment.deliverySignature.fileURL}
                alt="Proof of Delivery"
              />
            ) : (
              <Typography>N/A</Typography>
            )}
          </ContainerBox>
          {/* Barcode Section */}
          {consignment?.id && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <QRCodeCanvas
                value={`monitoring/consignments/summery/${consignment.id}`}
              />
              {/* Alternatively, use QRCodeSVG */}
              {/* <QRCodeSVG value={`http://localhost:3000/monitoring/consignments/summery/${consignment.id}`} /> */}
            </Box>
          )}
        </Grid>
      </StyledModal>
    </Modal>
  );
};

ConsignmentModal.propTypes = {
  consignmentOpen: PropTypes.bool.isRequired,
  handleConsignmentModalClose: PropTypes.func.isRequired,
};

export default ConsignmentModal;
