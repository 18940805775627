import * as yup from "yup";

const subscriptionStatusEnum = ["ACTIVE", "PENDING", "CANCELED", "EXPIRED"];
const pricingCycleEnum = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];

// Validation schema for subscriptions
const subscriptionSchema = yup.object().shape({
  priceId: yup.string().required("Pricing Cycle is required"),
  subscriptionStatus: yup
    .string()
    .oneOf(subscriptionStatusEnum, "Invalid subscription status")
    .required("Subscription Status is required"), // Ensure subscription status is always provided
  startDate: yup.date().required("Start Date is required"),
  installationDate: yup.date().required("Installation Date is required"),
  pricingCycle: yup
    .string()
    .oneOf(pricingCycleEnum, "Invalid pricing cycle")
    .required("Pricing Cycle is required"),
  amount: yup
    .number()
    .nullable()
    .test("check-amount", "Amount must be positive", function (value) {
      return value === null || value > 0; // Ensure amount is positive if provided
    }),
  currency: yup
    .string()
    .nullable()
    .test(
      "check-currency",
      "Currency is required if amount is provided",
      function (value) {
        const { amount } = this.parent; // Access the amount from the parent object
        if (amount !== null) {
          return !!value; // Ensure currency is provided if amount is specified
        }
        return true; // Skip validation if amount is not provided
      }
    ),
});

export const accountsSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, "Name should be at least 3 characters.")
    .max(50, "Name cannot exceed 50 characters.")
    .required("Account name is required."),
  billingPlanId: yup.string().required("Billing plan is required."),
  parentAccountId: yup.string().nullable(),
  subscriptions: yup.array().of(subscriptionSchema),
  settings: yup.object().shape({
    blockByBalanceAmount: yup
      .number()
      .typeError("Block By Balance Amount must be a number")
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "check-balance-amount",
        "Block By Balance Amount is required and cannot be negative",
        function (value) {
          const { blockByDue, gracePeriod } = this.parent;
          // Only require blockByBalanceAmount if blockByDue is false and gracePeriod is null or empty
          if (
            blockByDue === false &&
            !gracePeriod &&
            (value === null || value === "" || value <= 0)
          ) {
            return false;
          }
          return true;
        }
      ),
    gracePeriod: yup
      .number()
      .typeError("Grace Period must be a number")
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "check-grace-period",
        "Grace Period is required and cannot be negative",
        function (value) {
          const { blockByDue, blockByBalanceAmount } = this.parent;

          // If blockByDue is false or blockByBalanceAmount is null/empty, require gracePeriod
          if (
            blockByDue === false &&
            (!blockByBalanceAmount || blockByBalanceAmount <= 0)
          ) {
            if (value === null || value === "" || value <= 0) {
              return this.createError({
                path: "gracePeriod",
                message: "Grace Period is required and cannot be negative",
              });
            }
          }

          // If gracePeriod has a value, it should not be negative
          if (value !== null && value !== "" && value <= 0) {
            return this.createError({
              path: "gracePeriod",
              message: "Grace Period cannot be negative",
            });
          }

          return true;
        }
      ),
  }),
});
