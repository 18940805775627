import axios from "axios";
import { serviceApi } from "../utils/constant";

const api = axios.create({
  baseURL: serviceApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Fetch all services with optional query parameters
// export const fetchAllServices = async (queryParams) => {
//     try {
//       const response = await api.get('/', { params: queryParams });
//       return response.data;
//     } catch (error) {
//       handleApiError(error);
//     }
// };

// export const fetchAllServices = async () => {
//   try {
//     const response = await api.get("/?page=1&limit=99");
//     return response.data;
//   } catch (error) {
//     handleApiError(error);
//   }
// };

export const fetchAllServices = async (page, limit) => {
  try {
    const response = await api.get("/", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a unit by its ID
export const getServiceById = async (serviceId) => {
  try {
    const response = await api.get(`/${serviceId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new service
export const createService = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing service by its ID
export const updateService = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a service by its ID
export const deleteUnit = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch services by name with pagination
export const fetchServicesByName = async (name, page, limit) => {
  try {
    const response = await api.get("/fetchServicesByName", {
      params: { name, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch services by name with pagination
export const fetchServicesByType = async (type, page, limit) => {
  try {
    const response = await api.get("/fetchServicesByType", {
      params: { type, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchServicesByMultipleCriteria = async (
  id,
  type,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchServicesByMultipleCriteria", {
      params: {
        accountId,
        id,
        type,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
