import React from 'react';
import { StyledInputControlContainer, StyledDatePicker, StyledTextField2 } from '../../styledComponents';
import { Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import FormError from './FormError';

const FormDatepicker = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  required = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDateChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          value={value}
          onChange={handleDateChange}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          format="DD-MMM-YYYY"
          renderInput={(params) => (
            <StyledTextField2
              {...params}
              InputProps={{ ...params.InputProps, readOnly: true, required }}
              error={error}
              helperText={error ? errorMessage : null}
            />
          )}
        />
      </LocalizationProvider>
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormDatepicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormDatepicker;
