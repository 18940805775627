import React from 'react'
import {
  StyledInputControlContainer,
  StyledDescriptionTextField
} from '../../styledComponents'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import FormError from './FormError'

const FormFieldMultiline = ({
  label,
  placeholder,
  value,
  onChange,
  type = 'text',
  error,
  errorMessage,
  required = false,
  multiline = false,
  rows = 4
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <StyledDescriptionTextField
        variant="outlined"
        placeholder={placeholder}
        required={required}
        multiline={multiline}
        rows={rows}
        value={value}
        onChange={onChange}
        type={type}
        error={!!error}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  )
}

FormFieldMultiline.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number
}

export default FormFieldMultiline
