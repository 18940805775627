/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchUnitGroupsByAccountId } from "../../../../services/unitGroupNew.service";
import FilterListIcon from "@mui/icons-material/FilterList";

const UnitGroupsFilter = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
  });
  const [unitGroups, setUnitGroups] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingUnitGroups, setIsLoadingUnitGroups] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchUnitGroupsByAccountId();
  }, [page, limit]);

  const _fetchUnitGroupsByAccountId = async () => {
    if (isLoadingUnitGroups) return; // Prevent multiple calls
    setIsLoadingUnitGroups(true);

    try {
      let allUnits = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchUnitGroupsByAccountId(currentPage, limit);
        allUnits = [...allUnits, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setUnitGroups(allUnits);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching unitGroups:", errorMessage);
    } finally {
      setIsLoadingUnitGroups(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={unitGroups.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};

UnitGroupsFilter.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default UnitGroupsFilter;
