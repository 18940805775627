/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ListHeaderNew from '../../../common/ListHeaderNew'
import ViewModeSwitch from '../../../common/ViewModeSwitch'
import ExportButton from '../../../common/ExportButton'
import { fetchAlloptimizedRoutes } from '../../../../services/waypoint.service'

export default function RouteOptimizationPage() {
  const location = useLocation()
  const shouldShowHeader =
    location.pathname.endsWith('route-optimization') ||
    location.pathname.endsWith('card') ||
    location.pathname.endsWith('list')
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [optimizedRoutes, setOptimizedRoutes] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit] = useState(15)
  const response = location.state?.response

  useEffect(() => {
    setOptimizedRoutes([])
    _fetchAlloptimizedRoutes(page, limit)
  }, [page, response])

  const _fetchAlloptimizedRoutes = async (id, page, limit) => {
    if (isLoading) return // Prevent multiple calls
    setIsLoading(true)

    try {
      const response = await fetchAlloptimizedRoutes(id, page, limit)
      setCount(response.count)
      setOptimizedRoutes((prevRoutes) => [...prevRoutes, ...response.rows])
      setIsEmpty(response.rows.length === 0) // Set empty state
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Route Optimization" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: '30px',
              marginBottom: '30px',
              justifyContent: 'space-between'
            }}
          >
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <ViewModeSwitch name="Route Optimization" />
              <ExportButton
                name="OptimizedRoutes"
                accounts={optimizedRoutes}
              ></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ optimizedRoutes, isLoading, isEmpty }} />
    </div>
  )
}
