import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { StyledSearch, FilterSelect } from '../../styledComponents';
import { Grid, InputAdornment, MenuItem, IconButton } from '@mui/material';
import { getSearchFilterList } from '../../constants/searchFilterOptions';

const SearchField = ({ name, onSearchChange }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState('')

  // Set the default selectedFilterOption option when the component mounts or props.name changes
  useEffect(() => {
    const filterOptions = getSearchFilterList(name);
    if (filterOptions.length > 0) {
      setSelectedFilterOption(filterOptions[0]);
    }
  }, [name]);

  useEffect(() => {
      onSearchChange(searchValue, selectedFilterOption);
  }, [searchValue, selectedFilterOption]); // Add selectedFilterOption to the dependency array

  const handleFilterChange = (event) => {
    setSelectedFilterOption(event.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };
  
  return (

    <Grid item>
      {errorMessage}
      <StyledSearch
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        variant="standard"
        placeholder="What are you looking for ..."
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <FilterSelect
                value={selectedFilterOption}
                onChange={handleFilterChange}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {getSearchFilterList(name).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </FilterSelect>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear}>
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

SearchField.propTypes = {
  name: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};

export default SearchField;
