/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  StyledFormContainer,
  StyledInputControlContainer
} from '../../../management/billingPlans/styles/BillingPlansStyles.js'
import { useParams } from 'react-router-dom'
import { iconMap } from '../../../../constants/iconsList.js'
import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight
} from '../../../../styledComponents.js'
import FormField from '../../../common/FormField.js'
import FormSelect from '../../../common/FormSelect.js'
import FormPhone from '../../../common/FormPhone.js'
import { fetchAllUsers } from '../../../../services/userNew.service.js'
import FormPassword from '../../../common/FormPassword.js'
import { UsersModel } from '../../../../model/users.model.js'

const DriversForm1 = ({
  driver,
  onFormValueChange,
  validationErrors,
  setValidationErrors
}) => {
  const [selectedOption, setSelectedOption] = useState(
    driver.type || 'New Driver'
  )
  const [users, setUsers] = useState([UsersModel])
  const [selectedDriver, setSelectedDriver] = useState(driver)
  const { id } = useParams()
  const [profileImage, setProfileImage] = useState(null)
  const [page, setPage] = useState(1)
  const [limit] = useState(15)

  useEffect(() => {
    setProfileImage(driver.profileImage?.imageURL);
  }, [driver]);

  // useEffect(() => {}, [profileImage])

  const handleFieldChange = async (name, value, index = null) => {
    try {
      let updatedUser = { ...driver, [name]: value }

      if (name === 'phone') {
        const phoneNumberParts = value.split(' ')
        const countryCode = phoneNumberParts[0] // +255
        const phoneNumber = phoneNumberParts.slice(1).join(' ') // "21 233 44"
        updatedUser = { ...driver, areaCode: countryCode, phone: phoneNumber }
      } else if (name === 'firstName') {
        updatedUser = {
          ...driver,
          firstName: value,
          selectedDriver: value + ' ' + driver.lastName
        }
      } else if (name === 'lastName') {
        updatedUser = {
          ...driver,
          lastName: value,
          selectedDriver: driver.firstName + ' ' + value
        }
      } else {
        updatedUser = { ...driver, [name]: value }
      }
      if (updatedUser.type === 'New Driver') {
        onFormValueChange(updatedUser, name, value, 1)
      } else {
        onFormValueChange(updatedUser, name, value, 2)
      }
    } catch (error) {
      // No need to update validationErrors here, as it's done in the validateField function
    }
  }

  // Function to select new user or existing user
  const handleSelectOption = (name) => {
    setSelectedOption(name)
    if (name == 'New Driver') {
      onFormValueChange({
        ...driver,
        selectedDriver: driver.firstName + ' ' + driver.lastName,
        type: name
      })
    } else {
      onFormValueChange({
        ...driver,
        selectedDriver: '',
        type: name
      })
    }
  }

  // Fetch existing users for dropdown selection
  useEffect(() => {
    if (selectedOption === 'Existing Driver') {
      _fetchAllUsers()
    }
  }, [selectedOption])

  // Fetch existing users for dropdown selection
  const _fetchAllUsers = async () => {
    const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))

    // If accountId is empty, do not fetch users
    if (!accountId) {
      setUsers([])
      return
    }

    try {
      const response = await fetchAllUsers(page, limit)
      setUsers(response.rows)
    } catch (error) {
      if (error.response?.data) {
        console.log(error.response.data.message)
      } else {
        console.log(error.message)
      }
    }
  }

  // Function to handle selected driver from dropdown
  const handleSelectDriver = async (driver) => {
    setSelectedDriver(driver)
    let updatedUser = {
      ...driver,
      driver,
      selectedDriver: driver.firstName + ' ' + driver.lastName
    }
    if (updatedUser.type === 'New Driver') {
      onFormValueChange(updatedUser, 'selectedDriver', driver.selectedDriver, 1)
    } else {
      onFormValueChange(updatedUser, 'selectedDriver', driver.selectedDriver, 2)
    }
  }

  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        onFormValueChange({
          ...driver,
          profileImage: { file: file, imageURL: reader.result }
        }, 'profileImage', reader.result, 1);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle image delete
  const handleImageDelete = () => {
    onFormValueChange({
      ...driver,
      profileImage: { imageURL: driver.profileImage.imageURL, isDelete: true }
    })
  }

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Basic Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          {selectedOption === 'New Driver' && (
            <Box>
              {(profileImage || driver.profileImage?.imageURL) &&
              !driver.profileImage?.isDelete ? (
                <img
                  src={profileImage || driver.profileImage?.imageURL}
                  alt="Profile"
                  style={{
                    width: '68px',
                    height: '68px',
                    marginRight: '10px',
                    borderRadius: '8px'
                  }}
                />
              ) : (
                <img
                  src={iconMap['PROFILEICON']}
                  alt="profileIcon"
                  style={{
                    width: '68px',
                    height: '68px',
                    marginRight: '10px',
                    borderRadius: '8px'
                  }}
                />
              )}
              <input
                id="profile-image-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
              <button
                onClick={() =>
                  document.getElementById('profile-image-upload').click()
                }
                style={{
                  cursor: 'pointer',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  border: '1px solid #D8D8DF',
                  flexGrow: 1,
                  textAlign: 'center',
                  backgroundColor: '#FFFFFF',
                  color: '#6C6C81',
                  fontSize: '14px',
                  fontWeight: 600,
                  fontStyle: 'Archivo',
                  lineHeight: '19px',
                  position: 'relative',
                  bottom: '43px',
                  boxShadow: '0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11)'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={iconMap['UPLODEICON']}
                    alt="Upload"
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '5px'
                    }}
                  />
                  UPLOAD
                </div>
              </button>
              {(profileImage || driver.profileImage?.imageURL) &&
                !driver.profileImage?.isDelete && (
                  <img
                    src={iconMap['DeleteIcon']}
                    alt="Delete"
                    style={{
                      width: '37px',
                      height: '34px',
                      marginLeft: '10px',
                      cursor: 'pointer',
                      backgroundColor: '#FFFFFF',
                      position: 'relative',
                      bottom: '34px',
                      borderRadius: '8px',
                      border: '1px solid #D8D8DF',
                      padding: '5px',
                      boxShadow: '0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11)'
                    }}
                    onClick={handleImageDelete}
                  />
                )}
              <Typography
                sx={{
                  position: 'relative',
                  left: '78px',
                  bottom: '33px',
                  color: '#6C6C81',
                  fontSize: '14px',
                  fontWeight: 400
                }}
              >
                .png or jpg
              </Typography>
            </Box>
          )}
          {id === undefined && (
            <StyledInputControlContainer>
              <Typography variant="label">Driver Type *</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant={
                      selectedOption === 'New Driver' ? 'contained' : 'outlined'
                    }
                    onClick={() => handleSelectOption('New Driver')}
                  >
                    New User
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={
                      selectedOption === 'Existing Driver'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleSelectOption('Existing Driver')}
                  >
                    Existing User
                  </Button>
                </Grid>
              </Grid>
            </StyledInputControlContainer>
          )}
          {selectedOption === 'New Driver' ? (
            <Box>
              <FormField
                label="First Name"
                required
                placeholder="Enter first name"
                value={driver.firstName}
                onChange={(e) => handleFieldChange('firstName', e.target.value)}
                error={!!validationErrors.firstName}
                errorMessage={validationErrors.firstName}
              />
              <FormField
                label="Last Name"
                required
                placeholder="Enter last name"
                value={driver.lastName}
                onChange={(e) => handleFieldChange('lastName', e.target.value)}
                error={!!validationErrors.lastName}
                errorMessage={validationErrors.lastName}
              />
              <FormField
                label="Email"
                required
                placeholder="Enter email"
                value={driver.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                error={!!validationErrors.email}
                errorMessage={validationErrors.email}
              />
              <FormPhone
                label="Phone"
                value={driver.areaCode + ' ' + driver.phone}
                onChange={(newValue) => handleFieldChange('phone', newValue)}
                error={!!validationErrors.phone}
                errorMessage={validationErrors.phone}
                required
              />
            </Box>
          ) : (
            <FormSelect
              label="Choose Driver"
              required
              value={selectedDriver}
              onChange={(e) => handleSelectDriver(e)}
              options={users.map((item) => ({
                value: item,
                label: item.firstName
              }))}
              error={!!validationErrors.selectedDriver}
              errorMessage={validationErrors.selectedDriver}
            />
          )}
        </StyledSectionRight>
      </StyledFormSection>

      {selectedOption === 'New Driver' && (
        <StyledFormSection>
          <StyledSectionLeft>
            <Typography variant="heading">Credentials</Typography>
          </StyledSectionLeft>
          <StyledSectionRight>
            <FormField
              label="User Name"
              required
              placeholder="Enter user name"
              value={driver.userName}
              onChange={(e) => handleFieldChange('userName', e.target.value)}
              error={!!validationErrors.userName}
              errorMessage={validationErrors.userName}
            />
            {id === undefined && (
              <>
                <FormPassword
                  label="Password"
                  value={driver.password}
                  onChange={(e) =>
                    handleFieldChange('password', e.target.value)
                  }
                  error={!!validationErrors.password}
                  errorMessage={validationErrors.password}
                  required
                />
                <FormPassword
                  label="Confirm Password"
                  value={driver.confirmPassword}
                  onChange={(e) =>
                    handleFieldChange('confirmPassword', e.target.value)
                  }
                  error={!!validationErrors.confirmPassword}
                  errorMessage={validationErrors.confirmPassword}
                  required
                />
              </>
            )}
          </StyledSectionRight>
        </StyledFormSection>
      )}
    </StyledFormContainer>
  )
}

DriversForm1.propTypes = {
  driver: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired
}

export default DriversForm1
