import React from 'react';
import { BounceLoader } from 'react-spinners';
import { LoaderContainer, LoaderConfig } from '../../styledComponents';

const Loader = () => (
  <LoaderContainer>
    <BounceLoader {...LoaderConfig} />
  </LoaderContainer>
);

export default Loader;
