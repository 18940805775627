export const AccessRightsModel = {
  serviceId: '',
  serviceName: '',
  add: false,
  edit: false,
  view: false,
  delete: false,
  serviceValue: false,
  type: ''
};

export const RoleModel = {
  name: '',
  order: 0,
  accessRights: [AccessRightsModel],
  createdBy: '',
  updatedBy: ''
};
