import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1C2541',
      light: '#3A506B'
    },
    secondary: {
      main: '#5BC0BE',
      light: '#6FFFE9'
    }
  },
  typography: {
    fontFamily: [
      'Archivo',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontSize: 12,
    mainTitle: {
      fontSize: '32px',
      fontWeight: '600',
      textTransform: 'uppercase',
      color: '#0a2c47'
    },
    subText: {
      fontSize: '14px',
      fontWeight: '600',
      textTransform: 'uppercase',
      color: '#6c6c81',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },

    heading: {
      fontFamily: 'Archivo',
      fontSize: '19px',
      fontWeight: '600',
      lineHeight: '1.1',
      letterSpacing: '-0.38px',
      textAlign: 'left',
      color: '#0f446b',
      marginBottom: '8px'
    },
    subHeading: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#6c6c81',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    label: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.35',
      color: '#0F446B',
      display: 'flex',
      marginBottom: '8px'
    },
    endAdornment: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#5b5b6e',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    modalTitle:{
      fontSize: '26px',
      fontWeight: '900',
      color:'#0a2c47'
    },
    modalContent:{
      fontSize: '16px',
      color:'#6c6c81'
    },
    cardTitle1: {
      fontSize: '18px',
      fontWeight: '600',
      color: '#0a2c47',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    cardStatus: {
      backgroundColor: "white",
      // color: getAccountsSubscriptionColor(account.subscriptions[0]?.subscriptionStatus),
      padding: "4px 8px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: '700'
    },
    cardStatus2: {
      backgroundColor: "#f7f7f7f7",
      // color: getAccountsSubscriptionColor(account.subscriptions[0]?.subscriptionStatus),
      padding: "4px 8px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: '700'
    },
    cardTitle2: {
      fontSize: '14px',
      fontWeight: '500',
      color: '#6c6c81',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    cardLabel: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#6c6c81',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    cardValue: {
      fontSize: '14px',
      fontWeight: '500',
      color: ' #0a2c47',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    tableHeader: {
      fontSize: '14px',
      fontWeight: '600',
      color: '#5B5B6E',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    tableValue: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#0a2c47',
      lineHeight: '1.35',
      letterSpacing: 'normal'
    },
    subTitle1: {
      fontFamily: 'Archivo',
      fontSize: '23px',
      fontWeight: '600',
      color: '#0f446b'
    },
    h1: {
      fontSize: '6rem',
      fontWeight: 600
    },
    h2: {
      fontSize: '5rem',
      fontWeight: 600
    },
    h3: {
      fontSize: '4rem',
      fontWeight: 600
    },
    h4: {
      fontSize: '3rem',
      fontWeight: 600
    },
    h5: {
      fontSize: '2rem',
      lineHeight: '25px',
      fontWeight: 600
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          margin: '0px',
          '.MuiInputBase-root MuiInput-root': {
            width: '100%'
          }
        },
        outlined: {
          // Root class for the input field
          '& .MuiOutlinedInput-root': {
            color: '#000',
            fontFamily: 'Arial',
            fontWeight: 'bold',
            // Class for the border around the input field
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'red',
              borderWidth: '2px'
            }
          }
          // Class for the label of the input field
          //   "& .MuiInputLabel-outlined": {
          //     color: "#2e2e2e",
          //     fontWeight: "bold",
          //   },
        },
        filled: {
          //   '& .MuiFilledInput-root': {
          //     backgroundColor: 'green', // Background color for filled variant
          //     '&.Mui-focused': {
          //       backgroundColor: 'blue', // Background color when focused
          //     },
          //   },
        },
        customETS: {
          '& .MuiFilledInput-root': {
            backgroundColor: 'purple', // Background color for customETS variant
            color: 'white', // Text color for customETS variant
            height: '100px',
            '& .MuiInputAdornment-root': {
              color: 'red', // Text color for endAdornment icon
              backgroundColor: 'purple' // Background color for endAdornment icon
            }
          }
        }
      }
    }
  }
})

export default theme
