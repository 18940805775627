/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import CustomCard from '../../../common/CustomCard'
import { Grid } from '@mui/material'
import { getBillingPlanById } from '../../../../services/billingPlanNew.service'
import { getAccountById } from '../../../../services/accountNew.service'
import { useParams } from 'react-router-dom'
import Loader from '../../../common/Loader'

export default function AccountsService() {
  var { id, tab } = useParams()
  const [billingPlanData, setBillingPlanData] = useState([])
  const [accountData, setAccountData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getAccountId = async () => {
      setIsLoading(true)
      try {
        const data = await getAccountById(id)
        setAccountData(data)
        setIsLoading(false)
      } catch (error) {
        console.log(error.message)
        setIsLoading(false)
      }
    }
    if (tab == 'services') {
      getAccountId()
    }
  }, [])

  useEffect(() => {
    const getBillingPlanId = async () => {
      setIsLoading(true)
      try {
        const data = await getBillingPlanById(accountData?.billingPlanId)
        setBillingPlanData(data)
        setIsLoading(false)
        // setContentOne(data[0].servicesLimit?.filter((item)=>item.type=="General").map((item)=>(item)))
      } catch (error) {
        // Handle error
        setIsLoading(false)
      }
    }
    if (accountData != undefined && tab == 'services') {
      getBillingPlanId()
    }
  }, [accountData])

  const contentOne = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'General')
    .map((item) => item)
  const contentTwo = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'Management')
    .map((item) => item)
  const contentThree = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'Monitoring')
    .map((item) => item)
  const contentFour = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'Sensors')
    .map((item) => item)
  const contentFive = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'Reports')
    .map((item) => item)
  const contentSix = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'Alerts')
    .map((item) => item)
  const contentSeven = billingPlanData?.servicesLimit
    ?.filter((item) => item.type == 'Features')
    .map((item) => item)

  return (
    <>
      {isLoading && <Loader />}
      <div style={{ marginTop: '38px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {contentOne ? (
              <CustomCard header="GENERAL SERVICE LIMIT" content={contentOne} />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {contentTwo ? (
              <CustomCard
                header="MANAGEMENT SERVICE LIMIT"
                content={contentTwo}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {contentThree ? (
              <CustomCard
                header="MONITORING SERVICE LIMIT"
                content={contentThree}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {contentFour ? (
              <CustomCard header="SENSORS" content={contentFour} />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {contentFive ? (
              <CustomCard header="REPORTS" content={contentFive} />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {contentSix ? (
              <CustomCard header="ALERTS" content={contentSix} />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {contentSeven ? (
              <CustomCard header="FEATURES" content={contentSeven} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
