import React from 'react';
import { Outlet } from 'react-router-dom';

const PaymentsPage = () => {
    return (
        <div className="container">
            <Outlet />
        </div>
    );
};
export default PaymentsPage;