import * as yup from 'yup'

const pricingCycleEnum = ['Monthly', 'Quarterly', 'Half Yearly', 'Yearly']
const currencyEnum = ['TZS', 'AUD']

const servicesLimitSchema = yup.object().shape({
  serviceId: yup.string().required('Service ID is required'),
  limit: yup
    .number()
    .min(-1, 'Limit must be at least -1')
    .required('Limit is required'),
  value: yup.boolean().required('Value is required'),
  cost: yup.number().nullable(),
  type: yup.string().required('Type is required')
})

const pricesSchema = yup.object().shape({
  pricingCycle: yup
    .string()
    .oneOf(pricingCycleEnum, 'Invalid pricing cycle')
    .required('Pricing Cycle is required'),
  amount: yup.number().required('Amount is required'),
  currency: yup
    .string()
    .oneOf(currencyEnum, 'Invalid currency')
    .required('Currency is required')
})

export const billingPlanSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, 'Name cannot exceed 50 characters.')
    .required('Billing plan name is required.'),
  description: yup
    .string()
    .min(3, 'Description should be at least 3 characters.')
    .max(400, 'Description cannot exceed 400 characters.')
    .required('Description is required.'),
  color: yup.string().trim().required('Color is required.'),
  deviceCost: yup.number().required('Device cost is required.'),
  installationCost: yup.number().required('Installation cost is required.'),
  VAT: yup.number().required('VAT is required.'),
  prices: yup.array().of(pricesSchema).required('Prices are required.'),
  servicesLimit: yup
    .array()
    .of(servicesLimitSchema)
    .required('Services limit is required.'),
  createdBy: yup.string().nullable(),
  updatedBy: yup.string().nullable()
})

export const billingPlanValidationSchemaStep1 = yup.object().shape({
  name: yup
    .string()
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be at most 50 characters')
    .required('Name is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required')
    .min(3, 'Description must be at least 3 characters')
    .max(400, 'Description cannot exceed 400 characters'),
  color: yup.string().required('Color is required'),
  deviceCost: yup
    .number()
    .typeError('Device cost must be a number')
    .min(0, 'Device cost must be greater than or equal to 0')
    .required('Device cost is required')
    .moreThan(0, 'Device cost must be greater than 0'),
  installationCost: yup
    .number()
    .typeError('Installation cost must be a number')
    .min(0, 'Installation cost must be greater than or equal to 0')
    .required('Installation cost is required')
    .moreThan(0, 'Installation cost must be greater than 0'),
  VAT: yup
    .number()
    .typeError('VAT must be a number')
    .min(0, 'VAT must be greater than or equal to 0')
    .required('VAT is required')
    .moreThan(0, 'VAT must be greater than 0'),
  prices: yup
    .array()
    .of(
      yup.object().shape({
        pricingCycle: yup
          .string()
          .required()
          .oneOf(['Monthly', 'Quarterly', 'Half Yearly', 'Yearly']),
        amount: yup
          .number()
          .typeError('Amount must be a number')
          .min(0, 'Amount must be greater than or equal to 0')
          .required('Amount is required')
          .moreThan(0, 'Amount must be greater than 0'),
        currency: yup.string().required().oneOf(['TZS', 'USD'])
      })
    )
    .required('Prices are required')
})

export const billingPlanValidationSchemaStep2 = yup.object().shape({
  servicesLimit: yup
    .array()
    .of(servicesLimitSchema)
    .required('Services limit is required.')
})
