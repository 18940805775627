/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Grid, InputAdornment, MenuItem, Typography } from '@mui/material'

import {
  StyledAddMoreButton,
  StyledCheckboxButton,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSelect,
  StyledStartAdornmentTextfield
} from '../../../../styledComponents'
import FormField from '../../../common/FormField'
// import FormColorPicker from "../../../common/FormColorPicker";
import FormFieldMultiline from '../../../common/FormFieldMultiline'
import ColorPicker from '../../../common/ColorPicker'
import {
  DoubleArrowIconContainer,
  RemovePriceGrid,
  StyledInputControlContainer
} from '../styles/BillingPlansStyles'
import { iconMap } from '../../../../constants/iconsList'
import FormFieldWithEndAdornment from '../../../common/FormFieldWithEndAdornment'
import FormError from '../../../common/FormError'

const BillingPlansForm1 = ({
  billingPlan,
  onFormValueChange,
  validationErrors,
}) => {
  const pricingCycle = [
    { name: 'Monthly', value: 'Monthly', control: 'pricingCycle' },
    { name: 'Quarterly', value: 'Quarterly', control: 'pricingCycle' },
    { name: 'Half Yearly', value: 'Half Yearly', control: 'pricingCycle' },
    { name: 'Yearly', value: 'Yearly', control: 'pricingCycle' }
  ]
  const currencyOptions = ['TZS', 'USD']

  const handleColorChange = (newColor) => {
    // setColor(newColor);
    onFormValueChange({
      ...billingPlan,
      color: newColor
    })
  }

  const handleFieldChange = async (name, value) => {
    try {
      // Update the state with the new value
      let updatedBillingPlan = { ...billingPlan, [name]: value }
      // Update form state
      onFormValueChange(updatedBillingPlan, name, value, 0)
    } catch (error) {
      // No need to update validationErrors here, as it's done in the validateField function
    }
  }

  const addPrice = () => {
    if (billingPlan.prices.length < 4) {
      // Check if any pricing cycle option already exists
      const monthlyExists = billingPlan.prices.some(
        (price) => price.pricingCycle === 'Monthly'
      )
      const quarterlyExists = billingPlan.prices.some(
        (price) => price.pricingCycle === 'Quarterly'
      )
      const halfYearlyExists = billingPlan.prices.some(
        (price) => price.pricingCycle === 'Half Yearly'
      )
      const yearlyExists = billingPlan.prices.some(
        (price) => price.pricingCycle === 'Yearly'
      )

      // Determine the default pricing cycle
      let defaultPricingCycle = 'Monthly'
      if (
        monthlyExists &&
        quarterlyExists &&
        halfYearlyExists &&
        yearlyExists
      ) {
        // If all pricing cycles already exist, disable all options
        defaultPricingCycle = null
      } else if (monthlyExists && !quarterlyExists) {
        defaultPricingCycle = 'Quarterly'
      } else if (monthlyExists && quarterlyExists && !halfYearlyExists) {
        defaultPricingCycle = 'Half Yearly'
      } else if (
        monthlyExists &&
        quarterlyExists &&
        halfYearlyExists &&
        !yearlyExists
      ) {
        defaultPricingCycle = 'Yearly'
      }

      // Add a new price with default values
      const newPrice = {
        pricingCycle: defaultPricingCycle,
        amount: '',
        currency: 'TZS'
      }
      onFormValueChange({
        ...billingPlan,
        prices: [...billingPlan.prices, newPrice]
      })
    }
  }

  const removePrice = (indexToRemove) => {
    const updatedPrices = billingPlan.prices.filter(
      (_, index) => index !== indexToRemove
    )
    onFormValueChange({
      ...billingPlan,
      prices: updatedPrices
    })
  }

  const updatePricingCycle = (option, price, index) => {
    const updatedPrices = billingPlan.prices.map((price, i) =>
      i === index ? { ...price, pricingCycle: option.value } : price
    )
    onFormValueChange({
      ...billingPlan,
      prices: updatedPrices
    })
  }

  const updatePrice = async (index, value) => {
    // Clone the current prices array and update the price at the specified index
    const updatedPrices = billingPlan.prices.map((price, i) =>
      i === index
        ? { ...price, amount: value, currency: price.currency }
        : price
    )
    // Update the prices state after validation
    onFormValueChange({
      ...billingPlan,
      prices: updatedPrices
    })
    let updatedBillingPlan = {
      ...billingPlan,
      prices: updatedPrices
    }
    var fieldName = `prices[${index}].amount`
    // Update form state
    onFormValueChange(updatedBillingPlan, fieldName, value, 0)
  }

  const updateCurrency = (index, value) => {
    const updatedPrices = billingPlan.prices.map((price, i) =>
      i === index ? { ...price, currency: value } : price
    )
    onFormValueChange({
      ...billingPlan,
      prices: updatedPrices
    })
  }

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Billing Plan Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Plan Name"
            required
            placeholder="Enter plan name"
            value={billingPlan?.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormFieldMultiline
            label="Description"
            required
            placeholder="Enter plan description"
            value={billingPlan.description}
            onChange={(e) => handleFieldChange('description', e.target.value)}
            error={!!validationErrors.description}
            errorMessage={validationErrors.description}
            multiline
            rows={4}
          />
          {/* <FormColorPicker
            label="Pick Any Color in Hex Value"
            required
            defaultColor={billingPlan.color}
            onFormValueChange={handleColorChange}
          /> */}

          {/* <FormColorPicker
            label="Pick Any Color in Hex Value"
            value={billingPlan.color}
            onChange={handleColorChange}
            error={!!validationErrors.color}
            errorMessage={validationErrors.color}
            required
          /> */}
          <StyledInputControlContainer>
            <Typography item variant="label">
              Pick Any Color in Hex Value *
            </Typography>
            <ColorPicker
              defaultColor={billingPlan.color}
              onFormValueChange={handleColorChange}
            />
          </StyledInputControlContainer>
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Billing Pricing Cycle</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <StyledInputControlContainer container>
            <Typography variant="label">
              Set Pricing Cycle and Price *
            </Typography>
            {billingPlan.prices.map((price, index) => (
              <Grid container sx={{ alignItems: 'center' }} key={index}>
                <Grid item xs="auto">
                  <Grid
                    container
                    sx={{
                      backgroundColor: '#f7f7f8',
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px'
                    }}
                    spacing={1}
                  >
                    {pricingCycle.map((option, pricingCycleIndex) => (
                      <StyledCheckboxButton
                        key={pricingCycleIndex}
                        item
                        value={option.value}
                        variant={
                          price.pricingCycle === option.value
                            ? 'contained'
                            : 'outlined'
                        }
                        disabled={
                          index === 0
                            ? option.value !== 'Monthly'
                            : billingPlan.prices.some(
                                (existingPrice) =>
                                  existingPrice.pricingCycle === option.value
                              )
                        }
                        onClick={() => updatePricingCycle(option, price, index)}
                      >
                        {option.name}
                      </StyledCheckboxButton>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <DoubleArrowIconContainer>
                    <img src={iconMap['Arrow']} alt="arrow" />
                  </DoubleArrowIconContainer>
                </Grid>
                <Grid item xs sx={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledStartAdornmentTextfield
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputProps={{ step: 'any' }}
                    value={price.amount || ''}
                    onChange={(e) => {
                      const value =
                        e.target.value === ''
                          ? null
                          : parseFloat(e.target.value)
                      updatePrice(index, value)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <Grid className="startAdornmentContainer"> */}
                          <Grid
                            sx={{
                              paddingLeft: '6px',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#F7F7F8'
                            }}
                          >
                            <img
                              src={iconMap['TANZANIAFLAGICON']}
                              alt="flagIcon"
                              style={{
                                marginRight: '8px',
                                position: 'relative',
                                bottom: '3px',
                                width: '25px',
                                height: '25px'
                              }}
                            />
                            <Typography variant="startAdornment">
                              <StyledSelect
                                variant="standard"
                                disableUnderline
                                defaultValue="TZS"
                                placeholder="Filter"
                                value={price.currency}
                                onChange={(e) =>
                                  updateCurrency(index, e.target.value)
                                }
                              >
                                {currencyOptions.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </Typography>
                          </Grid>
                          {/* </Grid> */}
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                {billingPlan.prices.length > 1 && (
                  <RemovePriceGrid item onClick={() => removePrice(index)}>
                    <img src={iconMap['Remove']} alt="remove" />
                  </RemovePriceGrid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginRight: '50px'
                  }}
                >
                  {validationErrors[`prices[${index}].amount`] && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '5px'
                      }}
                    >
                      <img
                        src={iconMap['ALERTICON']}
                        alt="alertIcon"
                        style={{
                          marginRight: '5px',
                          width: '25px',
                          height: '25px'
                        }}
                      />
                      <Typography sx={{ fontSize: '16px', color: '#e71111' }}>
                        {validationErrors[`prices[${index}].amount`]}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            ))}

            <StyledAddMoreButton
              variant="outlined"
              sx={{
                marginTop: '10px'
              }}
              onClick={addPrice}
              disabled={billingPlan.prices.length > 3}
            >
              <img
                src={iconMap['AddMorePlusIcon']}
                alt="PlusIcon"
                style={{ height: '14px', width: '14px' }}
              />
              ADD MORE
            </StyledAddMoreButton>
          </StyledInputControlContainer>
          <FormField
            label="Device cost"
            required
            placeholder="Enter device cost"
            value={billingPlan.deviceCost || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('deviceCost', value)
            }}
            type="number"
            error={!!validationErrors.deviceCost}
            errorMessage={validationErrors.deviceCost}
          />
          <FormField
            label="Installation cost"
            required
            placeholder="Enter installation cost"
            value={billingPlan.installationCost || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('installationCost', value)
            }}
            type="number"
            error={!!validationErrors.installationCost}
            errorMessage={validationErrors.installationCost}
          />
          <FormFieldWithEndAdornment
            label="VAT %"
            placeholder="Enter VAT"
            value={billingPlan.VAT}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('VAT', value)
            }}
            type="number"
            error={!!validationErrors.VAT}
            errorMessage={validationErrors.VAT}
            required={true}
            endAdornmentText="%"
          />
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  )
}
BillingPlansForm1.propTypes = {
  billingPlan: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
}

export default BillingPlansForm1
