import axios from "axios";
import { roleApi } from "../utils/constant";

const api = axios.create({
  baseURL: roleApi,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};
// Fetch all roles with optional query parameters
export const fetchAllRoles = async (page, limit) => {
  try {
    const response = await api.get("/", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

//Get a role by its ID
export const getRoleById = async (roleId) => {
  try {
    const response = await api.get(`/${roleId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new role
export const createRole = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing role by its ID
export const updateRole = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a role by its ID
export const deleteRole = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch roles with pagination
export const fetchRolesByName = async (name, page, limit) => {
  try {
    const response = await api.get("/fetchRolesByName", {
      params: { name, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchRolesByMultipleCriteria = async (id, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchRolesByMultipleCriteria", {
      params: {
        accountId,
        id,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
