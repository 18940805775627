/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { ThemeProvider } from '@mui/material'
import { theme } from './utils/theme'
// import Routing from "./routing";
import { Provider } from 'react-redux'
import store from './utils/redux/store/store'
import MainLayout from './modules/common/Navigation/MainLayout'
import { BrowserRouter as Router } from 'react-router-dom'
import { generateToken } from './services/firebase/firebase'
import { useEffect, useState } from 'react'
import Loader from './modules/common/Loader'

const styles = {
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0)' /* transparent */,
    zIndex: '9999' /* ensure that the overlay appears on top */,
    transition: 'background-color 0.5s ease-in-out 0.2' // Fade in/out transition
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0, // Initially hidden
    transition: 'opacity 0.5s ease-in-out 0.2s', // Fade in/out transition with a slight delay
    zIndex: '99999'
  },
  spinnerActive: {
    opacity: 1 // Show spinner when active
  }
}
function App() {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    generateToken()
    setIsLoading(false)
  }, [])

  return (
    <Provider store={store}>
      {isLoading && (
        <div
          style={{ ...styles.overlay, backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        >
          <div style={{ ...styles.spinner, ...styles.spinnerActive }}>
            <Loader  />
          </div>
        </div>
      )}
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <MainLayout />
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default App
