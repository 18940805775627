/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Popover,
  Box,
  Grid,
  Slider,
  Typography,
  TextField,
  InputAdornment
} from '@mui/material'
import { ChromePicker } from 'react-color'
import { presetColors } from '../../utils/constant'
// const presetColors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00'];
import ColorLensIcon from '../../../src/assets/icons/colorWheelIcon.png'
// import CustomTextField from './CustomTextField';
// import { FlexContainer } from '../management/billingPlans/styles/BillingPlansStyles';
const ColorPicker = ({ defaultColor, onFormValueChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    setColor(defaultColor)
  }, [defaultColor])

  const handleColorChange = (newColor) => {
    setColor(newColor)
    if (onFormValueChange) {
      onFormValueChange(newColor)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeComplete = (newColor) => {
    setColor(newColor.hex)
  }

  const handleSliderChange = (event, newValue) => {
    setColor(`rgba(${color.r}, ${color.g}, ${color.b}, ${newValue / 100})`)
  }

  const handlePresetColor = (newColor) => {
    setColor(newColor)
  }

  const handleConfirm = () => {
    onFormValueChange(color)
    handleClose()
  }

  const handleCancel = () => {
    setColor(defaultColor)
    handleClose()
  }

  useEffect(() => {
    onFormValueChange(color)
  }, [color])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid container spacing={1}>
      {presetColors.map((presetColor, index) => (
        <Grid item key={index}>
          <Button
            variant="contained"
            style={{
              backgroundColor: presetColor,
              minHeight: '20px',
              minWidth: '20px'
            }}
            onClick={() => handlePresetColor(presetColor)}
          />
        </Grid>
      ))}
      <Grid item sx="display:'inline-flex', alignItems:'center'">
        <Button
          item
          onClick={handleClick}
          sx={{
            backgroundColor: '#f7f7f8',
            color: '#5b5b6e',
            fontSize: '16px',
            height: '25px',
            textTransform: 'lowercase'
          }}
        >
          {' '}
          <img
            height="20px"
            width="20px"
            style={{ marginRight: '10px' }}
            src={ColorLensIcon}
          ></img>{' '}
          {color}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ChromePicker
                  color={color}
                  onChangeComplete={handleChangeComplete}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography id="discrete-slider" gutterBottom>
                  Opacity
                </Typography>
                <Slider
                  defaultValue={100}
                  getAriaValueText={(value) => `${value}%`}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  onChange={handleSliderChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </Grid>
    </Grid>
  )
}

ColorPicker.propTypes = {
  defaultColor: PropTypes.string.isRequired,
  onFormValueChange: PropTypes.func.isRequired
}

export default ColorPicker
