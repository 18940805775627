import { SET_USER_DATA, LOGIN_SUCCESS, LOGIN_FAILURE } from '../actions/userActionTypes';

const initialState = {
  userData: null,
  isLoggedIn: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        isLoggedIn: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload.user,
        isLoggedIn: true,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
      };
    case 'RESET_LOGIN_FORM':
      return {
        ...state,
        loginForm: {}
      };
    default:
      return state;
  }
};

export default userReducer;





// // reducers.js
// const initialState = {
//   form1: {},
//   form2: {},
//   userData:{}
// };

// const userReducer = (state = initialState, action) => {
//   console.log(action.type)
//   switch (action.type) {
//     case 'UPDATE_FORM1':
//       return {
//         ...state,
//         form1: action.payload
//       };
//     case 'SET_USER_DATA':
//       return {
//         ...state,
//         userData: action.payload
//       };
//     case 'UPDATE_FORM2':
//       return {
//         ...state,
//         form2: action.payload
//       };
//     case 'UPDATE_FORM3':
//       return {
//         ...state,
//         form3: action.payload
//       };
//     case 'LOGIN_FORM':
//       return {
//         ...state,
//         loginForm: action.payload
//       };
//     case 'RESET_FORM1':
//       return {
//         ...state,
//         form1: {}
//       };
//     case 'RESET_FORM2':
//       return {
//         ...state,
//         form2: {}
//       };
//     case 'RESET_FORM3':
//       return {
//         ...state,
//         form3: {}
//       };
//     case 'RESET_LOGIN_FORM':
//       return {
//         ...state,
//         loginForm: {}
//       };
//     default:
//       return state;
//   }
// };

// export default userReducer;
