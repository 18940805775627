/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";
import { formatDate, getUnitsStatusColor } from "../../../../utils/functions";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";
import DeactivateAccountModal from "../../../modals/DeactivateAccountModal";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";

// Service and common components
import { deleteUnit, updateUnit } from "../../../../services/unitNew.service";
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import { setIpAndPortForUnit } from "../../../../services/aws.services";

const UnitsListCard = () => {
  const [errors, setErrors] = useState([]);
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by account ID on open and close 3 dots menu option
  const [openMenuUnitId, setOpenMenuUnitId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  // This is for the deactivate account modal
  const [activateOpen, setActivateOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  // Hooks for navigation and outlet context
  const navigate = useNavigate();
  const { units, fetchMore, isLoading, isEmpty } = useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [units, isLoading, handleScroll]); // Include dependencies that affect the event listener

  // Function that calls the API to perform handleUpdateUnit
  const handleUpdateUnit = useCallback(
    async (updatedUnit) => {
      try {
        const response = await updateUnit(updatedUnit);
        if (response) {
          setSuccessMessage("Unit updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuUnitId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuUnitId(null); // Set the ID of the account whose menu should be open
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, unit) => {
      handleActionsMenuClose();
      setSelectedItem(unit);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteUnit(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Unit deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  //Deactivate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalOpen = useCallback(
    (event, unit) => {
      handleActionsMenuClose();
      setSelectedItem(unit);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  // Function that calls the API to perform deleteAccount
  const deactivateUnit = useCallback(() => {
    const status = "DEACTIVATED";

    // Update the selectedItem with the new subscriptions array
    const updatedUnit = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedUnit);
    handleUpdateUnit(updatedUnit);

    // Close the modal
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateUnit, setDeactivateOpen, setSelectedItem]);

  //Activate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleActivateModalOpen = useCallback(
    (event, unit) => {
      handleActionsMenuClose();
      setSelectedItem(unit);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  // Function that calls the API to perform deleteAccount
  const activateUnit = useCallback(() => {
    const status = "ACTIVATED";

    // Update the selectedItem with the new subscriptions array
    const updatedUnit = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedUnit);
    handleUpdateUnit(updatedUnit);

    // Close the modal
    setActivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateUnit, setActivateOpen, setSelectedItem]);

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (unitId) => {
      navigate(`/management/units/edit/${unitId}`);
      // Your navigation logic here
    },
    [navigate]
  );
  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="UNITS"
              message="Oops! You have no units for your search criteria"
              info="Please feel free to create a new unit by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {units?.map((unit) => (
          <Grid item xs={12} sm={6} md={4} key={unit.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">{unit.name}</Typography>
                    <Typography
                      variant="cardStatus"
                      sx={{
                        color: getUnitsStatusColor(unit.status),
                      }}
                    >
                      &bull; &nbsp;
                      {unit.status}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${unit.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, unit)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${unit.id}`
                      )}
                      open={openMenuUnitId === unit.id} // The menu is open if the openMenuUnitId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      {unit && unit.status === "ACTIVATED" ? (
                        <StyledMenuItem
                          onClick={(e) => handleDeactivateModalOpen(e, unit)}
                        >
                          <BlockOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Deactivate Unit
                        </StyledMenuItem>
                      ) : (
                        <StyledMenuItem
                          onClick={(e) => handleActivateModalOpen(e, unit)}
                        >
                          <VerifiedUserOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Activate Unit
                        </StyledMenuItem>
                      )}
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, unit)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Unit</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              {unit.unitType === "Vehicle" ? (
                <StyledListCardContent
                  onClick={() => navigateToEditScreen(unit.id)}
                >
                  <Grid>
                    <FlexContainerSB item container>
                      <Typography variant="cardLabel">VIN</Typography>
                      <Typography variant="cardValue">
                        {unit?.vehicle.VIN ? unit?.vehicle.VIN : "--"}
                      </Typography>
                    </FlexContainerSB>
                    <FlexContainerSB item container>
                      <Typography variant="cardLabel">License Plate</Typography>
                      <Typography variant="cardValue">
                        {unit?.vehicle.licensePlate
                          ? unit?.vehicle.licensePlate
                          : "--"}
                      </Typography>
                    </FlexContainerSB>
                    <FlexContainerSB item container>
                      <Typography variant="cardLabel">Make</Typography>
                      <Typography variant="cardValue">
                        {unit?.vehicle.make ? unit?.vehicle.make : "--"}
                      </Typography>
                    </FlexContainerSB>
                    <FlexContainerSB item container>
                      <Typography variant="cardLabel">Model</Typography>
                      <Typography variant="cardValue">
                        {unit?.vehicle.model ? unit?.vehicle.model : "--"}
                      </Typography>
                    </FlexContainerSB>
                    <FlexContainerSB item container>
                      <Typography variant="cardLabel">Year</Typography>
                      <Typography variant="cardValue">
                        {unit?.vehicle.year
                          ? formatDate(unit?.vehicle.year)
                          : "--"}
                      </Typography>
                    </FlexContainerSB>
                  </Grid>
                </StyledListCardContent>
              ) : (
                <StyledListCardContent
                  onClick={() => navigateToEditScreen(unit.id)}
                >
                  <Grid>
                    <FlexContainerSB item container>
                      <Typography variant="cardLabel">Device Id</Typography>
                      <Typography variant="cardValue">
                        {unit?.deviceId ? unit?.deviceId : "--"}
                      </Typography>
                    </FlexContainerSB>
                  </Grid>
                </StyledListCardContent>
              )}
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={activateUnit}
      />
      <DeactivateAccountModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateItem={deactivateUnit}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default UnitsListCard;
