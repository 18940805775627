/* eslint-disable react/prop-types */
import React from 'react'
import {
  StyledSubscriptionBox,
  StyledSubscriptionContainer,
  StyledSubscriptionValue,
  StyledSubscriptionDetail,
  StyledSubscriptionPreviewContainer,
  StyledSubscriptionPreviewTitle,
  StyledInputControlContainer
} from '../../styledComponents'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import Vector from '../../assets/logo/Vector.png'

const SubscriptionBox = ({
  selectedBillingPlan,
  account,
  formattedRenewalDate
}) => {
  return (
    <StyledInputControlContainer>
      <StyledSubscriptionPreviewTitle>
        <img
          src={Vector}
          style={{
            width: '15px',
            height: '15px',
            margin: '0 5px'
          }}
        />
        Your Subscription Details Preview
      </StyledSubscriptionPreviewTitle>
      <StyledSubscriptionBox>
        <StyledSubscriptionPreviewContainer>
          <Grid
            container
            spacing={5}
            d-flex
            alignItems="end"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={3}>
              <StyledSubscriptionContainer>
                <StyledSubscriptionValue>
                  {console.log('selectedBillingPlan', account?.billingPlanId)}
                  {selectedBillingPlan?.name}
                </StyledSubscriptionValue>
                <StyledSubscriptionDetail>
                  Billing Plan
                </StyledSubscriptionDetail>
              </StyledSubscriptionContainer>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledSubscriptionContainer>
                <StyledSubscriptionValue>
                  {account?.subscriptions[0].pricingCycle}
                </StyledSubscriptionValue>
                <StyledSubscriptionDetail>
                  Pricing Cycle
                </StyledSubscriptionDetail>
              </StyledSubscriptionContainer>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledSubscriptionContainer>
                <StyledSubscriptionValue>
                  {formattedRenewalDate}
                </StyledSubscriptionValue>
                <StyledSubscriptionDetail>
                  Next Renewal Date
                </StyledSubscriptionDetail>
              </StyledSubscriptionContainer>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledSubscriptionContainer>
                <StyledSubscriptionValue>
                  {account?.subscriptions[0].currency}{' '}
                  {account?.subscriptions[0].amount}
                </StyledSubscriptionValue>
                <StyledSubscriptionDetail>Balance Due</StyledSubscriptionDetail>
              </StyledSubscriptionContainer>
            </Grid>
          </Grid>
        </StyledSubscriptionPreviewContainer>
      </StyledSubscriptionBox>
    </StyledInputControlContainer>
  )
}

SubscriptionBox.propTypes = {
  selectedBillingPlan: PropTypes.shape({
    name: PropTypes.string
  }),
  account: PropTypes.shape({
    subscriptions: PropTypes.arrayOf(
      PropTypes.shape({
        pricingCycle: PropTypes.string,
        currency: PropTypes.string,
        amount: PropTypes.number
      })
    )
  }),
  formattedRenewalDate: PropTypes.string.isRequired
}

export default SubscriptionBox
