/* eslint-disable react/prop-types */
import React from 'react';
import { iconMap } from '../../constants/iconsList';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function SuccessMessage({ message, hasId, onClose }) {
  if (!message) {
    return null;
  }

  const containerStyle = {
    backgroundColor: '#e8fce6',
    color: '#26861d',
    padding: '16px 32px',
    margin: hasId ? '-9rem -3rem 7rem -3rem' : '-3rem -3rem 50px -3rem',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  };

  return (
    <div style={containerStyle}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={iconMap['SUCCESSICON']} alt="successIcon" style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px', fontWeight: '600' }}>{message}</span>
        </div>
        <div>
          <IconButton size="small" style={{ color: '#26861d', marginLeft: "50px" }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
    </div>
  );
}

