/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { simCardTableColumnNew } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import { getSimCardsStatusColor } from "../../../../utils/functions";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const SimCardsListTable = () => {
  const [errors, setErrors] = useState([])
  const [openMenuSimCardId, setOpenMenuSimCardId] = useState(null);
  const [open, setOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { simCards, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [simCards, isLoading, handleScroll]);

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuSimCardId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuSimCardId(null);
    event?.stopPropagation();
  }, []);

  const handleDeleteModalOpen = useCallback(
    (event, simCardId) => {
      handleActionsMenuClose();
      setSelectedItem(simCardId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  const deleteItem = async () => {
    setOpen(false);
  setSelectedItem(null);
// try {
//   const response = await deleteSimCard(selectedItem.id);
//   if (response) {
//     handleResponse(response, 'SimCard deleted successfully.')
//     handleError([])
//     // Set the state to reload the page after successful update
//     setShouldReload(true);
//   }
// } catch (error) {
//   handleError(error)
// } finally {
//   setOpen(false);
//   setSelectedItem(null);
// }
};

  // // Function that calls the API to perform deleteSimCard
  // const deleteSimCard = useCallback(() => {
  //   setOpen(false);
  //   // setSelectedItem(null)
  // }, [setOpen, setSelectedItem]);

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected SimCard has been set:", selectedItem);
    }
  }, [selectedItem]);

  const navigateToEditScreen = useCallback(
    (simCardId) => {
      navigate(`/management/sim-cards/edit/${simCardId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error)
    // }
  }

  return (
    <>
     <Grid sx={{ marginTop: '50px' }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer container ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="SIM CARDS"
              message="Oops! You have no simCards for your search criteria"
              info="Please feel free to create a new simCard by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {simCardTableColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {simCards?.map((simCard) => (
                  <TableRow
                    key={simCard.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(simCard.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                        {simCard.areaCode} {simCard.simCardNumber}
                      </Typography>
                      <Typography
                        variant="cardStatus2"
                        sx={{
                          color: getSimCardsStatusColor(simCard.status),
                        }}
                      >
                        &bull; &nbsp;
                        {simCard.status}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {simCard.accountName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {simCard.imeiNumber}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {simCard.iccdNumber}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {simCard.mssidnNumber}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${simCard.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, simCard);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${simCard.id}`
                        )}
                        open={openMenuSimCardId === simCard.id} // The menu is open if the openMenuAccountId matches
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, simCard);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Delete SimCard</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default SimCardsListTable;
