/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { MenuItem, Select } from "@mui/material";
import { PaymentModel } from "../../../../model/payments.model";
import dayjs from "dayjs";
import {
  DoubleArrowIconContainer,
  StyledInputControlContainer,
} from "../../billingPlans/styles/BillingPlansStyles";
import {
  StyledCheckboxButton,
  StyledFormContainer,
  StyledFormSection,
  StyledHeader2,
  StyledSectionLeft,
  StyledSectionRight,
  StyledStartAdornmentTextfield,
  Title,
} from "../../../../styledComponents";
import { iconMap } from "../../../../constants/iconsList";
import { useNavigate, useParams } from "react-router-dom";
import { AccountModel } from "../../../../model/accounts.model";
import { fetchAllAccounts } from "../../../../services/accountNew.service.js";
import SuccessMessage from "../../../common/SuccessMessage.js";
import ErrorMessage1 from "../../../common/ErrorMessage1.js";
import FormDatepicker from "../../../common/FormDatepicker.js";
import { paymentsSchema } from "../../../../utils/validation-schemas/paymentsSchema.js";
import {
  getPaymentById,
  createPayment,
  updatePayment,
} from "../../../../services/paymentNew.service.js";
import FormField from "../../../common/FormField.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import FormFieldMultiline from "../../../common/FormFieldMultiline.js";
import Loader from "../../../common/Loader.js";
import {
  validateForm,
  validateForm2,
} from "../../../../utils/yupValidations.js";

const lateFee = [
  { name: "Nill", value: "Nill", control: "lateFeeAmount" },
  { name: "Yes", value: "Yes", control: "lateFeeAmount" },
];

const amountType = [
  { name: "Nill", value: "Nill", control: "amount" },
  { name: "Yes", value: "Yes", control: "amount" },
];

const currencyOptions = ["TZS", "USD"];

const paymentMode = [
  { name: "Cash", value: "Cash", control: "paymentMode" },
  { name: "Credit Card", value: "Credit Card", control: "paymentMode" },
  { name: "Debit Card", value: "Debit Card", control: "paymentMode" },
];

export default function AccountsPaymentCreate() {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [payment, setPayment] = useState(PaymentModel);
  const [validationErrors, setValidationErrors] = useState({});
  const today = dayjs();
  const navigate = useNavigate();
  const [selectedlateFeesType, setSelectedlateFeesType] = useState("Yes");
  const [selectedTaxType, setSelectedTaxType] = useState("Yes");
  // const [accountData, setAccountData] = useState([AccountModel])
  const [isLoading, setIsLoading] = useState(false);
  const [openInstallationDatePicker, setOpenInstallationDatePicker] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(
    payment?.billingDate ? dayjs(payment.billingDate) : today
  );
  const [defaultsSet, setDefaultsSet] = useState(false);
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    setPayment({
      ...payment,
      billingDate: today,
    });
  }, [today]);

  useEffect(() => {
    if (id) {
      _getPaymentById(id);
    } else {
      setDefaults();
    }
  }, [id]);

  const _getPaymentById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getPaymentById(id);
      setPayment(data);
      handleResponse(data, "Payment fetched successfully!", false);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const setDefaults = () => {
    const updatedPayment = {
      ...payment,
      accountId: defaultAccountId,
    };
    setPayment(updatedPayment);
    setDefaultsSet(true);
  };

  // useEffect(() => {
  //   const getAccounts = async () => {
  //     try {
  //       const data = await fetchAllAccounts()
  //       setAccountData(data.rows)
  //       // handleResponse(data, 'Accounts fetched successfully!', 'Get');
  //     } catch (error) {
  //       handleError(error);
  //     }
  //   }
  //   getAccounts()
  // }, [])

  const handleFieldChange = async (name, value) => {
    try {
      let updatedPayment = { ...payment };

      if (name === "lateFeesType") {
        setSelectedlateFeesType(value);
        updatedPayment.lateFeesType = value;
        if (value === "No") {
          updatedPayment.lateFeeAmount = 0;
          updatedPayment.lateFeeCurrency = "";
        }
      } else if (name === "amountType") {
        setSelectedTaxType(value);
        updatedPayment.amountType = value;
        if (value === "No") {
          updatedPayment.amount = 1;
          updatedPayment.amountCurrency = "";
        }
      } else {
        updatedPayment[name] = value;
      }
      // Call handleValueChange to update state and validate
      await handleValueChange(updatedPayment, name);

      // await paymentsSchema.validate(updatedPayment, { abortEarly: false })
      // setValidationErrors({})
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  const handleValueChange = async (updatedPayment, fieldName) => {
    setPayment((prevPayment) => ({
      ...prevPayment,
      ...updatedPayment,
      // accountId: defaultAccountId // Ensure accountId is included
    }));

    const schema = paymentsSchema;
    try {
      await validateForm2(
        updatedPayment,
        fieldName,
        setValidationErrors,
        schema
      );
    } catch (error) {
      console.error("Field validation error:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Validate the payment object
      const isValid = await validateForm(
        payment,
        setValidationErrors,
        paymentsSchema
      );

      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;
      if (id === undefined) {
        response = await createPayment(payment);
        handleResponse(response, "Payment created successfully!");
      } else {
        response = await updatePayment(payment);
        handleResponse(response, "Payment updated successfully!");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponse = (response, successMessage, shouldNavigate = true) => {
    window.scrollTo(0, 0);
    if (response && shouldNavigate) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } else if (response) {
      setSuccessMessage(successMessage); // Set success message without navigation
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
    setSuccessMessage("");
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleLicenseDateChange = (date) => {
    const formattedDate = date
      ? dayjs(date).startOf("day").add(18, "hours").toISOString()
      : dayjs().startOf("day").add(18, "hours").toISOString();

    setSelectedDate(date || dayjs().startOf("day").add(18, "hours"));
    handleFieldChange("billingDate", formattedDate);
  };

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null; // or any other fallback UI you want to show
  }

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title>{id ? "Update" : "Create"} Payment</Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Payment Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormDatepicker
            label="Billing Date"
            required
            value={selectedDate}
            onChange={handleLicenseDateChange}
            open={openInstallationDatePicker}
            onOpen={() => setOpenInstallationDatePicker(true)}
            onClose={() => setOpenInstallationDatePicker(false)}
            // error={!!validationErrors.billingDate}
            // errorMessage={validationErrors.billingDate}
          />
          <FormField
            label="VAT"
            required
            placeholder="Enter VAT percent"
            value={payment.VATPercent}
            onChange={(e) => handleFieldChange("VATPercent", e.target.value)}
            error={!!validationErrors.VATPercent}
            errorMessage={validationErrors.VATPercent}
          />
          <StyledInputControlContainer>
            <Typography variant="label">Late Fee amount</Typography>
            <Grid container alignItems="center">
              <Grid
                item
                xs="auto"
                sx={{
                  backgroundColor: "#f7f7f8",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                spacing={1}
              >
                {lateFee.map((option, optionIndex) => (
                  <Grid item key={optionIndex}>
                    <StyledCheckboxButton
                      item
                      variant={
                        selectedlateFeesType == option.name
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() =>
                        handleFieldChange("lateFeesType", option.value)
                      }
                    >
                      {option.name}
                    </StyledCheckboxButton>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={1}>
                {selectedlateFeesType === "Yes" && (
                  <DoubleArrowIconContainer>
                    <img src={iconMap["Arrow"]} alt="arrow" />
                  </DoubleArrowIconContainer>
                )}
              </Grid>
              <Grid item xs>
                {selectedlateFeesType === "Yes" && (
                  <StyledStartAdornmentTextfield
                    variant="outlined"
                    margin="normal"
                    type="number"
                    value={payment.lateFeeAmount || ""}
                    onChange={(e) => {
                      const value =
                        e.target.value === ""
                          ? null
                          : parseFloat(e.target.value);
                      handleFieldChange("lateFeeAmount", value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Grid className="startAdornmentContainer">
                            <Typography variant="endAdornment">
                              {/* <FormControl> */}
                              <Select
                                variant="standard"
                                disableUnderline
                                defaultValue="TZS"
                                placeholder="Filter"
                                value={payment.lateFeeCurrency}
                                onChange={(e) => {
                                  const value =
                                    e.target.value === ""
                                      ? null
                                      : parseFloat(e.target.value);
                                  handleFieldChange("lateFeeCurrency", value);
                                }}
                              >
                                {currencyOptions.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              {/* </FormControl> */}
                            </Typography>
                          </Grid>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
            {validationErrors.lateFeeAmount && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{
                    marginRight: "5px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors?.lateFeeAmount}
                </Typography>
              </div>
            )}
            {validationErrors.lateFeeCurrency && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{
                    marginRight: "5px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors?.lateFeeCurrency}
                </Typography>
              </div>
            )}
          </StyledInputControlContainer>
          <StyledInputControlContainer>
            <Typography variant="label">Amount *</Typography>
            <Grid container alignItems="center">
              <Grid
                item
                xs="auto"
                sx={{
                  backgroundColor: "#f7f7f8",
                  padding: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                spacing={1}
              >
                {amountType.map((option, optionIndex) => (
                  <Grid item key={optionIndex}>
                    <StyledCheckboxButton
                      item
                      variant={
                        selectedTaxType == option.name
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() =>
                        handleFieldChange("amountType", option.value)
                      }
                    >
                      {option.name}
                    </StyledCheckboxButton>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={1}>
                {selectedTaxType === "Yes" && (
                  <DoubleArrowIconContainer>
                    <img src={iconMap["Arrow"]} alt="arrow" />
                  </DoubleArrowIconContainer>
                )}
              </Grid>
              <Grid item xs>
                {selectedTaxType === "Yes" && (
                  <StyledStartAdornmentTextfield
                    variant="outlined"
                    margin="normal"
                    type="number"
                    value={payment.amount}
                    onChange={(e) => {
                      const value =
                        e.target.value === ""
                          ? null
                          : parseFloat(e.target.value);
                      handleFieldChange("amount", value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Grid className="startAdornmentContainer">
                            <Typography variant="endAdornment">
                              {/* <FormControl> */}
                              <Select
                                variant="standard"
                                disableUnderline
                                defaultValue="TZS"
                                placeholder="Filter"
                                value={payment.amountCurrency}
                                // onChange={(e) => updateCurrency(index, e.target.value)}
                                onChange={(e) =>
                                  handleFieldChange(
                                    "amountCurrency",
                                    e.target.value
                                  )
                                }
                              >
                                {currencyOptions.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              {/* </FormControl> */}
                            </Typography>
                          </Grid>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
            {validationErrors.amount && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{
                    marginRight: "5px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors?.amount}
                </Typography>
              </div>
            )}
            {validationErrors.amountCurrency && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{
                    marginRight: "5px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors?.amountCurrency}
                </Typography>
              </div>
            )}
          </StyledInputControlContainer>
          <FormOptionSelector
            label="Payment Mode"
            required
            options={paymentMode}
            selectedOption={payment?.paymentMode}
            handleOptionChange={(value) =>
              handleFieldChange("paymentMode", value)
            }
          />
          <FormFieldMultiline
            label="Description"
            placeholder="Enter description"
            value={payment.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
            error={!!validationErrors.description}
            errorMessage={validationErrors.description}
            multiline
            rows={4}
          />
        </StyledSectionRight>
      </StyledFormSection>
      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
