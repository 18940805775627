export const billingPlanModel = {
  name: '',
  description: '',
  color: '#ff0000', // Default color, can be changed as needed
  prices: [
    {
      pricingCycle: 'Monthly', // Default value based on the schema's default
      amount: null, // Initializing to 0 for numeric values
      currency: 'TZS' // Default value based on the schema's default
    }
  ],
  deviceCost: null, // Initializing to 0 for numeric values
  installationCost: null, // Initializing to 0 for numeric values
  VAT: null, // Initializing to 0 for numeric values
  servicesLimit: [
    {
      serviceId: '', // Initialize as empty string, should be set to an ObjectId in actual use
      limit: -1, // Initializing to -1 based on the schema
      value: false, // Initializing to false for boolean
      cost: null, // Initializing to 0 for numeric values
      type: '' // Initialize as empty string
    }
  ],
  createdBy: null, // Initialize as empty string
  updatedBy: null // Initialize as empty string
}
