/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// eslint-disable no-undef /
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import FormOptionSelector from './FormOptionSelector';
import FormSelect from './FormSelect';
import FormField from './FormField';
import { Country, State, City } from 'country-state-city';
import { Grid } from '@mui/material';
import { addressTypes } from '../../utils/constant';
import { fetchGeofencesByAccountId } from '../../services/geofencesNew.service';

const libraries = ['places', 'geometry', 'drawing'];

const FormAddress = ({
  address,
  addressKey,
  handleFieldChange,
  validationErrors
}) => {
  const [geofences, setGeofences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [count, setCount] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);

  const [autocompleteInstances, setAutocompleteInstances] = useState({});

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const onLoad = (autocomplete, key) => {
    setAutocompleteInstances((prevInstances) => ({
      ...prevInstances,
      [key]: autocomplete
    }));
  };

  useEffect(() => {
    _fetchGeofencesByAccountId();
    if (address.addressType == null) {
      setTimeout(() => {
        handleAddressFieldChange('addressType', addressTypes[0].value);
      }, 0);
    }
  }, []);

  const handleAddressFieldChange = (field, value) => {
    const updatedAddress = {
      ...address,
      [field]: value
    };

    const fieldKey = addressKey === "-1" ? field : `address[${addressKey}].${field}`;
    handleFieldChange(fieldKey, value);
    return updatedAddress;
  };

  const _fetchGeofencesByAccountId = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const data = await fetchGeofencesByAccountId(page, limit);
      setCount(data.count);
      setGeofences((prevGeofences) => [...prevGeofences, ...data.rows]);
      setIsEmpty(data.rows.length === 0);
    } catch (error) {
      const errorMessage = error?.message || 'An unknown error occurred';
      console.error('Error fetching geofences:', errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceChanged = () => {
    const autocomplete = autocompleteInstances[addressKey];
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const addressComponents = place.address_components;
        let ua = {
          addressType: "Address",
          streetOne: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
        };

        addressComponents.forEach(async (component) => {
          const types = component.types;

          if (types.includes('country')) {
            ua.country = component.short_name;
            if (addressKey == -1) {
              handleFieldChange("country", ua.country);
            }
            else {
              await handleAddressFieldChange('country', ua.country);
            }
          } else if (types.includes('route')) {
            ua.streetOne = `${ua.streetOne} ${component.long_name}`.trim();
            if (addressKey == -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('street_number')) {
            ua.streetOne = `${component.long_name} ${ua.streetOne}`.trim();
            if (addressKey == -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('sublocality_level_1')) {
            ua.streetOne = `${ua.streetOne}, ${component.long_name}`.trim();
            if (addressKey == -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('sublocality_level_2')) {
            ua.streetOne = `${ua.streetOne}, ${component.long_name}`.trim();
            if (addressKey == -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('sublocality_level_3')) {
            ua.streetOne = `${ua.streetOne}, ${component.long_name}`.trim();
            if (addressKey != -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('neighborhood')) {
            ua.streetOne = `${ua.streetOne}, ${component.long_name}`.trim();
            if (addressKey == -1) {

              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('premise')) {
            ua.streetOne = `${ua.streetOne}, ${component.long_name}`.trim();
            if (addressKey == -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('subpremise')) {
            ua.streetOne = `${ua.streetOne}, ${component.long_name}`.trim();
            if (addressKey == -1) {
              handleFieldChange("streetOne", ua.streetOne);
            }
            else {
              await handleAddressFieldChange('streetOne', ua.streetOne);
            }
          } else if (types.includes('administrative_area_level_1')) {
            ua.state = component.short_name;
            if (addressKey == -1) {
              handleFieldChange("state", ua.state);
            }
            else {
              await handleAddressFieldChange('state', ua.state);
            }

          } else if (types.includes('locality')) {
            ua.city = component.long_name;
            if (addressKey == -1) {

              handleFieldChange("city", ua.city);
            }
            else {
              await handleAddressFieldChange('city', ua.city);
            }
          } else if (types.includes('postal_code')) {
            ua.postalCode = component.long_name;
            if (addressKey == -1) {
              handleFieldChange("postalCode", ua.postalCode);
            }
            else {
              await handleAddressFieldChange('postalCode', ua.postalCode);
            }
          }
        });

      } else {
        console.error('No geometry found for selected place');
      }
    } else {
      console.error('Autocomplete is not initialized');
    }
  };


  const debouncedHandlePlaceChanged = debounce(handlePlaceChanged, 300);

  const getValidationError = (errors, field) => {
    for (const key in errors) {
      const keyParts = key.split('.');
      if (keyParts[keyParts.length - 1] === field) {
        return errors[key];
      }
    }
    return null;
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <FormOptionSelector label="Select Address Option"
        required
        options={addressTypes}
        selectedOption={address.addressType}
        handleOptionChange={(value) => handleAddressFieldChange('addressType', value)}
        error={!!getValidationError(validationErrors, 'addressType', addressKey)}
        errorMessage={getValidationError(validationErrors, 'addressType', addressKey)}
      />
      {address.addressType === 'Geofence' && (
        <FormSelect
          label="Choose Geofence"
          required
          value={address.geofenceId || ''}
          onChange={(selected) => { handleAddressFieldChange('geofenceId', selected); }}
          options={geofences.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          error={!!getValidationError(validationErrors, 'geofenceId', addressKey)}
          errorMessage={getValidationError(validationErrors, 'geofenceId', addressKey)}
        />
      )}
      {address.addressType === 'Address' && (
        <Grid>
          <Autocomplete onLoad={(autocomplete) => onLoad(autocomplete, addressKey)} onPlaceChanged={debouncedHandlePlaceChanged}>
            <FormField
              label="Street 1"
              required
              placeholder="Enter Street"
              value={address.streetOne}
              onChange={(e) => handleAddressFieldChange('streetOne', e.target.value)}
              error={!!getValidationError(validationErrors, 'streetOne', addressKey)}
              errorMessage={getValidationError(validationErrors, 'streetOne', addressKey)}
            />
          </Autocomplete>
          <FormField
            label="Street 2"
            placeholder="Enter Street"
            value={address.streetTwo}
            onChange={(e) => handleAddressFieldChange('streetTwo', e.target.value)}
            error={!!getValidationError(validationErrors, 'streetTwo', addressKey)}
            errorMessage={getValidationError(validationErrors, 'streetTwo', addressKey)}
          />
          <FormSelect
            label="Country"
            required
            value={address.country}
            onChange={(selected) => { handleAddressFieldChange('country', selected); }}
            options={Country.getAllCountries().map((country) => ({
              value: country.isoCode,
              label: country.name
            }))}
            error={!!getValidationError(validationErrors, 'country', addressKey)}
            errorMessage={getValidationError(validationErrors, 'country', addressKey)}
          />
          <FormSelect
            label="State"
            required
            value={address.state}
            onChange={(selected) => { handleAddressFieldChange('state', selected); }}
            options={State.getStatesOfCountry(address.country).map((state) => ({
              value: state.isoCode,
              label: state.name
            }))}
            error={!!getValidationError(validationErrors, 'state', addressKey)}
            errorMessage={getValidationError(validationErrors, 'state', addressKey)}
          />
          <FormSelect
            label="City"
            required
            value={address.city}
            onChange={(selected) => { handleAddressFieldChange('city', selected); }}
            options={
              address.state
                ? City.getCitiesOfState(address.country, address.state).map((city) => ({
                  value: city.name,
                  label: city.name
                }))
                : []
            }
            error={!!getValidationError(validationErrors, 'city', addressKey)}
            errorMessage={getValidationError(validationErrors, 'city', addressKey)}
          />
          <FormField
            label="Postal Code"
            required
            placeholder="Enter Postal Code"
            value={address.postalCode}
            onChange={(e) => handleAddressFieldChange('postalCode', e.target.value)}
            error={!!getValidationError(validationErrors, 'postalCode', addressKey)}
            errorMessage={getValidationError(validationErrors, 'postalCode', addressKey)}
          />
        </Grid>
      )}
    </>
  );
};

FormAddress.propTypes = {
  address: PropTypes.object.isRequired,
  addressKey: PropTypes.number.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired
};

export default FormAddress;

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}