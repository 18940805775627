/* eslint-disable react/prop-types */
import { CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for type-checking
import { iconMap } from "../../../../constants/iconsList";
import {
  StatusTypography,
  UnitMonitoringLable,
  UnitMonitoringUnitCard,
} from "../../../../styledComponents";
import UnitStatus from "./UnitStatusComponent";

// Helper function to determine motion status
const getMotionStatus = (speed, accStatus) => {
  // Convert speed to a number in case it's a string
  const numericSpeed = Number(speed);

  // Handle undefined or null values for accStatus
  if (accStatus === undefined || isNaN(numericSpeed)) {
    return "Data Not Available"; // Return a default message if any data is missing
  }

  // If speed is 0, check accStatus
  if (numericSpeed === 0) {
    if (accStatus === "ACC OFF") {
      return "Stationary (Ignition Off)";
    } else if (accStatus === "ACC ON") {
      return "Idle"; // Vehicle is stationary but ignition is on (idling)
    }
  } 
  // If speed is greater than 0, vehicle is moving
  else if (numericSpeed > 0) {
    return "Moving";
  }

  // Default case: stationary but with ignition on, not necessarily idling
  return "Stationary (Ignition On)";
};


const Units = ({ unitdata, handleUnitClick, selectedUnit }) => {

  const [onlineStatus, setOnlineStatus] = useState("Offline");
  const [a3Timeout, setA3Timeout] = useState(null);


  // Check if unitdata is present
  console.log("unitdata",unitdata)

 
  // Function to handle setting the status to offline
  const setOfflineStatus = () => {
    setOnlineStatus("Offline");
  };

  useEffect(() => {
    // Ensure unitdata and eLockDetails exist
  if (!unitdata.eLockDetails) return;
    const heartbeatPacket = unitdata.eLockDetails?.parsedPacket?.command === "b1";
    const dataPacket = unitdata.eLockDetails?.parsedPacket?.command === "a3";

    // Step 1: When a heartbeat (0xB1) packet is received, set status to "Online"
    if (heartbeatPacket) {
      setOnlineStatus("Online");

      // Clear any existing timeout to reset the wait for A3
      if (a3Timeout) {
        clearTimeout(a3Timeout);
      }

      // Step 3: Start a 60-second timeout to wait for an A3 packet
      const timeout = setTimeout(() => {
        setOfflineStatus(); // If no A3 packet, set device to Offline after 60 seconds
      }, 60000); // 60 seconds wait for A3

      setA3Timeout(timeout); // Store the timeout for future clearing
    }

    // Step 2: When an A3 packet is received, update the status and reset the timeout
    if (dataPacket) {
      console.log("A3 packet received");

      // Clear the existing timeout because we received new A3 data
      if (a3Timeout) {
        clearTimeout(a3Timeout);
      }

      // Set a fresh timeout to wait for the next A3 packet
      const timeout = setTimeout(() => {
        setOfflineStatus(); // If no A3 packet within 60 seconds, mark as Offline
      }, 60000); // 60 seconds

      setA3Timeout(timeout); // Store the timeout for future clearing

      // Ensure the device is marked as online since we got an A3 packet
      setOnlineStatus("Online");
    }
  },  [unitdata.eLockDetails]);


  const accStatus = unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus || {};
  const speed = unitdata.eLockDetails?.decodedResponse?.locationInfo?.speed || {};

// Determine motion status based on speed and accStatus
const motionStatus = getMotionStatus(speed, accStatus);
  // Determine if this unit is selected
  const isSelected = selectedUnit && selectedUnit.unitId === unitdata.unitId;

  // Assign the correct status icon based on the unit's status
  const statusIcon =
    unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus === "Open"
      ? iconMap["UNITOFFLINEICON"]
      : iconMap["UNITONLINEICON"];

  return (
    <div style={{ position: "relative", padding: "0px 20px" }}>
      <UnitMonitoringUnitCard
        selected={isSelected}
        onClick={() => handleUnitClick(unitdata)} // Ensure this function works properly
      >
        <CardContent>
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#0A2C47" }}
            >
              {unitdata.name}
            </Typography>
            <img
              src={statusIcon}
              alt="unit status icon"
              style={{ marginRight: "4px", width: "30px" }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                fontSize: "14px",
                color: onlineStatus === "Online" ? "green" : "red", // Conditional color
              }}
            >
              {onlineStatus}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontSize: "14px",
              color:
                unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus === "ACC ON"
                  ? "green"
                  : "red", // Conditional color
            }}
          >
            {unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus}
          </Typography>
        </Grid>

          <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} md={12}>
                <UnitMonitoringLable>Coordinates</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {unitdata.eLockDetails?.decodedResponse?.locationInfo?.latitude && unitdata.eLockDetails?.decodedResponse?.locationInfo?.longitude
                    ? `${unitdata.eLockDetails?.decodedResponse?.locationInfo?.latitude} ${unitdata.eLockDetails?.decodedResponse?.locationInfo?.longitude}`
                    : '--'}
                </Typography>
              </Grid>
            <Grid item xs={12} md={6}>
              <UnitMonitoringLable>Motion Status</UnitMonitoringLable>
              <StatusTypography status={motionStatus}>
                <img
                  src={
                     motionStatus === "Moving"
                      ? iconMap["MOVINGICON"]
                      : iconMap["STATIONERYICON"]
                  }
                  alt="motion icon"
                  style={{ marginRight: "4px", width: "12px" }}
                />
                {motionStatus}
              </StatusTypography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <UnitStatus unit={unitdata} />
            </Grid>
          </Grid>
        </CardContent>
      </UnitMonitoringUnitCard>
    </div>
  );
};

// Prop-types validation for Units component
Units.propTypes = {
  unitdata: PropTypes.shape({
    unitId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unitStatus: PropTypes.string.isRequired,
    motionStatus: PropTypes.string,
  }).isRequired,
  handleUnitClick: PropTypes.func.isRequired,
  selectedUnit: PropTypes.shape({
    unitId: PropTypes.string,
  }),
};

export default Units;
