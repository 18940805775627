/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledFormContainer,
  StyledInputControlContainer,
  Title,
} from "../../billingPlans/styles/BillingPlansStyles";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { RoleModel } from "../../../../model/roles.model";
import { iconMap } from "../../../../constants/iconsList";
import {
  StyledHeader2,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledCheckboxButton,
  NextButton,
} from "../../../../styledComponents";
import { rolesSchema } from "../../../../utils/validation-schemas/rolesSchema";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import { fetchAllServices } from "../../../../services/serviceNew.services";
import FormField from "../../../common/FormField";
import {
  createRole,
  getRoleById,
  updateRole,
} from "../../../../services/roleNew.service";
import Loader from "../../../common/Loader";
import { getRoute } from "../../../../constants/routes";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";

const checkboxTypes = [
  { name: "Access", value: true },
  { name: "No Access", value: false },
];

const RolesForm = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(99);
  const [role, setRole] = useState(RoleModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [existingUser, setExistingUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      _getRoleById(id);
      _fetchAllServices();
    } else {
      _fetchAllServices();
    }
  }, [id]);

  useEffect(() => {
    if (existingUser) {
      _fetchAllServices();
    }
  }, [existingUser]);

  const _getRoleById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getRoleById(id);
      setRole(data);
      setExistingUser(true);
      handleResponse(data, "Role fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = async (name, value) => {
    try {
      setRole((prevRole) => {
        const updatedRole = {
          ...prevRole,
          [name]: value,
        };
        return updatedRole;
      });

      await validateForm2(
        { ...role, [name]: value },
        name,
        setValidationErrors,
        rolesSchema
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleNavigate = () => {
    navigate("/management/roles");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const isValid = await validateForm(
        role,
        setValidationErrors,
        rolesSchema
      );

      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }
      let response;
      // Perform API call based on whether id is defined or not
      if (id === undefined) {
        response = await createRole(role);
        handleResponse(response, "Role created successfully!", "Card");
      } else {
        response = await updateRole(role);
        handleResponse(response, "Role updated successfully!", "Card");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchAllServices = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const data = await fetchAllServices(page, limit);

      // Create an array to hold the updated access rights
      const updatedAccessRights = [];

      // Loop through the data.rows to create access rights objects array
      data.rows.forEach((service) => {
        updatedAccessRights.push({
          serviceId: service.id,
          serviceName: service.name,
          serviceValue: false,
          add: false,
          edit: false,
          view: false,
          delete: false,
          type: service.type,
        });
      });
      setIsLoading(false);

      // Update the role state once after the loop
      if (id == undefined) {
        setRole((prevRole) => ({
          ...prevRole,
          accessRights: updatedAccessRights,
        }));
      } else {
        // Find missing access rights
        const missingAccessRights = updatedAccessRights.filter(
          (updatedAccessRight) =>
            !role.accessRights.some(
              (accessRight) =>
                accessRight.serviceId === updatedAccessRight.serviceId
            )
        );
        if (missingAccessRights.length > 0) {
          // If there are missing access rights, add them to the existing access rights
          setRole((prevRole) => ({
            ...prevRole,
            accessRights: [...role.accessRights, ...missingAccessRights],
          }));
          setIsLoading(false);
        } else {
          setRole((prevRole) => ({
            ...prevRole,
            accessRights: [...role.accessRights],
          }));
          setIsLoading(false);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleGeneralAccessChange = (option, service) => {
    const updatedAccessRights = role.accessRights?.map((accessRight) => {
      if (accessRight.serviceId === service.serviceId) {
        return { ...accessRight, serviceValue: option.value };
      }
      return accessRight;
    });

    setRole((role) => ({
      ...role,
      accessRights: updatedAccessRights,
    }));
  };

  const handleManagementAccessChange = (access, value, mode) => {
    const updatedAccessRights = role.accessRights?.map((accessRight) => {
      if (accessRight.serviceId === access.serviceId) {
        if (mode === "add") {
          return { ...accessRight, add: value };
        } else if (mode === "edit") {
          return { ...accessRight, edit: value };
        } else if (mode === "view") {
          return { ...accessRight, view: value };
        } else if (mode === "delete") {
          return { ...accessRight, delete: value };
        }
      }
      return accessRight;
    });

    setRole((role) => ({
      ...role,
      accessRights: updatedAccessRights,
    }));
  };

  const renderServiceItems = (serviceType) => {
    return (
      role &&
      role?.accessRights
        ?.filter((accessRight) => accessRight.type === serviceType)
        ?.map((accessRight2, index) => (
          <StyledInputControlContainer
            key={index}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {accessRight2.type === "General" && (
              <>
                <Typography variant="label" sx={{ width: "300px" }}>
                  {accessRight2.type === "General" && (
                    <img
                      src={iconMap[accessRight2.serviceName]}
                      alt="arrow"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  {accessRight2.serviceName}
                </Typography>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      backgroundColor: "#f7f7f8",
                      borderRadius: "8px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    // spacing={1}
                  >
                    <Grid container spacing={2}>
                      {checkboxTypes?.map((option, optionIndex) => (
                        <Grid item key={optionIndex}>
                          <StyledCheckboxButton
                            variant={
                              accessRight2.serviceValue === option.value
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleGeneralAccessChange(option, accessRight2)
                            }
                          >
                            {option.name}
                          </StyledCheckboxButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {(accessRight2.type === "Management" ||
              accessRight2.type === "Monitoring") && (
              <>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #D8D8DF",
                    padding: "20px 0px",
                    margin: "-10px",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={4}>
                    <Typography xs={4} variant="label">
                      {accessRight2.serviceName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      color="white"
                      checked={accessRight2.add === true}
                      sx={{
                        "& .MuiSwitch-track": {
                          backgroundColor:
                            accessRight2.add === true ? "#e1f0fd" : "#d8d8df",
                        },
                        "& .MuiSwitch-thumb": {
                          backgroundColor:
                            accessRight2.add === true ? "#2f70b0" : "white",
                        },
                      }}
                      value={accessRight2.add}
                      onChange={(event) =>
                        handleManagementAccessChange(
                          accessRight2,
                          event.target.checked,
                          "add"
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      color="white"
                      checked={accessRight2.edit === true}
                      sx={{
                        "& .MuiSwitch-track": {
                          backgroundColor:
                            accessRight2.edit === true ? "#e1f0fd" : "#d8d8df",
                        },
                        "& .MuiSwitch-thumb": {
                          backgroundColor:
                            accessRight2.edit === true ? "#2f70b0" : "white",
                        },
                      }}
                      value={accessRight2.edit}
                      onChange={(event) =>
                        handleManagementAccessChange(
                          accessRight2,
                          event.target.checked,
                          "edit"
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      color="white"
                      value={accessRight2.view}
                      checked={accessRight2.view === true}
                      sx={{
                        "& .MuiSwitch-track": {
                          backgroundColor:
                            accessRight2.view === true ? "#e1f0fd" : "#d8d8df",
                        },
                        "& .MuiSwitch-thumb": {
                          backgroundColor:
                            accessRight2.view === true ? "#2f70b0" : "white",
                        },
                      }}
                      onChange={(event) =>
                        handleManagementAccessChange(
                          accessRight2,
                          event.target.checked,
                          "view"
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      color="white"
                      value={accessRight2.delete}
                      checked={accessRight2.delete === true}
                      sx={{
                        "& .MuiSwitch-track": {
                          backgroundColor:
                            accessRight2.delete === true
                              ? "#e1f0fd"
                              : "#d8d8df",
                        },
                        "& .MuiSwitch-thumb": {
                          backgroundColor:
                            accessRight2.delete === true ? "#2f70b0" : "white",
                        },
                      }}
                      onChange={(event) =>
                        handleManagementAccessChange(
                          accessRight2,
                          event.target.checked,
                          "delete"
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </StyledInputControlContainer>
        ))
    );
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Role", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>
            {id ? "Update" : "Create"} Role
          </Title>
        </Grid>
      </StyledHeader2>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Role Name"
            required
            placeholder="Enter role name"
            value={role.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
        </StyledSectionRight>
      </StyledFormSection>
      <Box>
        <Grid container sx={{ marginTop: "25px" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "50px",
            }}
          >
            <Typography variant="heading">General</Typography>
          </Grid>
          <Grid item xs={12} md={7} sx={{ borderBottom: "1px solid #d8d8df" }}>
            {renderServiceItems("General")}
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "25px" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "50px",
            }}
          >
            <Typography variant="heading">Management</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f7f7f8",
                paddingTop: "6.5px",
                paddingBottom: "6.5px",
                marginBottom: "10px",
                color: "#5B5B6E",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              <Grid item xs={4}></Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["PlusIcon"]}
                  alt="PlusIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Add
              </Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["EditIcon"]}
                  alt="EditIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Edit
              </Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["EyeIcon"]}
                  alt="EyeIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                View
              </Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["UserflowDeleteIcon"]}
                  alt="UserflowDeleteIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Delete
              </Grid>
            </Grid>
            {renderServiceItems("Management")}
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "25px" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingRight: "50px",
            }}
          >
            <Typography variant="heading">Monitoring</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f7f7f8",
                paddingTop: "6.5px",
                paddingBottom: "6.5px",
                marginBottom: "10px",
                color: "#5B5B6E",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              <Grid item xs={4}></Grid>
              <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={iconMap["PlusIcon"]}
                  alt="PlusIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Add
              </Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["EditIcon"]}
                  alt="EditIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Edit
              </Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["EyeIcon"]}
                  alt="EyeIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                View
              </Grid>
              <Grid item container xs={2}>
                <img
                  src={iconMap["UserflowDeleteIcon"]}
                  alt="UserflowDeleteIcon"
                  style={{ marginRight: "8px", height: "16px" }}
                />
                Delete
              </Grid>
            </Grid>
            {renderServiceItems("Monitoring")}
          </Grid>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Grid
            sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
          >
            <Grid sx={{ mt: 2 }}>
              <NextButton onClick={handleSubmit}>SAVE</NextButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledFormContainer>
  );
};

export default RolesForm;
