import axios from "axios";
import { unitGroupsApi } from "../utils/constant";

// Create an instance of axios with default headers and base URL
const api = axios.create({
  baseURL: unitGroupsApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Fetch all unit groups with optional query parameters
export const fetchAllUnitGroups = async (queryParams) => {
  try {
    const response = await api.get("/", { params: queryParams });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a unit group by its ID
export const getUnitGroupById = async (unitGroupId) => {
  try {
    const response = await api.get(`/${unitGroupId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new unit group
export const createUnitGroup = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing unit group by its ID
export const updateUnitGroup = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a unit group by its ID
export const deleteUnitGroup = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch unit group by account id with pagination
export const fetchUnitGroupsByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitGroupsByAccountId", {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch unit group by name and account ID with pagination
export const fetchUnitGroupsByNameAndAccountId = async (name, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitGroupsByNameAndAccountId", {
      params: { name, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchUnitGroupsByMultipleCriteria = async (id, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUnitGroupsByMultipleCriteria", {
      params: {
        accountId,
        id,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
