import React from 'react'
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NavLink } from 'react-router-dom';
import { StyledButton } from '../../styledComponents';
import { getRoute } from '../../constants/routes';

const CreateButton = (props) => {
    return (
        <NavLink to={getRoute(props.name, props.mode)}>
        <StyledButton startIcon={<AddCircleOutlineIcon />}>
          CREATE NEW {props.name}
        </StyledButton>
    </NavLink>
    )
};

CreateButton.propTypes = {
    name: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired
  };
  
export default CreateButton;