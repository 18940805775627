export const validateForm = async (
  formData,
  setValidationErrors,
  validationSchema,
  // eslint-disable-next-line no-unused-vars
  context
) => {
  try {
    // , { abortEarly: false }
    await validationSchema.validate(formData, { context, abortEarly: false })
    setValidationErrors({})
    return true
  } catch (error) {
    const errors = {}
    error.inner.forEach((err) => {
      errors[err.path] = err.message
    })
    setValidationErrors(errors)
    console.log('Validation errors inside validateForm:', errors) // Log validation errors
    return false
  }
}

export const validateForm2 = async (
  formData,
  fieldName,
  setValidationErrors,
  validationSchema,
  context // Add context as a parameter
) => {
  try {
    await validationSchema.validateAt(fieldName, formData, { context })
    setValidationErrors((prevErrors) => {
      // eslint-disable-next-line no-unused-vars
      const { [fieldName]: removedError, ...restErrors } = prevErrors
      return restErrors
    })
    return true
  } catch (error) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error.message
    }))
    return false
  }
}

export const validateField = async (
  fieldName,
  fieldValue,
  setValidationErrors,
  validationSchema,
  fullValues
) => {
  try {
    const fieldParts = fieldName.split('.')
    let fieldSchema = validationSchema

    for (const part of fieldParts) {
      if (part.includes('[') && part.includes(']')) {
        let arrayName = part.split('[')[0]
        fieldSchema = fieldSchema?.fields?.[arrayName]?.innerType
        if (!fieldSchema) {
          throw new Error(`Invalid field name: ${fieldName}`)
        }
      } else {
        fieldSchema = fieldSchema?.fields?.[part]
        if (!fieldSchema) {
          throw new Error(`Invalid field name: ${fieldName}`)
        }
      }
    }

    await fieldSchema.validate(fieldValue, {
      abortEarly: false,
      context: fullValues
    })
    setValidationErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null }))
    return true
  } catch (error) {
    if (error.name === 'ValidationError' && error.errors) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]:
          error.errors.length > 0 ? error.errors[0] : 'Validation error'
      }))
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: 'An unexpected error occurred during validation'
      }))
    }
    return false
  }
}
