import axios from 'axios'
import { consignmentApi } from '../utils/constant'

const api = axios.create({
  baseURL: consignmentApi,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem('access_token'))
    if (access_token) {
      config.headers.Authorization = `${access_token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data)
    throw error.response.data
  } else if (error.request) {
    console.error('No response received:', error.request)
    throw new Error('No response received from server.')
  } else {
    console.error('Request setup error:', error.message)
    throw new Error('Request setup error.')
  }
}

// Fetch all consignments with optional query parameters
export const fetchAllConsignments = async (queryParams) => {
  try {
    const response = await api.get('/', { params: queryParams })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Get a consignment by its ID
export const getConsignmentById = async (consignmentId) => {
  try {
    const response = await api.get(`/${consignmentId}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Create a new consignment
export const createConsignment = async (data) => {
  // alert(data.billingInformation.paymentTermValue)
  //   console.log(typeof(data.billingInformation.paymentTermValue))
  try {
    const response = await api.post('/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// export const createConsignment = async (data) => {
//   try {
//     alert(data.billingInformation.paymentTermValue)
//     console.log(typeof(data.billingInformation.paymentTermValue))
//     const response = await api.post('/', data)
//     return response.data
//   } catch (error) {
//     handleApiError(error)
//   }
// }

// Update an existing consignment by its ID
export const updateConsignment = async (data) => {
  try {
    const response = await api.put(`/${data.get('id')}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Delete a consignment by its ID
export const deleteConsignment = async (id) => {
  try {
    const response = await api.delete(`/${id}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch units by consignment id with pagination
export const fetchConsignmentsByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get('/fetchConsignmentsByAccountId', {
      params: { accountId, page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch units by consignment id with pagination
export const fetchConsignmentsByDriverId = async (driverId, page, limit) => {
  try {
    const response = await api.get('/fetchConsignmentsByDriverId', {
      params: { driverId, page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by customer name and account ID with pagination
export const fetchConsignmentsByCustomerNameAndAccountId = async (
  customerName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get(
      '/fetchConsignmentsByCustomerNameAndAccountId',
      {
        params: { customerName, accountId, page, limit }
      }
    )
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by customer name and account ID with pagination
export const fetchConsignmentsByDriverIdAndAccountIdAndCustomerName = async (
  driverId,
  customerName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get(
      '/fetchConsignmentsByDriverIdAndAccountIdAndCustomerName',
      {
        params: { driverId, accountId, customerName, page, limit }
      }
    )
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by unit name and account ID with pagination
export const fetchConsignmentsByUnitNameAndAccountId = async (
  unitName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get('/fetchConsignmentsByUnitNameAndAccountId', {
      params: { unitName, accountId, page, limit }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by unit name and account ID with pagination
export const fetchConsignmentsByDriverIdAndAccountIdAndUnitName = async (
  driverId,
  unitName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get(
      '/fetchConsignmentsByDriverIdAndAccountIdAndUnitName',
      {
        params: { driverId, accountId, unitName, page, limit }
      }
    )
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by driver name and account ID with pagination
export const fetchConsignmentsByDriverNameAndAccountId = async (
  driverName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get(
      '/fetchConsignmentsByDriverNameAndAccountId',
      {
        params: { driverName, accountId, page, limit }
      }
    )
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by trip status and account ID with pagination
export const fetchConsignmentsByAccountIdAndTripStatus = async (
  tripStatus,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get(
      '/fetchConsignmentsByAccountIdAndTripStatus',
      {
        params: { accountId, tripStatus, page, limit }
      }
    )
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

// Fetch consignment by trip status and account ID with pagination
export const fetchConsignmentsByDriverIdAndAccountIdAndTripStatus = async (
  driverId,
  tripStatus,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get(
      '/fetchConsignmentsByDriverIdAndAccountIdAndTripStatus',
      {
        params: { driverId, accountId, tripStatus, page, limit }
      }
    )
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

export const fetchConsignmentsByMultipleCriteria = async (
  customerId,
  unitId,
  driverId,
  tripStatus,
  startDate,
  endDate,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))
  try {
    const response = await api.get('/fetchConsignmentsByMultipleCriteria', {
      params: {
        accountId,
        customerId,
        unitId,
        driverId,
        tripStatus,
        startDate,
        endDate,
        page,
        limit
      }
    })
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}
