/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import EditTabs from '../../../../layouts/accounts/editTabs'
import { fetchAllUnitGroups } from '../../../../services/unitGroupNew.service'
import { useParams } from 'react-router-dom'
import { fetchUnitsByAccountId } from '../../../../services/unitNew.service'
import { UnitGroupModel } from '../../../../model/unitGroups.model'

export default function AccountsUnitGroupsCreate() {
  const { tab } = useParams()
  // const { id, tab } = useParams();
  const [unitGroupData, setUnitGroupData] = useState([UnitGroupModel])
  const [unitData, setUnitData] = useState([])
  const [page, setPage] = useState(1)
  const [limit] = useState(15)
  var { id } = useParams()

  // const [errorMessage, setErrorMessage] = useState("");
  console.log(tab)
  useEffect(() => {
    const _fetchAllUnitGroups = async () => {
      try {
        const data = await fetchAllUnitGroups(page, limit)
        setUnitGroupData(data.rows)
        console.log(data.rows)
      } catch (error) {
        if (error.response.data) {
          // setErrorMessage(error.response.data.message)
        } else {
          // setErrorMessage(error.message)
        }
      }
    }
    const _fetchUnitsByAccountId = async () => {
      try {
        const data = await fetchUnitsByAccountId(page, limit)
        setUnitData(data.rows)
        console.log(data.rows)
      } catch (error) {
        if (error.response.data) {
          // setErrorMessage(error.response.data.message)
        } else {
          // setErrorMessage(error.message)
        }
      }
    }
    if (tab === 'unitsGroup') {
      _fetchAllUnitGroups()
      _fetchUnitsByAccountId()
    }
  }, [])

  return (
    <div style={{ marginTop: '38px' }}>
      <EditTabs
        name="Unit Group"
        navigate="/management/unit-groups/create"
        items={unitGroupData}
        unitData={unitData}
      />
    </div>
  )
}
