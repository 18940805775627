/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowModes,
} from '@mui/x-data-grid';
import { createMaintenanceChecklist, deleteMaintenanceChecklist, fetchAllMaintenanceChecklists, updateMaintenanceChecklist } from "../../../../services/maintenanceChecklist.service";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import FormField from "../../../common/FormField";
import { validateForm2 } from "../../../../utils/yupValidations";
import Loader from "../../../common/Loader";
import DeleteModal from "../../../modals/DeleteModal";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const styles = {
  nextButton: {
    width: "140px",
    height: "44px",
    padding: "16px 24px",
    backgroundColor: "#0772b8",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "600",
    borderRadius: "8px",
    boxShadow:
      " 0 1.6px 3.6px 0 rgba(38, 54, 0, 0.11), 0 0.3px 0.9px 0 rgba(38, 54, 0, 0.07)",
    gap: "8px",
  },
};

const checkListSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name cannot exceed 50 characters")
    .required("Name is required"),
});

function EditToolbar({ setRows, setRowModesModel, handleAdd }) {
  const [checklist, setCheckList] = useState({ name: '' });
  const defaultAccountId = localStorage.getItem("defaultAccountId") ? JSON.parse(localStorage.getItem("defaultAccountId")) : "";
  const [validationErrors, setValidationErrors] = useState({});

  const handleFieldChange = async (name, value) => {
    try {
      // Update the checkList state based on the field name
      setCheckList((prevCheckList) => {
        const updatedCheckList = { ...prevCheckList, [name]: value };
        return updatedCheckList; // Return the updated checkList object
      });
  
      // Validate the form field using validateForm2
      await validateForm2(
        { ...checklist, [name]: value }, // Pass updated value for validation
        name,
        setValidationErrors,
        checkListSchema
      );
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  const handleClick = async () => {
    const newCheckList = { ...checklist, accountId: defaultAccountId };

    try {
      await checkListSchema.validate(newCheckList, { abortEarly: false });
      handleAdd(newCheckList);
      setCheckList({ name: '' });
      setValidationErrors({});
    } catch (error) {
      if (error.inner) {
        const errors = error.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setValidationErrors(errors);
      }
    }
  };

  return (
    <GridToolbarContainer sx={{padding: '10px'}}>
      <Grid item container xs={12} md={12}>
        <Grid item xs={12} md={8} gap={3} sx={{display: 'flex', flexDirection: 'row'}}>
        <FormField
            label="Name"
            required
            placeholder="Enter name"
            value={checklist.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{display: 'flex', justifyContent: 'end', position: 'relative', top: '30px'}}>
          <Button sx={styles.nextButton} variant="contained" onClick={handleClick}>
            Save
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default function CheckListForm() {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState('')
  const [errors, setErrors] = useState([])
  const [validationErrors, setValidationErrors] = useState({})
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [checkListData, setCheckListData] = useState([]);
  const defaultAccountId = localStorage.getItem("defaultAccountId") ? JSON.parse(localStorage.getItem("defaultAccountId")) : "";
  const [updatedRow, setUpdatedRow] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    const _fetchAllMaintenanceChecklists = async () => {
      try {
        const data = await fetchAllMaintenanceChecklists();
        setCheckListData(data.rows);
      } catch (error) {
        console.error("Error fetching vehicle types:", error);
      } finally {
        setIsLoading(false);
      }
    };
    _fetchAllMaintenanceChecklists();
  }, []);

  const handleSubmit = async (newCheckList, id) => {
    try {
      let response;
      if (id) {
        response = await updateMaintenanceChecklist(id, newCheckList);
        handleResponse(response, 'Maintenance Checklist updated successfully!',)
        if (response) {
          setRows((oldRows) =>
            oldRows.map((row) => (row.id === id ? { ...row, ...newCheckList } : row))
          );
        }
      } else {
        response = await createMaintenanceChecklist(newCheckList);
        handleResponse(response, 'Maintenance Checklist created successfully!',)
        if (response) {
          setRows((oldRows) => [
            ...oldRows,
            { id: response.id, ...newCheckList },
          ]);
        }
      }
    } catch (error) {
      handleError(error)
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }
  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  const handleAdd = (newCheckList) => {
    handleSubmit({ ...newCheckList, accountId: defaultAccountId });
  };

  useEffect(() => {
    setRows(checkListData);
  }, [checkListData]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === 'rowFocusOut') {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const row = rows.find((row) => row.id === id);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => async () => {
    const updatedRow = rows.find((row) => row.id === id);
    if (updatedRow) {
      setRowModesModel((prev) => ({ ...prev, [id]: { mode: GridRowModes.View } }));
      await handleSubmit(updatedRow, id);
    }
  };

  // const [updatedRow, setUpdatedRow] = useState(null);

  useEffect(() => {
    if (updatedRow && updatedRow.id) {
      handleSubmit(updatedRow, updatedRow.id);
    }
  }, [updatedRow]);

  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
    };
    setUpdatedRow(updatedRow)
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleDeleteClick = (id) => () => {
    const selectedRow = rows.find((row) => row.id === id);
    setSelectedRow(selectedRow);
    setDeleteId(id);
    setOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteMaintenanceChecklist(deleteId);
      if (response) {
        setRows((oldRows) => oldRows.filter((row) => row.id !== deleteId));
      }
    } catch (error) {
      console.error("Error deleting vehicle type:", error);
    }
    setOpenDeleteModal(false);
    setDeleteId(null);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      setRows((prev) => prev.filter((row) => row.id !== id));
    }
  };

    // Function to close the modal on click of "Delete Account" in the menu options
    const handleDeleteModalClose = useCallback(() => {
      setOpen(false);
      setSelectedAccount(null);
    }, [setSelectedAccount, setOpen]);
  
    // Function that calls the API to perform deleteAccount
    const deleteAccount = useCallback(() => {
      setOpen(false);
      setSelectedAccount(null);
    }, [setOpen, setSelectedAccount]);

  const columns = [
    {
      field: 'name',
      headerName: 'Checklist Name',
      flex: 1,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
            key={`save-${id}`}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
            key={`cancel-${id}`}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
          key={`edit-${id}`}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
          key={`delete-${id}`}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }


  return (
    <>
      <SuccessMessage message={successMessage} onClose={handleClose} />
      <ErrorMessage1 errors={errors} onClose={handleClose} />
     {isLoading ? (<Loader />) :(
    <Box>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '16px',
            fontWeight: 600,
            color: '#0A2C47',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
            color: '#0A2C47',
          },
          '& .MuiDataGrid-cell--editing': {
            fontSize: '14px',
            color: '#0A2C47',
            '& .MuiInputBase-root': {
              fontSize: '14px',
              color: '#0A2C47',
            },
          },
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, handleAdd },
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[10, 25, 50]}
      />
      {/* <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Confirm Deletion
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this checklist?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={confirmDelete} color="error" variant="contained">
              Delete
            </Button>
            <Button onClick={() => setOpenDeleteModal(false)} variant="contained">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal> */}
      {/* <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px'
            }}
            id="child-modal-title"
          >
            Delete?
          </Typography>
          <Box sx={{ marginBottom: '15px' }}>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: 'center',
                flexDirection: 'column'
              }}
            >
              Are you sure you want to delete checklist{' '}
              <strong>{updatedRow?.name}</strong>?
            </Typography>
          </Box>
          <hr />
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              onClick={confirmDelete}
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                backgroundColor: '#e71111',
                color: '#ffffff',
                margin: '10px 0px 0px 0px',
                '&:hover': {
                  backgroundColor: '#e71111'
                }
              }}
            >
              YES, DELETE
            </Button>
            <Button
              onClick={() => setOpenDeleteModal(false)}
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                color: '#6c6c81',
                border: '1px solid #d8d8df',
                marginTop: '10px'
              }}
            >
              CANCEL
            </Button>
          </Grid>
        </Box>
      </Modal> */}
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedAccount={selectedRow}
        deleteAccount={deleteAccount}
      />
    </Box>
     )}
    </>
  );
}
