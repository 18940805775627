export const PaymentModel = {
  id: '',
  accountId: '',
  billingDate: '',
  amountCurrency: 'TZS', // Default value set to 'TZS'
  amount: null, // Minimum value is 1, so you may want to initialize this with a valid amount
  VATPercent: null,
  lateFeeCurrency: 'TZS', // Default value set to 'TZS'
  lateFeeAmount: null,
  description: '',
  paymentMode: 'Cash', // Default value set to 'Cash'
  createdBy: '',
  updatedBy: ''
}
