import { Card } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledTitle = styled(Typography)`
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-size: 26px !important;
  line-height: 28.6px;
  letter-spacing: -0.52px;
  text-transform: capitalize;
  color: #0a2c47;
`;

export const StyledSubtitle = styled.p`
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
  color: #6c6c81;
`;

export const StyledCard = styled(Card)`
  cursor: pointer;
  position: relative;
  height: 392px;
  transition: border-left 0.3s ease, background-color 0.3s ease;
  border-left: 4px solid transparent;

  &:hover {
    border-left: 4px solid ${(props) => props.hovercolor};
    background-color: ${(props) => props.hovercolor}0a;
  }
`;

export const EllipseContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 225px;
  height: 225px;
  background-color: ${(props) => props.ellipsecolor};
  border-top-left-radius: 100%;
  display: flex;
  justify-content: right;
  align-items: end;
  flex-shrink: 0;
  padding:24px;
  transition: background-color 0.3s ease;
`;
