import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { StyledExportButton, StyledIconButton2 } from '../../../src/styledComponents';
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

// Import your icons
import downloadArrowIcon from '../../assets/icons/download-arrow1.svg';
import caretDownIcon from '../../assets/icons/caret-down.svg';

const ExportButton = (props) => {
  const handleExport = () => {
    const currentDate = new Date();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const fileName = `${props.name}-${currentDate.getDate()}-${monthNames[currentDate.getMonth()]}-${currentDate.getFullYear()}-${currentDate.getHours()}Hrs${currentDate.getMinutes()}Mins.xlsx`;
    const xls = formatExcel(props.accounts)
    saveAs(xls, fileName);
  };

    const formatExcel = (data) => {
    // Flatten servicesLimit and prices arrays into strings
    const formattedData = data.map((item) => {
      let formattedItem = { ...item }

      if (props.name === 'Billing Plans') {
        formattedItem = {
          ...formattedItem,
          servicesLimit: item.servicesLimit
            .map((service) => `${service.serviceName}: ${service.limit}`)
            .join(', '),
          prices: item.prices
            .map(
              (price) =>
                `${price.pricingCycle}: ${price.amount} ${price.currency}`
            )
            .join(', ')
        }
      }
      return formattedItem
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    return new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
  }

  return (
    <StyledExportButton item onClick={handleExport}>
      <Tooltip title="Export">
        <StyledIconButton2>
          <img src={downloadArrowIcon} alt="Download Arrow" />
        </StyledIconButton2>
      </Tooltip> EXPORT
      <Tooltip title="Caret Down">
        <StyledIconButton2>
          <img src={caretDownIcon} alt="Caret Down" />
        </StyledIconButton2>
      </Tooltip>
    </StyledExportButton>
  );
};

ExportButton.propTypes = {
  name: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
};

export default ExportButton;