import React  from 'react';
import PropTypes from 'prop-types';
import { StyledFileLinkContainer, StyledIcon , StyledLink} from '../../styledComponents';

const FileLink = ({ href, fileName }) => (
  <StyledFileLinkContainer>
    <StyledIcon />
    <StyledLink href={href} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }} target="_blank" rel="noopener noreferrer">
      {fileName}
    </StyledLink>
  </StyledFileLinkContainer>
);

FileLink.propTypes = {
  href: PropTypes.string.isRequired, // href should be a string and is required
  fileName: PropTypes.string.isRequired, // fileName should be a string and is required
};

export default FileLink;