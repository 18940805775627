/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Typography } from '@mui/material'
import 'react-phone-number-input/style.css'
import { ServiceModel } from '../../../../model/services.model'
import { useNavigate, useParams } from 'react-router-dom'
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  NextButton
} from '../../../../styledComponents.js'
import FormOptionSelector from '../../../common/FormOptionSelector.js'
import FormField from '../../../common/FormField.js'
import { validateForm, validateForm2 } from '../../../../utils/yupValidations'
import { servicesSchema } from '../../../../utils/validation-schemas/servicesSchema'
import {
  createService,
  getServiceById,
  updateService
} from '../../../../services/serviceNew.services.js'
import {
  fieldTypeCheckboxTypes,
  frequencyTypeCheckboxTypes,
  serviceCheckboxTypes
} from '../../../../utils/constant.js'
import FormFieldWithEndAdornment from '../../../common/FormFieldWithEndAdornment'
import Loader from '../../../common/Loader.js'
import { getRoute } from '../../../../constants/routes.js'
import ErrorMessage1 from '../../../common/ErrorMessage1.js'
import SuccessMessage from '../../../common/SuccessMessage.js'
import { iconMap } from '../../../../constants/iconsList.js'

export const ServicesForm = () => {
  const { id } = useParams()
  const [errors, setErrors] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [service, setService] = useState(ServiceModel)
  const [validationErrors, setValidationErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      _getServiceById(id)
    }
  }, [id])

  const _getServiceById = async (id) => {
    setIsLoading(true)
    try {
      const data = await getServiceById(id)
      setService(data)
      handleResponse(data, 'Service fetched successfully!', 'Get')
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFieldChange = async (name, value) => {
    try {
      setService((prevService) => {
        const updatedService = { 
          ...prevService, 
          [name]: value 
        };
        return updatedService;
      });
  
      await validateForm2(
        { ...service, [name]: value },
        name,
        setValidationErrors,
        servicesSchema
      );
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const isValid = await validateForm(
        service,
        setValidationErrors,
        servicesSchema
      )
      if (!isValid) {
        console.error('Form validation failed')
        setIsLoading(false)
        return
      }
      let response
      // Perform API call based on whether id is defined or not
      if (id === undefined) {
        response = await createService(service)
        handleResponse(response, 'Service created successfully!', 'Card')
      } else {
        response = await updateService(service)
        handleResponse(response, 'Service updated successfully!', 'Card')
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
      setTimeout(() => {
        if (mode === 'Card') {
          navigate(getRoute('Service', mode))
        }
      }, 3000) // Closing parentheses and braces for setTimeout
    }
  }

  const handleError = (error) => {
    window.scrollTo(0, 0)
    if (error.name === 'ValidationError') {
      handleValidationErrors(error)
    } else {
      setErrors(error.error)
    }
  }

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message
      return acc
    }, {})
    setValidationErrors(errors)
  }

  const handleClose = () => {
    setErrors([])
    setSuccessMessage('')
  }
  
  const handleBackClick = () => {
    navigate(-1);
    };

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title item><IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>{id ? 'Update' : 'Create'} Service</Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Service Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormOptionSelector
            label="Service Type"
            required
            options={serviceCheckboxTypes}
            selectedOption={service.type}
            handleOptionChange={(value) => handleFieldChange('type', value)}
            error={!!validationErrors.type}
            errorMessage={validationErrors.type}
          />
          <FormField
            label="Service Name"
            required
            placeholder="Enter service name"
            value={service.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormOptionSelector
            label="Choose Field Type"
            required
            options={fieldTypeCheckboxTypes}
            selectedOption={service.fieldType}
            handleOptionChange={(value) =>
              handleFieldChange('fieldType', value)
            }
            error={!!validationErrors.fieldType}
            errorMessage={validationErrors.fieldType}
          />
          {service.fieldType === 'Frequency' && (
            <FormOptionSelector
              label="Choose Frequency Type"
              required
              options={frequencyTypeCheckboxTypes}
              selectedOption={service.frequency}
              handleOptionChange={(value) =>
                handleFieldChange('frequency', value)
              }
              error={!!validationErrors.frequency}
              errorMessage={validationErrors.frequency}
            />
          )}
          {service.fieldType === 'Days' && (
            <FormFieldWithEndAdornment
              value={service.days || ''}
              onChange={(e) => {
                const value =
                  e.target.value === '' ? null : parseFloat(e.target.value)
                handleFieldChange('days', value)
              }}
              type="number"
              error={!!validationErrors?.days}
              errorMessage={validationErrors?.days}
              endAdornmentText="days"
            />
          )}
          {service.fieldType === 'MB' && (
            <FormFieldWithEndAdornment
              value={service.MB || ''}
              onChange={(e) => {
                const value =
                  e.target.value === '' ? null : parseFloat(e.target.value)
                handleFieldChange('MB', value)
              }}
              type="number"
              error={!!validationErrors?.MB}
              errorMessage={validationErrors?.MB}
              endAdornmentText="MB"
            />
          )}
        </StyledSectionRight>
      </StyledFormSection>
      <Grid
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  )
}

export default ServicesForm
