import axios from 'axios'
import { notificationApi } from '../utils/constant'

const api = axios.create({
  baseURL: notificationApi,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem('access_token'))
    if (access_token) {
      config.headers.Authorization = `${access_token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('API Error:', error.response.data)
    throw error.response.data
  } else if (error.request) {
    // The request was made but no response was received
    console.error('No response received:', error.request)
    throw new Error('No response received from server.')
  } else {
    // Something happened in setting up the request that triggered an error
    console.error('Request setup error:', error.message)
    throw new Error('Request setup error.')
  }
}

const fetchNotification = async () => {
  try {
    const response = await api.get('/?sort=-createdAt&limit=50')
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

const fetchNotificationById = async (id) => {
  try {
    const response = await api.get(`/${id}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

const fetchNotificationByDate = async (date) => {
  try {
    const response = await api.get(`/findByDate?date=${date}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

const createNotification = async (notificationData) => {
  var body = {
    title: notificationData.notification.title,
    body: notificationData.notification.body
  }
  try {
    const response = await api.post('/', body)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

const updateNotification = async (id, notificationData) => {
  try {
    const response = await api.put(`/${id}`, notificationData)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

const deleteNotification = async (id) => {
  console.log(`deleting ${id})`)
  try {
    const response = await api.delete(`/${id}`)
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

const handleApiResponse = async (promise) => {
  try {
    const response = await promise
    return response.data
  } catch (error) {
    handleApiError(error)
  }
}

export {
  fetchNotification,
  fetchNotificationById,
  fetchNotificationByDate,
  createNotification,
  updateNotification,
  deleteNotification,
  handleApiResponse
}
