/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchAllBillingPlans } from "../../../../services/billingPlanNew.service";
import FilterListIcon from "@mui/icons-material/FilterList";
import { fetchAllAccounts } from "../../../../services/accountNew.service";

const BillingPlanFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
    accountId: "",
  });
  const [billingPlans, setBillingPlans] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingBillingPlans, setIsLoadingBillingPlans] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchAllBillingPlans();
    _fetchAllAccounts();
  }, [page, limit]);

  const _fetchAllBillingPlans = async () => {
    if (isLoadingBillingPlans) return; // Prevent multiple calls
    setIsLoadingBillingPlans(true);

    try {
      let allBillingPlans = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllBillingPlans(currentPage, limit);
        allBillingPlans = [...allBillingPlans, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setBillingPlans(allBillingPlans);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching billingPlans:", errorMessage);
    } finally {
      setIsLoadingBillingPlans(false);
    }
  };

  const _fetchAllAccounts = async () => {
    if (isLoadingAccounts) return; // Prevent multiple calls
    setIsLoadingAccounts(true);

    try {
      let allAccounts = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllAccounts(currentPage, limit);
        allAccounts = [...allAccounts, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setAccounts(allAccounts);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching accounts:", errorMessage);
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={billingPlans.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Accounts"
            value={filters.accountId}
            onChange={(selected) => {
              handleFieldChange("accountId", selected);
            }}
            options={accounts.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};

BillingPlanFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default BillingPlanFilters;
