import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { iconMap } from '../../../../constants/iconsList';
import { documentTypeOptions, otherDocumentTypeOptions } from '../../../../utils/constant';
import {
  StyledTable2,
  StyledTableBody2,
  StyledTableHead2,
  StyledTableRow2,
  StyledUploadContainer,
  StyledUploadIconContainer,
  StyledUploadText,
  StyledTableCell2,
  StyledSwitch2,
  StyledFormContainer1,
  StyledFormSection1,
  StyledSectionHeader,
} from '../../../../styledComponents';
import FormSelect from '../../../common/FormSelect';
import FileLink from '../../../common/FileLink';

// Helper function to initialize documents with default types
const initializeDocuments = (docs, defaultType) =>
  docs.map((doc) => ({
    ...doc,
    documentType: doc.documentType || defaultType,
  }));

const ConsignmentDocumentsForm = ({
  consignment,
  onFormValueChange,
  // eslint-disable-next-line no-unused-vars
  validationErrors,
}) => {
  const [documents, setDocuments] = useState({
    ...consignment,
    insuranceAndCustomsDocuments: initializeDocuments(
      consignment.insuranceAndCustomsDocuments,
      'Insurance'
    ),
    otherDocuments: initializeDocuments(consignment.otherDocuments, 'License'),
  });

  useEffect(() => {
    setDocuments({
      ...consignment,
      insuranceAndCustomsDocuments: initializeDocuments(
        consignment.insuranceAndCustomsDocuments,
        'Insurance'
      ),
      otherDocuments: initializeDocuments(consignment.otherDocuments, 'License'),
    });
  }, [consignment]);

  // Handle file drop and add to the appropriate document type
  const handleFileDrop = (docType) => (acceptedFiles) => {
    const defaultDocumentType = docType === 'otherDocuments' ? 'License' : 'Insurance';
    const newFiles = acceptedFiles.map((file) => ({
      file,
      fileURL: '',
      documentType: defaultDocumentType,
      driverView: false,
      customerView: false,
    }));

    const updatedDocuments = {
      ...documents,
      [docType]: [...documents[docType], ...newFiles],
    };

    setDocuments(updatedDocuments);
    onFormValueChange(updatedDocuments, '', '', 2);
  };

  // Handle file removal by filtering out the deleted file
  const handleRemoveFile = (docType, index) => {
    const updatedDocuments = documents[docType].filter((_, i) => i !== index);

    const newDocuments = { ...documents, [docType]: updatedDocuments };
    setDocuments(newDocuments);
    onFormValueChange(newDocuments, '', '', 2);
  };

  // Handle individual field changes within a document
  const handleFieldChange = (docType, index, fieldName, value) => {
    const updatedDocuments = documents[docType].map((doc, i) =>
      i === index ? { ...doc, [fieldName]: value } : doc
    );

    const newDocuments = { ...documents, [docType]: updatedDocuments };
    setDocuments(newDocuments);
    onFormValueChange(newDocuments, '', '', 2);
  };

  // Render the documents table for each document type
  const renderDocumentTable = (docType, docTypeOptions, defaultType) => (
    <StyledTable2>
      <StyledTableHead2>
        <StyledTableRow2>
          <StyledTableCell2>ATTACHMENTS</StyledTableCell2>
          <StyledTableCell2>DOCUMENT TYPE</StyledTableCell2>
          <StyledTableCell2>DRIVER</StyledTableCell2>
          <StyledTableCell2>CUSTOMER</StyledTableCell2>
          <StyledTableCell2>ACTION</StyledTableCell2>
        </StyledTableRow2>
      </StyledTableHead2>
      <StyledTableBody2>
        {documents[docType].map(
          (doc, index) =>
            !doc.isDelete && (doc.fileURL || doc.file) && (
              <StyledTableRow2 key={index}>
                <StyledTableCell2>
                  <FileLink
                    href={doc.fileURL || doc.file.name}
                    fileName={doc.fileURL || doc.file.name}
                  />
                </StyledTableCell2>
                <StyledTableCell2>
                  <FormSelect
                    sx={{ marginBottom: '0px', marginTop: '-5px' }}
                    value={doc.documentType || defaultType}
                    onChange={(e) =>
                      handleFieldChange(docType, index, 'documentType', e)
                    }
                    options={docTypeOptions.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                  />
                </StyledTableCell2>
                <StyledTableCell2 align="center">
                  <StyledSwitch2
                    checked={doc.driverView}
                    onChange={(e) =>
                      handleFieldChange(docType, index, 'driverView', e.target.checked)
                    }
                  />
                </StyledTableCell2>
                <StyledTableCell2 align="center">
                  <StyledSwitch2
                    checked={doc.customerView}
                    onChange={(e) =>
                      handleFieldChange(docType, index, 'customerView', e.target.checked)
                    }
                  />
                </StyledTableCell2>
                <StyledTableCell2 align="center">
                  <IconButton onClick={() => handleRemoveFile(docType, index)} sx={{ color: 'red' }}>
                    <img src={iconMap['DeleteIcon']} alt="DeleteIcon" />
                  </IconButton>
                </StyledTableCell2>
              </StyledTableRow2>
            )
        )}
      </StyledTableBody2>
    </StyledTable2>
  );

  const {
    getRootProps: getRootPropsInsurance,
    getInputProps: getInputPropsInsurance,
  } = useDropzone({
    onDrop: handleFileDrop('insuranceAndCustomsDocuments'),
    accept: 'image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx',
    multiple: true,
  });

  const {
    getRootProps: getRootPropsOther,
    getInputProps: getInputPropsOther,
  } = useDropzone({
    onDrop: handleFileDrop('otherDocuments'),
    accept: 'image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx',
    multiple: true,
  });

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Insurance & Customs Documents</Typography>
        </StyledSectionHeader>
        <Grid>
          <Grid {...getRootPropsInsurance()}>
            <input {...getInputPropsInsurance()} type="file" />
            <StyledUploadContainer>
              <StyledUploadIconContainer>
                <IconButton>
                  <img src={iconMap['DocUploadIcon']} alt="DocUploadIcon" />
                </IconButton>
              </StyledUploadIconContainer>
              <StyledUploadText>Drag and Drop or Browse files</StyledUploadText>
            </StyledUploadContainer>
          </Grid>
          {documents.insuranceAndCustomsDocuments.length > 0 && 
          renderDocumentTable('insuranceAndCustomsDocuments', documentTypeOptions, 'Insurance')
        }
        </Grid>
      </StyledFormSection1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Other Documents</Typography>
        </StyledSectionHeader>
        <Grid>
          <Grid {...getRootPropsOther()}>
            <input {...getInputPropsOther()} type="file" />
            <StyledUploadContainer>
              <StyledUploadIconContainer>
                <IconButton>
                  <img src={iconMap['DocUploadIcon']} alt="DocUploadIcon" />
                </IconButton>
              </StyledUploadIconContainer>
              <StyledUploadText>Drag and Drop or Browse files</StyledUploadText>
            </StyledUploadContainer>
          </Grid>
          {documents.otherDocuments.length > 0 && 
          renderDocumentTable('otherDocuments', otherDocumentTypeOptions, 'License')
        }
        </Grid>
      </StyledFormSection1>
    </StyledFormContainer1>
  );
};

ConsignmentDocumentsForm.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default ConsignmentDocumentsForm;
