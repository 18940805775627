/* eslint-disable no-unused-vars */
import { Typography, Checkbox, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  StyledFormContainer,
  StyledInputControlContainer
} from '../../billingPlans/styles/BillingPlansStyles.js'
import ColorPicker from '../../../common/ColorPicker.js'
import PropTypes from 'prop-types'
import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight
} from '../../../../styledComponents.js'
import { cargoType, engineModelTypes } from '../../../../utils/constant.js'
import FormField from '../../../common/FormField.js'
import FormSelect from '../../../common/FormSelect.js'
import FormOptionSelector from '../../../common/FormOptionSelector.js'
import dayjs from 'dayjs'
import { VehicleTypeModel } from '../../../../model/vehicleType.model.js'
import { fetchAllVehicleTypes } from '../../../../services/vehicleTypeNew.service.js'
import FormFieldWithEndAdornment from '../../../common/FormFieldWithEndAdornment.js'

const checkboxTypes = [
  { name: 'Petrol', value: 'Petrol' },
  { name: 'Diesel', value: 'Diesel' }
]

const UnitsForm2 = ({ unit, onFormValueChange, validationErrors }) => {
  const [selectedYear, setSelectedYear] = useState(null)
  const [vehicleTypes, setVehicleTypes] = useState([VehicleTypeModel])
  // const [identificationNumberChecked, setIdentificationNumberChecked] =
  //   useState(!!unit?.vehicle?.VIN);
  // const [licensePlateChecked, setLicensePlateChecked] = useState(
  //   !!unit?.vehicle?.licensePlate
  // );
  // const [isVINFieldEdited, setIsVINFieldEdited] = useState(false);
  // const [isLicensePlateFieldEdited, setIsLicensePlateFieldEdited] =
  // useState(false);
  // useState(false);
  // const [selectYearDate, setSelectYearDate] = useState(
  //   unit?.vehicle.year ? dayjs(unit.vehicle.year) : today
  // );
  const [color, setColor] = useState(unit?.vehicle?.color || '#ff0000')

  useEffect(() => {
    if (unit?.vehicle) {
      const { selectedYear } = unit.vehicle
      setSelectedYear(selectedYear ? dayjs(selectedYear) : null)
    }
  }, [unit])

  useEffect(() => {
    const _fetchAllVehicleTypes = async () => {
      try {
        const data = await fetchAllVehicleTypes()
        setVehicleTypes(data.rows)
      } catch (error) {
        if (error.response.data) {
          console.log(error.response.data.message)
        } else {
          console.log(error.message)
        }
      }
    }
    _fetchAllVehicleTypes()
  }, [])

  const handleFieldChange = async (name, value) => {
    try {
      let updatedUnit = { ...unit }

      if (name.startsWith('vehicle.')) {
        const vehicleField = name.split('.')[1] // Extract the field name after 'vehicle.'
        const subField = name.split('.')[2] // Extract the sub-field name after 'vehicle.field.'

        if (
          vehicleField === 'cargoParameters' ||
          vehicleField === 'engineParameters'
        ) {
          updatedUnit.vehicle = {
            ...updatedUnit.vehicle,
            [vehicleField]: {
              ...updatedUnit.vehicle[vehicleField],
              [subField]: value
            }
          }
        } else if (vehicleField === 'VIN') {
          // Handle VIN field separately
          updatedUnit.vehicle = {
            ...updatedUnit.vehicle,
            VIN: value
          }
        } else if (vehicleField === 'make') {
          // Handle VIN field separately
          updatedUnit.vehicle = {
            ...updatedUnit.vehicle,
            make: value
          }
        } else if (vehicleField === 'model') {
          // Handle VIN field separately
          updatedUnit.vehicle = {
            ...updatedUnit.vehicle,
            model: value
          }
        } else {
          updatedUnit.vehicle = {
            ...updatedUnit.vehicle,
            [vehicleField]: value // For other fields
          }
        }
      } else {
        updatedUnit[name] = value // Handle other fields
      }

      // Update the unit state or perform any other necessary actions
      console.log('IN UPDATED UNIT')
      console.log(updatedUnit)
      onFormValueChange(updatedUnit, name, value, 2) // Pass step index here
    } catch (error) {
      console.error('Error updating field:', error)
    }
  }

  const handleColorChange = (newColor) => {
    setColor(newColor)
    onFormValueChange({
      ...unit,
      vehicle: {
        ...unit.vehicle,
        color: newColor
      }
    })
  }

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General Parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <StyledInputControlContainer>
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                sx={{ padding: "0px", position: "relative", bottom: "3px" }}
              // checked={identificationNumberChecked}
              // onChange={(e) => {
              //   const isChecked = e.target.checked;
              //   setIdentificationNumberChecked(isChecked);
              //   if (!isChecked) {
              //     handleFieldChange("vehicle.VIN", e.target.checked);
              //   }
              // }}
              />
              <Typography variant="label">
                Yes, I have a Vehicle Identification Number(VIN)
              </Typography>
            </Box> */}
            {/* {identificationNumberChecked && ( */}
            <FormField
              placeholder="Enter VIN"
              label="VIN"
              required
              value={unit?.vehicle?.VIN}
              onChange={(e) => handleFieldChange('vehicle.VIN', e.target.value)}
              error={!!validationErrors['vehicle.VIN']}
              errorMessage={validationErrors['vehicle.VIN']}
            />
            {/* )} */}
          </StyledInputControlContainer>
          <StyledInputControlContainer>
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                sx={{ padding: "0px", position: "relative", bottom: "3px" }}
              checked={licensePlateChecked}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setLicensePlateChecked(isChecked);
                if (!isChecked) {
                  handleFieldChange(
                    "vehicle?.licensePlate",
                    e.target.checked
                  );
                }
              }}
              />
              <Typography variant="label">
                Yes, I have a License Plate
              </Typography>
            </Box> */}
            <FormField
              placeholder="Enter License Plate"
              label="License Plate"
              required
              value={unit?.vehicle?.licensePlate}
              onChange={(e) =>
                handleFieldChange('vehicle.licensePlate', e.target.value)
              }
              error={!!validationErrors['vehicle.licensePlate']}
              errorMessage={validationErrors['vehicle.licensePlate']}
            />
          </StyledInputControlContainer>
          <FormSelect
            label="Vehicle Type"
            value={unit?.vehicle?.vehicleTypeId}
            onChange={(selected) => {
              handleFieldChange('vehicle.vehicleTypeId', selected)
            }}
            options={vehicleTypes.map((item) => ({
              value: item.id,
              label: item.name
            }))}
            error={!!validationErrors.vehicleTypeId}
            errorMessage={validationErrors.vehicleTypeId}
          />
          <FormField
            label="Make"
            placeholder="Enter make"
            value={unit?.vehicle?.make}
            onChange={(e) => handleFieldChange('vehicle.make', e.target.value)}
            error={!!validationErrors['vehicle.make']}
            errorMessage={validationErrors['vehicle.make']}
          />
          <FormField
            label="Model"
            placeholder="Enter model"
            value={unit?.vehicle?.model}
            onChange={(e) => handleFieldChange('vehicle.model', e.target.value)}
            error={!!validationErrors?.['vehicle.model']}
            errorMessage={validationErrors['vehicle.model']}
          />
          <FormField
            label="Year"
            placeholder="Enter vehicle year"
            value={unit?.vehicle?.year}
            onChange={(e) => handleFieldChange('vehicle.year', e.target.value)}
            error={!!validationErrors?.['vehicle.year']}
            errorMessage={validationErrors['vehicle.year']}
          />

          <StyledInputControlContainer>
            <Typography item variant="label">
              Pick Any Color in Hex Value
            </Typography>
            <ColorPicker
              defaultColor={color}
              onFormValueChange={handleColorChange}
            />
          </StyledInputControlContainer>
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Engine Parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormSelect
            label="Engine Model"
            value={unit?.vehicle?.engineParameters?.engineModel}
            onChange={(selected) => {
              handleFieldChange(
                'vehicle.engineParameters.engineModel',
                selected
              )
            }}
            options={engineModelTypes.map((item) => ({
              value: item.value,
              label: item.name
            }))}
            error={!!validationErrors['vehicle.engineParameters.engineModel']}
            errorMessage={
              validationErrors['vehicle.engineParameters.engineModel']
            }
          />
          <FormField
            label="Engine Displacement, ccm:"
            placeholder="Enter engine displacement"
            value={unit.vehicle?.engineParameters?.engineDisplacement}
            onChange={(e) =>
              handleFieldChange(
                'vehicle.engineParameters.engineDisplacement',
                e.target.value
              )
            }
            type="text"
            error={
              !!validationErrors['vehicle.engineParameters.engineDisplacement']
            }
            errorMessage={
              validationErrors['vehicle.engineParameters.engineDisplacement']
            }
          />
          <FormFieldWithEndAdornment
            label="Engine Power"
            placeholder="Enter engine power"
            value={unit.vehicle?.engineParameters?.enginePower}
            onChange={(e) =>
              handleFieldChange(
                'vehicle.engineParameters.enginePower',
                e.target.value
              )
            }
            type="number"
            error={!!validationErrors['vehicle.engineParameters.enginePower']}
            errorMessage={
              validationErrors['vehicle.engineParameters.enginePower']
            }
            endAdornmentText="bhp"
          />
          <FormOptionSelector
            label="Fuel Type"
            options={checkboxTypes}
            selectedOption={unit.vehicle?.engineParameters?.fuelType}
            handleOptionChange={(value) =>
              handleFieldChange('vehicle.engineParameters.fuelType', value)
            }
          />
          <FormField
            label="Average CO2 emission, g/km"
            placeholder="Enter CO2 emission"
            value={unit.vehicle?.engineParameters?.averageCO2emission || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange(
                'vehicle.engineParameters.averageCO2emission',
                value
              )
            }}
            type="number"
            error={
              !!validationErrors['vehicle.engineParameters.averageCO2emission']
            }
            errorMessage={
              validationErrors['vehicle.engineParameters.averageCO2emission']
            }
          />
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Cargo Parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormSelect
            label="Cargo Type"
            value={unit.vehicle?.cargoParameters?.cargoType}
            onChange={(selected) => {
              handleFieldChange('vehicle.cargoParameters.cargoType', selected)
            }}
            options={cargoType.map((item) => ({
              value: item.value,
              label: item.name
            }))}
            error={!!validationErrors['vehicle.cargoParameters.cargoType']}
            errorMessage={validationErrors['vehicle.cargoParameters.cargoType']}
          />
          <FormField
            label="Carrying Capacity, t:"
            placeholder="Enter carriying capacity"
            value={unit.vehicle?.cargoParameters?.carryingCapacity}
            onChange={(e) =>
              handleFieldChange(
                'vehicle.cargoParameters.carryingCapacity',
                e.target.value
              )
            }
            type="number"
            error={
              !!validationErrors['vehicle.cargoParameters.carryingCapacity']
            }
            errorMessage={
              validationErrors['vehicle.cargoParameters.carryingCapacity']
            }
          />
          <FormField
            label="Dimensions(WxHxD), mm"
            placeholder="W"
            value={unit.vehicle?.cargoParameters?.width || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('vehicle.cargoParameters.width', value)
            }}
            type="number"
            error={!!validationErrors['vehicle.cargoParameters.width']}
            errorMessage={validationErrors['vehicle.cargoParameters.width']}
          />
          <FormField
            placeholder="H"
            value={unit.vehicle?.cargoParameters?.height || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('vehicle.cargoParameters.height', value)
            }}
            type="number"
            error={!!validationErrors['vehicle.cargoParameters.height']}
            errorMessage={validationErrors['vehicle.cargoParameters.height']}
          />
          <FormField
            placeholder="D"
            value={unit.vehicle?.cargoParameters?.depth || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('vehicle.cargoParameters.depth', value)
            }}
            type="number"
            error={!!validationErrors['vehicle.cargoParameters.depth']}
            errorMessage={validationErrors['vehicle.cargoParameters.depth']}
          />
          <FormField
            label="Useful Volume"
            placeholder="Enter useful volume"
            value={unit.vehicle?.cargoParameters?.usefulVolume || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('vehicle.cargoParameters.usefulVolume', value)
            }}
            type="number"
            error={!!validationErrors['vehicle.cargoParameters.usefulVolume']}
            errorMessage={
              validationErrors['vehicle.cargoParameters.usefulVolume']
            }
          />
          <FormField
            label="Gross Vehicle Weight, t"
            placeholder="Gross Vehicle weight"
            value={unit.vehicle?.cargoParameters?.grossVehicleWeight || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange(
                'vehicle.cargoParameters.grossVehicleWeight',
                value
              )
            }}
            type="number"
            error={
              !!validationErrors['vehicle.cargoParameters.grossVehicleWeight']
            }
            errorMessage={
              validationErrors['vehicle.cargoParameters.grossVehicleWeight']
            }
          />
          <FormField
            label="Number Of Axles"
            placeholder="Enter number of axles"
            value={unit.vehicle?.cargoParameters?.numberOfAxles || ''}
            onChange={(e) => {
              const value =
                e.target.value === '' ? null : parseFloat(e.target.value)
              handleFieldChange('vehicle.cargoParameters.numberOfAxles', value)
            }}
            type="number"
            error={!!validationErrors['vehicle.cargoParameters.numberOfAxles']}
            errorMessage={
              validationErrors['vehicle.cargoParameters.numberOfAxles']
            }
          />
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  )
}

UnitsForm2.propTypes = {
  unit: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired
}

export default UnitsForm2
