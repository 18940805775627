import * as yup from 'yup'

// Define Yup schema for optimized route
export const optimizedRouteSchema = yup.object().shape({
  consignmentId: yup.string().required('Consignment ID is required.'),
  routeName: yup
    .string()
    .required('Route name is required.')
    .trim()
    .min(3, 'Route name must be at least 3 characters long.')
    .max(100, 'Route name cannot exceed 100 characters.'),
  pickup: yup.string().required('Pickup location is required.').trim(),
  delivery: yup.string().required('Delivery location is required.').trim(),
  warnings: yup.array().default([])
})
