/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  LineController,
  BarController,
  DoughnutController,
  PolarAreaController,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
} from "chart.js";
import { Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getUserStatusCounts } from "../../../../services/userNew.service";
import BarChartIcon from "@mui/icons-material/BarChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  StatBox,
  StatItem,
  StatsContainer,
  StyledBox,
  StyledChartContainer,
  StyledChartHeader,
  StyledChartTitle,
  StyledDateButton,
  StyledDateButtonsContainer,
  StyledDatePickerContainer,
  StyledGraphIconButton,
} from "../../../../styledComponents";
import { useLogMessages } from "../../../common/WebsocketContext";
Chart.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  LineController,
  BarController,
  DoughnutController,
  PolarAreaController,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement
);

const UserGraph = (props) => {
  const currentDate = dayjs(); // Get the current date

  // Calculate default start date (1st of current month)
  const defaultStartDate = currentDate.startOf("month");
  // Calculate default end date (last day of current month)
  const defaultEndDate = currentDate.endOf("month");
  const defaultMonthlyStartDate = currentDate.startOf("year");
  const defaultMonthlyEndDate = currentDate.endOf("year");

  const [dateCycle, setDateCycle] = useState("daterange");
  const [graphType, setGraphType] = useState("bar");
  const [websocketMessage, setWebsocketMessage] = useState({});
  const [barTimeRange, setBarTimeRange] = useState({
    from: defaultStartDate,
    to: defaultEndDate,
  });
  // const [polarAreaTimeRange, setPolarAreaTimeRange] = useState('MONTHLY');
  const [userStatusCounts, setUserStatusCounts] = useState([]);
  const { logMessages } = useLogMessages();
  const [uniqueDates, setUniqueDates] = useState([]);
  const [totalCounts, setTotalCounts] = useState({});

  useEffect(() => {
    if (dateCycle == "daterange") {
      setBarTimeRange({ from: defaultStartDate, to: defaultEndDate });
    } else {
      setBarTimeRange({
        from: defaultMonthlyStartDate,
        to: defaultMonthlyEndDate,
      });
    }
  }, [dateCycle]);

  useEffect(() => {
    fetchUserStatusCounts(); // Call this whenever dateCycle or barTimeRange changes
  }, [barTimeRange]);

  // Effect for handling WebSocket messages
  useEffect(() => {
    if (websocketMessage?.type === "USER_STATUS_UPDATE") {
      console.log("Received WebSocket Message:", websocketMessage);
      fetchUserStatusCounts();
    }
  }, [websocketMessage]);

  useEffect(() => {
    setWebsocketMessage(logMessages);
  }, [logMessages]);
  //   try {
  //     // Format the from and to dates if the dateCycle is not "MONTHLY", "QUARTERLY", "HALF-YEARLY", or "YEARLY"
  //     const fromDate = barTimeRange.from
  //       ? dayjs(barTimeRange.from, "DD-MMM-YYYY").format("YYYY-MM-DD")
  //       : "";
  //     const toDate = barTimeRange.to
  //       ? dayjs(barTimeRange.to, "DD-MMM-YYYY").format("YYYY-MM-DD")
  //       : "";

  //     let dateRange = { from: fromDate, to: toDate };

  //     // Conditionally set the dateRange based on the dateCycle
  //     let response;
  //     if (
  //       dateCycle === "MONTHLY" ||
  //       dateCycle === "QUARTERLY" ||
  //       dateCycle === "HALF-YEARLY" ||
  //       dateCycle === "YEARLY"
  //     ) {
  //       // Pass only the interval for these cases
  //       response = await getUserStatusCounts(null, dateCycle);
  //     } else {
  //       // Pass dateRange for other cases
  //       response = await getUserStatusCounts(dateRange, dateCycle);
  //     }

  //     const statusCounts = {};
  //     const uniqueActiveDates = new Set();

  //     response.forEach(({ date, status, count }) => {
  //       let formattedDate;

  //       if (dateCycle === "MONTHLY") {
  //         // Format date by month if we're in "MONTHLY" mode
  //         formattedDate = dayjs(date).format("MMM-YYYY");
  //       } else if (dateCycle === "QUARTERLY") {
  //         // Use the existing date for "QUARTERLY" status
  //         formattedDate = date; // Use the original date format provided by the API
  //       } else if (dateCycle === "HALF-YEARLY") {
  //         // Use the original date format for HALF-YEARLY status
  //         formattedDate = date; // Use the original date string (e.g., "Jan 2024 - Jun 2024")
  //       } else if (dateCycle === "YEARLY") {
  //         // Format date by year
  //         formattedDate = dayjs(date).format("YYYY");
  //       } else {
  //         // Default daily format
  //         formattedDate = dayjs(date).format("DD-MMM-YYYY");
  //       }

  //       if (status === "ACTIVE") {
  //         statusCounts[`ACTIVE_${formattedDate}`] = count;
  //         uniqueActiveDates.add(formattedDate);
  //       } else if (status === "INACTIVE") {
  //         statusCounts[`INACTIVE_${formattedDate}`] = count;
  //         uniqueActiveDates.add(formattedDate);
  //       }
  //     });

  //     setUserStatusCounts(statusCounts);
  //     setUniqueDates(Array.from(uniqueActiveDates));
  //   } catch (error) {
  //     console.error("Error fetching user status counts:", error);
  //   }
  // };

  const fetchUserStatusCounts = async () => {
    try {
      const fromDate = barTimeRange.from
        ? dayjs(barTimeRange.from, "DD-MMM-YYYY").format("YYYY-MM-DD")
        : "";
      const toDate = barTimeRange.to
        ? dayjs(barTimeRange.to, "DD-MMM-YYYY").format("YYYY-MM-DD")
        : "";

      let dateRange = { from: fromDate, to: toDate };

      let apiResponse;
      if (
        dateCycle === "MONTHLY" ||
        dateCycle === "QUARTERLY" ||
        dateCycle === "HALF-YEARLY" ||
        dateCycle === "YEARLY"
      ) {
        apiResponse = await getUserStatusCounts(null, dateCycle);
      } else {
        apiResponse = await getUserStatusCounts(dateRange, dateCycle);
      }

      const { response, totalCounts } = apiResponse || {};
      if (!Array.isArray(response)) {
        console.error("Unexpected response format:", apiResponse);
        return;
      }

      setTotalCounts(totalCounts || {}); // Update state with totalCounts
      const statusCounts = {};
      const uniqueActiveDates = new Set();

      response.forEach(({ date, status, count }) => {
        let formattedDate;
        if (dateCycle === "MONTHLY") {
          formattedDate = dayjs(date).format("MMM-YYYY");
        } else if (dateCycle === "QUARTERLY" || dateCycle === "HALF-YEARLY") {
          formattedDate = date;
        } else if (dateCycle === "YEARLY") {
          formattedDate = dayjs(date).format("YYYY");
        } else {
          formattedDate = dayjs(date).format("DD-MMM-YYYY");
        }

        if (status === "ACTIVE") {
          statusCounts[`ACTIVE_${formattedDate}`] = count;
          uniqueActiveDates.add(formattedDate);
        } else if (status === "INACTIVE") {
          statusCounts[`INACTIVE_${formattedDate}`] = count;
          uniqueActiveDates.add(formattedDate);
        }
      });

      setUserStatusCounts(statusCounts);
      setUniqueDates(Array.from(uniqueActiveDates));
    } catch (error) {
      console.error("Error fetching user status counts:", error);
    }
  };

  const months = [];
  for (let i = 1; i <= 12; i++) {
    // Pad the day with leading zeros if needed
    const month = i < 10 ? "0" + i : "" + i;
    months.push(month);
  }
  const dates = [];
  for (let i = 1; i <= 31; i++) {
    // Pad the day with leading zeros if needed
    const month = i < 10 ? "0" + i : "" + i;
    dates.push(month);
  }
  const activatedYears = [];
  const deactivatedYears = [];

  // Iterate through each key in userStatusCounts
  Object.keys(userStatusCounts).forEach((key) => {
    // Split key to extract status and year
    const [status, year] = key.split("_");
    // Check status and push year to corresponding array
    if (status === "ACTIVE") {
      activatedYears.push(parseInt(year));
    } else if (status === "INACTIVE") {
      deactivatedYears.push(parseInt(year));
    }
  });
  const yearSet = new Set();

  // Iterate through each key in userStatusCounts
  Object.keys(userStatusCounts).forEach((key) => {
    // eslint-disable-next-line no-unused-vars
    const [status, year] = key.split("_");
    // Add year to the Set
    yearSet.add(parseInt(year));
  });

  const getLabels = () => {
    if (dateCycle === "MONTHLY") {
      return uniqueDates.map((date) =>
        dayjs(date, "MMM-YYYY").format("MMM-YYYY")
      );
    }
    return uniqueDates;
  };

  const userDate = {
    labels: getLabels(), // This should return all unique dates
    datasets: [
      {
        label: "",
        data: uniqueDates.map(
          (date) => userStatusCounts[`ACTIVE_${date}`] || 0
        ),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "",
        data: uniqueDates.map(
          (date) => userStatusCounts[`INACTIVE_${date}`] || 0
        ),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false, // Allow chart to adapt to container size
    scales: {
      x: {
        ticks: {
          autoSkip: false, // Don't skip any ticks
          // maxRotation: 90,
          // minRotation: 85,
          maxRotation: dateCycle === "QUARTERLY" ? 0 : 90, // No rotation for QUARTERLY
          minRotation: dateCycle === "QUARTERLY" ? 45 : 85, // No rotation for QUARTERLY
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
    },
  };

  const handleDateCycle = (value) => {
    setDateCycle(value);
  };

  const handleGraphChange = (value) => {
    setGraphType(value);
  };

  const handleBarFromTimeRangeChange = (date) => {
    const formattedDate = date ? date.format("DD-MMM-YYYY") : "";
    setBarTimeRange({ ...barTimeRange, from: formattedDate });
  };

  const handleBarToTimeRangeChange = (date) => {
    const formattedDate = date ? date.format("DD-MMM-YYYY") : "";
    setBarTimeRange({ ...barTimeRange, to: formattedDate });
  };

  return (
    <StyledChartContainer>
      <div className="chart-container">
        <StyledChartHeader>
          <StyledChartTitle>{`${props.title}s`} </StyledChartTitle>
          <Grid display="flex" justifyContent="space-between">
            <Grid item md={4}>
              <StyledGraphIconButton
                color={graphType === "bar" ? "#0A2C47" : ""}
                onClick={() => handleGraphChange("bar")}
              >
                <BarChartIcon />
              </StyledGraphIconButton>
            </Grid>
            <Grid item md={4}>
              <StyledGraphIconButton
                color={graphType === "line" ? "#0A2C47" : ""}
                onClick={() => handleGraphChange("line")}
              >
                <ShowChartIcon />
              </StyledGraphIconButton>
            </Grid>
            {/* <Grid item md={4}>
              <StyledGraphIconButton
                color={graphType === "doughnut" ? "#0A2C47" : ""}
                onClick={() => handleGraphChange("doughnut")}
              >
                <DonutLargeIcon />
              </StyledGraphIconButton>
            </Grid> */}
          </Grid>
        </StyledChartHeader>

        <StyledDateButtonsContainer>
          <StyledDateButton
            variant="outlined"
            active={dateCycle === "daterange"}
            onClick={() => handleDateCycle("daterange")}
          >
            Date Range
          </StyledDateButton>
          <StyledDateButton
            variant="outlined"
            active={dateCycle === "MONTHLY"}
            onClick={() => {
              handleDateCycle("MONTHLY");
            }}
          >
            Monthly
          </StyledDateButton>

          <StyledDateButton
            variant="outlined"
            active={dateCycle === "QUARTERLY"}
            onClick={() => handleDateCycle("QUARTERLY")}
          >
            Quarterly
          </StyledDateButton>
          <StyledDateButton
            variant="outlined"
            active={dateCycle === "HALF-YEARLY"}
            onClick={() => handleDateCycle("HALF-YEARLY")}
          >
            Half-Yearly
          </StyledDateButton>
          <StyledDateButton
            variant="outlined"
            active={dateCycle === "YEARLY"}
            onClick={() => handleDateCycle("YEARLY")}
          >
            Yearly
          </StyledDateButton>
        </StyledDateButtonsContainer>

        {dateCycle === "daterange" && (
          <StyledDatePickerContainer gap={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={barTimeRange.from}
                onChange={(e) => handleBarFromTimeRangeChange(e)}
                format="DD-MMM-YYYY"
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={barTimeRange.to}
                onChange={(e) => handleBarToTimeRangeChange(e)}
                format="DD-MMM-YYYY"
              />
            </LocalizationProvider>
          </StyledDatePickerContainer>
        )}
        <StatsContainer>
          <StatItem>
            <StatBox
              bgColor="rgba(75, 192, 192, 0.2)"
              borderColor="rgba(75, 192, 192, 0.2)"
            />
            Active: <strong>{totalCounts?.Total_ACTIVE || 0}</strong>
          </StatItem>
          <StatItem>
            <StatBox
              bgColor="rgba(255, 99, 132, 0.2)"
              borderColor="rgba(255, 99, 132, 0.2)"
            />
            Inactive: <strong>{totalCounts?.Total_INACTIVE || 0}</strong>
          </StatItem>
        </StatsContainer>

        <StyledBox>
          <div
            style={{
              overflowX: "auto", // Enable horizontal scrolling
              width: "100%",
            }}
          >
            <div
              style={{
                minWidth: `${uniqueDates.length * 60}px`, // Adjust the width based on number of dates
                height: "250px", // Set the height you want for your chart
              }}
            >
              {graphType === "bar" && (
                <Bar data={userDate} options={chartOptions} />
              )}
              {graphType === "line" && (
                <Line data={userDate} options={chartOptions} />
              )}
              {/* {graphType === "doughnut" && (
                <Doughnut data={userDate} options={chartOptions} />
              )} */}
            </div>
          </div>
        </StyledBox>
      </div>
    </StyledChartContainer>
  );
};

export default UserGraph;
